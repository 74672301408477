<template>
  <div class="Precautions_edit">
      <GlobalInfoBar
        title="编辑"
    />
    <GlobalChunk :padding="[32, 0, 95, 55]">
          <GlobalForm
            v-if="this.initData.title"
            :init-data="initData"
            :form-rules="formRules"
            :form-item-list="formItemList"
            @handleConfirm="handleConfirm"
            confirmBtnName="确认修改"
          >
            <el-form-item class="el_from" slot="operation">
                <div class="title">特别说明: 注意事项无需设置图片，可能会影响前端H5页面排版</div>
            </el-form-item>
            <el-form-item class="el_from" label="前端H5页面:" slot="operation" prop="title">
                <el-input
                v-model="initData.title"
                :disabled="disabled"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="el_from" label="保险说明:" slot="operation" prop="content">
              <div style="width:900px">
                <editor
                  @handleEditContent="handleEditContent"
                  @handleSettingEditContent="handleSettingEditContent"
                  type='content'
                ></editor>
                <div style="margin-top:10px;color:#ffaf29">
                  添加后将展示在前端H5对应页面顶部‘注意事项’ 处（字数建议不超过200字，可能将影响排版美观）
                </div>
              </div>
            </el-form-item>
            <el-button slot="btnSlot" @click="FromBack">取消</el-button>
          </GlobalForm>
    </GlobalChunk>
  </div>
</template>

<script>
import editor from '../component/editor'
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "Precautions_edit",
  components:{editor},
  data() {
    return {
      disabled:false,
      initData: {
        title:'',
        content:'',
      },
      formItemList: [
        { slotName: "operation" },
      ],
      formRules: {
        // title: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "保险类型",
        //   }
        // ],
      }
    };
  },
  mounted() {
    if(this.$route.query.id){
      _api.articleInfo({article_id:this.$route.query.id}).then(res=>{
        this.disabled = true
        this.initData.title = res.data.title
        this.initData.content = res.data.content
      })
    }
  },

  methods: {
    // 提交
    handleConfirm(data, cd) {
      cd()
      const vo ={
        article_id:this.$route.query.id,
        content:this.initData.content,
      }
      _api.editArticle(vo).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg)
          this.$router.go(-1);
        }
      })
    },
    FromBack() {
      this.$router.go(-1);
    },
    handleEditContent (html, type) {
				this.initData.content = html
				this.$forceUpdate()
    },
    handleSettingEditContent (set, type) {
      set.html(this.initData.content)
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Precautions_edit {
    .text_item{
        color:#999;
        margin-left: 20px;
    }
    .el_from {
        margin-left: -120px;
    }
    /deep/ .el-input__inner{
        width: 380px;
    }
    /deep/ .el-input{
        width: 380px;
    }
    .item_dia{
        display: flex;
        justify-content: left;
        align-items: center;
        .left{
            width: 60px;
            margin: 0px 0 10px 10px;
            color: black;
        }
        .right{
            margin: 0px 0 10px 10px;
            color: #FF8080;
        }
    }
    .btn_bot{
        float: right;
        margin: 30px;
    }
    .title{
      color: #FF687B;
      margin-left: 28px;
    }
}
</style>
