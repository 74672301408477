import {validateMobile,validateNumber,validateNumberEight,validateAlphabets,validateNumberFloat,validateNumberSeven,validatePassWard} from './validate'

/* 手机号*/
export function formValidateMobile(rule, value, callback) {
    if (!value || validateMobile(value)) {
        callback()
    } else {
        callback(new Error('请输入正确的手机号码'))
    }
}

// 数字年限
export function formValidateNumber(rule, value, callback) {
    if (!value || validateNumber(value)) {
        callback()
    } else {
        callback(new Error('请输入正确年限（50以内的正整数）'))
    }
}


export function formValidateNumberEight(rule, value, callback) {
    if (!value || validateNumberEight(value)) {
        callback()
    } else {
        callback(new Error('输入大于0的正整数且在5位数内'))
    }
}



export function formValidateAlphabets(rule, value, callback) {
    if (!value || validateAlphabets(value)) {
        callback()
    } else {
        callback(new Error('请输入大小写字母'))
    }
}


export function formValidateNumberFloat(rule, value, callback) {
    if (!value || validateNumberFloat(value)) {
        callback()
    } else {
        callback(new Error('请输入大于0小于1000000的数字（最多支持2位小数）'))
    }
}

export function formValidateNumberSeven(rule, value, callback) {
    if (!value || validateNumberSeven(value)) {
        callback()
    } else {
        callback(new Error('请输入大于0小于1000000的数字（最多支持2位小数）'))
    }
}


export function formValidatePassWard(rule, value, callback) {
    if (!value || validatePassWard(value)) {
        callback()
    } else {
        callback(new Error('请输入数字或26个英文'))
    }
}

