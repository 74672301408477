<template>
  <div>
    <GlobalInfoBar :title="this.$route.query.pass_id?'编辑通道':'新增通道'"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <el-form ref="form" :model="form" label-width="150px" size="medium" :rules="rules">
        <el-form-item label="通道名称" prop="pass_name">
          <el-input v-model="form.pass_name" placeholder="请输入运营渠道，最多15个字" maxlength="15"></el-input>
        </el-form-item>

        <el-form-item label="宣传图片" prop="imgList">
          <div class="imgs">
            <p class="note">建议使用686*920 px（待定）大小的图片，最多上传3张图片，单张图片不能超过2M，仅支持jpg、png格式。</p>
            <div class="uploader-wrapper">
              <div class="img-wrapper" v-for="(img,index) in form.imgList" :key="index" draggable="true"
                   @dragstart="dragstart(index)" @dragend="dragEnd" @dragover="dragover"
                   :id="'img-'+index">
                <div class="drag-notice" v-if="form.imgList.length>1">拖拽排序</div>
                <el-image fit="contain" class="img" :src="img" alt="" :preview-src-list="form.imgList" @click.stop="closePreview"></el-image>
                <span class="delete-img-icon el-icon-error" @click="deleteImg(index)"></span>
              </div>
              <el-upload
                  ref="uploader"
                  v-if="form.imgList.length<3"
                  action="#"
                  :limit="1"
                  list-type="picture-card"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="getFile">
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
            <p class="note2">提交后，图片按照从左到右的顺序分别在H5链接首页从上到下拼接展示</p>
          </div>
        </el-form-item>

        <el-form-item label="通道H5链接" prop="pass_url">
          <el-input v-model="form.pass_url" placeholder="提交后系统自动生成" disabled></el-input>
        </el-form-item>

        <el-form-item label="所属合伙人" prop="partner_id">
          <el-select v-model="form.partner_id" placeholder="请选择">
            <el-option
                v-for="item in partnerList"
                :key="item.partner_id"
                :label="item.partner_name"
                :value="item.partner_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开启手机号验证" prop="is_sms ">
          <el-switch v-model="form.is_sms" :active-value="1" :inactive-value="0" disabled></el-switch>
        </el-form-item>

        <el-form-item label="开启支付" prop="pay ">
          <el-switch v-model="form.is_pay" :active-value="1" :inactive-value="0" ></el-switch>
        </el-form-item>

        <el-form-item label="开启使用优惠券" prop="coupon ">
          <el-switch v-model="form.coupon" :active-value="1" :inactive-value="0" disabled></el-switch>
        </el-form-item>

        <el-form-item label="付款二维码" prop="qrImg">
          <div class="img-wrapper" v-if="form.make_qr">
            <span class="delete-img-icon el-icon-error" @click="deleteQrImg"></span>
            <el-image fit="contain" class="img" :src="form.make_qr" alt="" :preview-src-list="[form.make_qr]" @click.stop="closePreview"></el-image>
          </div>
         <div class="qr-img-upload">
           <el-upload
               ref="uploader2"
               action="#"
               :limit="1"
               v-if="!form.make_qr"
               list-type="picture-card"
               :auto-upload="false"
               :show-file-list="false"
               :file-list="[form.make_qr]"
               :on-change="getFileQrImg">
             <i slot="default" class="el-icon-plus"></i>
           </el-upload>
         </div>
        </el-form-item>

        <el-form-item label="服务协议名称" prop="title">
          <el-input v-model="form.title" placeholder="请填写服务协议名称"></el-input>
        </el-form-item>
        <el-form-item label="服务协议内容" prop="agreement">
          <!--          <el-input v-model="form.agreement" ></el-input>-->
          <editor
              @handleEditContent="editAgreement"
              @handleSettingEditContent="settingEditAgreement"
              type='content'
              :inintTxt="form.agreement"
          ></editor>
        </el-form-item>

        <div style="margin-left: 150px;">
          <el-button type="primary" plain size="medium" @click="$router.go(-1)">返回</el-button>
          <el-button type="primary" size="medium" @click="confirm">提交</el-button>
        </div>
      </el-form>
    </GlobalChunk>
  </div>
</template>

<script>
import {
  addEditPassageway,
  imageUp, partnerList,
  passagewayInfo, selectPartner,
  selectPass
} from "../../../utils/request/changePhone";
import axios from "axios";
import editor from '@/views/safety/component/editor.vue'

export default {
  name: "operate",
  components: {editor},
  data() {
    let igmsValidate = (rule, value, callback) => {
      let imgs = this.form.imgList
      if (!imgs || (Array.isArray(imgs) && !imgs.length)) {
        callback('请上传宣传图片')
        return
      }
      callback()
    };
    let agreementValidate = (rule, value, callback) => {
      if (!this.agreement) {
        callback('请填写服务协议内容')
        return
      }
      callback()
    };
    return {
      form: {
        pass_name: '',
        imgList: [],
        is_sms: 1,
        is_pay:0,
        title: '',
        agreement: '',
        partner_id:'',
        make_qr:''
      },
      agreement: '',
      channelList: [],
      partnerList: [],
      rules: {
        pass_name: [{required: true, message: '请填写通道名称', trigger: 'change'}],
        imgList: [{required: true, validator: igmsValidate, trigger: 'change'}],
        title: [{required: true, message: '请填写协议名称', trigger: 'change'}],
        agreement: [{required: true, validator:agreementValidate, trigger: 'change'}],
      },
      dragTargetIndex: null,
      dragStartIndex: null
    }
  },
  mounted() {
    if (this.$route.query.pass_id) {
      this.getChannelDetail()
    }
    this.getChannelList();
    this.getPartnerList()
  },
  methods: {
    getChannelDetail() {
      passagewayInfo({
        pass_id: this.$route.query.pass_id
      }).then(res => {
        let data = res.data;
        if (data) {
          this.form = data
          let imgList=[]
          if (data.img1) {
            imgList.push(data.img1);
          }
          if (data.img2) {
            imgList.push(data.img2);
          }
          if (data.img3) {
            imgList.push(data.img3);
          }
          this.$set(this.form,'imgList',imgList)
        }
      })
    },
    getChannelList() {
      selectPass().then(res => {
        this.channelList = res.data || []
      })
    },
    getPartnerList() {
      selectPartner().then(res=>{
        this.partnerList=res.data||[]
      })
    },
    genCoordinate() {
      if (!this.form.region || !this.form.region.length) {
        this.$message.error('请选择地区')
        return
      }
      if (!this.form.address) {
        this.$message.error('请输入详细地址')
        return
      }
      let key = process.env.VUE_APP_MAP_KEY;
      let region = this.$refs.cascader.inputValue.split(' / ')
      let address = `${region[0]}${region[1]}${region[2]}${this.form.address}`
      axios.get(`http://restapi.amap.com/v3/geocode/geo?key=${key}&s=rsv3&city=35&address=${address}`).then(res => {
        console.log(res.data, '得到经纬度')
        let location = res && res.data && res.data.geocodes && res.data.geocodes[0].location || ''
        let lon = location.split(',')[0]
        let lat = location.split(',')[1]

        this.form.lon = lon
        this.form.lat = lat
      }).catch(err => {
        this.$message.error('无法获取坐标，请核实位置信息')
      })
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          addEditPassageway({
            pass_id: this.form.pass_id,
            pass_name: this.form.pass_name,
            img1: this.form.imgList[0]||'',
            img2: this.form.imgList[1]||'',
            img3: this.form.imgList[2]||'',
            title: this.form.title,
            partner_id:this.form.partner_id,
            // is_sms: this.form.is_sms,
            is_sms: 1,
            is_pay:this.form.is_pay,
            agreement: this.agreement,
            make_qr:this.form.make_qr
          }).then(data => {
            this.$router.go(-1)
            this.$message.success(data && data.msg || '操作成功')
          }).catch(err => {
            console.error(err)
          })
        }
      })
    },
    getFile(file) {
      let loadingInstance = this.$loading({
        target: '.imgs'
      })
      this.$refs.uploader.clearFiles()
      let formData = new FormData();
      formData.append('file', file.raw)
      imageUp(formData).then(res => {
        this.form.imgList.push(res.data.url)
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        loadingInstance.close()
      })
    },
    getFileQrImg(file) {
      let loadingInstance = this.$loading({
        target: '.qr-img-upload'
      })
      this.$refs.uploader2.clearFiles()
      let formData = new FormData();
      formData.append('file', file.raw)
      imageUp(formData).then(res => {
        this.form.make_qr=res.data.url
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        loadingInstance.close()
      })
    },
    dragEnd(e) {
      let imgList = JSON.parse(JSON.stringify(this.form.imgList))
      let temp = imgList[this.dragTargetIndex]
      imgList[this.dragTargetIndex] = imgList[this.dragStartIndex]
      imgList[this.dragStartIndex] = temp
      this.form.imgList = imgList
    },
    dragstart(index) {
      this.dragStartIndex = index
    },
    dragover(e) {
      let target;
      e.path.forEach(item => {
        if (item && item.className && item.className.indexOf('img-wrapper') !== -1) {
          target = item
        }
      })
      let targetId = target.id
      this.dragTargetIndex = targetId.replace('img-', '')
    },
    deleteImg(index) {
      this.$delete(this.form.imgList, index)
    },
    deleteQrImg() {
      this.form.make_qr=''
    },
    editAgreement(html) {
      this.agreement = html
      this.$forceUpdate()
    },
    settingEditAgreement(set, type) {
      set.html(this.agreement)
    },
    closePreview() {
      // 获取遮罩层dom
      setTimeout(function(){
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if(e.target.parentNode.className === 'el-image-viewer__actions__inner') {
            return;  //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      },300)
    }
  }
}
</script>

<style scoped>
.el-input, .el-cascader, .el-select {
  width: 420px;
}

.imgs {
  border: 1px solid #E4ECFD;
  border-radius: 3px;
  padding: 10px 20px;
  width: 620px;
}

.note {
  font-size: 12px;
  color: red;
}

.note2 {
  font-size: 12px;
  color: #999
}

.img-wrapper {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  position: relative;
  border: 1px dashed #c0ccda;
  border-radius: 5px;
}

.img-wrapper:hover {
  border: 1px dashed #0981FF;

}

.img-wrapper:hover .drag-notice {
  display: block;
}

.drag-notice {
  display: none;
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #00000055;
  z-index: 99;
  line-height: 30px;
  text-align: center;
  color: #fff;
}

.img-wrapper:hover .delete-img-icon {
  display: block;
}

.delete-img-icon {
  position: absolute;
  color: red;
  right: -10px;
  top: -10px;
  font-size: 30px;
  display: none;
  z-index: 999;
}

.img {
  width: 148px;
  height: 148px;
}

.uploader-wrapper {
  display: flex;
}
::v-deep .el-image-viewer__wrapper {
  z-index: 99999;
}
::v-deep .el-select-dropdown{
  z-index: 99999;
}
</style>
<style>
.el-image-viewer__wrapper {
  z-index: 99999!important;
}
.el-select-dropdown{
  z-index: 99999!important;

}
</style>
