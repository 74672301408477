<template>
  <div class="planNum_list">
    <GlobalInfoBar title="会员列表" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="70"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="头像" slot="headimg" align="center">
        <template slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px; border-radius: 50%"
            :src="row.headimg"
            :preview-src-list="[row.headimg]"
          >
          </el-image>
        </template>
      </el-table-column>
    </GlobalTable>
  </div>
</template>

<script>
import { usersList } from "../../../utils/request/users";
export default {
  name: "usersList",
  data() {
    return {
      initData: null,
      formItemList: [
        {
          key: "phone",
          type: "input",
          labelName: "手机号:",
          placeholder: "请输入手机号查询",
        },
      ],
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      phone: "",
      tableColumns: [
        { slotName: "headimg" },
        { label: "用户姓名", prop: "name" },
        { label: "手机号", prop: "phone" },
        { label: "积分", prop: "points" },
        { label: "总累计积分", prop: "total_points" },
      ],
    };
  },
  mounted() {
    this.PostPlanList();
  },
  methods: {
    //列表请求
    PostPlanList() {
      const params = {
        page: this.PlanIpageNum,
        limit: this.PlanIpageSize,
        phone: this.phone,
      };
      usersList(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostPlanList();
    },
    handleChange(val) {
      console.log(val);
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      this.phone = data.phone;
      this.prefix = data.prefix;
      const params = {
        page: 1,
        limit: 10,
        phone: data.phone,
      };
      usersList(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  /deep/ .el-form-item__label {
    width: 100px !important;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r {
    float: right;
    margin-right: 80px;
  }
}
.icon {
  cursor: pointer;
  margin-right: 20px;
}
</style>
