<template>
  <div class="Carmi_generat">
    <GlobalInfoBar title="生成卡密" />

    <GlobalChunk :icon="DetailIMG" :title="titleDetail" :padding="[32, 0, 95, 55]">
      <GlobalForm :init-data="initData" :form-rules="formRules" :form-item-list="formItemList"
        @handleConfirm="handleConfirm" confirmBtnName="确认生成">
        <el-form-item class="el_from" slot="operation">
          <div style="color:#FF8080;margin-left:20px">
            说明: 带星号的为必填项，生成卡密完成制卡后请及时邮寄给对应合作商家。
          </div>
        </el-form-item>
        <el-form-item class="el_from" label="选择险种类型" slot="operation" prop="ins_type">
          <el-select v-model="initData.ins_type" placeholder="请选择险种类型" clearable @change="handleChange2">
            <el-option v-for="item in options2" :key="item.instype_id" :label="item.instype_name"
              :value="item.instype_id"></el-option>
          </el-select>
          <span class="text_item">险种类型系统定义为: 碎屏险、全面保、换机保、年年焕新保</span>
        </el-form-item>

        <el-form-item class="el_from" label="选择子险种" slot="operation" prop="goods_id">
          <el-select v-model="initData.goods_id" placeholder="请选择子险种" clearable filterable @change="handleChange1">
            <el-option v-for="item in options1" :key="item.goods_id" :label="item.goods_name"
              :value="item.goods_id"></el-option>
          </el-select>
          <span class="text_item">如未创建子险种，可点击
            <span style="color:#0981FF;cursor:pointer;border-bottom:1px solid #0981FF;"
              @click="$router.push('/insurance/list')">创建子险种</span>
            子险种与子方案号、卡号前缀需相互关联</span>
        </el-form-item>

        <el-form-item class="el_from" label="生成数量" slot="operation" prop="num">
          <el-input v-model="initData.num" placeholder="请输入本次生成的数量（单次生成上限10000个）" clearable></el-input>
          <span class="text_item">卡号示例:
            <span style="color:#FF8080;">JKLE20219999</span>
            (规则说明:子险种前缀+8位递增自然数) 卡密示例:
            <span style="color:#FF8080">8956258</span>
            (规则说明:七位数随机自然数)</span>
        </el-form-item>
        <el-form-item class="el_from" label="类型" slot="operation" prop="virtually">
          <el-radio-group v-model="initData.virtually">
            <el-radio :label="1">虚拟卡</el-radio>
            <el-radio :label="0">实体卡</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-button slot="btnSlot" @click="FromBack">取消</el-button>
      </GlobalForm>
    </GlobalChunk>
    <el-dialog title="信息确认" :visible="visible" width="30%" @close="visible = false">
      <div class="item_dia">
        <div class="left">生成数量:</div>
        <div class="right">{{ initData.num }}</div>
      </div>
      <div class="item_dia">
        <div class="left">子 险 种:</div>
        <div class="right">{{ this.goods_name }}</div>
      </div>
      <div class="item_dia">
        <div class="left">卡密前缀:</div>
        <div class="right">{{ this.prefix }}</div>
      </div>
      <div class="item_dia">
        <div class="left">起始卡号:</div>
        <div class="right">{{ this.beg_card_num }}</div>
      </div>
      <div class="item_dia">
        <div class="left">截止卡号:</div>
        <div class="right">{{ this.end_card_num }}</div>
      </div>
      <div class="item_dia">
        <div class="left">卡密效期:</div>
        <div class="right">{{ this.valid_stage }}</div>
      </div>
      <div class="btn_bot">
        <el-button size="small" type="primary" @click="submit">确认生成</el-button>
        <el-button size="small" @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "Carmi_generat",
  data() {
    return {
      fullscreenLoading: false,
      visible: false,
      titleDetail: "",
      DetailIMG: "",
      options1: [],
      options2: [],
      prefix: "", //前缀
      beg_card_num: "", //起始卡号
      end_card_num: "", //截止卡号
      valid_stage: "", //年限
      goods_name: "",
      initData: {
        num: "",
        ins_type: "",
        goods_id: "",
        virtually:""
      },
      formItemList: [{ slotName: "operation" }],
      formRules: {
        num: [
          {
            required: true,
            trigger: "blur",
            message: "输入生成数量",
          },
        ],
        ins_type: [
          {
            required: true,
            trigger: "blur",
            message: "请选择选择险种类型",
          },
        ],
        goods_id: [
          {
            required: true,
            trigger: "blur",
            message: "请选择子险种",
          },
        ],
        virtually: [
          {
            required: true,
            trigger: "blur",
            message: "请选择类型",
          },
        ],
      },
    };
  },
  created() { },
  mounted() {
    this.pullCardGoods();
    this.pullInsTypeList();
  },

  methods: {
    // 选择
    handleChange1(val) {
      console.log(val);
      this.initData.goods_id = val;
      const obj = this.options1.find((item) => {
        return item.goods_id === val;
      });
      console.log(obj);
      this.goods_name = obj.goods_name;
    },
    handleChange2(val) {
      this.initData.ins_type = val;
    },
    // 子险种下拉
    pullCardGoods() {
      _api.pullCardGoods().then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.options1 = res.data;
        }
      });
    },
    // 保险类型下拉
    pullInsTypeList() {
      _api.pullInsTypeList().then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.options2 = res.data;
        }
      });
    },
    // 提交
    handleConfirm(data, cd) {
      cd();
      const vo = {
        num: this.initData.num,
        goods_id: this.initData.goods_id,
      };
      console.log(vo);
      _api.checkCardNum(vo).then((res) => {
        if (res.code === 1) {
          this.visible = true;
          this.prefix = res.data.prefix;
          this.beg_card_num = res.data.beg_card_num;
          this.end_card_num = res.data.end_card_num;
          this.valid_stage = res.data.valid_stage;
        }
      });
    },
    submit() {
      const vo = {
        num: this.initData.num,
        ins_type: this.initData.ins_type,
        goods_id: this.initData.goods_id,
        virtually: this.initData.virtually,
      };
      const loading = this.$loading({
        lock: true,
        text: "正在生成卡号及卡密，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        _api.addCardPool(vo).then((res) => {
          console.log(res);
          if (res.code === 1) {
            loading.close();
            this.visible = false;
            this.$message.success(res.msg);
          } else {
            loading.close();
          }
        });
      }, 2000);
    },
    FromBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Carmi_generat {
  .text_item {
    color: #999;
    margin-left: 20px;
  }

  .el_from {
    margin-left: -120px;
  }

  /deep/ .el-input__inner {
    width: 380px;
  }

  /deep/ .el-input {
    width: 380px;
  }

  .item_dia {
    display: flex;
    justify-content: left;
    align-items: center;

    .left {
      width: 60px;
      margin: 0px 0 10px 10px;
      color: black;
    }

    .right {
      margin: 0px 0 10px 10px;
      color: #ff8080;
    }
  }

  .btn_bot {
    float: right;
    margin: 30px;
  }
}
</style>
