<template>
  <div class="data-output">
    <div class="title">
      <span><img src="@/assets/images/data_output.png" alt="" />数据导出</span>
      <span style="margin-left: 50px">
        <el-radio v-model="timeType" :label="1">申请时间</el-radio>
        <el-radio v-model="timeType" :label="2">审核时间</el-radio>
      </span>
    </div>
    <div class="types">
      <div class="type" v-for="(type, index) in types" :key="type.name">
        <div class="order-name">{{ type.name }}数据导出</div>
        <div class="item">
          <div class="output-type">单日导出</div>
          <el-date-picker
            value-format="yyyy-MM-dd"
            size="small"
            v-model="type.singleDay"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-button :loading="downloadLoading" @click="output(1, index)" size="small" type="primary"
            >导出</el-button
          >
          <el-button @click="output(1, index, true)" size="small" type="primary"
            >查看</el-button
          >
        </div>
        <div class="item">
          <div class="output-type">按日期导出</div>
          <el-date-picker
            value-format="yyyy-MM-dd"
            size="small"
            v-model="type.range"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button :loading="downloadLoading" @click="output(2, index)" size="small" type="primary"
            >导出</el-button
          >
          <el-button @click="output(2, index, true)" size="small" type="primary"
            >查看</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/request";

export default {
  name: "index",
  data() {
    return {
      timeType: 1,
      downloadLoading: false,
      types: [
        // 注册
        {
          name: "专属权益注册",
          singleDay: "",
          range: [],
          id: 33,
        },
        {
          name: "亚丁Care+注册",
          singleDay: "",
          range: [],
          id: 32,
        },
        {
          name: "智选苹果售后注册",
          singleDay: "",
          range: [],
          id: 20,
        },
        {
          name: "亚丁智选保障计划注册",
          singleDay: "",
          range: [],
          id: 15,
        },
        {
          name: "智选华为售后注册",
          singleDay: "",
          range: [],
          id: 16,
        },
        {
          name: "碎屏保注册",
          singleDay: "",
          range: [],
          id: 1,
        },
        {
          name: "全面保注册",
          singleDay: "",
          range: [],
          id: 2,
        },
        {
          name: "换机保注册",
          singleDay: "",
          range: [],
          id: 3,
        },
        {
          name: "年年焕新保注册",
          singleDay: "",
          range: [],
          id: 4,
        },
        {
          name: "延保服务注册",
          singleDay: "",
          range: [],
          id: 22,
        },
        {
          name: "电池险注册",
          singleDay: "",
          range: [],
          id: 25,
        },
        // 新增up+
        {
          name: "up+全心服务",
          singleDay: "",
          range: [],
          id: 27,
        },
        {
          name: "后盾保障计划",
          singleDay: "",
          range: [],
          id: 30,
        },
        // 理赔
        {
          name: "专属权益理赔",
          singleDay: "",
          range: [],
          id: 34,
        },
        {
          name: "后盾保障理赔",
          singleDay: "",
          range: [],
          id: 31,
        },
        {
          name: "智选苹果售后理赔",
          singleDay: "",
          range: [],
          id: 21,
        },
        {
          name: "亚丁智选理赔",
          singleDay: "",
          range: [],
          id: 17,
        },
        {
          name: "智选华为售后理赔",
          singleDay: "",
          range: [],
          id: 19,
        },
        {
          name: "碎屏保理赔",
          singleDay: "",
          range: [],
          id: 5,
        },
        {
          name: "全面保理赔",
          singleDay: "",
          range: [],
          id: 6,
        },
        {
          name: "换机保理赔",
          singleDay: "",
          range: [],
          id: 7,
        },
        {
          name: "年年焕新保理赔",
          singleDay: "",
          range: [],
          id: 8,
        },
        {
          name: "延保服务理赔",
          singleDay: "",
          range: [],
          id: 23,
        },
        {
          name: "电池险理赔",
          singleDay: "",
          range: [],
          id: 26,
        },
        // 新增up+
        {
          name: "up+全心服务理赔",
          singleDay: "",
          range: [],
          id: 28,
        },
        {
          name: "维修商家订单照",
          singleDay: "",
          range: [],
          id: 9,
        },
        {
          name: "IMEI更换申请",
          singleDay: "",
          range: [],
          id: 10,
        },
        {
          name: "照片补交",
          singleDay: "",
          range: [],
          id: 18,
        },
        {
          name: "全面保换机完成照",
          singleDay: "",
          range: [],
          id: 11,
        },
        {
          name: "延保换机完成照",
          singleDay: "",
          range: [],
          id: 35,
        },
        {
          name: "换机保换机完成照",
          singleDay: "",
          range: [],
          id: 12,
        },
        {
          name: "安卓年年焕新换机完成照",
          singleDay: "",
          range: [],
          id: 13,
        },
        {
          name: "苹果年年焕新换机完成照",
          singleDay: "",
          range: [],
          id: 14,
        },
        {
          name: "智选换机完成照",
          singleDay: "",
          range: [],
          id: 24,
        },
        // 新增up+
        {
          name: "up+换机完成照",
          singleDay: "",
          range: [],
          id: 29,
        },
      ],
    };
  },
  /*
   * *  1 碎屏保注册 2 全面保注册 3 换机保注册 4 年年焕新保注册
   *  5 碎屏保理赔 6 全面保理赔 7 换机保理赔 8 年年焕新保理赔
   *  9 维修商家订单 10 IMEI更换申请 11 全面保换机完成照 12 换机保换机完成照 13 安卓年年焕新换机完成照 14苹果年年焕新换机完成照
   * 2021-10-11更新
   *15 亚丁智选注册
   *16 智选华为售后注册
   * 17 亚丁智选理赔
   * 18 -照片补交
   * 19 智选华为售后理赔*/
  methods: {
    output(outputType, index, isView = false) {
      let type = this.types[index];
      let params = {
        type: outputType,
        mold: type.id,
        time_type: this.timeType,
      };
      if (outputType === 1) {
        if (!type.singleDay) {
          this.$message.error("请选择单日导出时间");
          return;
        }
        params.add_date = type.singleDay;
      } else {
        if (!type.range || type.range.length !== 2) {
          this.$message.error("请选择按日期导出时间");
          return;
        }
        params.beg_date = type.range[0];
        params.end_date = type.range[1];
      }
      if (isView) {
        open(
          this.$router.resolve({
            path: "/dataOutput/table",
            query: params,
          }).href
        );
      } else {
        this.downloadLoading = true
        api.exportExcel(params).then((res) => {
          if (res.type === "application/json") {
            let fileReader = new FileReader();
            fileReader.readAsText(res);
            fileReader.addEventListener("loadend", (e) => {
              let err = JSON.parse(fileReader.result || "");
              this.$message.error(err.msg || "导出失败");
            });
          } else {
            var blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            }); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
            var downloadElement = document.createElement("a");
            var href = window.URL.createObjectURL(blob); // 创建下载的链接
            downloadElement.href = href;
            downloadElement.download = `${type.name}${-new Date()}.xls`; // 下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); // 点击下载
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
          }
        }).finally(() => {
          this.downloadLoading = false
        });
      }
    },
  },
};
</script>

<style scoped>
.data-output {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.title {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e3e9f1;
  background: #ffffff;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  padding-left: 30px;
}

.title img {
  margin-right: 3px;
}

.types {
  display: flex;
  flex-wrap: wrap;
  /*justify-content: space-between;*/
  padding: 20px 20px 0;
}

.type {
  width: calc((100% - 40px) / 3);
  margin-right: 20px;
  background: #f1f3ff;
  margin-bottom: 20px;
  height: 170px;
  border: 1px solid #e0e4ff;
  border-radius: 4px;
  padding: 15px 20px 20px;
}

.type:nth-child(3n) {
  margin-right: 0;
}

.order-name {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.item {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.output-type {
  min-width: 70px;
  text-align: right;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

::v-deep .el-date-editor--date {
  margin: 0 20px;
}

::v-deep .el-date-editor--daterange {
  margin: 0 20px;
}

::v-deep .el-range-separator {
  width: 40px;
}

/*::v-deep .el-date-editor--date .el-input__inner {*/
/*  padding-left: 50px;*/
/*}*/
::v-deep .el-button--small {
  padding: 8px 15px;
}
</style>
