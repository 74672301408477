<template>
  <div class="planNum_list">
    <GlobalInfoBar title="发送红包记录" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="70"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
      label-width="80px"
        class="el_from"
        label="选择时间:"
        slot="is_push_state_time"
        style="margin: 0"
      >
        <el-date-picker
          v-model="stateTime"
          type="daterange"
          value-format="timestamp"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
    </GlobalTable>
    <el-dialog :visible.sync="visible" :title="title" width="600px">
      <div v-if="this.title === '新增子险种'" class="tit_color">
        说明:子险种名称不能重复添加，名称长度限制50字内
      </div>
      <div v-else class="tit_color">
        说明: 修改前请仔细核对信息，否则将影响生成卡密
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="子险种名称" prop="goods_name">
          <el-input
            v-model="ruleForm.goods_name"
            placeholder="请输入子险种名称"
            :maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="关联子方案号" prop="zifang_id">
          <!-- <el-select v-model="ruleForm.zifang_id" placeholder="请关联子方案号（如无数据请先创建）">
            <el-option label="1年" value="1年"></el-option>
            <el-option label="2年" value="2年"></el-option>
          </el-select> -->
          <el-select
            v-model="ruleForm.zifang_id"
            placeholder="请关联子方案号（如无数据请先创建）"
            clearable
            @change="handleChange"
          >
            <el-option
              v-for="item in GroupList"
              :key="item.zifang_id"
              :label="item.zifang_name"
              :value="item.zifang_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_form" label="卡号前缀" prop="prefix">
          <el-input
            v-model="ruleForm.prefix"
            placeholder="请输入卡号前缀（4位大写或小写字母）"
            :maxlength="4"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form_r">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 'add')"
            v-if="this.title === '新增子险种'"
            >确认新增</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 'edit')"
            v-else
            >确认修改</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { redLog } from "../../../utils/request/users";
import { formValidateAlphabets } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "planNum_list",
  data() {
    return {
      //弹窗参数
      visible: false,
      title: "",
      ruleForm: {
        zifang_id: "",
        goods_name: "",
        prefix: "",
        goods_id: "", //	是	int	0新增 大于0：修改
      },
      rules: {
        goods_name: [
          { required: true, message: "请填写子险种号名称", trigger: "blur" },
        ],
        zifang_id: [
          { required: true, message: "请选择关联子方案号", trigger: "blur" },
        ],
        prefix: [
          { required: true, trigger: "blur", validator: formValidateAlphabets },
        ],
      },
      //列表
      GroupList: [],
      initData: null,
      formItemList: [
      {
          key: "phone",
          type: "input",
          labelName: "手机号:",
          placeholder: "请输入手机号查询",
        },
        { slotName: "is_push_state_time" },
      ],
      stateTime: null,
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      phone:'',
      tableColumns: [
        { label: "手机号", prop: "phone" },
        { label: "金额", prop: "red_money" },
        { label: "备注", prop: "remarks" },
        { label: "时间", prop: "add_time" },
        { label: "操作人", prop: "user_name" },
        { label: "状态", prop: "status" },
        { label: "发送红包来源", prop: "model_title" },
      ],
    };
  },
  mounted() {
    this.PostPlanList();
    _api.pullZifangan().then((res) => {
      console.log(res);
      this.GroupList = res.data;
    });
  },
  methods: {
    //列表请求
    PostPlanList() {
      const params = {
        page: this.PlanIpageNum,
        limit: this.PlanIpageSize,
        phone: this.phone,
        beg_time: this.stateTime!==null?this.stateTime[0]:'',
        end_time: this.stateTime!==null?this.stateTime[1]:'',
      };
      redLog(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostPlanList();
    },
    handleChange(val) {
      console.log(val);
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data",this.stateTime);
      this.phone=data.phone
      const params = {
        page: 1,
        limit: 10,
        phone: data.phone,
        beg_time: this.stateTime!==null?this.stateTime[0]:'',
        end_time: this.stateTime!==null?this.stateTime[1]:'',
      };
      redLog(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    addPlan() {
      this.ruleForm.goods_name = "";
      this.ruleForm.prefix = "";
      this.ruleForm.zifang_id = "";
      this.visible = true;
      this.title = "新增子险种";
    },
    //修改
    editPlan(row, type) {
      console.log(row);
      this.visible = true;
      this.title = "修改子险种";
      this.ruleForm.goods_name = row.goods_name;
      this.ruleForm.prefix = row.prefix;
      this.ruleForm.zifang_id = row.zifang_id;
      this.ruleForm.goods_id = row.goods_id;
    },
    submitForm(formName, type) {
      if (type === "add") {
        this.ruleForm.goods_id = 0;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.addCardGoods(this.ruleForm).then((res) => {
            console.log(res);
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.PostPlanList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  /deep/ .el-form-item__label {
    width: 100px !important;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r {
    float: right;
    margin-right: 80px;
  }
}
.icon {
  cursor: pointer;
  margin-right: 20px;
}
</style>
