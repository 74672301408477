module.exports = {
    id: 13,
    name: "维修商家订单",
    type: "maintain",
    form: [
        {
            label: "出险产品",
            prop: "instype_name",
            obj: "order",
            type: "input",
            append: "viewOrder"
        },
        {
            label: "维修编号",
            prop: "finish_sn",
            obj: "order",
            type: "input"
        },
        {
            label: "IMEI码",
            prop: "imei",
            obj: "order",
            type: "input"
        },
        {
            label: "维修完成照片",
            prop: "tem_img",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "维修工单照片",
            prop: "tem_work",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "工单照片2",
            prop: "tem_work2",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "工单3",
            prop: "tem_work3",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "上传日期",
            prop: "add_time",
            obj: "order",
            type: "date"
        },
        {
            label: "维修类型",
            prop: "finish_type",
            obj: "order",
            type: "select",
            optListName: "repairTypeList"
        },
        {
            label: "维修方式",
            prop: "finish_mode",
            obj: "order",
            type: "select",
            optListName: "repairMethodList"
        },
        {
            label: "维修金额",
            prop: "money",
            obj: "order",
            type: "input"
        },
        {
            label: "备注",
            prop: "remarks",
            obj: "order",
            type: "input"
        },
        {
            label: "订单类型",
            prop: "identifyType",
            obj: "order",
            type: "select",
            optListName: 'orderTypeList',
            optLabel: 'name',
            optValue: 'id'
        },
        {
            label: "审核结果",
            prop: "exa_status",
            obj: "order",
            type: "radio",
            optListName: "statusList",
            optLabel: "name",
            optValue: "status"
        },
        {
            label: "回复内容",
            prop: "fail_exp",
            obj: "order",
            type: "textarea"
        },
        {
            label: "订单备注",
            prop: "remark",
            obj: "order",
            type: "input"
        }
    ],
    tableColumns: [
        {
            slotName: "headimg"
        },
        {
            label: "注册者昵称",
            prop: "nickname"
        },
        {
            label: "手机号码",
            prop: "phone"
        },
        {
            label: "商家名称",
            prop: "group_name"
        },
        {
            label: "省份",
            prop: "province"
        },
        {
            label: "城市",
            prop: "city"
        },
        {
            label: "区县",
            prop: "district"
        },
        {
            label: "分销商",
            prop: "fxs_name"
        },
        {
            label: "店铺名称",
            prop: "shop_name"
        },
        {
            slotName: "operation"
        }
    ],
    piccCallbackType: 2
}
