<template>
  <div>
    <GlobalInfoBar
        title="角色管理"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <div class="head">
        <span class="notice">说明: 新增或修改角色时可设置对应菜单权限</span>
        <el-button size="small" type="primary" @click="addRole">添加角色</el-button>
      </div>
      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="editRole(row)">编辑角色</el-button>
          </template>
        </el-table-column>
      </GlobalTable>

      <el-dialog :title="dialog.type+'角色'" :visible.sync="dialog.isShow" width="600px">
<!--        <div class="dialog-notice">{{ dialog.notice }}</div>-->
        <el-form ref="dialogForm" :model="dialog.form" label-width="80px" size="small" class="dialog-form"
                 :rules="dialog.rules">
          <el-form-item prop="role_name" label="角色名称">
            <el-input v-model="dialog.form.role_name" placeholder="请输入角色名称(15个汉字以内)" maxlength="15"></el-input>
          </el-form-item>

          <el-form-item prop="role_desc" label="角色说明">
            <el-input type="textarea" v-model="dialog.form.role_desc" placeholder="请输入角色说明(如无则可跳过)" maxlength="50"
                      show-word-limit></el-input>
          </el-form-item>

          <el-form-item prop="act" label="菜单权限" class="">
            <el-tree
                ref="tree"
                :check-strictly="true"
                @check="treeCheck"
                :data="dialog.menu"
                show-checkbox
                :default-expanded-keys="dialog.form.act"
                node-key="id"
                @check-change="checkChange"
                :props="dialog.defaultTreeProps">
            </el-tree>
          </el-form-item>
        </el-form>

        <div class="dialog-btns">
          <el-button type="primary" size="small" @click="confirm">确认{{ dialog.type }}</el-button>
          <el-button type="primary" size="small" plain @click="dialog.isShow=false">取消</el-button>
        </div>
      </el-dialog>
    </GlobalChunk>
  </div>
</template>

<script>
import {addEditRole, roleInfo, roleList} from "@/utils/request/systemManage";

export default {
  name: "role",
  data() {
    let validateAct = (rule, value, callback) => {
    this.$nextTick(()=>{
      let act=this.dialog.form.act
      if (!(act && act.length)) {
        callback('请选择菜单权限')
      }else {
        callback()
      }
    })
    }
    let validateRoleName=(rule, value, callback)=>{
      if (!(new RegExp(/^[\u4e00-\u9fa5]{1,15}$/).test(value))) {
        callback('请输入15位内中文')
      } else {
        callback()
      }
    }
    return {
      tableColumns: [
        {label: "角色名称", prop: "role_name"},
        {label: "账号数量", prop: "num"},
        {label: "角色说明", prop: "role_desc"},
        {label: "添加人", prop: "add_admin"},
        {label: "添加时间", prop: "add_time"},
        {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      dialog: {
        isShow: false,
        notice: '说明: 修改时不输入密码提交则系统不作密码修改',
        form: {
          role_name: '',
          role_desc: '',
          act: []
        },
        rules: {
          role_name: [
            {required: true, message: '请填写角色名称', trigger: 'blur'},
            {validator: validateRoleName, trigger: 'blur'},
          ],
          act: [
            {validator: validateAct, trigger: 'change'}
          ]
        },
        menu: [],
        defaultTreeProps: {
          children: 'children',
          label: 'name'
        },
        curPId:''
      },

    }
  },
  mounted() {
    this.getRoleList()
  },
  methods: {
    currentChange(page) {
      this.curPage = page;
      this.getRoleList()
    },
    editRole(row) {
      this.dialog.isShow = true
      this.dialog.type = '修改'
      this.$nextTick(() => {
        this.getRoleInfo(row.role_id)
      })

    },
    getRoleList() {
      roleList().then(res => {
        this.tableData = res.data || []
        this.totalElements = res.count || 0
      })
    },
    addRole() {
      this.dialog.isShow = true;
      this.dialog.type = '新增'
      this.dialog.form={
        role_name: '',
        role_desc: '',
        act: []
      }

      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
      })
      this.getMenu().then(() => {
        this.$refs.tree.setCheckedKeys([])
      })
    },
    getMenu() {
      return roleInfo({role_id: ''}).then(res => {
        this.dialog.menu = res.data.menu
      })
    },
    getRoleInfo(role_id) {
      return roleInfo({role_id}).then(res => {
        this.dialog.menu = res.data.menu
        this.dialog.form = res.data.info
        this.dialog.form.act = res.data.act.map(item => {
          return parseInt(item)
        })
        this.$refs.tree.setCheckedKeys(this.dialog.form.act)
      })
    },
    treeCheck(data, node) {
      this.dialog.form.act = node.checkedKeys
    },
    checkChange(data,check) {
      if (data.id === this.dialog.curPId) {
        return
      }
      if (data.parent_id) {
        let brothers=this.$refs.tree.getNode(data.parent_id).data.children;
        let flag = false;
        brothers.forEach(b=>{
          let bNode=this.$refs.tree.getNode(b.id)
          if(bNode.checked){
            flag=true
          }
        });
        this.$refs.tree.setChecked(data.parent_id,flag,false);
        this.dialog.curPId=data.parent_id
        setTimeout(()=>{
          this.dialog.curPId=''
        },100)
      }

      if (data.children) {
        data.children.forEach(child=>{
          this.$refs.tree.setChecked(child.id,check)
        })
      }
    },
    confirm() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          addEditRole(this.dialog.form).then(res => {
            this.$message.success(res.msg || this.dialog.type + '角色成功')
            this.dialog.isShow = false

            this.curPage = 1
            this.getRoleList()
          }).catch(err => {
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.head {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .notice {
    font-size: 14px;
    color: red;
    position: relative;
    bottom: -25px;
  }
}

.dialog-notice {
  color: red;
  margin-bottom: 20px;
}

.dialog-form {
  .el-input, .el-select, .el-textarea, .el-tree {
    width: 300px;
  }

  .prompt {
    margin-left: 20px;
    color: #999;
  }
}

.dialog-btns {
  margin: 30px 0 30px 60px;

  .el-button {
    margin-left: 20px;
  }
}
</style>
<style>
.el-textarea textarea::-webkit-input-placeholder{
  font-family: "Microsoft YaHei",serif;
  color: #A1A4AF
}
.el-tree{
  max-height: 350px;
  overflow:auto;
}
</style>
