import { render, staticRenderFns } from "./personalClaimsDetail.vue?vue&type=template&id=0695d657&scoped=true&"
import script from "./personalClaimsDetail.vue?vue&type=script&lang=js&"
export * from "./personalClaimsDetail.vue?vue&type=script&lang=js&"
import style0 from "./personalClaimsDetail.vue?vue&type=style&index=0&id=0695d657&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0695d657",
  null
  
)

export default component.exports