import Layout from '@/layout/layout'

const DemoVideoRouter = {
    path: '/insurancePush',
    component: Layout,
    redirect: 'noRedirect',
    name: '人保推送',
    children: [
        {
            path:'personalClaims',
            component: () => import('@/views/insurancePush/personalClaims'),
            name: '个人理赔',
            meta: { title: '个人理赔', noCache: true }
        },
        {
            path:'personalClaimsDetail/:id',
            props: true,
            component: () => import('@/views/insurancePush/personalClaimsDetail'),
            name: '个人理赔详情',
            meta: { title: '个人理赔详情', noCache: true }
        },
        {
            path:'reportDetail/:id',
            props: true,
            component: () => import('@/views/insurancePush/reportDetail'),
            name: '报案详情',
            meta: { title: '报案详情', noCache: true }
        },
        {
            path:'paymentCallback',
            component: () => import('@/views/insurancePush/paymentCallback'),
            name: '打款回调',
            meta: { title: '打款回调', noCache: true }
        },
        {
            path:'coveredPush',
            component: () => import('@/views/insurancePush/coveredPush'),
            name: '承保推送',
            meta: { title: '承保推送', noCache: true }
        },
        {
            path:'reportPush',
            component: () => import('@/views/insurancePush/reportPush'),
            name: '报案推送',
            meta: { title: '报案推送', noCache: true }
        },
        {
            path:'claimPush',
            component: () => import('@/views/insurancePush/claimPush'),
            name: '理赔推送',
            meta: { title: '理赔推送', noCache: true }
        },
    ]
}

export default DemoVideoRouter
