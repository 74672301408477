var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register_list"},[_c('GlobalInfoBar',{attrs:{"title":"注册协议及说明"}}),_c('GlobalChunk',{attrs:{"padding":[20,20,20,20]}},[_c('div',{staticClass:"top_tit"},[_vm._v(" 主要动态配置前端H5保险注册页面的‘保险说明’及‘保险注册服务协议’，以及保险注册后的‘可理赔次数’，以及子险种及成本 ")]),_c('GlobalTable',{ref:"GlobalTable",attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"isPagination":false,"total":_vm.PlanItotal},on:{"handleCurrentChange":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"slot":"operating","label":"保险说明是否配置","align":"center"},slot:"operating",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.instype_explain)?_c('span',{staticStyle:{"color":"#FF687B"}},[_vm._v("未配置")]):_c('span',[_vm._v("已配置")])]}}])}),_c('el-table-column',{attrs:{"slot":"operating","label":"保险协议是否配置","align":"center"},slot:"operating",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.instype_agree)?_c('span',{staticStyle:{"color":"#FF687B"}},[_vm._v("未配置")]):_c('span',[_vm._v("已配置")])]}}])}),_c('el-table-column',{attrs:{"slot":"subInsurance","label":"子险种配置","align":"center"},slot:"subInsurance",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_son===1)?_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.subInsuranceSetting(row.instype_id)}}},[_vm._v("查看")]):_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.subInsuranceSetting(row.instype_id)}}},[_vm._v("添加")])]}}])}),_c('el-table-column',{attrs:{"slot":"points","label":"积分","width":"160px","align":"center"},slot:"points",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"min":0,"max":999,"size":"small"},on:{"change":function($event){return _vm.handleChangeNum(row)}},model:{value:(row.points),callback:function ($$v) {_vm.$set(row, "points", $$v)},expression:"row.points"}})]}}])}),_c('el-table-column',{attrs:{"slot":"operat","label":"操作","align":"center"},slot:"operat",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"mr_sty",attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({path:'registerEdit',query:{id:row.instype_id}})}}},[_vm._v("编辑")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }