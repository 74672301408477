<template>
    <div>
      <GlobalInfoBar>
        商家保险分配
      </GlobalInfoBar>
      <GlobalChunk :padding="[20, 20, 20, 20]">
        <GlobalForm :form-item-list="formItemList" :inline="true" round @handleConfirm="query" confirmBtnName="查询">
        </GlobalForm>
  
        <GlobalTable ref="GlobalTable" :columns="tableColumns" :data="tableData" :currentPage="curPage"
          :total="totalElements" @handleCurrentChange="currentChange">
          <el-table-column label="门店数量" slot="group_num" align="center">
            <template slot-scope="{ row }">
  
              <el-popover placement="right" width="700" @show="storePopoverShow(row)" trigger="click">
                <GlobalForm :form-item-list="storePopover.formItemList" :inline="true" round @handleConfirm="storeQuery"
                  confirmBtnName="查询"></GlobalForm>
                <span class="popover-sore-num">门店数量:<span>{{ row.group_num }}</span></span>
                <GlobalTable :columns="storePopover.tableColumns" :data="storePopover.tableData"
                  :currentPage="storePopover.curPage" :total="storePopover.totalElements" :page-size="5"
                  @handleCurrentChange="storeCurrentChange">
                </GlobalTable>
                <a href="javascript:" slot="reference" :class="{ 'no-count': !row.group_num }">{{ row.group_num }}</a>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="排序" slot="lastLoginTime" align="center">
            <template slot-scope="{ row }">
              <el-input-number size="mini" v-model="row.sort" @change="(currentValue) => (handleSort(row, currentValue))"
                :min="1" label="描述文字"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="展示" slot="show" align="center">
            <template slot-scope="{ row }">
              <el-switch :value="row.show" :active-value="1" :inactive-value="0" @change="(e) => changeOpen(row, e)"/>
            </template>
          </el-table-column>
          <el-table-column label="最后更新时间" slot="lastLoginTime" align="center">
            <template slot-scope="{ row }">
              {{ row.add_time || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="登录账号" slot="loginAccount" align="center">
            <template slot-scope="{ row }">
              {{ row.account || '--' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center" width="223">
            <template slot-scope="{ row }">
              <el-button type="text" @click="editInfo(row)">修改</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </GlobalChunk>
  
      <el-dialog title="保险分配" :visible.sync="dialog.isShow" width="500px">
        <el-form ref="dialogForm" :model="dialog.form" label-width="80px" size="small" class="dialog-form"
          :rules="dialog.rules">
          <el-form-item prop="insurance_str" label="选择保险">
            <el-select v-model="dialog.form.insurance_str" placeholder="请选择保险" multiple collapse-tags>
              <el-option v-for="item in dialog.insurance" :key="item.instype_id" :label="item.instype_name" :value="item.instype_id">
              </el-option>
            </el-select>
          </el-form-item>
          <div class="dialog-btns">
            <el-button type="primary" size="small" @click="confirm">提交</el-button>
            <el-button type="primary" size="small" plain @click="dialog.isShow = false">取消</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </template>
  
  <script>
  
  
  import { editGroupIns, editGroup, groupList, groupShopList, openGroup } from "@/utils/request/partnerManage";
  import api from '@/utils/request/index'
  
  export default {
    name: "list",
    data() {
      return {
        group_id: 0,//商家id
        // **************
        formItemList: [
          {
            key: 'name',
            type: 'input',
            labelName: '商家名称:',
            placeholder: '请输入商家名称'
          }, {
            key: 'province',
            idKey: 'id',
            labelKey: 'name',
            type: 'selectFilterable',
            labelName: '所属省份:',
            option: [],
            placeholder: '请选择所属省份'
          }
        ],
        tableColumns: [
          { label: "商家名称", prop: "group_name" },
          { label: "所属省份", prop: "province_name" },
          { slotName: "group_num" },
          { slotName: "sort" },
          { slotName: "loginAccount" },
          { slotName: 'lastLoginTime' },
          { slotName: 'show' },
          { slotName: "operation" },
        ],
        tableData: [],
        curPage: 1,
        totalElements: 0,
        province: '',
        name: '',
        dialog: {
          isShow: false,
          form: {
            group_id: '',
            insurance_str: ''
          },
          rules: {
            insurance_str: { required: true, message: '请选择保险', trigger: 'change' },
          },
          insurance: []
        },
        storePopover: {
          tableColumns: [
            { label: "门店名称", prop: "shop_name" },
            { label: "注册店员", prop: "num" },
          ],
          shop_name: '',
          group_id: '',
          tableData: [],
          curPage: 1,
          totalElements: 0,
          formItemList: [{
            key: 'shop_name',
            type: 'input',
            labelName: '门店名称:',
            placeholder: '请输入门店名称搜索'
          }]
        },
      }
    },
    mounted() {
      this.pullInsTypeList()
      this.getPartners()
      this.getProvinces()
    },
    methods: {
      changeOpen(row, value) {
        openGroup({
          group_id: row.group_id,
          show: value
        }).then(() => {
          this.$message.success('操作成功！')
          this.getPartners()
        })
      },
      // **********
      getPartners() {
        groupList({
          page: this.curPage,
          limit: 10,
          group_name: this.name,
          province: this.province
        }).then(res => {
          this.tableData = res.data || []
          this.totalElements = res.count || 0
        })
      },
      query(data, cd) {
        this.name = data.name
        this.province = data.province
        this.curPage = 1
        this.getPartners()
      },
      currentChange(page) {
        this.curPage = page
        this.getPartners()
      },
      editInfo(row) {
        this.dialog.isShow = true
        this.$nextTick(() => {
          this.$refs.dialogForm.resetFields();
          let form = {
            group_id: row.group_id,
            insurance_str: row.insurance_str || []
          }
          this.$set(this.dialog, 'form', form)
        })
      },
      handleSort(row, currentValue) {
        editGroup({ group_id: row.group_id, sort: currentValue }).then(res => {
          this.$message.success('排序成功')
          this.getPartners()
        })
      },
      getProvinces() {
        api.getRegion({ pid: '' }).then(res => {
          this.formItemList[1].option = res.data || []
        })
      },
      storePopoverShow(row) {
        this.storePopover.totalElements = 0
        this.storePopover.shop_name = ''
        this.storePopover.group_id = row.group_id
        this.storePopover.curPage = 1
        this.getGroupShopList()
      },
      getGroupShopList() {
        groupShopList({
          page: this.storePopover.curPage,
          limit: 5,
          shop_name: this.storePopover.shop_name,
          group_id: this.storePopover.group_id
        }).then(res => {
          this.storePopover.tableData = res.data
          this.storePopover.totalElements = res.count
        })
      },
      storeCurrentChange(page) {
        this.storePopover.curPage = page
        this.getGroupShopList()
      },
      storeQuery(data) {
        this.storePopover.curPage = 1
        this.storePopover.shop_name = data.shop_name
        this.getGroupShopList()
      },
      confirm() {
        this.$refs.dialogForm.validate(valid => {
          if (valid) {
            console.log(this.dialog.form);
            
            editGroupIns(this.dialog.form).then(res => {
              this.$message.success(res.msg || '操作成功')
              this.dialog.isShow = false
  
              this.curPage = 1
              this.getPartners()
            }).catch(err => {
            })
          }
        })
      },
      // 保险类型下拉
      pullInsTypeList() {
        api.pullInsTypeList().then((res) => {
            if (res.code === 1) {
                this.dialog.insurance = res.data || []
            }
        });
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  /deep/ .el-form-item__label {
    width: 80px !important;
  }
  
  .search-box {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
  }
  
  .box-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 10px;
    justify-content: space-between;
  }
  
  .search {
    display: flex;
    align-items: center;
    width: 300px;
    margin-right: 10px;
  }
  
  .timer-config {
    display: flex;
    align-items: center;
  }
  
  .timer {
    width: 360px !important;
    // height: 30px;
    // line-height: 30px !important;
  }
  
  .dialog-notice {
    color: red;
    margin-bottom: 20px;
  }
  
  .dialog-form {
  
    .el-input,
    .el-select {
      width: 300px;
    }
  }
  
  .dialog-btns {
    margin: 30px 0 30px 60px;
  
    .el-button {
      margin-left: 20px;
    }
  }
  
  .el-popover {
    .el-icon-warning {
      color: #FAAD14;
      margin-right: 5px;
      font-size: 16px;
    }
  
    .btns {
      text-align: right;
      margin-top: 20px;
    }
  }
  
  .delete-btn {
    color: red;
    margin-left: 10px;
  }
  
  .el-popover {
    position: relative;
  }
  
  .popover-sore-num {
    position: absolute;
    right: 20px;
    top: 20px;
  
    span {
      color: red;
    }
  }
  
  .no-count {
    color: #000;
    pointer-events: none;
  }
  
  .remark {
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block
  }
  </style>
  