import Layout from '@/layout/layout'

const ChangePhoneRouter = {
    path: '/coupon',
    component: Layout,
    redirect: 'noRedirect',
    name: '优惠券活动',
    children: [
        {
            path:'modelConfig',
            component: () => import('@/views/coupon/modelConfig/index'),
            name: '机型配置',
            meta: { title: '机型配置', noCache: true }
        },  {
            path:'couponList',
            component: () => import('@/views/coupon/couponList/index'),
            name: '优惠券列表',
            meta: { title: '优惠券列表', noCache: true }
        },  {
            path:'order',
            component: () => import('@/views/coupon/orderList/index'),
            name: '订单列表',
            meta: { title: '订单列表', noCache: true }
        },{
            path:'modelOperation',
            component: () => import('@/views/coupon/modelConfig/operate.vue'),
            name: '机型操作',
            meta: { title: '机型操作', noCache: true }
        }, {
            path:'orderOperation',
            component: () => import('@/views/coupon/orderList/operate.vue'),
            name: '活动订单审核',
            meta: { title: '活动订单审核', noCache: true }
        }, 
    ]
}

export default ChangePhoneRouter
