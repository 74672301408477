<template>
  <div class="login">
    <img class="background" src="./images/bg.png" />
    <div class="background_title">
      <div class="first">Copyright©2020亚丁科技 All Riqhts Reserved</div>
      <div class="first">四川亚丁屏卫科技有限公司版权所有</div>
      <a
        href="https://beian.miit.gov.cn"
        target="_blank"
        class="first"
        style="color: #fff"
        >蜀IPC备2022017134号-1</a
      >
    </div>
    <div class="login-page">
      <div class="header">
        <span>亚丁屏卫科技-后台管理系统</span>
      </div>

      <div class="main">
        <div class="content">
          <div class="title">登录</div>
          <div class="title_img">
            <div>
              <img src="./images/login_z_ico_zh.png" alt="" />
            </div>
            <div class="input_img">
              <input
                class="input"
                type="text"
                v-model="params.account"
                placeholder="请输入登录账号"
              />
            </div>
          </div>
          <div class="show_pass">
            <div class="title_img">
              <div>
                <img src="./images/login_z_ico_mm.png" alt="" />
              </div>
              <div class="input_img">
                <input
                  class="input"
                  type="text"
                  placeholder="请输入登录密码"
                  v-if="pwdType"
                  v-model="params.password"
                />
                <input
                  class="input"
                  type="password"
                  placeholder="请输入登录密码"
                  v-model="params.password"
                  v-else
                />
              </div>
            </div>

            <div class="show_img">
              <img
                :src="seen ? seenImg : unseenImg"
                @click="changeType()"
                class="eye_img"
              />
            </div>
          </div>
          <el-checkbox class="remember-code" v-model="checkedLogin"
            >记住账号</el-checkbox
          >
          <div class="submit" @click="handleLodin">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "login",
  data() {
    return {
      seen: "",
      unseenImg: require("@/assets/images/login_icon_eye_close@2x.png"), //看不见
      seenImg: require("@/assets/images/login_icon_eye_@2x.png"), //看得见密码
      pwdType: false,
      checkedLogin: false,
      params: {
        account: "",
        password: "",
      },
    };
  },
  created() {
    if (JSON.parse(localStorage.getItem("loginUser"))) {
      this.params.account = JSON.parse(
        localStorage.getItem("loginUser")
      ).account;
      // this.params.password = JSON.parse(localStorage.getItem('loginUser')).password
      this.checkedLogin = JSON.parse(
        localStorage.getItem("loginUser")
      ).checkedLogin;
    }
    console.log(
      "loginUser11111111111111111",
      this.checkedLogin,
      this.params.password,
      this.params.account
    );
    console.log(JSON.parse(localStorage.getItem("loginUser")));
  },
  methods: {
    tolink() {},
    //密码的显示隐藏
    changeType: function () {
      this.seen = !this.seen; //小眼睛的变化
      this.pwdType = !this.pwdType; //跟着小眼睛变化，密码框隐藏显示文本框，内容就显示了
    },
    handleLodin() {
      const pcLoginRequest = {
        username: this.params.account,
        password: this.params.password,
      };
      if (this.params.account !== "" && this.params.password !== "") {
        // this.pcLoginRequest = JSON.stringify(pcLoginRequest)
        _api.postLogin(pcLoginRequest).then((res) => {
          //请求返回来的数据
          console.log(res, "this.checkedLogin:", this.checkedLogin);
          //  保存token：
          if (res.code === 1) {
            localStorage.setItem("token", res.data.token);
            // sessionStorage.setItem("userImg", res.data.user.userImg);
            // sessionStorage.setItem("userName", res.data.user.userName);
            if (this.checkedLogin) {
              let userForm = {
                account: this.params.account,
                checkedLogin: this.checkedLogin,
              };
              console.log(userForm);
              localStorage.setItem("loginUser", JSON.stringify(userForm));
            } else {
              localStorage.removeItem("loginUser");
            }
            this.$router.push({ path: "/dashboard" });
            this.$message({
              showClose: true,
              message: "登录成功",
              type: "success",
            });
          } else {
            //  登录失败
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          }
        });
        // } else {
        //   this.$message({
        //     showClose: true,
        //     message: "用户名和密码不能为空",
        //     type: "error"
        //   });
      }
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.login {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  position: relative;
  min-height: 700px;
  .background_title {
    position: absolute;
    bottom: 20px;
    right: 45%;
    color: white;
    // text-align: right;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    .first {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-top: 10px;
      text-align: center;
      width: 365px;
      font-size: 14px;
    }
  }
  .background {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .login-page {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;

    .header {
      position: absolute;
      left: 37%;
      top: 60px;
      display: flex;
      align-items: center;
      > img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 13px;
      }
      > span {
        font-size: 38px;
        font-family: PingFang SC;
        font-weight: bold;
        color: white;
      }
    }

    .main {
      position: absolute;
      right: 400px;
      top: 156px;
      width: 560px;
      height: 560px;
      background: #0981ff;
      box-shadow: 0px 5px 96px 0px rgba(78, 130, 185, 0.1),
        0px 30px 70px 0px rgba(78, 130, 185, 0.27);
      border-radius: 20px;
      padding: 8px 0 0 0;
      .content {
        width: 560px;
        height: 560px;
        background: #ffffff;
        border-radius: 20px;
        padding: 50px 50px 0px 50px;
        .title {
          width: 100%;
          text-align: center;
          font-size: 46px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #1091ff;
          margin-bottom: 20px;
        }

        .title2 {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .title_img {
          width: 100%;
          display: flex;
          justify-content: left;
          align-items: center;
          border-bottom: 2px solid #0981ff;
          img {
            margin-right: 30px;
            width: 24px;
            height: 24px;
          }
          .input_img {
            width: 100%;
          }
          .input {
            display: block;
            width: 100%;
            border: none;
            padding: 30px 0;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1091ff;
            outline: none;
            &::placeholder {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #b6b9d0;
              outline: none;
            }
          }
        }

        .remember-code {
          margin: 30px 0 60px 0;
        }

        .submit {
          width: 460px;
          height: 60px;
          background: #0981ff;
          border-radius: 30px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .footer {
      position: absolute;
      left: 52px;
      bottom: 39px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #0981ff;
    }
  }
  .show_pass {
    position: relative;
    .show_img {
      position: absolute;
      top: 40%;
      right: 5px;
      width: 25px;
      max-height: 25px;
    }
  }
}
</style>
