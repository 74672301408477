import Layout from '@/layout/layout'

const RepairerRouter = {
    path: '/repairerManage',
    component: Layout,
    redirect: 'noRedirect',
    name: '维修商管理',
    children: [
        {
            path:'list',
            component: () => import('@/views/repairerManage/list'),
            name: '维修商列表',
            meta: { title: '维修商列表', noCache: true }
        },
        {
            path:'storeList',
            component: () => import('@/views/repairerManage/storeList'),
            name: '维修门店列表',
            meta: { title: '维修门店列表', noCache: true }
        },
        {
            path:'registeredStaff',
            component: () => import('@/views/repairerManage/registeredStaff'),
            name: '注册维修员',
            meta: { title: '注册维修员', noCache: true }
        },

    ]
}

export default RepairerRouter
