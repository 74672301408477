<template>
  <el-table
      ref="table"
      size="mini"
      border
      :data="tableData"
      highlight-current-row
      @current-change="currentRowChane"
      style="width: 800px">
    <el-table-column
        width="60px"
        align="center"
        prop="selection"
        label="选择">
      <template slot-scope="{row}">
        <el-checkbox :value="row.order_id===currentRow.order_id"></el-checkbox>
      </template>
    </el-table-column>
    <el-table-column
        align="center"
        prop="order_sn"
        label="订单编号">
      <template slot-scope="{row}">
        <a :href="`/order/detail/${row.mold}/${row.order_id}`" target="_blank">
          {{row.order_sn}}
        </a>
      </template>
    </el-table-column>
    <el-table-column
        align="center"
        prop="group_name"
        label="商家">
    </el-table-column>
    <el-table-column
        align="center"
        prop="imei"
        label="IMEI">
    </el-table-column>
    <el-table-column
        align="center"
        prop="shop_name"
        label="门店">
    </el-table-column>
  </el-table>

</template>

<script>
export default {
  model: {
    prop:'value',
    event:'change'
  },
  name: "photoResubmissionOrderList",
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    value:{
      type:[String,Number],
      default:''
    }
  },
  data() {
    return{
      currentRow: {}
    }
  },
  watch: {
    tableData:{
      handler(tableData) {
        if (tableData && tableData.length) {
          tableData.forEach(item=>{
            if (item.is_select) {
              this.currentRow=item
              this.$emit('change',item.order_id)
              this.$refs.table.setCurrentRow(item)
            }
          })
        }
      },immediate:true,deep:true
    }
  },
  methods:{
    currentRowChane(row) {
      this.currentRow=row
      this.$emit('change',row.order_id)
    }
  }
}
</script>

<style scoped>

</style>
