<template>
  <section ref="editor" style="text-align:left"></section>
</template>

<script>
import environments from "@/config/url";
import Editor from "wangeditor";
export default {
  name: "JEditor",
  props: {
    type: {
      type: String,
      default: ""
    },
    inintTxt: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editorContent: "",
      editorContainer: null
    };
  },

  mounted() {
    this.initEditor();
  },
  watch: {
    inintTxt() {
      this.setContent(this.inintTxt);
    }
  },

  methods: {
    initEditor() {
      let editor = new Editor(this.$refs.editor);
      this.editorContainer = editor;
      editor.config.onchange = html => {
					// 派发监听 Editor 输入的值
					this.$emit('handleEditContent', html, this.type,editor.txt.text())
				}
      editor.config.pasteTextHandle = function(content) {
        return content;
      };

      // 上传图片到服务器
      editor.config.uploadImgServer = environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl;
      // 将图片大小限制为 3M
      editor.config.uploadImgMaxSize = 3 * 1024 * 1024;
      // 自定义 fileName
      editor.config.uploadFileName = "file";

      editor.config.uploadImgHooks = {
        customInsert(insertImg, result) {
          if (!result.data) return;
          // result.filesUrl.forEach(url => {
            insertImg(result.data.url);
          // });
        }
      };

      editor.create();
      if (this.inintTxt) {
        this.setContent(this.inintTxt);
      }

      // 派发设置 Editor 的值
      this.$emit("handleSettingEditContent", editor.txt, this.type);
    },
    setContent(val) {
      this.editorContainer.txt.html(val);
    }
  }
};
</script>
