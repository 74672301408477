<template>
  <div class="carmi_inquire">
    <GlobalInfoBar
        title="卡密查询"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
        :padding="[20,20,20,20]"
    >

      <div slot="filter">
        <Form @changeNav="changeNavFrom"></Form>
      </div>

      <Table :tableData="tableDataIndex" :carmItotal="carmItotal" :carmIpageNum="carmIpageNum" @handleCurrentChange="handleCurrentChange"></Table>
    </GlobalChunk>
  </div>
</template>

<script>
import Form from "./Form"
import Table from "./Table"
import _api from "@/utils/request";
export default {
  name: 'carmi_inquire',
  components: {Form, Table},

  data(){
    return{
      tableDataIndex:[],
      card_name :'',
      is_use :'',
      virtually :'',
      group_id :'',
      card_num :'',
      card_secret:'',
      carmItotal:0,
      carmIpageNum:1,
      carmIpageSize:10,
    }
  },
  created(){
    this.carmIDataList()
  },
  methods: {
    //分页
    handleCurrentChange(val){
      this.carmIpageNum = val
      this.carmIDataList()
    },
    //查询
    changeNavFrom(val){
      console.log(val,'canshu2222222');
      this.card_name =val.card_name
      this.is_use =val.is_use
      this.group_id =val.group_id
      this.card_num =val.card_num
      this.card_secret=val.card_secret
      this.instype_id=val.instype_id
      this.virtually=val.virtually
      const SeachParams ={
        card_name :val.card_name,
        instype_id :val.instype_id,
        is_use :val.is_use,
        group_id :val.group_id,
        card_num :val.card_num,
        card_secret:val.card_secret,
        virtually:val.virtually,
        page:1,
        limit:10,
      }
      _api.cardPoolList(SeachParams).then(res => {
        if(res.code === 1){
          this.tableDataIndex = res.data
          this.carmItotal = res.count
          this.carmIpageNum = 1
        }
      })
    },
    //列表请求
    carmIDataList(){
      const vo ={
        page:this.carmIpageNum,
        limit:this.carmIpageSize,
        card_name:this.card_name,
        is_use:this.is_use,
        group_id:this.group_id,
        card_num:this.card_num,
        card_secret:this.card_secret,
        instype_id:this.instype_id,
        virtually:this.virtually
      }
      _api.cardPoolList(vo).then(res => {
        if(res.code === 1){
          this.tableDataIndex = res.data
          this.carmItotal = res.count
          console.log(this.tableDataIndex,'this.tableDataIndex')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.carmi_inquire{

}
</style>
