<template>
  <div>
    <GlobalInfoBar title="订单列表"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :initData="{order_sn}"
          ref="form"
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询">
        <div slot="channelAndStore">
          <span class="el-form-item__label" style="margin-left: 50px">通道：</span>
          <el-select v-model="pass_id" placeholder="请选择通道" style="width: 200px;margin-right: 20px" filterable
                     @change="channelChange" clearable>
            <el-option
                v-for="item in channelList"
                :key="item.pass_id"
                :label="item.pass_name"
                :value="item.pass_id">
            </el-option>
          </el-select>
          <el-select v-model="store_id" placeholder="请选择门店" style="width: 200px;margin-right: 20px" filterable
                     clearable>
            <el-option
                v-for="item in storeList"
                :key="item.store_id"
                :label="item.store_name"
                :value="item.store_id">
            </el-option>
          </el-select>
        </div>
        <el-button type="warning" slot="AddSlot" @click="exportData">导出数据</el-button>
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="渠道" slot="pass_name" align="center" width="150px">
          <template slot-scope="{ row }">
            {{ row.pass_name || '渠道' }}
          </template>
        </el-table-column>
        <el-table-column label="门店" slot="store_name" align="center" width="100px">
          <template slot-scope="{ row }">
            {{ row.store_name || '-' }}
          </template>
        </el-table-column>

        <el-table-column label="旧机型号/规格/颜色" slot="oldProp" align="center" width="150px">
          <template slot-scope="{ row }">
            {{ row.old_model }}/{{ row.old_ram }}/{{ row.old_colour }}
          </template>
        </el-table-column>

        <el-table-column label="旧机序列号" slot="query" align="center" width="150px">
          <template slot-scope="{ row }">
            <el-popover
                placement="left"
                width="300">
              <div style="font-weight: bold;margin-bottom: 10px">查询结果</div>
              <table class="popover-table" style="width: 100%">
                <tr>
                  <td>旧机序列号</td>
                  <td>{{ row.sn }}</td>
                </tr>
                <tr>
                  <td>旧机颜色</td>
                  <td>{{ row.old_colour }}</td>
                </tr>
                <tr>
                  <td>旧机型号</td>
                  <td>{{ row.old_model }}</td>
                </tr>
                <tr>
                  <td>旧机容量</td>
                  <td>{{ row.old_ram }}</td>
                </tr>
                <tr>
                  <td>旧机型号号码</td>
                  <td>{{ row.query }}</td>
                </tr>
              </table>
              <a href="javascript:" slot="reference">{{ row.sn }}</a>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="焕新方式" slot="new_way" align="center" width="250px">
          <template slot-scope="{ row }">
            <div v-if="row.new_way===1">
              <p>到店焕新</p>
              <p>{{ row.store_name }}</p>
            </div>
            <div v-if="row.new_way===2">
              <p>快递上门</p>
              <p>{{ row.address }}</p>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="新机型号/规格/颜色" slot="newProp" align="center" width="150px">
          <template slot-scope="{ row }">
            {{ row.new_model }}/{{ row.new_ram }}/{{ row.new_colour }}
          </template>
        </el-table-column>
        <!--       {slotName: 'money'},
                {slotName: 'cost_price'},
                {slotName: 'profit_price'},   -->

        <el-table-column label="焕新价格" slot="money" align="center" width="100px">
          <template slot-scope="{ row }">
            <p>￥{{ row.money }}</p>
            <el-popover
                placement="left"
                width="300">
              <div style="font-weight: bold;margin-bottom: 20px;border-bottom: 1px solid #eee;padding-bottom: 10px">焕新价格-查看明细</div>
              <div style="display: flex;font-weight: bold;margin-bottom: 10px;color:#8080FF">
                <div style="min-width: 100px;text-align: right">旧机：</div>
                <div style="text-align: left">{{ row.old_model }} {{row.old_ram}}</div>
              </div>

              <div style="display: flex;font-weight: bold;margin-bottom: 10px;color:#8080FF">
                <div style="min-width: 100px;text-align: right">新机：</div>
                <div style="text-align: left">{{ row.new_model }} {{row.new_ram}}</div>
              </div>

              <div style="display: flex;margin-bottom: 10px">
                <div style="min-width: 100px;text-align: right">旧机抵扣价格：</div>
                <div style="text-align: left">￥{{ row.deduct_price }}</div>
              </div>

              <div style="display: flex;margin-bottom: 10px">
                <div style="min-width: 100px;text-align: right">迭代补差价格：</div>
                <div style="text-align: left">￥{{ row.diff_money }}</div>
              </div>

              <div style="display: flex;margin-bottom: 30px">
                <div style="min-width: 100px;text-align: right">5折换新价格：</div>
                <div style="text-align: left">￥{{ row.money }}</div>
              </div>
              <a href="javascript:" slot="reference">明细</a>

            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="旧机残值" slot="scrap_price" align="center" width="100px">
          <template slot-scope="{ row }">
            <p>￥{{ row.scrap_price }}</p>
            <p><a href="javascript:" @click="residualValueDialog.isShow=true;residualValueDialog.row=JSON.parse(JSON.stringify(row))">修改</a></p>
          </template>
        </el-table-column>
        <el-table-column label="成本费用" slot="cost_price" align="center" width="100px">
          <template slot-scope="{ row }">
            <p>￥{{ row.cost_price }}</p>
            <p><a href="javascript:" @click="modifyCostDialog.isShow=true;modifyCostDialog.row=JSON.parse(JSON.stringify(row))">修改</a></p>
          </template>
        </el-table-column>
        <el-table-column label="利润" slot="cost_price" align="center" width="100px">
          <template slot-scope="{ row }">
            ￥{{ row.profit_price }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态" slot="exa_status" align="center" width="100px">
          <template slot-scope="{ row }">
            <p>{{ [ '待处理' , '换机完成' ,'取消' ,'作废'][row.exa_status] }}</p>
            <p><a href="javascript:" v-if="row.exa_status===1"  @click="changeCompleteDialog.isShow=true;changeCompleteDialog.row=JSON.parse(JSON.stringify(row))">完成照信息</a></p>
            <p><a href="javascript:" v-if="row.exa_status===0"  @click="changeCompleteDialog.isShow=true;changeCompleteDialog.row=JSON.parse(JSON.stringify(row))">添加完成照</a></p>

            <el-popover
                placement="left"
                v-if="row.exa_status===3||row.exa_status===2"
                width="300">
              <div style="font-weight: bold;margin-bottom: 20px;border-bottom: 1px solid #eee;padding-bottom: 10px">
                {{'订单'+{'2':'取消','3':'作废'}[row.exa_status]+'-'+row.order_sn}}
              </div>
              <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">{{ {'2':'取消','3':'作废'}[row.exa_status] }}时间：</span>{{ row.op_time }}</p>
              <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">操作人员：</span>{{ row.op_admin }}</p>

              <div style="display: flex;margin-bottom: 10px">
                <div style="min-width: 100px;text-align: right">{{ {'2':'取消','3':'作废'}[row.exa_status] }}原因：</div>
                <div>{{row.remarks4}}</div>
              </div>

              <a href="javascript:" slot="reference">查看原因</a>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="操作" slot="operation" align="center" width="250px" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="remarkOperation(row)">{{
                row.remarks ? '查看' : '添加'
              }}备注
            </el-button>
            <el-button type="warning" size="mini" slot="reference"
                       v-if="row.exa_status===1"
                       @click="voidDialog.isShow=true;voidDialog.row=JSON.parse(JSON.stringify(row))">作废
            </el-button>
            <el-button type="warning" size="mini" slot="reference"
                       v-if="row.exa_status===0"
                       @click="cancelDialog.isShow=true;cancelDialog.row=JSON.parse(JSON.stringify(row))">取消</el-button>

            <el-button type="danger" size="mini" slot="reference"
            @click="deleteDialog.isShow=true;deleteDialog.row=JSON.parse(JSON.stringify(row))">删除</el-button>
          </template>
        </el-table-column>

      </GlobalTable>
    </GlobalChunk>

    <el-dialog title="编辑备注" :visible.sync="remarkDialog.isShow" width="450px" top="300px">
      <span>备注内容：</span>
      <el-input style="width: 300px;vertical-align: top" type="textarea" v-model="remarkDialog.row.remarks"
                placeholder="请输入备注内容，限50字" maxlength="50"></el-input>
      <div style="text-align: right;margin:20px 40px 20px">
        <el-button type="primary" size="mini" plain @click="remarkCancel()">取消</el-button>
        <el-button type="primary" size="mini" @click="saveRemark()">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'订单作废-'+voidDialog.row.order_sn" :visible.sync="voidDialog.isShow" width="450px" top="300px">
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">说明：</span>作废订单前请确认已与顾客及门店进行确认</p>
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">旧机：</span>{{ voidDialog.row.old_model }} {{ voidDialog.row.old_ram }}</p>
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">新机：</span>{{ voidDialog.row.new_model }} {{voidDialog.row.new_ram}}</p>
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">用户：</span>{{ voidDialog.row.name }}-{{voidDialog.row.phone}}</p>

      <div style="display: flex;margin-bottom: 10px">
        <div style="min-width: 100px;text-align: right">作废原因：</div>
        <el-input  style="width: 300px;vertical-align: top" type="textarea" v-model="voidDialog.row.remarks4"
                   placeholder="请输入作废原因（必填，100字内）" maxlength="100" size="mini"></el-input>
      </div>

      <div style="text-align: right;margin:20px 10px 20px">
        <el-button type="primary" size="mini" plain @click="voidDialog.isShow=false">取消</el-button>
        <el-button type="primary" size="mini" @click="voidConfirm()">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'订单取消-'+cancelDialog.row.order_sn" :visible.sync="cancelDialog.isShow" width="450px" top="300px">

      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">说明：</span>取消订单前请确认已与顾客及门店进行确认</p>
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">旧机：</span>{{ cancelDialog.row.old_model }} {{cancelDialog.row.old_ram}}</p>
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">新机：</span>{{ cancelDialog.row.new_model }} {{cancelDialog.row.new_ram}}</p>
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">用户：</span>{{ cancelDialog.row.name }}-{{cancelDialog.row.phone}}</p>

      <div style="display: flex;margin-bottom: 10px">
        <div style="min-width: 100px;text-align: right">取消原因：</div>
        <el-input  style="width: 300px;vertical-align: top" type="textarea" v-model="cancelDialog.row.remarks4"
                   placeholder="请输入取消原因（必填，100字内）" maxlength="100" size="mini"></el-input>
      </div>

      <div style="text-align: right;margin:20px 10px 20px">
        <el-button type="primary" size="mini" plain @click="cancelDialog.isShow=false">取消</el-button>
        <el-button type="primary" size="mini" @click="cancelConfirm()">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'订单删除-'+deleteDialog.row.order_sn" :visible.sync="deleteDialog.isShow" width="450px" top="300px">
      <div>确定要删除此订单吗？</div>
      <div style="text-align: right;margin:20px 10px 20px">
        <el-button type="primary" size="mini" plain @click="deleteDialog.isShow=false">取消</el-button>
        <el-button type="primary" size="mini" @click="deleteConfirm()">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="成本费用" :visible.sync="modifyCostDialog.isShow" width="450px" top="200px">
      <p style="font-weight: bold;margin-bottom: 20px;color:#8080FF">说明: 成本费用包括新机采购价、物流费用及其他费用，新机采购价自动获取（可修改）</p>

      <div style="display: flex;margin-bottom: 10px">
        <div style="min-width: 100px;text-align: right">新机采购价：</div>
        <el-input style="width: 300px;" v-model="modifyCostDialog.row.new_purchase" @input="moneyInput(modifyCostDialog.row,'new_purchase')"
                  placeholder="请输入新机采购价" maxlength="50" size="mini"></el-input>
      </div>

      <div style="display: flex;margin-bottom: 10px;font-weight: bold">
        <div style="min-width: 100px;text-align: right"></div>
        <div>机型：{{modifyCostDialog.row.new_model}} {{modifyCostDialog.row.new_ram}}</div>
      </div>

      <div style="display: flex;margin-bottom: 10px">
        <div style="min-width: 100px;text-align: right">物流费用：</div>
        <el-input style="width: 300px;" v-model="modifyCostDialog.row.logistics_price" @input="moneyInput(modifyCostDialog.row,'logistics_price')"
                  placeholder="请输入物流费用" maxlength="50" size="mini"></el-input>
      </div>

      <div style="display: flex;margin-bottom: 10px">
        <div style="min-width: 100px;text-align: right">其他费用：</div>
        <el-input style="width: 300px;" v-model="modifyCostDialog.row.other_price" @input="moneyInput(modifyCostDialog.row,'other_price')"
                  placeholder="请输入其他费用" maxlength="50" size="mini"></el-input>
      </div>

      <div style="display: flex;margin-bottom: 10px">
        <div style="min-width: 100px;text-align: right">备注说明：</div>
        <el-input  style="width: 300px;vertical-align: top" type="textarea" v-model="modifyCostDialog.row.remarks2"
                  placeholder=" 请输入备注，如其他费用组成明细" maxlength="50" size="mini"></el-input>
      </div>

      <div style="text-align: right;margin:20px 10px 20px">
        <el-button type="primary" size="mini" plain @click="modifyCostDialog.isShow=false">取消</el-button>
        <el-button type="primary" size="mini" @click="modifyCostConfirm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title=" 旧机残值回收价" :visible.sync="residualValueDialog.isShow" width="450px" top="200px">
      <p style="font-weight: bold;margin-bottom: 20px;color:#8080FF"><span style="display: inline-block;width: 100px;text-align: right">说明：</span> 旧机残值价格在确认换新并回收旧机后手动录入</p>
      <p style="font-weight: bold;margin-bottom: 20px;color:#8080FF"><span style="display: inline-block;width: 100px;text-align: right">旧机：</span>{{ residualValueDialog.row.new_model }} {{residualValueDialog.row.new_ram}}</p>

      <div style="display: flex;margin-bottom: 10px">
        <div style="min-width: 100px;text-align: right">残值价格：</div>
        <el-input style="width: 300px;" v-model="residualValueDialog.row.scrap_price" @input="moneyInput(residualValueDialog.row,'scrap_price')"
                  placeholder="请输入价格" maxlength="50" size="mini"></el-input>
      </div>

      <div style="display: flex;margin-bottom: 10px">
        <div style="min-width: 100px;text-align: right">备注说明：</div>
        <el-input  style="width: 300px;vertical-align: top" type="textarea" v-model="residualValueDialog.row.remarks3"
                   placeholder="请输入备注（非必填）" maxlength="50" size="mini"></el-input>
      </div>

      <div style="text-align: right;margin:20px 10px 20px">
        <el-button type="primary" size="mini" plain @click="residualValueDialog.isShow=false">取消</el-button>
        <el-button type="primary" size="mini" @click="residualValueConfirm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="['添加完成照','完成照信息'][changeCompleteDialog.row.exa_status]" :visible.sync="changeCompleteDialog.isShow" width="700px" top="100px" v-if="changeCompleteDialog.row.new_uimgs">
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">说明：</span>{{ ['如用户无法在H5页面上传换机完成照，可由后台人员录入完成照信息', '如用户上传旧机完成照后，如信息不准确，可后台人工二次修改'][changeCompleteDialog.row.exa_status] }}</p>
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">用户：</span>{{ changeCompleteDialog.row.name }}-{{ changeCompleteDialog.row.phone }}</p>
      <p style="font-weight: bold;margin-bottom: 10px;color:#8080FF"><span style="min-width: 100px;display: inline-block;text-align: right">新机：</span>{{ changeCompleteDialog.row.new_model }} {{ changeCompleteDialog.row.new_ram }}</p>

      <div style="display: flex;margin-bottom: 10px;align-items: center">
        <div style="min-width: 100px;text-align: right">身份证号：</div>
        <el-input style="width: 300px;" v-model="changeCompleteDialog.row.id_card"
                  placeholder="请输入身份证号" size="mini"></el-input>
      </div>
      <div style="display: flex;margin-bottom: 10px;align-items: center">
        <div style="min-width: 100px;text-align: right">新机串号：</div>
        <el-input style="width: 300px;" v-model="changeCompleteDialog.row.new_imei"
                  placeholder="请输入新机串号" size="mini"></el-input>
      </div>
      <div style="display: flex;margin-bottom: 10px;align-items: center">
        <div style="min-width: 100px;text-align: right">旧机串号：</div>
        <el-input style="width: 300px;" v-model="changeCompleteDialog.row.old_imei"
                  placeholder="请输入旧机串号" size="mini"></el-input>
      </div>
      <div style="display: flex;margin-bottom: 10px;align-items: center">
        <div style="min-width: 100px;text-align: right">新机价格：</div>
        <el-input style="width: 300px;" v-model="changeCompleteDialog.row.new_uprice" @input="moneyInput(changeCompleteDialog.row,'new_uprice')"
                  placeholder="请输入新机价格" size="mini"></el-input>
      </div>
      <div style="display: flex;margin-bottom: 10px;align-items: flex-start">
        <div style="min-width: 100px;text-align: right">新机照片：</div>
        <div class="imgs">
          <p class="note">最多支持上传6张照片</p>
          <div class="uploader-wrapper">
            <div class="img-wrapper" v-for="(img,index) in changeCompleteDialog.row.new_uimgs" :key="index" draggable="true"
                 @dragstart="dragstart(index)" @dragend="dragEnd" @dragover="dragover"
                 :id="'img-'+index">
              <div class="drag-notice" v-if="changeCompleteDialog.row.new_uimgs.length>1">拖拽排序</div>
              <el-image fit="contain" class="img" :src="img" alt="" :preview-src-list="changeCompleteDialog.row.new_uimgs" @click.stop="closePreview"></el-image>
              <span class="delete-img-icon el-icon-error" @click="deleteImg(index)"></span>
            </div>
            <el-upload
                ref="uploader"
                v-if="changeCompleteDialog.row.new_uimgs.length<6"
                action="#"
                :limit="1"
                list-type="picture-card"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="getFile">
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <div style="text-align: right;margin:20px 0">
        <el-button type="primary" size="mini" plain @click="changeCompleteDialog.isShow=false">取消</el-button>
        <el-button type="primary" size="mini" @click="changeCompleteConfirm">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  addPhoneModel2, completeOrder, imageUp, orderCostPrice, orderDel,
  orderHandle,
  orderList,
  orderRemarks, orderScrapPrice,
  selectPass,
  selectStore
} from "../../../utils/request/changePhone";
import environments from "../../../config/url";

export default {
  name: "index",
  data() {
    return {
      formItemList: [
        {
          slotName: 'channelAndStore'
        },
        {
          key: 'order_sn',
          type: 'input',
          labelName: '订单编号:',
          placeholder: '请输入订单编号'
        }, {
          key: 'name',
          type: 'input',
          labelName: '下单人:',
          placeholder: '请输入下单人名称'
        }, {
          key: 'phone',
          type: 'input',
          labelName: '联系方式:',
          placeholder: '请输入下单人手机号码'
        },
        {
          key: 'orderTime',
          type: 'daterange',
          labelName: '下单时间:',
          placeholder: '请选择下单时间',
          valueFormat: "timestamp"
        },
        {
          key: 'old_model',
          idKey: 'model_name',
          labelKey: 'model_name',
          type: 'selectFilterable',
          labelName: '旧机机型:',
          option: [],
          placeholder: '请选择旧机机型'
        },
        // {
        //   key: 'is_repair',
        //   idKey: 'id',
        //   labelKey: 'name',
        //   type: 'select',
        //   labelName: '是否有维修历史:',
        //   option: [{name: '是', id: 1}, {name: '否', id: 0},],
        //   placeholder: '请选择'
        // },
        {
          key: 'exa_status',
          idKey: 'id',
          labelKey: 'name',
          type: 'select',
          labelName: '订单状态:',
          option: [{name: '待处理', id: 0}, {name: '换机完成', id: 1}, {name: '取消', id: 2}, {name: '作废', id: 3},],
          placeholder: '请选择'
        },
        {
          key: 'new_way',
          idKey: 'id',
          labelKey: 'name',
          type: 'select',
          labelName: '焕新方式:',
          option: [{name: '到店焕新', id: 1}, {name: '快递上门', id: 2},],
          placeholder: '请选择焕新方式'
        },
      ],
      pass_id: '',
      store_id: '',
      order_sn: '',
      name: '',
      phone: '',
      orderTime: '',
      beg_time: '',
      end_time: '',
      old_model: '',
      exa_status: '',
      new_way: '',

      channelList: [],
      storeList: [],

      tableColumns: [
        {label: "订单编号", prop: "order_sn", width: '150px'},
        {slotName: "pass_name"},
        {slotName: "store_name"},
        {label: "下单时间", prop: "add_time", width: '150px'},
        {label: "下单人", prop: "name"},
        {label: "联系方式", prop: "phone", width: '100px'},
        {slotName: 'oldProp'},
        {slotName: 'query'},
        // {slotName: 'phone_status'},
        // {slotName: 'is_repair'},
        {slotName: 'new_way'},
        {slotName: 'newProp'},
        // {label: "迭代补差金额", prop: "diff_money"},
        // {label: "旧机抵扣金额", prop: "deduct_price"},
        // {label: "焕新价格", prop: "money"},
        // {label: "成本费用", prop: "cost_price"},
        // {label: "利润", prop: "profit_price"},
        {slotName: 'money'},
        {slotName: 'scrap_price'},
        {slotName: 'cost_price'},
        {slotName: 'profit_price'},
        {slotName: 'exa_status'},
        {slotName: 'operation'},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      remarkDialog: {
        isShow: false,
        row: {},
      },
      voidDialog: {
        isShow: false,
        row: {},
      },
      cancelDialog: {
        isShow: false,
        row: {},
      },
      modifyCostDialog:{
        isShow: false,
        row: {},
      },
      residualValueDialog:{
        isShow: false,
        row: {},
      },
      changeCompleteDialog:{
        isShow: false,
        row: {},
      },
      deleteDialog:{
        isShow: false,
        row: {},
      },
      dragTargetIndex: null,
      dragStartIndex: null
    };
  },
  mounted() {
    this.order_sn=this.$route.query.order_sn||''

    this.getList()
    this.getChannelList()
    this.getPhoneModelList()
  },
  methods: {
    getChannelList() {
      selectPass({}).then(res => {
        this.channelList = res.data || []
      })
    },
    channelChange() {
      this.store_id = ''
      this.getStoreList()
    },
    getStoreList() {
      selectStore({
        pass_id: this.pass_id
      }).then(res => {
        this.storeList = res.data || []
      })
    },
    getPhoneModelList() {
      addPhoneModel2().then(res => {
        this.formItemList[5].option = res.data
      })
    },
    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    query(data) {
      this.order_sn = data.order_sn
      this.name = data.name
      this.phone = data.phone
      this.beg_time = data.orderTime && data.orderTime[0] || ''
      this.end_time = data.orderTime && data.orderTime[1] || ''
      this.old_model = data.old_model
      this.exa_status = data.exa_status
      this.new_way = data.new_way

      this.curPage = 1;
      this.getList();
    },
    remarkOperation(row) {
      this.$set(this.remarkDialog, 'isShow', true)
      this.$set(this.remarkDialog, 'row', JSON.parse(JSON.stringify(row)))
    },
    remarkCancel() {
      this.$set(this.remarkDialog, 'isShow', false)
      this.$set(this.remarkDialog, 'row', {})
    },
    saveRemark() {
      let {row} = this.remarkDialog
      orderRemarks({
        order_id: row.order_id,
        remarks: row.remarks
      }).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '编辑成功')
        this.$set(this.remarkDialog, 'isShow', false)
      }).catch(err => {
        console.error(err)
      })
    },
    voidConfirm() {
      if (!this.voidDialog.row.remarks4) {
        this.$message.error('请输入作废原因')
        return
      }
      orderHandle({
        order_id:this.voidDialog.row.order_id,
        exa_status:3,
        remarks4:this.voidDialog.row.remarks4
      }).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '作废成功')
        this.$set(this.voidDialog, 'isShow', false)
      }).catch(err => {
        console.error(err)
      });
    },
    cancelConfirm() {
      if (!this.cancelDialog.row.remarks4) {
        this.$message.error('请输入取消原因')
        return
      }
      orderHandle({
        order_id:this.cancelDialog.row.order_id,
        exa_status:2,
        remarks4:this.cancelDialog.row.remarks4
      }).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '取消成功')
        this.$set(this.cancelDialog, 'isShow', false)
      }).catch(err => {
        console.error(err)
      });
    },
    deleteConfirm() {
      orderDel({
        order_id:this.deleteDialog.row.order_id
      }).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '删除成功')
        this.$set(this.deleteDialog, 'isShow', false)
      }).catch(err => {
        console.error(err)
      });
    },
    modifyCostConfirm() {
      let {row}=this.modifyCostDialog
      orderCostPrice({
        order_id:row.order_id,
        new_purchase:row.new_purchase,
        logistics_price:row.logistics_price,
        other_price:row.other_price,
        remarks2:row.remarks2,
      }).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '修改成功')
        this.$set(this.modifyCostDialog, 'isShow', false)
      }).catch(err => {
        console.error(err)
      })
    },
    residualValueConfirm() {
      let {row}=this.residualValueDialog
      orderScrapPrice({
        order_id:row.order_id,
        scrap_price:row.scrap_price,
        remarks3:row.remarks3
      }).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '修改成功')
        this.$set(this.residualValueDialog, 'isShow', false)
      }).catch(err => {
        console.error(err)
      })
    },
    changeCompleteConfirm() {
      let {row}=this.changeCompleteDialog
      completeOrder({
        order_id:row.order_id,
        id_card:row.id_card,
        new_uprice:row.new_uprice,
        new_imei:row.new_imei,
        old_imei:row.old_imei,
        new_uimgs:row.new_uimgs,
      }).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '修改成功')
        this.$set(this.changeCompleteDialog, 'isShow', false)
      }).catch(err => {
        console.error(err)
      })
    },
    exportData() {
      let formData = this.$refs.form.formData
      window.location.href =
          `${environments[process.env.VUE_APP_BASE_URL].baseURL}/Passageway/orderList?download=1&order_sn=${formData.order_sn}&name=${formData.name}&phone=${formData.phone}&beg_time=${formData.orderTime && formData.orderTime[0] || ''}&end_time=${formData.orderTime && formData.orderTime[1] || ''}&old_model=${formData.old_model}&exa_status=${formData.exa_status}&new_way=${formData.new_way}`
    },
    getList() {
      orderList({
        page: this.curPage,
        limit: 10,
        phone: this.phone,
        name: this.name,
        order_sn: this.order_sn,
        pass_id: this.pass_id,
        store_id: this.store_id,
        old_model: this.old_model,
        exa_status: this.exa_status,
        new_way: this.new_way,
        beg_time: this.beg_time,
        end_time: this.end_time
      }).then(res => {
        this.tableData = res.data || [];
        this.totalElements = res.count || 0
      })
    },
    moneyInput(row, prop) {
      row[prop] = row[prop].replace('。', '.')
      let value = row[prop]
      if (value.indexOf('.') === value.length - 1) {
        return
      }
      value = value.replace(/[^\d.]/g, '');
      value = value.replace('..', '');
      if (value) {
        this.$set(row,prop,parseFloat(parseFloat(value).toFixed(2)))
        // row[prop] = parseFloat(parseFloat(value).toFixed(2));
      } else {
        // row[prop] = ''
        this.$set(row,prop,'')
      }
    },
    getFile(file) {
      let loadingInstance = this.$loading({
        target: '.imgs'
      })
      this.$refs.uploader.clearFiles()
      let formData = new FormData();
      formData.append('file', file.raw)
      imageUp(formData).then(res => {
        this.changeCompleteDialog.row.new_uimgs.push(res.data.url)
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        loadingInstance.close()
      })
    },
    dragEnd(e) {
      let new_uimgs = JSON.parse(JSON.stringify(this.changeCompleteDialog.row.new_uimgs))
      let temp = new_uimgs[this.dragTargetIndex]
      new_uimgs[this.dragTargetIndex] = new_uimgs[this.dragStartIndex]
      new_uimgs[this.dragStartIndex] = temp
      this.changeCompleteDialog.row.new_uimgs = new_uimgs
    },
    dragstart(index) {
      this.dragStartIndex = index
    },
    dragover(e) {
      let target;
      e.path.forEach(item => {
        if (item && item.className && item.className.indexOf('img-wrapper') !== -1) {
          target = item
        }
      })
      let targetId = target.id
      this.dragTargetIndex = targetId.replace('img-', '')
    },
    deleteImg(index) {
      this.$delete(this.changeCompleteDialog.row.new_uimgs, index)
    },
    closePreview() {
      // 获取遮罩层dom
      setTimeout(function(){
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if(e.target.parentNode.className === 'el-image-viewer__actions__inner') {
            return;  //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      },300)
    }
  }
}
</script>

<style scoped>
::v-deep textarea {
  min-height: 7em !important;
}

.popover-table, .popover-table td {
  border: 1px solid #c4d7fd;
}

.popover-table td {
  padding: 10px;
}

.imgs {
  border: 1px solid #E4ECFD;
  border-radius: 3px;
  padding: 10px 20px 0;
  width: 620px;
}

.note {
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
}

.note2 {
  font-size: 12px;
  color: #999
}

.img-wrapper {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  position: relative;
  border: 1px dashed #c0ccda;
  border-radius: 5px;
  margin-bottom: 20px;
}

.img-wrapper:hover {
  border: 1px dashed #0981FF;

}

.img-wrapper:hover .drag-notice {
  display: block;
}
::v-deep .el-upload--picture-card{
  margin-bottom: 20px;
}
.drag-notice {
  display: none;
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #00000055;
  z-index: 99;
  line-height: 30px;
  text-align: center;
  color: #fff;
}

.img-wrapper:hover .delete-img-icon {
  display: block;
}

.delete-img-icon {
  position: absolute;
  color: red;
  right: -10px;
  top: -10px;
  font-size: 30px;
  display: none;
  z-index: 999;
}

.img {
  width: 148px;
  height: 148px;
}

.uploader-wrapper {
  display: flex;
  flex-wrap: wrap;
}
::v-deep .el-form-item--small {
  float: none!important;
}
</style>
