import Layout from '@/layout/layout'

const DemoVideoRouter = {
  path: '/Model',
  component: Layout,
  redirect: 'noRedirect',
  name: '机型库管理',
  children: [
    {
      path:'list',
      component: () => import('@/views/Model/list/index'),
      name: '机型管理列表',
      meta: { title: '机型管理列表', noCache: true }
    },
    {
        path:'editAdd',
        component: () => import('@/views/Model/list/editAdd'),
        name: '机型详情',
        meta: { title: '机型详情', noCache: true }
      },
    {
      path:'Brand',
      component: () => import('@/views/Model/Brand/index'),
      name: '品牌管理',
      meta: { title: '品牌管理', noCache: true }
    },
    {
        path:'format',
        component: () => import('@/views/Model/format/index'),
        name: '规格管理',
        meta: { title: '规格管理', noCache: true }
    },
    {
        path:'manage',
        component: () => import('@/views/Model/manage/index'),
        name: '机型管理',
        meta: { title: '机型管理', noCache: true }
    },
  ]
}

export default DemoVideoRouter
