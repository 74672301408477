<template>
  <el-table
      style="width: 100%"
      v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-table>
</template>

<script>
export default {
  name: "CyberpunkTable",
  props: {}
}
</script>

<style scoped lang="scss">
.el-table, .el-table__header-wrapper {
  background: #162241;
}

.el-table::before {
  //background: #00FFFF;
  width: 0;
}

::v-deep .el-table__header th {
  background: #162241;
  border-right: none;

  &:not(.gutter) {
    border-bottom: 2px solid #00FFFF !important;
  }

  .cell {
    color: #00FFFF;
  }
}

::v-deep .el-table__body td {
  background: #162241;
  border-right: none;
  border-bottom: 1px solid #00FFFF;

  .cell {
    color: #fff;
  }
}

::v-deep tr:hover > td {
  background: #236464 !important;
}
</style>
