<template>
  <div class="visualization">
    <el-scrollbar>
      <div class="head">
        <div class="logo">
          <img src="@/assets/images/ydlogo.png" alt/>
          <span>亚丁科技-后台管理系统</span>
        </div>
        <div class="forehead">
          <div class="title">亚丁科技数据统计</div>
        </div>
        <div class="account-info">
          <img class="avatar" src="@/assets/images/Avatar.png" alt="">
          <span class="username">欢迎您，王大老板（{{ userName }}）</span>
        </div>
      </div>
      <div class="container">
        <Aside class="aside"></Aside>
        <div class="content">
          <div class="inquiry-wrapper">
            <div class="time-period">
              <span class="label">时间段查询</span>
              <cyberpunk-date-picker @change="periodChange" v-model="period"></cyberpunk-date-picker>
            </div>
          </div>
          <div class="grid">
            <div class="top-left">
              <div class="statistics-wrapper" v-if="statisticsData">
                <!-- <cyberpunk-box class="statistics">
                  <div class="top">
                    <img class="icon" src="./img/lrtb.png" alt="">
                    <span class="title">利润贡献</span>
                    <span>
                      <span class="digital">{{ statisticsData.profit }}</span>
                      <span class="unit">元</span>
                    </span>
                  </div>
                  <div class="bottom-content">
                    <p>注册金额：{{ statisticsData.zhuce[0].cost_price || 0 }}元</p>
                    <p>理赔金额：{{ statisticsData.lipei[0].pay_price || 0 }}元</p>
                  </div>
                </cyberpunk-box> -->
                <cyberpunk-box class="statistics">
                  <div class="top">
                    <img class="icon" src="./img/zcddtb.png" alt="">
                    <span class="title">注册订单</span>
                    <span>
                    <span class="digital">{{ statisticsData.zhuce[0].num || 0 }}</span>
                    <span class="unit">单</span>
                  </span>
                  </div>
                  <!-- <div class="bottom-content">
                    <p>注册金额：{{ statisticsData.zhuce[0].cost_price || 0 }}元</p>
                  </div> -->
                </cyberpunk-box>
                <cyberpunk-box class="statistics">
                  <div class="top">
                    <img class="icon" src="./img/lpddtb.png" alt="">
                    <span class="title">理赔订单</span>
                    <span>
                    <span class="digital">{{ statisticsData.lipei[0].lpnum }}</span>
                    <span class="unit">单</span>
                    <span class="line"></span>
                    <span class="digital">{{ statisticsData.lipei[0].pay_price }}</span>
                    <span class="unit">元</span>
                  </span>
                  </div>
                  <!-- <div class="bottom-content">
                    <p>报案：{{ statisticsData.report[0].renum }}单 <span class="line"></span>
                      {{ statisticsData.report[0].pay_price }}元</p>
                    <p>承案：{{ statisticsData.acc[0].accnum || 0 }}单 <span class="line"></span>
                      {{ statisticsData.acc[0].cost_price || 0 }}元</p>
                  </div> -->
                </cyberpunk-box>
              </div>
              <cyberpunk-box class="table_1-wrapper" :type="2" style="width: 100%">
                <cyberpunk-title :length="5">
                  商户利润年度排行榜
                </cyberpunk-title>
                <cyberpunk-table
                    max-height="560rpx"
                    :data="merchantProfit.data">
                  <el-table-column
                      type="index"
                      width="40">
                  </el-table-column>
                  <el-table-column
                      prop="group_name"
                      label="商户名称"
                      align="center">
                  </el-table-column>
                  <el-table-column
                      prop="num"
                      label="注册数量"
                      align="center">
                  </el-table-column>
                  <el-table-column
                      prop="lpnum"
                      label="理赔数量"
                      align="center">
                  </el-table-column>
                  <el-table-column
                      prop="order_lipei_ratio"
                      label="理赔占比"
                      align="center">
                  </el-table-column>
                  <el-table-column
                      prop="profit_lipei_ratio"
                      label="注册占比"
                      align="center">
                  </el-table-column>
                </cyberpunk-table>
                <cyberpunk-pagination class="pagination"
                                      layout="total, sizes, prev, pager, next, jumper"
                                      @size-change="size=>{sizeChange(size,'merchantProfit')}"
                                      @current-change="page=>{currentChange(page,'merchantProfit')}"
                                      :current-page.sync="merchantProfit.currentPage"
                                      :page-size="10"
                                      :total="merchantProfit.total"></cyberpunk-pagination>
              </cyberpunk-box>
            </div>
            <cyberpunk-box class="bottom_2" :type="2">
              <cyberpunk-title :length="4">
                产品利润排行榜
              </cyberpunk-title>
              <cyberpunk-table
                  max-height="600px"
                  :data="insuranceType.data[insuranceType.currentPage-1]">
                <el-table-column
                    type="index"
                    width="40">
                </el-table-column>
                <el-table-column
                    prop="instype_name"
                    label="产品"
                    width="200px"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="注册单数"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="profit"
                    label="注册占比"
                    align="center">
                </el-table-column>
              </cyberpunk-table>
              <cyberpunk-pagination class="pagination"
                                    :pager-count="5"
                                    layout="total, prev, pager, next, jumper"
                                    @size-change="insuranceTypeSizeChange"
                                    @current-change="page=>{insuranceTypeCurrentChange}"
                                    :current-page.sync="insuranceType.currentPage"
                                    :page-size="10"
                                    :total="insuranceType.total"></cyberpunk-pagination>
            </cyberpunk-box>
            <!-- <cyberpunk-box class="top-right" :type="2">
              <div class="selector-wrapper">
                <cyberpunk-title :length="2" style="width: 200px;">
                  险种累计
                </cyberpunk-title>
                <cyberpunk-selector v-model="selectedInsuranceType" class="insurance-selector" :list="insuranceList"
                                    value-key="instype_id"
                                    label-key="instype_name" @change="insuranceTypeChange"></cyberpunk-selector>
              </div>
              <div class="echarts echarts1"></div>
              <div class="echarts echarts2"></div>
            </cyberpunk-box> -->
            <!-- <cyberpunk-box class="bottom_1" :type="2">
              <cyberpunk-title :length="5">
                商户利润年度排行榜
              </cyberpunk-title>
              <div class="info" v-if="merchantProfit2.info">
                <span>商户：{{ merchantProfit2.info.shangNum }}家</span>
                <span>门店：{{ merchantProfit2.info.menNum }}家</span>
                <span>注册单数：{{ merchantProfit2.info.zongNum }}单</span>
                <span>利润：{{ merchantProfit2.info.zongProfit }}元</span>
              </div>
              <cyberpunk-table
                  max-height="540px"
                  :data="merchantProfit2.data">
                <el-table-column
                    type="index"
                    width="40">
                </el-table-column>
                <el-table-column
                    prop="group_name"
                    label="商户名称"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="注册单数量"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="profit"
                    label="利润"
                    align="center">
                </el-table-column>
              </cyberpunk-table>
              <cyberpunk-pagination class="pagination"
                                    layout="total, prev, pager, next, jumper"
                                    @size-change="size=>{sizeChange(size,'merchantProfit2')}"
                                    @current-change="page=>{currentChange(page,'merchantProfit2')}"
                                    :current-page.sync="merchantProfit2.currentPage"
                                    :page-size="10"
                                    :total="merchantProfit2.total"></cyberpunk-pagination>
            </cyberpunk-box>
            <cyberpunk-box class="bottom_2" :type="2">
              <cyberpunk-title :length="4">
                产品利润排行榜
              </cyberpunk-title>
              <cyberpunk-table
                  max-height="600px"
                  :data="insuranceType.data[insuranceType.currentPage-1]">
                <el-table-column
                    type="index"
                    width="40">
                </el-table-column>
                <el-table-column
                    prop="instype_name"
                    label="产品"
                    width="200px"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="注册单数"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="profit"
                    label="利润"
                    align="center">
                </el-table-column>
              </cyberpunk-table>
              <cyberpunk-pagination class="pagination"
                                    :pager-count="5"
                                    layout="total, prev, pager, next, jumper"
                                    @size-change="insuranceTypeSizeChange"
                                    @current-change="page=>{insuranceTypeCurrentChange}"
                                    :current-page.sync="insuranceType.currentPage"
                                    :page-size="10"
                                    :total="insuranceType.total"></cyberpunk-pagination>
            </cyberpunk-box>
            <cyberpunk-box class="bottom_2" :type="2">
              <cyberpunk-title :length="2">
                维修商家
              </cyberpunk-title>
              <cyberpunk-table
                  max-height="600px"
                  :data="repairer.data">
                <el-table-column
                    type="index"
                    width="40">
                </el-table-column>
                <el-table-column
                    prop="manager_name"
                    label="商户名称"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="数量"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="money"
                    label="金额"
                    align="center">
                </el-table-column>
              </cyberpunk-table>
              <cyberpunk-pagination class="pagination"
                                    layout="total, prev, pager, next, jumper"
                                    @size-change="size=>{sizeChange(size,'repairer')}"
                                    @current-change="page=>{currentChange(page,'repairer')}"
                                    :current-page.sync="repairer.currentPage"
                                    :page-size="10"
                                    :total="repairer.total"></cyberpunk-pagination>
            </cyberpunk-box> -->
          </div>
        </div>
      </div>

    </el-scrollbar>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {Aside} from "@/layout/components";
import CyberpunkDatePicker from './components/CyberpunkDatePicker'
import CyberpunkBox from './components/CyberpunkBox'
import CyberpunkTable from "./components/CyberpunkTable";
import CyberpunkTitle from "./components/CyberpunkTitle";
import CyberpunkPagination from "./components/CyberpunkPagination";
import {test1, test2, test3, test4, test5} from "../../utils/request/visualization";

export default {
  name: "Visualization",
  components: {
    CyberpunkPagination, CyberpunkTitle, CyberpunkTable, Aside, CyberpunkDatePicker, CyberpunkBox
  },
  data() {
    return {
      period: [],
      statisticsData: null,
      merchantProfit: {
        data: [],
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      merchantProfit2: {
        data: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
        info: {}
      },
      insuranceType: {
        data: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      repairer: {
        data: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      insuranceList: [],
      selectedInsuranceType: '',
      echarts1: null,
      echarts2: null,
      echartsOptions1: {
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#00BBFF'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#00BBFF'
            }
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          containLabel: true,
          y2: 10
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        toolTip: {
          show: true,
          trigger: 'item'
        },
        series: [
          {
            data: [],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(0,178, 255, 0.2)'
            }
          }
        ]
      },
      echartsOptions2: {
        legend: {
          top: 'bottom',
          textStyle: {
            color: '#00BBFF'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color: [ 'rgba(87,183,253,75%)', 'rgba(113,243,122,75%)', 'rgba(255,206,83,75%)','rgba(232,114,114,75%)'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: [20, 100],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: []
          }
        ]
      },
    }
  },
  computed: {
    userName() {
      let admin = localStorage.getItem('admin');
      if (admin) {
        return JSON.parse(admin).user_name
      }
      return '管理员'
    }
  },
  mounted() {
    // this.echarts1 = echarts.init(document.querySelector('.echarts1'))
    // this.echarts2 = echarts.init(document.querySelector('.echarts2'))


    this.resetPeriod()
    this.getStatisticalData()
    this.getMerchantProfitData()
    this.getMerchantProfit2Data()
    this.getInsuranceTypeData()
    this.getRepairerData()
    // this.getChartsData()
  },
  beforeMount() {
    // this.fullScreen(1)
  },
  beforeDestroy() {
    // this.fullScreen(0)
  },
  methods: {
    periodChange(e) {
      this.$nextTick(() => {
        if (!e) {
          this.resetPeriod()
        }
        this.getStatisticalData()

        this.merchantProfit.currentPage = 1
        this.getMerchantProfitData()

        this.merchantProfit2.currentPage = 1
        this.getMerchantProfit2Data()

        this.insuranceType.currentPage = 1
        this.getInsuranceTypeData()

        this.repairer.currentPage = 1
        this.getRepairerData()
      })
    },
    resetPeriod() {
      this.period = []
      let dateEnd = new Date()
      let dateStart = new Date(+new Date() - 1000 * 60 * 60 * 24 * 356 / 2)
      this.period[0] = `${dateStart.getFullYear()}-${(dateStart.getMonth() + 1 + '').padStart(2, '0')}-${('' + dateStart.getDate()).padStart(2, '0')}`
      this.period[1] = `${dateEnd.getFullYear()}-${(dateEnd.getMonth() + 1 + '').padStart(2, '0')}-${('' + dateEnd.getDate()).padStart(2, '0')}`
    },
    getStatisticalData() {
      test1({
        beg_time: this.period[0],
        end_time: this.period[1]
      }).then(res => {
        this.statisticsData = res && res.data || {}
      }).catch(err => {

      })
    },
    getMerchantProfitData() {
      test5({
        beg_time: this.period[0],
        end_time: this.period[1],
        page: this.merchantProfit.currentPage,
        limit: this.merchantProfit.pageSize
      }).then(res => {
        this.merchantProfit.data = res && res.data || []
        this.merchantProfit.total = res && res.count || 0
      }).catch(err => {

      })
    },
    getMerchantProfit2Data() {
      test5({
        beg_time: this.period[0],
        end_time: this.period[1],
        page: this.merchantProfit2.currentPage,
        limit: this.merchantProfit2.pageSize
      }).then(res => {
        this.merchantProfit2.data = res && res.data || []
        this.merchantProfit2.total = res && res.count || 0
        this.merchantProfit2.info = res && res.config || {}
      }).catch(err => {

      })
    },
    getInsuranceTypeData() {
      test2({
        beg_time: this.period[0],
        end_time: this.period[1],
      }).then(res => {
        let list = res && res.data || []
        list.unshift({
          instype_id: '',
          instype_name:'全部类型'
        })
        this.insuranceList = list
        this.selectedInsuranceType = list[0] && list[0].instype_id
        this.insuranceType.total = list.length || 0

        this.insuranceTypeSplit(list, this.insuranceType.pageSize)

        // this.getChartsData()
      }).catch(err => {

      })
    },
    getChartsData() {
      test4({
        beg_time: this.period[0],
        end_time: this.period[1],
        instype_id: this.selectedInsuranceType,
      }).then(res => {
        let data1 = res.zhu || [];
        let colors = [ 'rgba(87,183,253,75%)', 'rgba(113,243,122,75%)', 'rgba(255,206,83,75%)','rgba(232,114,114,75%)','rgba(179,122,232,75%)']
        this.echartsOptions1.xAxis.data = []
        this.echartsOptions1.series[0].data = []
        data1.forEach((item, index) => {
          this.echartsOptions1.xAxis.data.push(item.name)
          this.echartsOptions1.series[0].data.push({
            value: item.value,
            itemStyle: {
              color: colors[index % colors.length]
            }
          })
        })

        let data2 = res.ratio || []
        data2.forEach(item => {
          if (typeof item.value === 'string') {
            item.value = parseFloat(item.value)
          }
        })
        this.echartsOptions2.series[0].data = data2

        this.echarts1.setOption(this.echartsOptions1);
        this.echarts2.setOption(this.echartsOptions2);
      })
    },
    insuranceTypeSplit(list, length) {
      let resultList = [[]]

      for (let i = 0; i < list.length; i++) {
        if ((i + 1) % length === 0 && i !== list.length - 1) {
          resultList.push([])
        }
        resultList[Math.floor((i) / length)].push(list[i])
      }
      this.insuranceType.data = resultList
    },
    insuranceTypeSizeChange(size) {
      this.insuranceType.pageSize = size
      this.insuranceTypeSplit(this.insuranceType.data.flat(Infinity), size)
    },
    insuranceTypeCurrentChange(page) {
      this.insuranceType.currentPage = page
    },
    insuranceTypeChange() {
      // this.getChartsData()
    },
    getRepairerData() {
      test3({
        beg_time: this.period[0],
        end_time: this.period[1],
        page: this.repairer.currentPage,
        limit: this.repairer.pageSize
      }).then(res => {
        this.repairer.data = res && res.data || []
        this.repairer.total = res && res.count || 0
      }).catch(err => {

      })
    },
    sizeChange(size, type) {
      this[type].currentPage = 1
      this[type].pageSize = size
      this.getDataByTypeName(type)
    },
    currentChange(page, type) {
      this[type].currentPage = page
      this.getDataByTypeName(type)
    },
    getDataByTypeName(type) {
      let functionName = 'get' + type.replace(/^[a-z]/, initial => (initial.toUpperCase())) + 'Data';
      if (typeof this[functionName] === 'function') {
        this[functionName]()
      }
    },
    fullScreen(status) {
      let element = document.documentElement;
      if (status) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    }
  }

}
</script>

<style scoped lang="scss">

.visualization {
  width: 100%;
  height: 100vh;
  background-image: url("./img/bg.jpg");
  background-size: 50px 50px;
  cursor: url(./img/cursor.ico), auto;
}

::v-deep .el-scrollbar {
  height: 100%;
}

::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  height: calc(100vh + 18px);
}

::v-deep .aside {
  position: relative;
  top: -50px;
  background: transparent !important;

  * {
    background: transparent !important;
  }
}

.head {
  height: 114px;
  width: 100%;
  display: flex;

  .logo {
    width: calc((100vw - 1250px) / 2);
    height: 64px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 22px;
    font-weight: 400;

    img {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      margin: 0 18px 0 34px;
    }
  }

  .account-info {
    width: calc((100vw - 1250px) / 2);
  }

  .forehead {
    width: 1250px;
    height: 114px;
    line-height: 72px;
    text-align: center;
    background-image: url("./img/dbbj.png");
    background-position: 100% 100%;
    flex: 1;
    color: #23CEFD;
    user-select: none;

    .title {
      width: 1250px;
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      color: rgba(35, 206, 253, 0.6);
      background: -webkit-linear-gradient(-30deg, rgba(255, 255, 255, 0) 100px, rgba(255, 255, 255, 1) 180px, rgba(255, 255, 255, 1) 230px, rgba(255, 255, 255, 0) 300px) -300px 0 no-repeat;
      -webkit-background-clip: text;
      animation: shine 3s infinite;
    }

  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1250px;
  }
}

.account-info {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #23CEFD;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin-right: 15px;
  }
}

.container {
  display: flex;

  .content {
    flex: 1;
    height: 100%;
  }
}

.inquiry-wrapper {
  display: flex;
  padding-left: 30px;
}

.time-period {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 20px;

  .label {
    white-space: nowrap;
    margin-right: 10px;
  }

}

.grid {
  display: grid;
  /*1920 - 18(滚动条) - 210(侧边栏) -30 * 4*/
  grid-template-columns: repeat(3, 524px);
  grid-template-rows: 820px 0;
  gap: 30px;
  padding: 0 30px 30px 30px;

  .top-left {
    grid-column-start: span 2;
  }
}

.statistics-wrapper {
  display: flex;

  .statistics {
    // height: 141px;
    width: calc((100% - 30px) / 2);
    min-width: 300px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    .top {
      display: flex;
      align-items: center;

      .line {
        display: inline-block;
        width: 2px;
        height: 18px;
        background: #23CEFD;
        margin: 0 8px 0 10px;
      }
    }

    .logo {
      width: 26px;
      height: 26px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      margin-left: 10px;
      flex: 1;
    }

    .digital {
      font-size: 26px;
      color: #58DBFF;
    }

    .unit {
      font-size: 16px;
      color: #58DBFF;
    }

    .bottom-content {
      width: 268px;
      height: 60px;
      line-height: 24px;
      background: linear-gradient(135deg, #1e589f, #00B6FF);
      border-radius: 8px;
      margin: 15px auto;
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .line {
        width: 1px;
        height: 15px;
        background: #FFFFFF;
        display: inline-block;
        position: relative;
        top: 2px;
        margin: 0 5px;
      }
    }
  }
}

.table_1-wrapper {
  width: 100%;
  height: 737px;
  margin-top: 20px;
}

.pagination {
  float: right;
  margin-top: 20px;
  margin-right: 10px;
}

.selector-wrapper {
  display: flex;
  justify-content: space-between;
}

.echarts {
  width: 100%;
  height: calc((100% - 28px) / 2);
}

::v-deep .el-menu-item.is-active {
  background: transparent !important;
  box-shadow: inset 0 0 10px #58DBFF;

  span, .el-icon-s-marketing:before {
    color: #58DBFF !important;
  }
}

.bottom_1 {
  .info {
    height: 28px;
    line-height: 28px;
    border: 1px solid #00BBFF;
    opacity: 0.8;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #58DBFF;
    margin-top: 20px;
    padding: 0 10px;
    box-shadow: inset 0 0 20px #00BBFF;
    display: flex;
    justify-content: space-around;
  }
}
</style>
