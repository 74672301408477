<template>
  <div class="Brand_list">
    <GlobalInfoBar title="品牌列表" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div slot="AddSlot">
        <el-button size="small" @click="addBrand()" type="primary" icon="el-icon-plus">新增品牌</el-button>
      </div>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      
      <el-table-column label="品牌图片" slot="operat_img" align="center">
        <template slot-scope="{ row }">
          <img :src="row.brand_img" alt="" width="50px" height="50px">
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editBrand(row,'edit')" type="text" class="mr_sty">修改</el-button>
          <el-button @click="deleteDis(row)" type="text" class="btn_delete">删除</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :visible.sync="visible" :title="title" width="600px">
      <div v-if="this.title==='新增品牌'" class="tit_color">说明:品牌名称不能重复添加，名称长度限制25字内</div>
      <div v-else class="tit_color">说明:品牌名称不能重复添加，名称长度限制25字内</div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="品牌名称" prop="brand">
          <el-input v-model="ruleForm.brand" placeholder="请输入品牌名称" :maxlength="25"></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="品牌Logo" prop="brand_img">
          <ElImgUpload
            key="brand_img"
            :exist-image="ruleForm.brand_img"
            @handleDelete="handleDelete('brand_img')"
            @handleChange="handleChange"
        />
        <span style="color:#999;margin-left:20px">
          请上传1:1的图片，大小不超过1M
        </span>
        </el-form-item>
        

        <el-form-item class="el_form_r">
          <el-button type="primary" @click="submitForm('ruleForm','add')" v-if="this.title==='新增品牌'">确认新增</el-button>
          <el-button type="primary" @click="submitForm('ruleForm','edit')" v-else>确认修改</el-button>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import _api from "@/utils/request";
export default {
  name: "Brand_list",
  components: { ElImgUpload },
  data() {
    return {
      //弹窗参数
      visible: false,
      title: "",
      ruleForm: {
        brand_id: "", //	是	int	0新增 大于0：修改
        brand: "", //品牌号名称
        brand_img:'',
      },
      rules: {
        brand: [
          { required: true, message: "请填写品牌名称", trigger: "blur" }
        ],
        brand_img: [
          { required: true, message: "请上传品牌图片", trigger: "blur" }
        ],
      },
      //列表
      initData: null,
      end_time: "",
      beg_time: "",
      formItemList: [
        {
          key: "brand",
          type: "input",
          labelName: "品牌名:",
          placeholder: "请输入品牌名"
        }
      ],
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      brand: "",
      tableColumns: [
        { label: "品牌名称", prop: "brand" },
        // { label: "品牌图片", prop: "brand_img" },
        { slotName: "operat_img" },
        { label: "最后更新时间", prop: "add_time" },
        { slotName: "operat" }
      ]
    };
  },
  mounted() {
    this.PostPlanList();
  },
  methods: {
    //列表请求
    PostPlanList() {
      const params = {
        page: this.PlanIpageNum,
        limit: this.PlanIpageSize,
        brand: this.brand
      };
      _api.phoneBrandList(params).then(res => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostPlanList();
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      this.brand = data.brand;
      const params = {
        // page: 1,
        // limit: 10,
        brand: data.brand
      };
      _api.phoneBrandList(params).then(res => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    addBrand() {
      this.ruleForm.brand = "";
      this.ruleForm.brand_img = ''
      this.visible = true;
      this.title = "新增品牌";
    },
    //修改
    editBrand(row, type) {
      this.visible = true;
      this.title = "修改品牌";
      console.log(row,'editBrand')
      this.ruleForm.brand_img = row.brand_img;
      this.ruleForm.brand_id = row.brand_id
      this.ruleForm.brand = row.brand
    },
    //删除
    deleteDis(row){
      console.log(row)
      this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _api.delPhoneBrand({brand_id:row.brand_id}).then(res=>{
            if(res.code===1){
              this.$message.success('删除成功')
              this.PostPlanList()
            }else{
              this.$message.error(res.msg)
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });          
        });
    },
    submitForm(formName, type) {
      if (type === "add") {
        this.ruleForm.brand_id = 0;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          _api.addPhoneBrand(this.ruleForm).then(res => {
            console.log(res);
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.PostPlanList()
            }else{
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.visible = false;
    },
    handleDelete(val) {
        console.log(val);
    },
    // 图标上传回调
    handleChange(e) {
      this.ruleForm.brand_img = e
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Brand_list {
  /deep/ .el-form-item__label{
        width: 70px!important;
    }
  .btn_delete{
    color: #FF687B;
  }
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r{
    float: right;
    margin-right: 80px;
  }
}
</style>
