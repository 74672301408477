<template>
  <div>
    <GlobalInfoBar title="机型配置" subtitle=""/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      >
        <div slot="AddSlot">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addModel">新增机型</el-button>
        </div>
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          v-loading="loading"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
          <el-table-column label="类型" slot="order_mold" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.order_mold=='2'?'以旧换新':'纯回收' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否开启" slot="is_open" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.is_open" :inactive-value="0" :active-value="1"
                       @change="changeSellSwitch(row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="editInfo(row)">修改</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

  </div>
</template>

<script>
import {couponPhoneList,couponPhoneOpen} from "../../../utils/request/coupon";

export default {
  name: "index",
  data() {
    return {
      loading: false,
      formItemList: [
        {
          key: 'model_name',
          type: 'input',
          labelName: '机型名称:',
          placeholder: '请输入机型名称查询'
        },
      ],
      tableColumns: [
        {label: "机型名称", prop: "model_name"},
        {label: "机型规格", prop: "ram_size"},
        {label: "活动价格", prop: "price"},
        {slotName: "order_mold"},
        {slotName: "is_open"},
        {label: "更改时间", prop: "add_time"},
        {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      model_name: '',
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    query(data) {
      this.model_name=data.model_name
      this.curPage = 1
      this.getList()
    },
    getList() {
      this.loading = true
      couponPhoneList({
        page: this.curPage,
        limit: 10,
        model_name: this.model_name
      }).then(res => {
        this.tableData = res.data
        this.totalElements = res.count
        this.loading = false
      })
    },
    addModel() {
      this.$router.push('/coupon/modelOperation')
    },
    currentChange(page) {
      this.curPage = page;
      this.getList()
    },
    editInfo(row) {
      this.$router.push({path:'/coupon/modelOperation',query:{id:row.id,type:'edit'}})
    },
    changeSellSwitch(row) {
      console.log(`%c row`,'color:red;font-size:20px',row)
      couponPhoneOpen({
        id: row.id,
        field: "is_open",
        value: row.is_open,
      }).then(data=>{
        this.$message.success(data&&data.msg||'操作成功')
      }).catch(err=>{
        console.error(err)
      }).finally(()=>{
        this.getList()
      });
    },
  }
}
</script>

<style scoped>
.dialog-content p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.close-reason {
  margin-top: 20px;
  text-align: center;
}

.dialog-textarea {
  width: 300px;
  vertical-align: top;
}

.dialog-btns {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 42px;

}

.dialog-btns .cancel {
  margin-right: 20px;
}
::v-deep textarea {
  min-height: 7em!important;
}
</style>
