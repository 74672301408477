<template>
  <div class="editAdd">
    <GlobalInfoBar :title="titleBar" />
    <GlobalChunk
      :icon="DetailIMG"
      :title="titleDetail"
      :padding="[32, 0, 95, 55]"
    >
      <GlobalForm
        :init-data="initData"
        :form-rules="formRules"
        :form-item-list="formItemList"
        @handleConfirm="handleConfirm"
        :confirmBtnName="confirmBtnName"
      >
        <el-form-item
          class="el_from"
          label="机型名称:"
          slot="operation"
          prop="model_name"
        >
          <el-input
            :disabled="disabled"
            v-model="initData.model_name"
            placeholder="请输入机型名称"
            :maxlength="40"
            clearable
          ></el-input>
          <span class="text_item">
            机型名限制输入40个字符内
          </span>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="机型类型:"
          slot="operation"
          prop="btype"
        >
          <el-select
            :disabled="disabled"
            v-model="initData.btype"
            placeholder="请选择机型类型"
            clearable
            @change="handleChange2"
          >
            <el-option
              v-for="item in options2"
              :key="item.btype"
              :label="item.name"
              :value="item.btype"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          class="el_from"
          label="选择规格:"
          slot="operation"
          prop="ram_size"
        >
          <el-select
            :disabled="disabled"
            v-model="initData.ram_size"
            :placeholder="options1.length ? '请选择规格' : '请先选择机型类型'"
            clearable
            @change="handleChange1"
          >
            <el-option
              v-for="item in options1"
              :key="item.ram_size"
              :label="item.ram_size"
              :value="item.ram_size"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="选择类型:"
          slot="operation"
          prop="order_mold"
        >
          <el-select
            v-model="initData.order_mold"
            placeholder="请选择类型"
            clearable
          >
            <el-option
              v-for="item in orderMoldOptions"
              :key="item.key"
              :label="item.name"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="活动价格:"
          slot="operation"
          prop="price"
        >
          <el-input
            v-model="initData.price"
            placeholder="请输入活动价格（元）"
            clearable
          ></el-input>
          <span class="text_item">
            请输入大于0小于1000000的数字（最多支持2位小数）
          </span>
        </el-form-item>
        <el-button slot="btnSlot" @click="FromBack">取消</el-button>
      </GlobalForm>
    </GlobalChunk>
  </div>
</template>

<script>
import {
  couponPhoneRam,
  couponPhonePriceInfo,
  addCouponPhonePrice,
} from "../../../utils/request/coupon";
import { formValidateNumberSeven } from "@/utils/form-validate";
export default {
  name: "editAdd",
  data() {
    return {
      disabled: false,
      titleBar: "",
      confirmBtnName: "",
      titleDetail: "",
      DetailIMG: "",
      options1: [],
      orderMoldOptions: [
        {
          key: 2,
          name: "以旧换新",
        },
        {
          key: 3,
          name: "纯回收",
        },
      ],
      options2: [
        {
          btype: 1,
          name: "苹果",
        },
        {
          btype: 2,
          name: "安卓",
        },
      ],
      initData: {
        model_name: "",
        btype: "",
        ram_size: "",
        price: "",
        order_mold: "",
      },
      formItemList: [{ slotName: "operation" }],
      formRules: {
        model_name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入机型名称",
          },
        ],
        btype: [
          {
            required: true,
            trigger: "change",
            message: "请选择机型类型",
          },
        ],
        price: [
          {
            required: true,
            trigger: "blur",
            message: "请输入活动价格",
          },
          {
            required: true,
            trigger: "blur",
            validator: formValidateNumberSeven,
          },
        ],
        order_mold: [
          {
            required: true,
            trigger: "change",
            message: "请选择类型",
          },
        ],
        ram_size: [
          {
            required: true,
            trigger: "change",
            message: "请选择规格",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    if (this.$route.query.type === "edit") {
      this.titleBar = "修改机型";
      this.confirmBtnName = "确认修改";
      this.disabled = true;
      couponPhonePriceInfo({ id: this.$route.query.id }).then((res) => {
        if (res.code === 1) {
          this.initData.btype = res.data.btype;
          this.initData.ram_size = res.data.ram_size;
          this.initData.order_mold = res.data.order_mold;
          this.initData.model_name = res.data.model_name;
          this.initData.price = res.data.price;
        }
      });
    } else {
      this.titleBar = "新增机型";
      this.confirmBtnName = "确认添加";
      this.disabled = false;
    }
  },
  methods: {
    // 选择
    handleChange1(val) {
      this.initData.ram_size = val;
    },
    handleChange2(val) {
      this.initData.btype = val;
      this.initData.ram_size = "";
      this.options1 = [];
      if (val) {
        this.phoneRam();
      }
    },
    // 规格下拉
    phoneRam() {
      couponPhoneRam({ btype: this.initData.btype }).then((res) => {
        if (res.code === 1) {
          this.options1 = res.data;
        }
      });
    },
    // 提交
    handleConfirm(data, cd) {
      cd();
      if (this.$route.query.type === "edit") {
        const params = {
          id: this.$route.query.id,
          model_name: this.initData.model_name,
          btype: this.initData.btype,
          ram_size: this.initData.ram_size,
          order_mold: this.initData.order_mold,
          price: this.initData.price,
        };
        addCouponPhonePrice(params).then((res) => {
          if (res.code === 1) {
            this.$message.success(res.msg);
            this.$router.push("/coupon/modelConfig");
          }
        });
      } else {
        const vo = {
          model_name: this.initData.model_name,
          price: this.initData.price,
          ram_size: this.initData.ram_size,
          order_mold: this.initData.order_mold,
          btype: this.initData.btype,
        };
        addCouponPhonePrice(vo).then((res) => {
          if (res.code === 1) {
            this.$message.success(res.msg);
          }
        });
      }
    },
    FromBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
::v-deep .el-form-item__label {
  width: 140px !important;
}
.editAdd {
  .text_item {
    color: #999;
    margin-left: 20px;
  }
  .el_from {
    margin-left: -120px;
  }
  /deep/ .el-input__inner {
    width: 380px;
  }
  /deep/ .el-input {
    width: 380px;
  }
  .item_dia {
    display: flex;
    justify-content: left;
    align-items: center;
    .left {
      width: 60px;
      margin: 0px 0 10px 10px;
      color: black;
    }
    .right {
      margin: 0px 0 10px 10px;
      color: #ff8080;
    }
  }
  .btn_bot {
    float: right;
    margin: 30px;
  }
}
</style>
