<template>
  <div class="push-dialog">
    <template>
      <div class="title">新建{{ types[type] }}推送</div>
      <el-form ref="form" class="form" :model="form" label-width="80px" size="mini">
        <el-form-item prop="title" label="表单名称">
          <el-input v-model="form.title" placeholder="请输入表单名称" maxlength="30" :disabled="isPushing"></el-input>
          <span class="note">必填（30字内）</span>
        </el-form-item>
        <!-- 理赔新加 维修，换机-->
        <el-form-item prop="type" label="理赔类型" v-if="type === 3">
          <el-radio v-model="form.type" label="1">维修</el-radio>
          <el-radio v-model="form.type" label="2">换机</el-radio>
        </el-form-item>
        <el-form-item prop="instype_id" label="产品类型" v-if="type === 1">
          <el-select v-model="form.instype_id" placeholder="请选产品类型" @change="insuranceTypeChange" :disabled="isPushing">
            <el-option v-for="item in insuranceTypeList" :key="item.instype_id" :label="item.instype_name"
              :value="item.instype_id">
            </el-option>
          </el-select>
          <span class="note">仅支持单选，单次推送只能选择一种产品</span>
        </el-form-item>
        <el-form-item prop="file" label="表格上传" style="height: 30px">
          <div style="display: flex">
            <el-upload :disabled="isPushing" ref="uploader" style="width: 300px;" action="#" list-type="text" :limit="1"
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              show-file-list :auto-upload="false" :before-remove="removeFile" :on-change="getFile">
              <el-button type="primary" size="mini" v-if="!form.file" :disabled="isPushing">点击上传</el-button>
            </el-upload>
            <span class="note">建议单次上传数据不超过1000条</span>
          </div>
        </el-form-item>

        <el-form-item prop="process" label="推送进度">
          <div style="display: flex">
            <el-progress :text-inside="true" :format="processFormat" :stroke-width="15" :percentage="percentage"
              class="process"></el-progress>
            <span class="note" style="color: #409EFF" v-if="isPushing">推送中...</span>
            <span class="note" style="color: #67C23A" v-if="beg === sum && beg !== 0">推送完成</span>
            <span class="note" style="color: gray" v-if="beg === 0 && sum === 0">未推送</span>
          </div>
        </el-form-item>

        <div class="pushing-note" v-if="isPushing">
          <p style="color:#e33131">当前订单推送完毕后，才能进行下一单</p>
          <p style="margin-top: 5px"><span>开始推送时间: {{ startPushTime }}</span><span style="margin-left: 20px"> 预计耗时: {{
            timeConsuming }}</span></p>
          <div class="pushing-banner">正在推送，约5秒/单，请勿关闭本弹窗</div>
        </div>

      </el-form>
      <el-button class="push-btn" type="primary" :disabled="!canPush" @click="startPush">开始推送</el-button>
    </template>
  </div>
</template>

<script>
import {
  importAccept,
  importClaim,
  importReport,
  vivoinsImportReport,
  vivoinsImportClaim,
  pullInsTypeList,
  uploadAccept, uploadClaim,
  uploadReport,
  vivoinsUploadReport, vivoinsUploadClaim
} from "../../utils/request";
import { imageUp } from "../../utils/request/changePhone";

export default {
  name: "PushDialog",
  data() {
    return {
      form: {
        title: '',
        instype_id: '',
        type:'',//理赔类型
        file: ''
      },
      insuranceTypeList: [],
      canPush: false,
      beg: 0,
      sum: 0,
      isPushing: false,
      startPushTime: '',
      types: {
        '1': '承保',
        '2': '报案',
        '3': '理赔',
      }
    }
  },
  computed: {
    type() {
      let type = this.$route.query.type
      return type ? Number(type) : 1
    },
    percentage() {
      if (this.beg === 0 || this.sum === 0) {
        return 0
      }
      return this.beg / this.sum * 100;
    },
    timeConsuming() {
      let totalTime = this.sum * 5
      let s = totalTime % 60
      let m = Math.floor(totalTime / 60 % 60)
      let h = Math.floor(totalTime / 60 / 60)
      if (h === 0 && m === 0) {
        return s + '秒';
      }
      if (h === 0) {
        return m + '分' + s + '秒';
      }
      return h + '小时' + m + '分' + s + '秒';
    }
  },
  watch: {
    form: {
      handler(form) {
        let canPush = true
        if (this.type === 1) {
          if (!this.form.file || !this.form.instype_id || !this.form.title) {
            canPush = false
          }
        } else {
          if (!this.form.file || !this.form.title) {
            canPush = false
          }
        }
        this.canPush = canPush;
      }, deep: true, immediate: true
    }
  },
  mounted() {
    this.getInsuranceTypeList()

    //退出提醒
    window.onbeforeunload = (e) => {
      e = e || window.event;
      if (e) {
        e.returnValue = '关闭弹窗推送将失败，确定要关闭吗';
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return '关闭弹窗推送将失败，确定要关闭吗';
    }
  },
  methods: {
    getInsuranceTypeList() {
      pullInsTypeList().then(res => {
        this.insuranceTypeList = res && res.data || []

      })
    },
    getFile(file) {
      this.form.file = file
      this.uploadFile()
    },
    insuranceTypeChange() {
      this.uploadFile()
    },
    uploadFile() {
      if (!this.form.file) {
        return
      }
      if (this.type === 1 && !this.form.instype_id) {
        return;
      }
      let formData = new FormData();
      formData.append('file', this.form.file.raw)
      if (this.type === 1) {
        formData.append('instype_id', this.form.instype_id)
      }
      let methods = [importAccept, importReport, importClaim, vivoinsImportReport, vivoinsImportClaim]
      methods[this.type - 1](formData).then(res => {
        console.log(res)
      }).catch(err => {
        this.form.file = ''
        this.$refs.uploader.clearFiles()
      });
    },
    removeFile() {
      this.form.file = ''
    },
    processFormat(percentage) {
      if (percentage === 0) {
        return ''
      }
      return `${this.beg} / ${this.sum}`;
    },
    startPush() {
      this.isPushing = true
      this.startPushTime = new Date().toLocaleString()
      let methods = [uploadAccept, uploadReport, uploadClaim, vivoinsUploadReport, vivoinsUploadClaim]
      let params = {
        beg: this.beg,
        title: this.form.title,
      }
      if (this.type === 1) {
        params.instype_id = this.form.instype_id
      }
      if (this.type === 3) {
        // params.type = 1
        params.type=this.form.type  //理赔类型
      }
      methods[this.type - 1](params).then(res => {
        if (!res) {
          this.$message.error('推送失败');
          return
        }
        if (res.code !== 2) {
          this.sum = res.sum
          this.beg = res.beg
          this.startPush();
        } else {
          this.beg = this.sum
          this.isPushing = false
          // this.$message.success(res.msg || '推送完成');
          this.$confirm(res.msg || '推送完成', '', {
            showCancelButton: false
          })

          setTimeout(() => {
            // window.location.reload()
            window.opener.location.reload()
          }, 500)

        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.push-dialog {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;

  .title {
    width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
  }
}

.form {

  .el-input,
  .el-select {
    width: 300px;
  }

  .note {
    margin-left: 20px;
    color: #e33131;
  }
}

.pushing-note {
  font-size: 14px;
  width: 100%;
  padding-left: 12px;
  color: #666666;
}

::v-deep .el-upload-list {
  position: relative;
  top: -35px
}

::v-deep .el-icon-close {
  background: red;
  color: #fff;
  border-radius: 20px;
  padding: 2px;
}

.process {
  width: 300px;
  margin-top: 5px;
}

.push-btn {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.pushing-banner {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #F56C6C;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  color: #fff;
}
</style>
<style>
html,
body {
  width: 100%;
  height: 100%;
}
</style>
