<template>
  <div class="editAdd">
    <GlobalInfoBar title="承保单详情" />
    <GlobalChunk title="承包单信息" :padding="[32, 0, 32, 55]">
      <el-form ref="form" disabled :model="form" label-width="160px">
        <template v-for="item in formItems">
          <el-form-item :label="item.label" :key="item.prop" v-show="!item.isNullValueHiding ||
            (item.isNullValueHiding && form[item.prop])
            ">
            <el-button type="text" @click="viewOrder('register')" v-if="item.prop === 'viewOrder'">查看注册订单</el-button>

            <!--输入框-->
            <el-input v-if="item.type === 'input'" v-model="form[item.prop]" :placeholder="'请输入' + item.label"
              :disabled="item.disabled"></el-input>

            <!--选择框-->
            <!-- <el-select v-if="item.type === 'select' &&
              item.optValue &&
              item.optLabel &&
              !item.multiple
              " v-model="form[item.prop]" :placeholder="'请选择' + item.label" filterable :clearable="item.clearable"
              :disabled="item.disabled" @change="selectChange(item.relate)">
              <el-option v-for="opt in getOptions(item.optListName)" :key="opt[item.optValue]" :label="opt[item.optLabel]"
                :value="opt[item.optValue]">
              </el-option>
            </el-select>

            <el-select v-if="item.type === 'select' &&
              item.optValue &&
              item.optLabel &&
              item.multiple
              " :multiple="true" filterable :clearable="item.clearable" v-model="form[item.prop]"
              :placeholder="'请选择' + item.label" :disabled="item.disabled" @change="selectChange(item.relate)">
              <el-option v-for="opt in getOptions(item.optListName)" :key="opt[item.optValue]" :label="opt[item.optLabel]"
                :value="opt[item.optValue]">
              </el-option>
            </el-select>

            <el-select v-if="item.type === 'select' && !item.optValue && !item.optLabel" v-model="form[item.prop]"
              :placeholder="'请选择' + item.label" filterable :clearable="item.clearable" :disabled="item.disabled"
              @change="selectChange(item.relate)">
              <el-option v-for="opt in getOptions(item.optListName)" :key="opt" :label="opt" :value="opt">
              </el-option>
            </el-select> -->

            <!--日期框-->
            <el-date-picker v-if="item.type === 'date'" v-model="form[item.prop]" align="right" type="datetime"
              :placeholder="'请选择' + item.label" value-format="yyyy-MM-dd HH:mm:ss" :disabled="item.disabled">
            </el-date-picker>

          <!--dateRange -->
            <el-date-picker v-if="item.type === 'dateRange'" type="datetimerange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="dateRange"
              :value="[form[item.props[0]] , form[item.props[1]]]">
            </el-date-picker>

            <!--textarea-->
            <el-input v-if="item.type === 'textarea'" v-model="form[item.prop]" type="textarea"
              :placeholder="'请填写' + item.label" :disabled="item.disabled"></el-input>

            <!--radio-->
            <el-radio-group v-if="item.type === 'radio'" v-model="form[item.prop]">
              <!-- <el-radio v-for="opt in item.options" :key="opt.name" :label="opt.label" :disabled="item.disabled">{{
                opt.name }}
              </el-radio> -->
                <el-radio v-model="form[item.prop]" :label="1">个人</el-radio>
                <el-radio v-model="form[item.prop]" :label="0">企业</el-radio>
            </el-radio-group>

            <!--img-->
            <!-- <template v-if="item.type === 'img'">
              <div>（点击图片查看大图）</div>

              <div style="display: flex">
                <el-image v-if="form[item.prop]" class="preview-img" style="margin-right: 20px; margin-bottom: 20px"
                  :src="form[item.prop]" :preview-src-list="[form[item.prop]]" :data-watermark="!!item.watermark"
                  @click.stop="closePreview">
                </el-image>
                <div class="original-pic" v-if="formData[item.obj] &&
                  formData[item.obj]['old' + item.prop.replace('tem', '')]
                  ">
                  <el-image class="preview-img" :src="formData[item.obj] &&
                    formData[item.obj]['old' + item.prop.replace('tem', '')]
                    " :preview-src-list="[
                      formData[item.obj] &&
                      formData[item.obj][
                      'old' + item.prop.replace('tem', '')
                      ],
                    ]" :data-watermark="!!item.watermark" @click.stop="closePreview">
                  </el-image>
                  <div class="mark">原图</div>
                </div>

                <el-upload :ref="item.prop" v-if="!form[item.prop]" action="#" list-type="picture-card" :limit="1"
                  show-file-list :auto-upload="false" :on-change="(file) => {
                    getFile(file, item.prop);
                  }
                    ">
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </div>

              <div class="img-operation">
                <el-button plain size="mini" @click="uploadImg(item.prop)" v-if="form[item.prop]">编辑
                </el-button>

                <template>
                  <el-button type="primary" size="mini" @click="saveOriginalImage(item.prop, item.obj)">保留原图</el-button>
                  <div class="notice">
                    最多支持上传1张图片，大小不超过5M，格式为JPG或PNG
                  </div>
                </template>
              </div>
            </template> -->

            <!--img-->
            <!-- <template v-if="item.type === 'img2'">
              <div>（点击图片查看大图）</div>

              <div style="display: flex">
                <el-image v-if="form[item.prop]" class="preview-img" style="margin-right: 20px; margin-bottom: 20px"
                  :src="form[item.prop]" :preview-src-list="[form[item.prop]]" :data-watermark="!!item.watermark"
                  @click.stop="closePreview">
                </el-image>

                <el-upload :ref="item.prop" v-if="!form[item.prop]" action="#" list-type="picture-card" :limit="1"
                  show-file-list :auto-upload="false" :on-change="(file) => {
                    getFile(file, item.prop);
                  }
                    ">
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </div>

              <div class="img-operation">
                <el-button type="primary" size="mini" @click="uploadImg(item.prop)" v-if="form[item.prop]">重新上传
                </el-button>
              </div>
            </template> -->

          </el-form-item>
        </template>

      </el-form>
    </GlobalChunk>
    <GlobalChunk  title="产品信息" :padding="[32, 0, 32, 55]" style="margin-top: 20px">
      <GlobalTable ref="GlobalTable" :columns="tableColumnsPlan" :data="tableDataPlan" :isPagination="false">
        <el-table-column label="是否主险" slot="isMain" align="center">
          <template slot-scope="{ row }">
            <el-switch
                v-model="row.isMain"
                :active-value="1"
                :inactive-value="0"
                disabled>
            </el-switch>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
    <GlobalChunk  title="分期信息" :padding="[32, 0, 32, 55]" style="margin-top: 20px">
      <GlobalTable ref="GlobalTable" :columns="tableColumnsInstallment" :data="tableDataInstallment" :isPagination="false">
        <el-table-column label="缴费状态" slot="payStatus" align="center">
          <template slot-scope="{ row }">
            <el-switch
                v-model="row.payStatus"
                :active-value="1"
                :inactive-value="0"
                disabled>
            </el-switch>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import api from '@/utils/request'
import dateFormat from "@/utils";
import { imageUp } from "../../utils/request/changePhone";
export default {
  name: "acceptDetail",
  props: { acceptId: [String, Number] },
  data() {
    return {
      acceptInfo: {},
      form:{},
      formItems: [
        {
          label: "操作流水号",
          prop: "serialNo",
          value:"csgsehtru567k12143464",
          type:"input"
        },
        {
          label: "产品编号",
          prop: "productCode",
          value:"21568gehrthrj",
          type:"input"
        },
        {
          label: "保险生效日期",
          type:"dateRange",
          props: [
            "insuranceBeginDate",
            "insuranceEndDate"
          ],
        },
        {
          label: "投保时间",
          prop: "inputDate",
          value:"2023-10-08 17:13:25",
          type:"date"
        },
        {
          label: "设备唯一标识IMEI ",
          prop: "deviceIdentity",
          value:"652656512239812",
          type:"input"
        },
        {
          label: "设备品牌",
          prop: "deviceBrand",
          value:"Vivo",
          type:"input"
        },
        {
          label: "设备机型",
          prop: "deviceModel",
          value:"X90s",
          type:"input"
        },
        {
          label: "设备唯一标识IMEI2",
          prop: "deviceIdentity2",
          value:"xsx",
          type:"input"
        },
        {
          label: "销售渠道",
          prop: "marketingChannel",
          value:"06",
          type:"input"
        },
        // {
        //   label: "图片",
        //   prop: "img",
        //   value: null,
        //   type: "img",
        //   watermark: true
        // },
        {
          label: "个企标志", //1个人，0企业
          prop: "personnelType",
          value:0,
          type:"radio"
        },
        {
          label: "投保人名称",
          prop: "applicantName",
          value:"小小公司",
          type:"input"
        },
        {
          label: "证件类型",
          prop: "certificateType",
          value:"03",
          type:"input"
        },
        {
          label: "证件号码",
          prop: "certificateNo",
          value:"454656526526232555",
          type:"input"
        },
        {
          label: "联系人名称",
          prop: "linkManName",
          value:"张3",
          type:"input"
        },     
        {
          label: "地址",
          prop: "address",
          value:"成都市xxxxxxxxxxxxx",
          type:"input"
        },
        {
          label: "E-mail",
          prop: "email",
          value:"123@163.com",
          type:"input"
        },     
        {
          label: "联系电话",
          prop: "mobileTelephone",
          value:"13277776666",
          type:"input"
        },
        {
          label: "个企标志（被保人）",//（1个人，0企业）
          prop: "personnelType2",
          value:1,
          type:"radio"
        },
        {
          label: "被保人名称",
          prop: "InsurantName2",
          value:"王二",
          type:"input"
        },
        {
          label: "证件类型（被保人）",
          prop: "certificateType2",
          value:"01",
          type:"input"
        },        
        {
          label: "证件号码（被保人）",
          prop: "certificateNo2",
          value:"511xxxxxxxxxxx1234",
          type:"input"
        },
        {
          label: "联系人名称（被保人）",
          prop: "linkManName2",
          value:"王二",
          type:"input"
        },
        {
          label: "地址（被保人）",
          prop: "address2",
          value:"成都市xxxxxxxxxxxxx",
          type:"input"
        },
        {
          label: "E-mail（被保人）",//被保人
          prop: "email2",
          value:"成都市xxxxxxxxxxxxx",
          type:"input"
        },
        // {
        //   label: "地址",
        //   prop: "address2",
        //   value:"成都市xxxxxxxxxxxxx"
        // },
        {
          label: "联系电话（被保人）",
          prop: "mobileTelephone2",
          value:"13277776636",
          type:"input"
        },
        {
          label: "订单状态",
          prop: "auditStatus",
          value:1,
          type:"input"
        },
        {
          label: "添加时间",
          prop: "add_time",
          value:1,
          type:"input"
        },
        {
          label: "保单号",
          prop: "policyNo",
          value:1,
          type:"input"
        }
      ],
      // formData: {},
      dateRange: [],
      tableDataPlan:[],
      tableColumnsPlan: [
        { label: "承包单ID", prop: "acceptId"},
        { label: "险种编码", prop: "planCode"},
        { slotName: "isMain" },
        { label: "险种保费（分）", prop: "totalActualPremium"},
        { label: "险种保额（分）", prop: "totalInsuredAmount"}
      ],
      tableDataInstallment: [],
      tableColumnsInstallment:[
        { label: "承包单ID", prop: "acceptId"},
        { label: "操作流水号", prop: "serialNo"},
        { label: "保单号", prop: "policyNo"},
        { label: "分期号", prop: "installmentNo"},
        { label: "分期保费（分）", prop: "installmentPremium"},
        { label: "应缴时间", prop: "shouPayDate"},
        { label: "实缴时间", prop: "realPayDate"},
        { slotName: "payStatus" },
        { label: "宽限期", prop: "graceDate"},
        { label: "添加时间", prop: "add_time"},        
      ]
    }
  },
  computed: {
    // getOptions() {
    //   return (optsName) => {
    //     return this[optsName];
    //   };
    // },
  },
  mounted() {
    api.vivoAcceptInfo({
      acceptId: this.acceptId
    }).then(res => {
      this.form = res.data.accept || {}
      this.dateRange = [this.form["insuranceBeginDate"] , this.form["insuranceEndDate"]]
      this.tableDataPlan = res.data.plan
      this.tableDataInstallment = res.data.installment
    })
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.el-input,
.el-select,
.el-date-picker,
.el-textarea,
.el-date-editor,
::v-deep .el-input__inner {
  width: 500px !important;
}

::v-deep .img {
  width: 100%;
}

.transfer-agreement {
  margin-left: 40px;

  .title {
    font-weight: bold;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 15px;
      background: #1091FF;
      border-radius: 1px;
    }
  }
}

.generate-img {
  float: right;
}

.preview-img {
  width: 148px;
  height: 148px;
  border-radius: 5px;
}

.bottom-btns {
  margin-left: 160px;
}
</style>
