<template>
  <div class="editAdd">
    <GlobalInfoBar title="个人理赔详情"/>
    <GlobalChunk :padding="[32, 0, 95, 55]">
      <div style="display: flex;">
        <el-form ref="form" :model="form" label-width="200px" size="small" style="width: 600px">
          <el-form-item label="IMEI串码：">
            <el-input v-model="form.imei" placeholder="请输入IMEI串码"></el-input>
          </el-form-item>

          <el-form-item label="报案申请号（理赔单编码）：">
            <el-input v-model="form.claimId" placeholder="请输入报案申请号（理赔单编码）"></el-input>
          </el-form-item>

          <el-form-item label="	维修单编码：">
            <el-input v-model="form.finish_sn" placeholder="请输入维修单编码"></el-input>
          </el-form-item>

          <el-form-item label="	保单类型：">
            <el-select v-model="form.baotype" placeholder="请选择保单类型">
              <el-option label="大保单" value="大保单"></el-option>
              <el-option label="小保单" value="小保单"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="保单号：">
            <el-input v-model="form.policyNo" placeholder="请输入保单号" @input="policyNoInput"></el-input>
          </el-form-item>

          <el-form-item label="理赔金额（维修金额）：">
            <el-input v-model="form.sumClaim" placeholder="请输入理赔金额（维修金额）"></el-input>
          </el-form-item>

          <el-form-item label="领款人名称：">
            <el-input v-model="form.payeeName" placeholder="请填写领款人名称"></el-input>
          </el-form-item>

          <el-form-item label="联系电话：">
            <el-input v-model="form.telephone" placeholder="请填写联系电话"></el-input>
          </el-form-item>

          <el-form-item label="开户银行名：">
            <el-input v-model="form.bankCodeOrName" placeholder="请填写开户银行名"></el-input>
          </el-form-item>

          <el-form-item label="银行卡号：">
            <el-input v-model="form.bankAccount" placeholder="请填写银行卡号"></el-input>
          </el-form-item>

          <el-form-item label="身份证号：">
            <el-input v-model="form.identifyNumber" placeholder="请填写身份证号"></el-input>
          </el-form-item>

          <el-form-item label="维修商名称：">
            <el-input v-model="form.unitName" placeholder="请填写维修商名称"></el-input>
          </el-form-item>

          <el-form-item label="门店名称：">
            <el-input v-model="form.storeName" placeholder="请填写门店名称"></el-input>
          </el-form-item>

          <el-form-item label="操作人员：">
            <el-input v-model="form.handleUserName" placeholder="请填写操作人员"></el-input>
          </el-form-item>

          <el-form-item label="	是否有协议图片：">
            <el-select v-model="form.is_sign" placeholder="请选择是否有工单图片">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="协议图片：">
            <el-image class="img" :src="form.sign_img	" alt="" :preview-src-list="[form.sign_img	]" @click.stop="closePreview"></el-image>
          </el-form-item>


          <el-form-item label="换机完成照：">
            <el-image class="img" v-if="form.tem_img" :src="form.tem_img" alt="" :preview-src-list="[form.tem_img]" @click.stop="closePreview"></el-image>

            <el-button type="primary" @click="form.tem_img=''" v-if="form.tem_img" style="margin-top: 10px;float: right">重新上传</el-button>

            <el-upload
                v-if="!form.tem_img"
                ref="uploader1"
                action="#"
                list-type="picture-card"
                :limit="1"
                show-file-list
                :auto-upload="false"
                :on-change="(file)=>{getFile(file,'tem_img')}">
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="	是否有工单图片：">
            <el-select v-model="form.is_work" placeholder="请选择是否有工单图片">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="工单照片：">
            <el-image class="img" v-if="form.tem_work" :src="form.tem_work" alt="" :preview-src-list="[form.tem_work]" @click.stop="closePreview"></el-image>

            <el-button type="primary" @click="form.tem_work=''" v-if="form.tem_work" style="margin-top: 10px;float: right">重新上传</el-button>

            <el-upload
                v-if="!form.tem_work"
                ref="uploader2"
                action="#"
                list-type="picture-card"
                :limit="1"
                show-file-list
                :auto-upload="false"
                :on-change="(file)=>{getFile(file,'tem_work')}">
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="	是否有发票图片：">
            <el-select v-model="form.is_invoice" placeholder="请选择是否有工单图片">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="发票图片：">
            <el-image class="img" v-if="form.tem_invoice" :src="form.tem_invoice" alt="" :preview-src-list="[form.tem_invoice]" @click.stop="closePreview"></el-image>

            <el-button type="primary" @click="form.tem_invoice=''" v-if="form.tem_invoice" style="margin-top: 10px;float: right">重新上传</el-button>

            <el-upload
                v-if="!form.tem_invoice"
                ref="uploader2"
                action="#"
                list-type="picture-card"
                :limit="1"
                show-file-list
                :auto-upload="false"
                :on-change="(file)=>{getFile(file,'tem_invoice')}">
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button type="plain" @click="$router.go(-1)">返回</el-button>
            <el-button type="primary" @click="confirm">确定编辑</el-button>
            <el-button type="primary" @click="send">推送</el-button>
          </el-form-item>

        </el-form>

        <div class="transfer-agreement"  v-if="form.sign1">
          <div class="title">转让协议 <el-button size="mini" type="primary" class="generate-img" @click="generateImg">生成图片</el-button></div>
          <canvas id="contract-canvas"></canvas>
        </div>
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import api from '@/utils/request'
import {imageUp} from "../../utils/request/changePhone";
export default {
  name: "personalClaimsDetail",
  props: {id:[String,Number]},
  data() {
    return {
      form: {
        imei: '',
        claimId: '',
        finish_sn: '',
        baotype: '',
        policyNo: '',
        sumClaim: '',
        tem_img: '',
        tem_work: '',
        is_work: 1,
        payeeName: '',
        telephone: '',
        bankCodeOrName: '',
        bankAccount: '',
        identifyNumber: '',
        unitName: '',
        storeName: '',
        handleUserName: '',
        tem_invoice:'',
        is_invoice:'',
        is_sign: 1,
        sign_img: '',
      },
      canvas:null,
      ctx:null,
      contractImgBase64: '',
    }
  },
  mounted() {
    api.perClaimInfo({
      per_claim_id:this.id
    }).then(res=>{
      this.form=res.data||{}
      this.$nextTick(()=>{
        this.draw(true)
      })
    })
  },
  methods:{
    policyNoInput() {
      this.draw()
    },
    formatDate(dateStr) {
      if (!dateStr) return ''
      let date = new Date(dateStr)
      return [
        date.getFullYear() + '',
        date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) + '',
        date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '',
      ]
    },
    draw(isFirst=false) {
      if (!this.canvas||!this.ctx) {
        this.canvas = document.getElementById('contract-canvas');
        this.ctx = this.canvas.getContext('2d')
      }
      let screenWidth = 800;
      let contractImg = new Image()
      contractImg.src = require('@/assets/images/contract.jpg')
      contractImg.onload = () => {
        let width = contractImg.width
        let height = contractImg.height
        if (isFirst) {
          this.canvas.style.width = screenWidth + 'px'
          this.canvas.style.height = screenWidth * contractImg.height / contractImg.width + 'px'
          this.canvas.setAttribute('width', width + '')
          this.canvas.setAttribute('height', height + '')
        }

        this.ctx.drawImage(contractImg, 0, 0, width, height);

        this.form.policyNo && this.drawText(this.form.policyNo, 665, 390)
        this.form.model_name && this.drawText(this.form.model_name, 220, 428)
        this.form.add_date[0] && this.drawText(this.formatDate(this.form.add_date)[0], 530, 428)
        this.form.add_date[1] && this.drawText(this.formatDate(this.form.add_date)[1], 640, 428)
        this.form.add_date[2] && this.drawText(this.formatDate(this.form.add_date)[2], 715, 428)
        this.form.name && this.drawText(this.form.name, 250, 463)
        this.form.payeeName && this.drawText(this.form.payeeName, 620, 463)
        this.form.telephone && this.drawText(this.form.telephone, 408, 656)
        this.form.add_time[0] && this.drawText(this.formatDate(this.form.add_time)[0], 290, 692)
        this.form.add_time[0] && this.drawText(this.formatDate(this.form.add_time)[0], 830, 692)
        this.form.add_time[1] && this.drawText(this.formatDate(this.form.add_time)[1], 372, 692)
        this.form.add_time[1] && this.drawText(this.formatDate(this.form.add_time)[1], 919, 692)
        this.form.add_time[2] && this.drawText(this.formatDate(this.form.add_time)[2], 420, 692)
        this.form.add_time[2] && this.drawText(this.formatDate(this.form.add_time)[2], 965, 692)

        if (this.form.sign1) {
          this.ctx.save()
          let signature1 = new Image();
          signature1.src = this.form.sign1
          signature1.onload = () => {
            this.ctx.translate(360, 630)
            this.ctx.rotate(-Math.PI / 2);
            this.ctx.drawImage(signature1, 0, 0, 100 * signature1.width / signature1.height, 100)
            this.ctx.restore()

            this.contractImgBase64 = this.canvas.toDataURL('image/jpg')
          }

        }

        if (this.form.sign2) {
          this.ctx.save()
          let signature2 = new Image();
          signature2.src = this.form.sign2
          signature2.onload = () => {
            this.ctx.translate(955, 630)
            this.ctx.rotate(-Math.PI / 2);
            this.ctx.drawImage(signature2, 0, 0, 100 * signature2.width / signature2.height, 100)
            this.ctx.restore()
            this.contractImgBase64 = this.canvas.toDataURL('image/jpg')
          }

        }
        this.contractImgBase64 = this.canvas.toDataURL('image/jpg')

      }
    },
    drawText(text, x, y, font = '20px Microsoft YaHei') {
      this.ctx.font = font
      this.ctx.fillText(text, x, y)
    },
    uploadImg(dataurl=this.contractImgBase64, filename=+new Date()+'.png') {
      if(!dataurl)return
      let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file= new File([u8arr], filename, {type: mime});

      let data = new FormData();
      data.append('file', file)
      return imageUp(data)
    },
    generateImg() {
      this.uploadImg().then(res=>{
        this.form.sign_img=res&&res.data&&res.data.url||''
        this.form.is_sign=1
      })
    },
    closePreview(event) {
      // 获取遮罩层dom
      setTimeout(() => {
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if (e.target.parentNode.className === 'el-image-viewer__actions__inner' || e.target.parentNode.className === 'el-image-viewer__canvas') {
            return;  //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });

      }, 300);
    },
    send() {
      this.$set(this.form,'push',1)
      api.perClaimPush(this.form).then(data=>{
        this.$message.success(data&&data.msg||'操作成功')
      }).catch(err=>{
        console.error(err)
      })
    },
    confirm() {
      if (this.form.push) {
        this.$delete(this.form,'push')
      }
      api.perClaimPush(this.form).then(data=>{
        this.$message.success(data&&data.msg||'操作成功')
      }).catch(err=>{
        console.error(err)
      });
    },
    getFile(file, prop) {
      let formData = new FormData();
      formData.append('file', file.raw)
      api.imageUp(formData).then(res => {
        this.form[prop] = res.data.url
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-input,
.el-select,
.el-date-picker,
.el-textarea,
.el-date-editor,
::v-deep .el-input__inner {
  width: 100% !important;
}
::v-deep .img{
  width: 100%;
}
.transfer-agreement {
  margin-left: 40px;
  .title {
    font-weight: bold;
    position: relative;
    margin-bottom: 20px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 15px;
      background: #1091FF;
      border-radius: 1px;
    }
  }
}
.generate-img{
  float: right;
}
</style>
