

import request from './request'
// 个人中心-会员列表
export function usersList(params) {
    return request({
        method: 'POST',
        url: '/Users/usersList',
        data: params,
    })
}
// 个人中心-排行榜
export function usersRanking(params) {
    return request({
        method: 'POST',
        url: '/Users/usersRanking',
        data: params,
    })
}
// 个人中心--排行榜发送红包
export function rankRed(params) {
    return request({
        method: 'POST',
        url: '/Users/rankRed',
        data: params,
    })
}
// 个人中心--抽奖记录
export function redLog(params) {
    return request({
        method: 'POST',
        url: '/Users/redLog',
        data: params,
    })
}
// 个人中心--红包发送记录
export function prizeLog(params) {
    return request({
        method: 'POST',
        url: '/Users/prizeLog',
        data: params,
    })
}
// 个人中心--抽奖核销
export function prizeOff(params) {
    return request({
        method: 'POST',
        url: '/Users/prizeOff',
        data: params,
    })
}
// 个人中心--抽奖配置
export function prizeList(params) {
    return request({
        method: 'POST',
        url: '/Users/prizeList',
        data: params,
    })
}
// 个人中心--奖品信息
export function prizeInfo(params) {
    return request({
        method: 'POST',
        url: '/Users/prizeInfo',
        data: params,
    })
}
// 个人中心--奖品编辑
export function prizeEdit(params) {
    return request({
        method: 'POST',
        url: '/Users/prizeEdit',
        data: params,
    })
}
// 个人中心--奖品类型
export function prizeType(params) {
    return request({
        method: 'POST',
        url: '/Users/prizeType',
        data: params,
    })
}