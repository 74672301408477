<template>
  <div class="editAdd">
    <GlobalInfoBar title="个人理赔详情"/>
    <GlobalChunk :padding="[32, 0, 95, 55]">

      <el-form ref="form" :model="form" label-width="200px" size="small" style="width: 600px">
        <el-form-item label="理赔编码，报案号：">
          <el-input v-model="form.claims_sn" placeholder="请输入理赔编码，报案号"></el-input>
        </el-form-item>

        <el-form-item label="用户名称：">
          <el-input v-model="form.name" placeholder="请输入用户名称"></el-input>
        </el-form-item>

        <el-form-item label="	用户手机号：">
          <el-input v-model="form.phone" placeholder="请输入用户手机号"></el-input>
        </el-form-item>

        <el-form-item label="	身份证号：">
          <el-input v-model="form.id_card" placeholder="请输入身份证号"></el-input>
        </el-form-item>

        <el-form-item label="	IMEI串码：">
          <el-input v-model="form.imei" placeholder="请输入IMEI串码"></el-input>
        </el-form-item>

        <el-form-item label="	服务名称：">
          <el-input v-model="form.ins_service_name" placeholder="请输入服务名称"></el-input>
        </el-form-item>

        <el-form-item label="	损坏信息：">
          <el-input v-model="form.damage_title" placeholder="请输入损坏信息"></el-input>
        </el-form-item>

        <el-form-item label="	报案类型：">
          <el-select v-model="form.riskType" placeholder="请选择报案类型">
            <el-option label="换内屏" value="换内屏"></el-option>
            <el-option label="换外屏" value="换外屏"></el-option>
            <el-option label="换机" value="换机"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报案地点：">
          <el-input v-model="form.gps" placeholder="请输入报案地点"></el-input>
        </el-form-item>

        <el-form-item label="保单号：">
          <el-input v-model="form.policyNo" placeholder="请输入保单号"></el-input>
        </el-form-item>

        <el-form-item label="	保单类型：">
          <el-select v-model="form.baotype" placeholder="请选择报案类型">
            <el-option label="大保单" value="大保单"></el-option>
            <el-option label="小保单" value="小保单"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="手机图片：">
          <el-image class="img" :src="form.tem_img" alt="" :preview-src-list="[form.tem_img]" @click.stop="closePreview"></el-image>
        </el-form-item>

        <el-form-item label="身份证图片：">
          <el-image class="img" :src="form.tem_card" alt="" :preview-src-list="[form.tem_card]" @click.stop="closePreview"></el-image>
        </el-form-item>

        <el-form-item label="爱斯助手截图：" v-if="isShowAiSi">
          <el-image class="img" :src="form.tem_elsie" alt="" :preview-src-list="[form.tem_elsie]" @click.stop="closePreview"></el-image>
        </el-form-item>

        <el-form-item>
          <el-button type="plain" @click="$router.go(-1)">返回</el-button>
          <el-button type="primary" @click="send">推送</el-button>
        </el-form-item>

      </el-form>
    </GlobalChunk>
  </div>
</template>

<script>
import api from '@/utils/request'
export default {
  name: "reportDetail",
  props: {id:[String,Number]},
  data() {
    return {
      form: {
        claims_id: '',
        claims_sn: "",
        order_id: '',
        name: "",
        phone: "",
        id_card: "",
        imei: "",
        tem_img: "",
        tem_card: "",
        ins_service_name: "",
        damage_title: "",
        gps: "",
        policyNo: '',
        baotype: "",
        riskType: "",
        tem_elsie:''
      }
    }
  },
  computed: {
    isShowAiSi() {
      return this.form.ins_service_name==='免费换电池'&&this.form.type===1
    }
  },
  mounted() {
    api.perReportInfo({
      per_claim_id:this.id
    }).then(res=>{
      this.form=res.data||{}
    })
  },
  methods:{
    closePreview(event) {
      // 获取遮罩层dom
      setTimeout(() => {
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if (e.target.parentNode.className === 'el-image-viewer__actions__inner' || e.target.parentNode.className === 'el-image-viewer__canvas') {
            return;  //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });

      }, 300);
    },
    send() {
      let form = JSON.parse(JSON.stringify(this.form));
      if (!this.isShowAiSi) {
        delete form.tem_elsie
      }
      api.perReportPush(form).then(data=>{
        this.$message.success(data&&data.msg||'操作成功')
      }).catch(err=>{
        console.error(err)
      });
    },
  }
}
</script>

<style scoped>
.el-input,
.el-select,
.el-date-picker,
.el-textarea,
.el-date-editor,
::v-deep .el-input__inner {
  width: 100% !important;
}
::v-deep .img{
  width: 100%;
}
</style>
