<template>
  <div class="register_edit">
      <GlobalInfoBar
        title="编辑"
    />
    <GlobalChunk :padding="[32, 0, 95, 55]">
          <GlobalForm
            v-if="this.initData.instype_name"
            :init-data="initData"
            :form-rules="formRules"
            :form-item-list="formItemList"
            @handleConfirm="handleConfirm"
            confirmBtnName="确认修改"
          >
            <el-form-item class="el_from" slot="operation">
                <div class="title">特别说明: 保险说明无需设置图片，可能会影响前端H5页面排版</div>
            </el-form-item>
            <el-form-item class="el_from" label="保险类型:" slot="operation" prop="instype_name">
                <el-input
                v-model="initData.instype_name"
                placeholder="请输入保险类型"
                :disabled="disabled"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="el_from" label="理赔上限:" slot="operation"  prop="claims_num">
              <!-- <el-input
                v-model="initData.claims_num"
                placeholder="请输入理赔上限"
                clearable
              ></el-input> -->
              <el-select v-model="initData.claims_num" class="select_z" :popper-append-to-body="false">
                <el-option v-for="item in claNumList" 
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="保险说明:" slot="operation" prop="instype_explain">
              <div style="width:900px;">
                <editor
                  @handleEditContent="handleEditContent1"
                  @handleSettingEditContent="handleSettingEditContent1"
                  type='instype_explain'
                ></editor>
                <div style="margin-top:2px;color:#ffaf29">
                    添加后将展示在前端H5对应页面顶部‘注意事项’ 处（字数建议不超过200字，可能将影响排版美观）
                </div>
              </div>
            </el-form-item>
            <el-form-item class="el_from" label="保险注册协议:" slot="operation" prop="instype_agree">
              <div style="width:900px">
                <editor
                  @handleEditContent="handleEditContent2"
                  @handleSettingEditContent="handleSettingEditContent2"
                  type='instype_agree'
                ></editor>
                <div style="margin-top:2px;color:#ffaf29">
                  添加后将展示在前端H5页面底部‘保险注册-注册服务协议’处，点击后查看内容详情
                </div>
              </div>
            </el-form-item>
            <el-button slot="btnSlot" @click="FromBack">取消</el-button>
          </GlobalForm>
    </GlobalChunk>
  </div>
</template>

<script>
import editor from '../component/editor'
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "register_edit",
  components:{editor},
  data() {
    return {
      claNumList:[
        {id:1,label:1},
        {id:2,label:2},
        {id:3,label:3},
        {id:4,label:4},
      ],
      disabled:false,
      initData: {
        instype_name:'',
        claims_num:'',
        instype_agree:'',
        instype_explain:'',
      },
      formItemList: [
        { slotName: "operation" },
      ],
      formRules: {
        instype_name: [
          {
            required: true,
            trigger: "blur",
            message: "保险类型",
          }
        ],
        claims_num: [
          {
            required: true,
            trigger: "blur",
            message: "请输入理赔上限",
          }
        ],
      }
    };
  },
  mounted() {
    if(this.$route.query.id){
      _api.insuranceTypeInfo({instype_id:this.$route.query.id}).then(res=>{
        console.log(res)
        this.disabled = true
        this.initData.instype_name = res.data.instype_name
        this.initData.instype_explain = res.data.instype_explain
        this.initData.instype_agree = res.data.instype_agree
        this.initData.claims_num = res.data.claims_num
      })
    }
  },

  methods: {
    // 提交
    handleConfirm(data, cd) {
      cd()
      const vo ={
        instype_id:this.$route.query.id,
        instype_explain:this.initData.instype_explain,
        instype_agree:this.initData.instype_agree,
        claims_num:this.initData.claims_num,
      }
      _api.editInsuranceType(vo).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg)
          this.$router.go(-1);
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    FromBack() {
      this.$router.go(-1);
    },

    handleEditContent1 (html, type) {
				this.initData.instype_explain = html
				this.$forceUpdate()
    },
    handleSettingEditContent1 (set, type) {
      set.html(this.initData.instype_explain)
    },
    handleEditContent2 (html, type) {
      this.initData.instype_agree = html
      this.$forceUpdate()
    },
    handleSettingEditContent2 (set, type) {
      set.html(this.initData.instype_agree)
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.register_edit {
       .select_z{
        z-index: 20002!important;
      }
    .text_item{
        color:#999;
        margin-left: 20px;
    }
    .el_from {
        margin-left: -120px;
    }
    /deep/ .el-input__inner{
        width: 380px;
    }
    /deep/ .el-input{
        width: 380px;
    }
    .item_dia{
        display: flex;
        justify-content: left;
        align-items: center;
        .left{
            width: 60px;
            margin: 0px 0 10px 10px;
            color: black;
        }
        .right{
            margin: 0px 0 10px 10px;
            color: #FF8080;
        }
    }
    .btn_bot{
        float: right;
        margin: 30px;
    }
    .title{
      color: #FF687B;
      margin-left: 45px;
    }
}
</style>
