<template>
  <div class="planNum_list">
    <GlobalInfoBar title="排行榜列表" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="80"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    <el-form-item
      label-width="80px"
        class="el_from"
        label="选择时间:"
        slot="is_push_state_time"
        style="margin: 0"
      >
        <el-date-picker
          v-model="stateTime"
          type="daterange"
          value-format="timestamp"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :isIndex="false"
      :maxHeight="800"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      :isPagination="false"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column
        slot="sort"
        type="index"
        align="center"
        label="排名"
        width="70"
      />
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button type="primary" size="small" @click="editPlan(row, 'edit')"  class="mr_sty"
            >发红包</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :visible.sync="visible" @closed="resetForm('ruleForm')" title="操作提示" width="600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="红包金额" prop="red_money">
          <el-input
            v-model="ruleForm.red_money"
            placeholder="请输入红包金额"
            :maxlength="6"
          ></el-input>
        </el-form-item>
        <el-form-item  label="备注" prop="remarks" label-width="100px">
          <el-input
            type="textarea"
            v-model="ruleForm.remarks"
            placeholder="请输入备注"
            :maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form_r">
          <el-button type="primary" @click="submitForm('ruleForm', 'add')"
            >确认</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { usersRanking ,rankRed} from "../../../utils/request/users";
import _api from "@/utils/request";
export default {
  name: "planNum_list",
  data() {
    return {
      //弹窗参数
      visible: false,
      ruleForm: {
        red_money: "",
        remarks: "",
      },
      rules: {
        red_money: [
          { required: true, message: "请输入红包金额", trigger: "blur" },
        ],
        remarks: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      //列表
      GroupList: [
        {
          name: "今日",
          id: 1,
        },
        {
          name: "本月",
          id: 2,
        },
        {
          name: "今年",
          id: 3,
        },
      ],
      stateTime: null,
      initData: null,
      formItemList: [{ slotName: "is_push_state_time" }],
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      mold: 2,
      tableColumns: [
        { slotName: "sort" },
        { label: "用户姓名", prop: "name" },
        { label: "手机号", prop: "phone" },
        { label: "总积分", prop: "t_points" },
        { slotName: "operat" },
      ],
    };
  },
  mounted() {
    this.PostPlanList();
    console.log(this.stateTime);
  },
  methods: {
    //列表请求
    PostPlanList() {
      usersRanking({
        beg_time: this.stateTime!==null?this.stateTime[0]:'',
        end_time: this.stateTime!==null?this.stateTime[1]:'',
      }).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostPlanList();
    },
    handleChange(val) {
      console.log(val);
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      usersRanking({
        beg_time: this.stateTime!==null?this.stateTime[0]:'',
        end_time: this.stateTime!==null?this.stateTime[1]:'',
      }).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //发红包
    editPlan(row) {
      this.visible = true;
      this.user_id = row.id;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          rankRed({user_id:this.user_id,...this.ruleForm}).then((res) => {
            console.log(res);
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.resetForm('ruleForm');
              this.PostPlanList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm={
        red_money: "",
        remarks: "",
      }
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  /deep/ .el-form-item__label {
    width: 100px !important;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r {
    float: right;
    margin-right: 80px;
  }
}
/deep/.el-textarea {
  width: 80%;
} 
.icon {
  cursor: pointer;
  margin-right: 20px;
}
</style>
