<template>
  <div>
    <GlobalInfoBar title="估价单列表" />
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <GlobalForm
        :initData="initData"
        ref="form"
        :form-item-list="formItemList"
        :inline="true"
        round
        @handleConfirm="query"
        confirmBtnName="查询"
      >
        <el-button type="warning" slot="AddSlot" @click="exportData"
          >导出数据</el-button
        >
      </GlobalForm>

      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns"
        :data="tableData"
        :currentPage="curPage"
        :total="totalElements"
        @handleCurrentChange="currentChange"
      >
        <el-table-column
          label="估价类型"
          slot="pass_name"
          align="center"
          width="100px"
        >
          <template slot-scope="{ row }">
            {{ row.estimateSource == 1 ? "用户估价" : "店员估价" }}
          </template>
        </el-table-column>
        <el-table-column label="机况图片" slot="headimg" align="center">
          <template slot-scope="{ row }">
            <el-image
              style="width: 50px; height: 60px;"
              :src="row.statusImages[0]"
              :preview-src-list="row.statusImages"
            >
            </el-image>
            <p style="text-decoration: underline;color: #0981FF;">共{{ row.statusImages.length }}张</p>
          </template>
        </el-table-column>
        <el-table-column
          label="旧机机型"
          slot="store_name"
          align="center"
          width="100px"
        >
          <template slot-scope="{ row }">
            {{ row.productModel || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          label="旧机IMEI"
          slot="imei"
          align="center"
          width="100px"
        >
          <template slot-scope="{ row }">
            {{ row.imei || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          label="服务单号"
          slot="oldProp"
          align="center"
          width="150px"
        >
          <template slot-scope="{ row }">
            {{ row.channelOrderNo }}
          </template>
        </el-table-column>

        <el-table-column
          label="换新子表单号"
          slot="query"
          align="center"
          width="150px"
        >
          <template slot-scope="{ row }">
            <span>{{ row.replaceNewOrderNo }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="支付订单号"
          slot="new_way"
          align="center"
          width="150px"
        >
          <template slot-scope="{ row }">
           <span>{{ row.paymentNo ||"--"}}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="终端零售价"
          slot="newProp"
          align="center"
          width="150px"
        >
          <template slot-scope="{ row }">
            {{ row.retailPrice }}
          </template>
        </el-table-column>
        <el-table-column
          label="自动报价折扣"
          slot="money"
          align="center"
          width="100px"
        >
          <template slot-scope="{ row }">
            <p>{{ row.rebate }}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="报价金额"
          slot="scrap_price"
          align="center"
          width="100px"
        >
          <template slot-scope="{ row }">
            <p>￥{{ row.estimatePrice }}</p>
          </template>
        </el-table-column>
        <el-table-column
        v-if="type==2"
          label="驳回类型"
          slot="cost_price"
          align="center"
          width="100px"
        >
          <template slot-scope="{ row }">
            <p>{{ row.result==1?'通过':row.result==2?'图片不清晰 ': '其他'}}</p>
          </template>
        </el-table-column>
        <el-table-column
        v-if="type==2"
          label="驳回原因"
          slot="exa_status"
          align="center"
          width="100px"
        >
          <template slot-scope="{ row }"> 
            {{ row.rejectMessage||"--" }} 
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import {
  uploadXyAssess,
  orderxyAssessList,
} from "../../../utils/request/changePhone";

export default {
  name: "index",
  data() {
    return {
      formItemList: [
        {
          key: "imei",
          type: "input",
          labelWidth: "50px",
          labelName: "串号:",
          placeholder: "请输入串号",
        },
        {
          labelWidth: "80px",
          key: "channelOrderNo",
          type: "input",
          labelName: "服务单号:",
          placeholder: "请输入服务单号",
        },
        {
          labelWidth: "110px",
          key: "replaceNewOrderNo",
          type: "input",
          labelName: "换新子表单号:",
          placeholder: "请输入换新子表单号",
        },
        {
          labelWidth: "80px",
          key: "orderTime",
          type: "daterange",
          labelName: "报价时间:",
          placeholder: "请选择报价时间",
          valueFormat: "timestamp",
        },
        {
          key: "type",
          idKey: "id",
          labelWidth: "100px",
          labelKey: "name",
          type: "select",
          noclear:true,
          labelName: "估价单状态:",
          option: [
            { name: "待报价", id: 0 },
            { name: "已报价", id: 1 },
            { name: "已驳回", id: 2 },
          ],
          placeholder: "请选择",
        },
      ],
      initData: {
        order_sn: "",
        type: 1,
      },
      tableColumns: [
        { label: "报案号", prop: "reportNo", width: "160px" },
        { slotName: "pass_name" },
        { slotName: "headimg" },
        { slotName: "store_name" },
        { slotName: "imei" },
        { slotName: "oldProp" },
        { slotName: "query" },
        { slotName: "new_way" },
        { slotName: "newProp" },
        { slotName: "money" },
        { slotName: "scrap_price" },
        { slotName: "cost_price" },
        { slotName: "exa_status" },
        { label: "报价时间", prop: "add_time", width: "150px" },
       
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      type: 1,
      imei: "",
      channelOrderNo: "",
      replaceNewOrderNo: "",
      beg_time: "",
      end_time: "",
    };
  },
  mounted() {
    this.query({ type: 1 });
  },
  methods: {
    currentChange(page) {
      this.curPage = page;
      this.getList();
    },
    query(data) {
      this.channelOrderNo = data.channelOrderNo;
      this.imei = data.imei;
      this.replaceNewOrderNo = data.replaceNewOrderNo;
      this.beg_time = (data.orderTime && data.orderTime[0]) || "";
      this.end_time = (data.orderTime && data.orderTime[1]) || "";
      this.type = data.type;
      this.curPage = 1;
      this.getList();
    },
    exportData() {
      uploadXyAssess({
        page: this.curPage,
        limit: 10,
        type: this.type,
        imei: this.imei,
        channelOrderNo: this.channelOrderNo,
        replaceNewOrderNo: this.replaceNewOrderNo,
        beg_time: this.beg_time,
        end_time: this.end_time,
      }).then((res) => {
        const content = res;
        const blob = new Blob([content]);
        const fileName = "估价单.xls";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    getList() {
      orderxyAssessList({
        page: this.curPage,
        limit: 10,
        type: this.type,
        imei: this.imei,
        channelOrderNo: this.channelOrderNo,
        replaceNewOrderNo: this.replaceNewOrderNo,
        beg_time: this.beg_time,
        end_time: this.end_time,
      }).then((res) => {
        this.tableData = res.data || [];
        this.totalElements = res.count || 0;
      });
    },
  },
};
</script>

<style scoped>
::v-deep textarea {
  min-height: 7em !important;
}

.popover-table,
.popover-table td {
  border: 1px solid #c4d7fd;
}

.popover-table td {
  padding: 10px;
}

.imgs {
  border: 1px solid #e4ecfd;
  border-radius: 3px;
  padding: 10px 20px 0;
  width: 620px;
}

.note {
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
}

.note2 {
  font-size: 12px;
  color: #999;
}

.img-wrapper {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  position: relative;
  border: 1px dashed #c0ccda;
  border-radius: 5px;
  margin-bottom: 20px;
}

.img-wrapper:hover {
  border: 1px dashed #0981ff;
}

.img-wrapper:hover .drag-notice {
  display: block;
}
::v-deep .el-upload--picture-card {
  margin-bottom: 20px;
}
.drag-notice {
  display: none;
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #00000055;
  z-index: 99;
  line-height: 30px;
  text-align: center;
  color: #fff;
}

.img-wrapper:hover .delete-img-icon {
  display: block;
}

.delete-img-icon {
  position: absolute;
  color: red;
  right: -10px;
  top: -10px;
  font-size: 30px;
  display: none;
  z-index: 999;
}

.img {
  width: 148px;
  height: 148px;
}

.uploader-wrapper {
  display: flex;
  flex-wrap: wrap;
}
::v-deep .el-form-item--small {
  float: none !important;
}
</style>
