<template>
    <div class="orderManger_list">
        <GlobalInfoBar
            title="订单回收列表"
        />
        <GlobalForm
            :init-data="initData"
            :form-item-list="formItemList"
            :inline="true"
            round
            @handleConfirm="handleConfirm"
            confirmBtnName="查询"
            >
            <el-form-item class="el_from" label="订单类型:" slot="operation">
              <el-select
                v-model="mold"
                placeholder="请选择订单类型"
                clearable
                @change="selectClick"
              >
                <el-option
                  v-for="item in selsctList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
        </GlobalForm>
        <div slot="AddSlot" style="margin-bottom:10px">
            <el-button size="small" @click="recycleBatch('recyle')" type="warning" >批量还原</el-button>
            <el-button size="small" @click="recycleBatch('delete')" type="danger" icon="el-icon-delete">批量删除</el-button>
        </div>
        <GlobalTable
            ref="GlobalTable"
            :isSelection='true'
            :columns="tableColumns"
            :data="tableData"
            :currentPage="page"
            :total="total"
            @handleCurrentChange="handleCurrentChange"
            @handleSelectionChange="handleSelectionChange"
            >
            <el-table-column label="订单类型" slot="operatAddress" align="center">
                <template slot-scope="{ row }">
                <div class='send_color'>
                    <div>[{{row.mold_name}}]</div>
                </div>
                </template>
            </el-table-column>
            <el-table-column width="250" label="操作" slot="operating" align="center">
                <template slot-scope="{ row }">
                <el-button type="text" @click="handleBtn(row,'reduct')" class="btn_delete">还原</el-button>
                <el-button type="text" @click="handleBtn(row,'delete')" class="btn_delete">删除</el-button>
                </template>
            </el-table-column>
        </GlobalTable>
    </div>
</template>

<script>
import _api from "@/utils/request";
export default {
    name:'orderManger_list',
    data(){
        return{
            limit:10,//每页条数
            page:1,//页码
            total:0,//总条数
            orderIds:[],
            beg_time:'',//开始时间
            end_time:'',//结束时间
            mold:'',//订单类型
            initData: null,
            formItemList: [
                { slotName: 'operation' },
                {
                    key: 'entryTime',
                    type: 'daterange',
                    labelName: '加入时间:',
                    placeholder: '请选择加入时间',
                    valueFormat:"timestamp"
                },
            ],
            // 表格
            tableData:[],
            selsctList:[],
            tableColumns: [
                { label: "订单id", prop: "id" },
                { slotName:'operatAddress'},
                { label: "IMEI/卡密", prop: "imei" },
                { label: "加入时间", prop: "add_time" },
                { slotName:'operating'}
            ],
        }
    },
    mounted(){
        this.orderMold()
        this.orderList()
    },
    methods:{
        //订单类型下拉
        orderMold(){
            _api.orderMold().then(res=>{
                this.selsctList = res.data
            })
        },
        selectClick(val){
            this.mold = val
        },
        // 分页
        handleCurrentChange(val) {
            console.log(val)
            this.page = val
            this.orderList()
        },
        handleSelectionChange(val){
            this.orderIds=[]
            val.forEach(item=>{
                this.orderIds.push(item.id)
            })
        },
        orderList(){
            const SeachParams = {
                limit:this.limit,
                page:this.page,
                // group_id:this.group_id,
                mold:this.mold,
                beg_time:this.beg_time,
                end_time:this.end_time,
            }
            _api.recycleList(SeachParams).then(res=>{
                this.tableData = res.data
                this.total = res.count
            })
        },
        handleConfirm(data, cd) {
            cd()
            console.log('提交了form', data)
            this.page = 1
            if(data){
                // this.imei = data.imei
                if(data.entryTime===null){
                    this.beg_time="",
                    this.end_time=""
                }else{
                    this.beg_time=data.entryTime[0],
                    this.end_time=data.entryTime[1]
                }
            }else{
                this.beg_time="",
                this.end_time=""
            }
            const SeachParams = {
                limit:this.limit,
                page:this.page,
                // group_id:this.group_id,
                mold:this.mold,
                beg_time:this.beg_time,
                end_time:this.end_time,
            }
            _api.recycleList(SeachParams).then(res=>{
                this.tableData = res.data
                this.total = res.count
            })
        },
        //操作
        handleBtn(row,type){
            if(type==='delete'){
                this.$confirm('删除后数据不可恢复，确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    _api.recycleHandle({type:2,id:row.id}).then(res=>{
                        if(res.code===1){
                        this.$message.success(res.msg)
                        this.orderList()
                        }
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消'
                    // });          
                });
            }else{
                this.$confirm('确认要还原订单数据吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    _api.recycleHandle({type:1,id:row.id}).then(res=>{
                        if(res.code===1){
                        this.$message.success(res.msg)
                        this.orderList()
                        }
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消'
                    // });          
                });
            }
        },
        //批量操作
        recycleBatch(val){
            if(this.orderIds.length>0){
                if(val==='delete'){
                    this.$confirm('删除后数据不可恢复，确认删除吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        _api.recycleBatch({type:2,id_array:this.orderIds}).then(res=>{
                            if(res.code===1){
                                this.$message.success(res.msg)
                                this.orderList()
                            }else{
                                this.$message.error(res.msg)
                            }
                        })
                    }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消'
                        // });          
                    });
                }else{
                    this.$confirm('确认要还原订单数据吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        _api.recycleBatch({type:1,id_array:this.orderIds}).then(res=>{
                            if(res.code===1){
                                this.$message.success(res.msg)
                                this.orderList()
                            }else{
                                this.$message.error(res.msg)
                            }
                        })
                    }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消'
                        // });          
                    });
                }
            }else{
                this.$message.error('请先勾选数据！')
            }
            
        }
    },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.orderManger_list{
    .btn_delete{
        color: #FF687B;
    }
    /deep/ .el-form-item__label{
        width: 80px!important;
    }
}
</style>