import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './router'
import store from './store'
import '@/plugins/plugins.js'
import '@/components/global'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import '@/utils/scale'

Vue.use(Viewer);
Vue.config.productionTip = false
import _api from "@/utils/request";

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

/*beforeRouteEnter(to,from,next) {
  console.log(`%c tp`,'color:red;font-size:20px',to)
  console.log(`%c from`,'color:red;font-size:20px',from)
  next()
  // _api.getMenu().then(res=>{
  //   let menu = res.data.menu || [];
  //   let defaultPath=menu[0]
  //   if (defaultPath&&defaultPath.path) {
  //     if (defaultPath.path === "/dashboard") {
  //       next()
  //     }else {
  //       if (defaultPath.children && defaultPath.children.length&&defaultPath.children[0].path) {
  //         next(defaultPath.children[0].path)
  //       }else {
  //         next(defaultPath.path)
  //       }
  //     }
  //   }else {
  //     alert('获取用户权限失败')
  //   }
  // }).catch(err=>{
  //   console.log(err)
  //   alert('获取用户权限失败')
  // })
},*/

let checkAuth = (menu, to, next) => {
    let toPathPre = to.path.match(/\/[^/]+/)[0]

    if (menu.some(item=>{
        return item.path===toPathPre
    })) {
        next()
    } else {
        if (menu[0]&&menu[0].children && menu[0].children.length && menu[0].children[0].path) {
            next(menu[0].children[0].path);
            return;
        }
        next()
    }
}

router.beforeEach((to, from, next) => {
    if (to.path === '/login'||!localStorage.getItem('token')) {
        store.dispatch("setMenu", null)
        next();
        return
    }
    if (!store.state.menu) {
        _api.getMenu().then(res => {
            let menu = res&&res.data&&res.data.menu || []
            store.dispatch('setMenu', menu);

            // if (!menu.length) {
            //     alert('获取用户权限失败');
            //     return
            // }
            checkAuth(menu, to, next)

        }).catch(err => {
            console.log(err)
            alert('获取用户权限失败')
        });
    } else {
        checkAuth(store.state.menu, to, next);
    }

})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
