<template>
  <div class="global-nav">
    <div
        v-for="(item,index) in navList"
        :key="index"
        class="global-nav_item"
        :class="currentNav.id===item.id&&'active'"
        @click="handleNav(item)"
    >
      <span class="global-nav_item_name">{{ item.name }}</span>
      <span
          v-if="item.numKey"
          class="global-nav_item_num"
          :class="item.isRed&&'global-nav_item_num_red'"
      >({{ numList[item.numKey] }})</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalNav',
  props: {
    navList: {
      require: true,
      type: Array
    },
    initNav: {
      require: true,
      type: Object
    },
    numList: Object
  },
  data() {
    return {
      currentNav: 1
    }
  },
  mounted() {
    this.currentNav = this.initNav
  },
  methods: {
    handleNav(item) {
      this.currentNav = item
      this.$emit('changeNav', item)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
@import 'src/assets/scss/element/theme-color';
.global-nav{
  display: inline-block;
  background: #F9FBFD;
  border: 1px solid #C1D1FF;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 16px;
  &_item{
    display: inline-block;
    background: #F9FBFD;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    text-align: center;
    padding: 0 20px;
    cursor: pointer;
    &.active{
      background: #0981FF;
      color: #FFFFFF;
      .global-nav_item_num_red{
        color: #FFFFFF!important;
      }
    }
    &_num_red{
      color: $--color-danger;
    }
  }
}
</style>
