<template>
  <div class="carmi_list">
    <GlobalInfoBar
        title="卡密列表"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
        :padding="[20,20,20,20]"
    >

      <div slot="filter">
        <Form @changeNav="changeNavFrom"></Form>
      </div>

      <Table :tableData="tableDataIndex" @carmIDataList='carmIDataList' :carmItotal="carmItotal" :carmIpageNum="carmIpageNum" @handleCurrentChange="handleCurrentChange"></Table>
    </GlobalChunk>
  </div>
</template>

<script>
import Form from "./Form"
import Table from "./Table"
import _api from "@/utils/request";
export default {
  name: 'carmi_list',
  components: {Form, Table},
  
  data(){
    return{
      tableDataIndex:[],
      card_name:"",
      is_send:"",
      carmItotal:0,
      carmIpageNum:1,
      carmIpageSize:10,
    }
  },
  created(){
    this.carmIDataList()
  },
  methods: {
    //分页
    handleCurrentChange(val){
      this.carmIpageNum = val
      this.carmIDataList()
    },
    //查询
    changeNavFrom(val){
      this.card_name = val.card_name,
      this.is_send = val.is_send,
      this.beg_time = val.beg_time,
      this.end_time = val.end_time
      const SeachParams ={
        card_name :val.card_name,
        is_send :val.is_send,
        beg_time :val.beg_time,
        end_time :val.end_time,
        page:1,
        limit:10,
      }
      _api.cardBatchList(SeachParams).then(res => {
        if(res.code === 1){
          this.tableDataIndex = res.data
          this.carmItotal = res.count
          this.carmIpageNum = 1
        }
      })
    },
    //列表请求
    carmIDataList(){
      const vo ={
        page:this.carmIpageNum,
        limit:this.carmIpageSize,
        card_name:this.card_name,
        is_send:this.is_send,
        beg_time:this.beg_time,
        end_time:this.end_time
      }
      _api.cardBatchList(vo).then(res => {
        if(res.code === 1){
          this.tableDataIndex = res.data
          this.carmItotal = res.count
        }
      })
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.carmi_list{}
</style>
