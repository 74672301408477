<template>
  <div class="Precautions_list">
    <GlobalInfoBar title="注意事项" />
    <div class="top_tit">
      主要动态配置前端H5页面‘店员注册’、‘更换IMEI’、‘理赔申请’、‘维修师注册’的注意事项内容
    </div>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="注意事项是否配置" slot="operating" align="center">
        <template slot-scope="{ row }">
          <span v-if="!row.content" style="color:#FF687B">未配置</span>
          <span v-else>已配置</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="$router.push({path:'PrecautionsEdit',query:{id:row.article_id}})" type="text" class="mr_sty">编辑</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Precautions_list",
  data() {
    return {
    PlanItotal:0,
    tableData:[],
    PlanIpageNum:'',
    tableColumns: [
        { label: "前端H5页面", prop: "title" },
        { slotName: "operating" },
        { label: "最后更新时间", prop: "add_time" },
        { label: "操作账号", prop: "handle_admin" },
        { slotName: "operat" }
      ]
    };
  },
  mounted() {
    this.PrecautionsList();
  },
  methods: {
    //列表请求
    PrecautionsList() {
        _api.articlelist().then(res=>{
          console.log(res)
          this.tableData = res.data
          this.PlanItotal = res.data.length
        })
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PrecautionsList();
    },

  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Precautions_list {
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r{
    float: right;
    margin-right: 80px;
  }
  .top_tit{
    margin: 0 0 20px 0;
    color: #0981FF;
  }
}
</style>
