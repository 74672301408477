import Vue from 'vue'

// 注册全局组件
const componentsFiles = require.context('./components', false, /\.vue$/)
componentsFiles.keys().forEach(key => {
	const currentComponents = componentsFiles(key).default || componentsFiles(key)
	Vue.component(
		currentComponents.name, componentsFiles(key).default || componentsFiles(key)
	)
})
