<template>
  <div class="planNum_list">
    <GlobalInfoBar title="子险种列表" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="子方案号:" slot="operationSelect">
        <el-select
          v-model="zifangan"
          placeholder="请选择子方案号查询"
          clearable
          @change="handleChange"
        >
          <el-option
            v-for="item in GroupList"
            :key="item.zifang_id"
            :label="item.zifang_name"
            :value="item.zifang_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <div slot="AddSlot">
        <el-button
          size="small"
          @click="addPlan()"
          type="primary"
          icon="el-icon-plus"
          >添加子险种</el-button
        >
      </div>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="排序" slot="store" align="center">
        <template slot-scope="{ row }">
          <svg
            t="1665975252079"
            @click="upClick(row.goods_id)"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="12433"
            width="30"
            height="30"
          >
            <path
              d="M954.24 325.12a480 480 0 0 0-629.12-256 480 480 0 0 0-256 629.12 480 480 0 0 0 629.12 256 480 480 0 0 0 256-629.12zM512 923.2A411.2 411.2 0 1 1 923.2 512 411.68 411.68 0 0 1 512 923.2z"
              fill="#4592D8"
              p-id="12434"
            ></path>
            <path
              d="M487.68 236.64L276.96 448a34.4 34.4 0 1 0 48 48l151.36-151.36v418.4a34.4 34.4 0 1 0 68.64 0V343.2L698.4 496a34.4 34.4 0 1 0 48-48L536.32 236.64a34.4 34.4 0 0 0-48 0z"
              fill="#4592D8"
              p-id="12435"
            ></path>
          </svg>
          <svg
            t="1665975213110"
            @click="downClick(row.goods_id)"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="10820"
            width="30"
            height="30"
          >
            <path
              d="M992 512A480 480 0 0 0 512 32 480 480 0 0 0 32 512a480 480 0 0 0 480 480 480 480 0 0 0 480-480z m-68.8 0A411.2 411.2 0 1 1 512 100.8 411.68 411.68 0 0 1 923.2 512z"
              fill="#1296db"
              p-id="10821"
            ></path>
            <path
              d="M487.68 787.36L276.96 576a34.4 34.4 0 1 1 48-48l151.36 151.36V260.96a34.4 34.4 0 1 1 68.64 0v419.84L698.4 528a34.4 34.4 0 1 1 48 48L536.32 787.36a34.4 34.4 0 0 1-48 0z"
              fill="#1296db"
              p-id="10822"
            ></path>
          </svg>
          <!-- <el-switch v-model="row.is_ais" :active-value="1" :inactive-value="0" @change="aisiChange(row)"></el-switch> -->
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editPlan(row, 'edit')" type="text" class="mr_sty"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :visible.sync="visible" :title="title" width="600px">
      <div v-if="this.title === '新增子险种'" class="tit_color">
        说明:子险种名称不能重复添加，名称长度限制50字内
      </div>
      <div v-else class="tit_color">
        说明: 修改前请仔细核对信息，否则将影响生成卡密
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="子险种名称" prop="goods_name">
          <el-input
            v-model="ruleForm.goods_name"
            placeholder="请输入子险种名称"
            :maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="关联子方案号" prop="zifang_id">
          <!-- <el-select v-model="ruleForm.zifang_id" placeholder="请关联子方案号（如无数据请先创建）">
            <el-option label="1年" value="1年"></el-option>
            <el-option label="2年" value="2年"></el-option>
          </el-select> -->
          <el-select
            v-model="ruleForm.zifang_id"
            placeholder="请关联子方案号（如无数据请先创建）"
            clearable
            @change="handleChange"
          >
            <el-option
              v-for="item in GroupList"
              :key="item.zifang_id"
              :label="item.zifang_name"
              :value="item.zifang_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_form" label="卡号前缀" prop="prefix">
          <el-input
            v-model="ruleForm.prefix"
            placeholder="请输入卡号前缀（4位大写或小写字母）"
            :maxlength="4"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form_r">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 'add')"
            v-if="this.title === '新增子险种'"
            >确认新增</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 'edit')"
            v-else
            >确认修改</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { formValidateAlphabets } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "planNum_list",
  data() {
    return {
      //弹窗参数
      visible: false,
      title: "",
      ruleForm: {
        zifang_id: "",
        goods_name: "",
        prefix: "",
        goods_id: "", //	是	int	0新增 大于0：修改
      },
      rules: {
        goods_name: [
          { required: true, message: "请填写子险种号名称", trigger: "blur" },
        ],
        zifang_id: [
          { required: true, message: "请选择关联子方案号", trigger: "blur" },
        ],
        prefix: [
          { required: true, trigger: "blur", validator: formValidateAlphabets },
        ],
      },
      //列表
      GroupList: [],
      initData: null,
      formItemList: [
        {
          key: "goods_name",
          type: "input",
          labelName: "子险种名称:",
          placeholder: "请输入子险种名称查询",
        },
        {
          key: "prefix",
          type: "input",
          labelName: "卡号前缀:",
          placeholder: "请输入卡号前缀查询",
        },
        // {
        //   key: 'zifangan',
        //   type: 'select',
        //   labelName: '子方案号:',
        //   placeholder: '请选择子方案号查询',
        //   option:[]
        // },
        { slotName: "operationSelect" },
      ],
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      goods_name: "",
      zifangan: "",
      prefix: "",
      tableColumns: [
        { label: "子险种名称", prop: "goods_name" },
        { label: "卡号前缀", prop: "prefix" },
        { label: "关联子方案号", prop: "zifangan" },
        { label: "保费（￥）", prop: "goods_price" },
        { label: "保期（年）", prop: "valid_stage" },
        { label: "最后更新时间", prop: "add_time" },
        { slotName: "store" },
        { slotName: "operat" },
      ],
    };
  },
  mounted() {
    this.PostPlanList();
    _api.pullZifangan().then((res) => {
      console.log(res);
      this.GroupList = res.data;
    });
  },
  methods: {
    // 上移
    upClick(id) {
      this.move(id, 1);
    },
    // 下移
    downClick(id) {
      this.move(id, 2);
    },
    move(id, move) {
      _api.CardsortCard({ goods_id: id, move }).then((res) => {
        this.PostPlanList();
      });
    },
    //列表请求
    PostPlanList() {
      const params = {
        page: this.PlanIpageNum,
        limit: this.PlanIpageSize,
        goods_name: this.goods_name,
        zifangan: this.zifangan,
        prefix: this.prefix,
      };
      _api.cardGoodsList(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostPlanList();
    },
    handleChange(val) {
      console.log(val);
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      this.goods_name = data.goods_name;
      this.prefix = data.prefix;
      const params = {
        page: 1,
        limit: 10,
        goods_name: data.goods_name,
        zifangan: this.zifangan,
        prefix: data.prefix,
      };
      _api.cardGoodsList(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    addPlan() {
      this.ruleForm.goods_name = "";
      this.ruleForm.prefix = "";
      this.ruleForm.zifang_id = "";
      this.visible = true;
      this.title = "新增子险种";
    },
    //修改
    editPlan(row, type) {
      console.log(row);
      this.visible = true;
      this.title = "修改子险种";
      this.ruleForm.goods_name = row.goods_name;
      this.ruleForm.prefix = row.prefix;
      this.ruleForm.zifang_id = row.zifang_id;
      this.ruleForm.goods_id = row.goods_id;
    },
    submitForm(formName, type) {
      if (type === "add") {
        this.ruleForm.goods_id = 0;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.addCardGoods(this.ruleForm).then((res) => {
            console.log(res);
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.PostPlanList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  /deep/ .el-form-item__label {
    width: 100px !important;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r {
    float: right;
    margin-right: 80px;
  }
}
.icon {
  cursor: pointer;
  margin-right: 20px;
}
</style>
