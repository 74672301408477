<template>
  <el-select v-bind="$attrs" size="mini" filterable v-on="$listeners" clearable placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "CyberpunkYearPicker",
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input__inner{
  border-color: #00BBFF !important;
  color: #58DBFF !important;
  background-color: #0D1427 !important;
}
::v-deep .el-input__icon:before {
  color: #00BBFF;
}
</style>
<style lang="scss">
</style>
