<template>
  <div>
    <GlobalInfoBar title="订单日志"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-rules="formRules"
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      ></GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import {adminLog} from "@/utils/request/systemManage";

export default {
  name: "orderLog",
  data() {
    return {
      initData: {},
      formRules: {},
      formItemList: [
        {
          key: 'orderNo',
          type: 'input',
          labelName: '订单编号:',
          placeholder: '请输入订单编号'
        }, {
          key: 'operator',
          type: 'input',
          labelName: '操作人员:',
          placeholder: '请输入操作人员查询'
        }
      ],
      tableColumns: [
        {label: "订单编号", prop: "order_sn"},
        {label: "订单类型", prop: "order_type"},
        {label: "卡密/IME", prop: "card_imei"},
        {label: "操作人", prop: "add_admin"},
        {label: "执行操作", prop: "handle"},
        {label: "操作时间", prop: "add_time"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      orderNo: '',
      operator:''
    }
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    getOrders() {
      adminLog({
        page:this.curPage,
        limit:10,
        order_sn:this.orderNo,
        name:this.operator
      }).then(res=>{
        this.tableData=res.data||[]
        this.totalElements = res.count || 0
      })
    },
    query(data, cd) {
      this.operator = data.operator
      this.orderNo = data.orderNo
      this.curPage=1
      this.getOrders()
    },
    currentChange(page) {
      this.curPage=page
      this.getOrders()
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-form-item__label{
        width: 80px!important;
    }
</style>
