<template>
  <div>
    <GlobalInfoBar title="优惠券列表" subtitle=""/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          :labelWidth="80"
          @handleConfirm="query"
          confirmBtnName="查询"
      >
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          v-loading="loading"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="优惠卷状态" slot="coupon_state" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.coupon_state=='0'?'未激活':row.coupon_state=='1'?'（已激活）未使用':row.coupon_state=='2'?'已使用':'已过期' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="激活限制天数" slot="validity" align="center" min-width="120px">
          <template slot-scope="{ row }">
            <el-input-number @change='editValidity($event,row)' size="mini" v-model="row.validity"></el-input-number>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

  </div>
</template>

<script>
import {couponList,editCoupon} from "../../../utils/request/coupon";

export default {
  name: "index",
  data() {
    return {
      loading: false,
      formItemList: [
        {
          key: 'coupon_code',
          type: 'input',
          labelName: '激活码:',
          placeholder: '请输入激活码查询'
        },
        {
          key: 'phone',
          type: 'input',
          labelName: '手机号:',
          placeholder: '请输入手机号查询'
        },
      ],
      tableColumns: [
        {label: "优惠劵名称", prop: "coupon_title"},
        {label: "用户姓名", prop: "name"},
        {label: "用户手机", prop: "phone"},
        {label: "激活码", prop: "coupon_code"},
        {slotName: "coupon_state"},
        {label: "添加时间", prop: "add_time"},
        {slotName: "validity"},
        {label: "开启时间", prop: "beg_time"},
        {label: "到期时间", prop: "end_time"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      coupon_code: '',
      phone: '',
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    query(data) {
      this.phone=data.phone
      this.coupon_code=data.coupon_code
      this.curPage = 1
      this.getList()
    },
    getList() {
      this.loading = true
      couponList({
        page: this.curPage,
        limit: 10,
        coupon_code: this.coupon_code,
        phone: this.phone
      }).then(res => {
        this.tableData = res.data
        this.totalElements = res.count
        this.loading = false
      })
    },
    currentChange(page) {
      this.curPage = page;
      this.getList()
    },
    editValidity(val,row){
      editCoupon({value:val,coupon_id:row.coupon_id,field:'validity'}).then(res=>{
        if(res.code===1){
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style scoped>
.dialog-content p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.close-reason {
  margin-top: 20px;
  text-align: center;
}

.dialog-textarea {
  width: 300px;
  vertical-align: top;
}

.dialog-btns {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 42px;

}

.dialog-btns .cancel {
  margin-right: 20px;
}
::v-deep textarea {
  min-height: 7em!important;
}
</style>
