<template>
  <el-date-picker
    size="mini"
    v-bind="$attrs"
    v-on="$listeners"
    type="year"
    placeholder="选择年份"
    format="yyyy"
    value-format="yyyy"
  >
  </el-date-picker>
</template>

<script>
export default {
  name: "CyberpunkYearPicker",
};
</script>

<style scoped lang="scss">
::v-deep .el-input__inner{
  border-color: #00BBFF !important;
  color: #58DBFF !important;
  background-color: #0D1427 !important;
}
::v-deep .el-input__icon:before {
  color: #fff;
}
</style>
<style lang="scss">
</style>
