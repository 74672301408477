<template>
  <div class="planNum_list">
    <GlobalInfoBar title="保价业务配置" subtitle="" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div slot="AddSlot">
        <el-button
          size="small"
          @click="addPlanDia('export')"
          type="warning"
          icon="el-icon-plus"
          >批量导入数据</el-button
        >
        <el-button
          size="small"
          @click="addPlan()"
          type="primary"
          icon="el-icon-setting"
          >自动报价折扣范围设置</el-button
        >
      </div>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editPlan(row, 'edit')" type="text" class="mr_sty"
            >编辑</el-button
          >
          <el-button @click="deleteDis(row)" type="text" class="btn_delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <!--批量导入数据弹框-->
    <el-dialog
      title="批量导入数据"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <div class="step">
        <div class="step-title">
          <span>Step1</span>
        </div>
        <div class="step-content">
          <span style="margin-right: 12px">表格下载</span>
          <el-link type="primary" @click="download">点击下载模板表格</el-link>
        </div>
        <div class="step-title">
          <span>Step2</span>
        </div>
        <div class="tip">
          <span class="tip-title">上传表格</span>
          <span class="tip-content"
            >请上传.xls或.xlsx格式的excel文件，大小10M内</span
          >
        </div>
        <div class="step2-content">
          <el-upload
            ref="upload"
            :limit="1"
            :auto-upload="false"
            :http-request="uploadFile"
            accept=".xls,.xlsx"
            action="customize"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess"
          >确认导入</el-button
        >
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <!--自动报价折扣设置弹框-->
    <el-dialog
      title="自动报价折扣范围"
      :visible.sync="AutomaticVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handlediscountClose"
    >
      <p>说明:下单后将白动从设定范围取随机折扣，并乘以终端销售价</p>
      <div class="setNUM">
        <span style="margin-right: 20px">安卓</span>
        <div class="numInout">
          <el-input
            placeholder="请输入开始折扣值"
            maxlength="3"
            @input="calcNum100($event, 'Androidleftnum')"
            v-model="Androiddata.Androidleftnum"
            clearable
          >
          </el-input
          >%
        </div>
        <span style="margin: 0 25px">~</span>
        <div class="numInout">
          <el-input
            @input="calcNum100($event, 'Androidrightnum')"
            placeholder="请输入结束折扣值"
            v-model="Androiddata.Androidrightnum"
            clearable
          >
          </el-input
          >%
        </div>
      </div>
      <div class="setNUM">
        <span style="margin-right: 20px">苹果</span>
        <div class="numInout">
          <el-input
            placeholder="请输入开始折扣值"
            maxlength="3"
            @input="ioscalcNum100($event, 'iosleftnum')"
            v-model="iosdata.iosleftnum"
            clearable
          >
          </el-input
          >%
        </div>
        <span style="margin: 0 25px">~</span>
        <div class="numInout">
          <el-input
            @input="ioscalcNum100($event, 'iosrightnum')"
            placeholder="请输入结束折扣值"
            v-model="iosdata.iosrightnum"
            clearable
          >
          </el-input
          >%
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handlediscountSuccess"
          >确定</el-button
        >
        <el-button @click.native="handlediscountClose">取消</el-button>
      </span>
    </el-dialog>
    <!--编辑机型弹框-->
    <el-dialog
      title="操作提示"
      :visible.sync="enditVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handlediscountClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="机型" prop="model_name">
          <el-input disabled v-model="ruleForm.model_name"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input disabled v-model="ruleForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="内存" prop="ram_size">
          <el-input disabled v-model="ruleForm.ram_size"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="ruleForm.price"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import {
  discountInfo,
  discountSet,
  phonePriceXiaoyiList,
  phonePriceInfo,
} from "../../../utils/request/changePhone";
export default {
  name: "planNum_list",
  data() {
    return {
      ruleForm: {
        model_name: "",
        brand: "",
        ram_size: "",
        price: "",
      },
      rules: {
        model_name: [
          { required: true, message: "请输入机型名称", trigger: "blur" },
        ],
        brand: [{ required: true, message: "请输入品牌名称", trigger: "blur" }],
        ram_size: [
          { required: true, message: "请输入机型内存", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入机型价格", trigger: "blur" }],
      },
      Androiddata: {
        Androidleftnum: "",
        Androidrightnum: "",
      },
      iosdata: {
        iosleftnum: "",
        iosrightnum: "",
      },
      // 弹窗
      enditVisible: false,
      AutomaticVisible: false,
      dialogVisible: false,
      //列表
      initData: null,
      end_time: "",
      beg_time: "",
      formItemList: [
        {
          key: "model_name",
          type: "input",
          labelName: "机型名称:",
          placeholder: "请输入机型名称",
        },
        {
          key: "brand",
          type: "input",
          labelName: "品牌名称:",
          placeholder: "请输入品牌名称",
        },
        { slotName: "operation" },
      ],
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      model_name: "",
      brand: "",
      tableColumns: [
        { label: "机型", prop: "model_name" },
        { label: "品牌", prop: "brand" },
        { label: "规格", prop: "ram_size" },
        { label: "价格", prop: "price" },
        { label: "更新时间", prop: "add_time" },
        { slotName: "operat" },
      ],
    };
  },
  mounted() {
    this.PostList();
  },
  methods: {
    //修改
    editPlan(row) {
      phonePriceInfo({ price_id: row.price_id }).then((res) => {
        this.ruleForm = res.data;
        this.enditVisible = true;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api
            .actionphoneXiaoyiPrice({
              price_id: this.ruleForm.price_id,
              price: this.ruleForm.price,
            })
            .then((res) => {
              this.$message({
                message: "操作成功！",
                type: "success",
              });
              this.enditVisible = false;
              this.PostList();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].clearValidate();
      this.ruleForm={}
      this.enditVisible = false;
    },
    calcNum100(num, name) {
      this.Androiddata[name] = this.Androiddata[name].replace(/[^0-9]/g, ""); // 清除“数字”以外的字符
      if (this.Androiddata[name] > 100) {
        return (this.Androiddata[name] = 100);
      }
      return this.Androiddata[name];
    },
    ioscalcNum100(num, name) {
      this.iosdata[name] = this.iosdata[name].replace(/[^0-9]/g, ""); // 清除“数字”以外的字符
      if (this.iosdata[name] > 100) {
        return (this.iosdata[name] = 100);
      }
      return this.iosdata[name];
    },
    handlediscountSuccess() {
      if (
        (this.iosdata.iosleftnum && this.iosdata.iosrightnum === "") ||
        (this.iosdata.iosleftnum === "" && this.iosdata.iosrightnum) ||
        (this.Androiddata.Androidleftnum &&
          this.Androiddata.Androidrightnum === "") ||
        (this.Androiddata.Androidleftnum === "" &&
          this.Androiddata.Androidrightnum)
      ) {
        return this.$message.error("请输入完整的折扣范围");
      }
      discountSet([
        {
          id: 1,
          mold: 1,
          min: this.iosdata.iosleftnum,
          max: this.iosdata.iosrightnum,
        },
        {
          id: 2,
          mold: 2,
          min: this.Androiddata.Androidleftnum,
          max: this.Androiddata.Androidrightnum,
        },
      ]).then((res) => {
        this.$message({
          message: "操作成功！",
          type: "success",
        });
        this.AutomaticVisible = false;
      });
    },
    handlediscountClose() {
      this.AutomaticVisible = false;
    },
    addPlan() {
      this.AutomaticVisible = true;
      discountInfo().then((res) => {
        console.log(res);
        const { list } = res.data;
        // 1 苹果 2 安卓
        this.iosdata.iosleftnum = list.filter((item) => item.mold == 1)[0].min;
        this.iosdata.iosrightnum = list.filter((item) => item.mold == 1)[0].max;
        this.Androiddata.Androidleftnum = list.filter(
          (item) => item.mold == 2
        )[0].min;
        this.Androiddata.Androidrightnum = list.filter(
          (item) => item.mold == 2
        )[0].max;
      });
    },
    //列表请求
    PostList() {
      const params = {
        page: this.PlanIpageNum,
        limit: this.PlanIpageSize,
        brand: this.brand,
        model_name: this.model_name,
      };
      phonePriceXiaoyiList(params).then((res) => {
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostList();
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      this.model_name = data.model_name;
      this.brand = data.brand;
      const params = {
        page: 1,
        limit: 10,
        brand: this.brand,
        model_name: data.model_name,
      };
      phonePriceXiaoyiList(params).then((res) => {
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },

    //删除
    deleteDis(row) {
      console.log(row);
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _api.delXiaoyiPhonePrice({ price_id: row.price_id }).then((res) => {
            if (res.code === 1) {
              this.$message.success("删除成功");
              this.PostList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });
        });
    },
    // 弹窗
    addPlanDia(type) {
      console.log(type);
      this.dialogVisible = true;
    },
    download() {
      _api.downloadXiaoyi().then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);
        const fileName = "模板数据.xls";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    uploadFile(params) {
      console.log("uploadFile", params);
      const _file = params.file;
      // 通过 FormData 对象上传文件
      var formData = new FormData();
      formData.append("file", _file);
      // 发起请求
      const loading = this.$loading({
        lock: true,
        text: "正在导入数据，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        _api.uploadXiaoyi(formData).then((res) => {
          if (res.code === 1) {
            loading.close();
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$refs["upload"].clearFiles();
            this.PostList();
          } else {
            loading.close();
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        });
      }, 1000);
    },
    handleSuccess() {
      this.dialogVisible = false;
      this.$refs.upload.submit();
    },
    // 弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    handleDialogClosed() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.setNUM {
  margin-top: 20px;
  display: flex;
  align-items: center;
  .numInout {
    display: flex;
    align-items: center;
    width: 170px;
  }
}
.planNum_list {
  /deep/ .el-form-item__label {
    width: 80px !important;
  }
  .step {
    .step-title {
      color: #0981ff;
      margin-bottom: 10px;
      padding-left: 6px;
      border-left: 3px solid;
      border-radius: 1.5px;
    }
    .step-content,
    .step2-content {
      margin-bottom: 26px;
    }
    .step2-content > div {
      display: flex;
    }
    .step2-content {
      .upload-demo {
        display: flex;
        justify-content: flex-start;
        .el-upload-list {
          width: 200px;
        }
      }
    }
    .tip {
      margin-bottom: 12px;
      .tip-title {
        color: #666666;
        margin-right: 12px;
      }
      .tip-content {
        color: #ff687b;
        font-size: 12px;
      }
    }
  }
  .btn_delete {
    color: #ff687b;
  }
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r {
    float: right;
    margin-right: 80px;
  }
}
</style>
