import Layout from '@/layout/layout'

const SystemManageRouter = {
    path: '/systemManage',
    component: Layout,
    redirect: 'noRedirect',
    name: '系统管理',
    children: [
        {
            path:'account',
            component: () => import('@/views/systemManage/account'),
            name: '系统账号',
            meta: { title: '系统账号', noCache: true }
        },  {
            path:'role',
            component: () => import('@/views/systemManage/role'),
            name: '角色管理',
            meta: { title: '角色管理', noCache: true }
        },  {
            path:'orderLog',
            component: () => import('@/views/systemManage/orderLog'),
            name: '订单日志',
            meta: { title: '订单日志', noCache: true }
        },{
            path:'cardLog',
            component: () => import('@/views/systemManage/cardLog'),
            name: '卡密修改日志',
            meta: { title: '卡密修改日志', noCache: true }
        },

    ]
}

export default SystemManageRouter
