<template>
  <div class="register_list">
    <GlobalInfoBar title="注册协议及说明" />
    <GlobalChunk
        :padding="[20,20,20,20]">
    <div class="top_tit">
      主要动态配置前端H5保险注册页面的‘保险说明’及‘保险注册服务协议’，以及保险注册后的‘可理赔次数’，以及子险种及成本
    </div>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :isPagination="false"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="保险说明是否配置" slot="operating" align="center">
        <template slot-scope="{ row }">
          <span v-if="!row.instype_explain" style="color:#FF687B">未配置</span>
          <span v-else>已配置</span>
        </template>
      </el-table-column>
      <el-table-column label="保险协议是否配置" slot="operating" align="center">
        <template slot-scope="{ row }">
          <span v-if="!row.instype_agree" style="color:#FF687B">未配置</span>
          <span v-else>已配置</span>
        </template>
      </el-table-column>
      <el-table-column label="子险种配置" slot="subInsurance" align="center">
        <template slot-scope="{ row }">
          <a href="javascript:" @click="subInsuranceSetting(row.instype_id)" v-if="row.is_son===1">查看</a>
          <a href="javascript:" @click="subInsuranceSetting(row.instype_id)" v-else>添加</a>
        </template>
      </el-table-column>
      <el-table-column label="积分" slot="points" width="160px" align="center">
        <template slot-scope="{ row }">
          <el-input-number :min="0" :max="999"  size="small" v-model="row.points" @change="handleChangeNum(row)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="$router.push({path:'registerEdit',query:{id:row.instype_id}})" type="text" class="mr_sty">编辑</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import {hotPhoneMode} from "../../../utils/request/renewal"
export default {
  name: "register_list",
  data() {
    return {
      PlanItotal:0,
      PlanIpageNum:'',
      tableData:[],
      tableColumns: [
        { label: "保险类型", prop: "instype_name" },
        { slotName: "operating" },
        {label: "理赔上限（次）", prop: "claims_num" },
        { slotName: "subInsurance" },
        { label: "最后更新时间", prop: "add_time" },
        { label: "操作账号", prop: "handle_admin" },
        {slotName:"points"},
        { slotName: "operat" }
      ]
    };
  },
  mounted() {
    this.registerList();
  },
  methods: {
   //排序
   handleChangeNum(val) {
      const params = {
        instype_id: val.instype_id,
        points: val.points
      };
      hotPhoneMode(params).then(res => {
        if (res.code === 1) {
          this.registerList();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //列表请求
    registerList() {
      _api.insuranceTypeList().then(res=>{
        console.log(res)
        this.tableData = res.data
        this.PlanItotal = res.data.length
      })
    },
    //翻页
    handleCurrentChange(val) {
      console.log(val);
      this.PlanIpageNum = val;
      this.registerList();
    },
    subInsuranceSetting(instype_id) {
      this.$router.push({
        path:'subInsuranceSetting',
        query:{
          instype_id
        }
      })
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.register_list {
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r{
    float: right;
    margin-right: 80px;
  }
  .top_tit{
    margin: 0 0 20px 0;
    color: #0981FF;
  }
}
</style>
