<template>
  <el-date-picker
      v-bind="$attrs"
      v-on="$listeners"
      ref="timePiker"
      size="mini"
      type="daterange"
      align="left"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
  </el-date-picker>
</template>

<script>
export default {
  name: "CyberpunkDatePicker",
  data() {
    let generatePeriod = (picker, intervals) => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - intervals);
      picker.$emit('pick', [start, end]);
    }
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周', onClick(picker) {
            generatePeriod(picker, 3600 * 1000 * 24 * 7)
          }
        }, {
          text: '最近一个月', onClick(picker) {
            generatePeriod(picker, 3600 * 1000 * 24 * 30)
          }
        }, {
          text: '最近三个月', onClick(picker) {
            generatePeriod(picker, 3600 * 1000 * 24 * 90)
          }
        }, {
          text: '最近半年', onClick(picker) {
            generatePeriod(picker, 3600 * 1000 * 24 * 182)
          }
        }, {
          text: '最近一年', onClick(picker) {
            generatePeriod(picker, 3600 * 1000 * 24 * 365)
          }
        }]
      },
    }
  },
}
</script>

<style scoped lang="scss">

.el-range-editor--mini.el-input__inner {
  background: #162241;
  border-color: #00BBFF;
  width: 210px !important;
  box-shadow: inset 0 0 10px rgba(0, 203, 255, 0.7);

  ::v-deep .el-range-separator {
    width: 30px !important;
  }

}

::v-deep .el-range-input {
  background: transparent;
  color: #00BBFF !important;

  &::placeholder {
    color: #00BBFF !important;
  }
}


::v-deep .el-input__icon:before {
  color: #fff;
}

::v-deep .el-range-separator {
  color: #fff;
}
</style>
<style lang="scss">
.el-date-range-picker {
  border-color: #00CBFF !important;
  background: #162241 !important;

  .el-picker-panel__sidebar {
    background: #162241 !important;
  }

  .available {
    color: #fff !important;

    &.in-range {
      color: #00CBFF !important;
    }

    &.today span {
      color: #00CBFF !important;
    }
  }

  th {
    border-color: #00CBFF !important;
  }

  .next-month, .prev-month {
    color: #666 !important;
  }

  th, .el-date-range-picker__header, .el-picker-panel__shortcut {
    color: #00CBFF;
  }

  .el-picker-panel__icon-btn:before {
    color: #00CBFF !important;
  }

  .popper__arrow {
    border-bottom-color: #00CBFF !important;
  }

  .popper__arrow:after {
    border-bottom-color: #162241 !important;
  }

  .el-picker-panel__sidebar, .el-date-range-picker__content.is-left {
    border-color: #00CBFF !important;
  }

  .start-date span,
  .end-date span, {
    background: #00CBFF !important
  }
}

.el-picker-panel__sidebar,
.el-picker-panel__content {
  box-shadow: inset 0 0 10px rgba(0, 203, 255, 0.7);
}

.el-date-table td.in-range div {
  background: rgba(0, 203, 255, 0.5) !important;
}
</style>
