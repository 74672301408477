<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    <div slot="AddSlot" >
      <el-button size="small" @click="$router.push('/Carmi/generate')" type="primary" icon="el-icon-plus">批量生成卡密</el-button>
    </div>
    </GlobalForm>
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
export default {
  name: 'Form',
  data() {
    return {
      initData: null,
      end_time:"",
      beg_time:"",
      formItemList: [
        {
          key: 'card_name',
          type: 'input',
          labelName: '子险种名称:',
          placeholder: '请输入名称查询'
        },
        {
          key: 'entryTime',
          type: 'daterange',
          labelName: '生成时间:',
          placeholder: '请选择生成时间',
          valueFormat:"timestamp"
        },
        {
          key: 'is_send',
          type: 'select',
          labelName: '印刷状态:',
          placeholder: '请选择印刷状态',
          option:[
            {
              value:"0",
              label:"未印刷",
            },{
              value:"1",
              label:"已印刷",
            },
          ]
        },//0未寄送 1已寄送
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    handleConfirm(data, cd) {
      cd()
      console.log('提交了form', data)
      if(data){
        if(data.entryTime===null){
          this.beg_time="",
          this.end_time=""
        }else{
          this.beg_time=data.entryTime[0],
          this.end_time=data.entryTime[1]
        }
      }else{
        this.beg_time="",
        this.end_time=""
      }
      const SeachParams = {
        card_name:data.card_name,
        is_send:data.is_send,
        beg_time:this.beg_time,
        end_time:this.end_time,
        pageNum: 1,
        pageSize: 10,
      }
      this.$emit("changeNav",SeachParams)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{
  /deep/ .el-form-item__label{
        width: 100px!important;
    }
}
</style>
