<template>
  <div>
    <GlobalInfoBar
        title="物流订单列表"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
        :padding="[20,20,20,20]"
    >
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="handleConfirm"
          confirmBtnName="查询"
      >
        <!--        <el-button style="float: right;margin-left: 30px" slot="btnSlot" type="primary" size="small" round>物流编码导入</el-button>-->

        <el-upload
            style="float: right;margin-left: 30px"
            ref="uploader"
            action="#"
            :limit="1"
            :auto-upload="false"
            :show-file-list="false"
            :file-list="fileList"
            slot="btnSlot"
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            :on-change="fileChange">
          <el-button type="primary" size="small" round>物流编码导入(签收)</el-button>
        </el-upload>

      </GlobalForm>
      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="page"
          :total="total"
          @handleCurrentChange="handleCurrentChange"
      >

        <el-table-column label="状态" slot="status" align="center" width="80px">
          <template slot-scope="{row}">
            <el-tag v-if="row.is_sign===1" size="mini">已签收</el-tag>
            <el-tag v-if="row.is_sign===0" size="mini" type="warning">未签收</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="原始订单编码" slot="order_sn" align="center">
          <template slot-scope="{row}">
            <a style="color: #7F7FFF" href="javascript:" @click="toOriginalOrder(row)">{{ row.order_sn }}</a>
          </template>
        </el-table-column>
        <el-table-column label="物流订单编码" slot="orderNumber" align="center">
          <template slot-scope="{row}">
            <el-popover
                ref="popover1"
                placement="top-start"
                width="500"
                trigger="hover">
              <p style="font-weight: bold;margin-bottom: 20px">物流信息查询</p>
              <!--              <p style="color:#0981FF;margin-bottom: 10px">提示:物流单号已自动复制,可点击'立即查询'查看物流详情</p>-->
              <p>系统默认查询网址为:快递100 <a href="javascript:">http://www.kuaidi100.com
              </a>
                <el-button type="primary" style="padding: 4px 10px;margin-left: 20px"
                           @click="queryLogisticsInfoNow(row)">立即查询
                </el-button>
              </p>
              <a slot="reference" style="color: #3ABAF3" href="javascript:">{{ row.orderNumber }}</a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operate" align="center" fixed="right" width="150px">
          <template slot-scope="{row}">
            <a href="javascript:" @click="sign(row)">{{ row.is_sign === 0 ? '标记已签收' : '标记未签收' }}</a>
            <el-popover
                ref="popover2"
                placement="left"
                trigger="hover">
              <p style="font-weight: bold;margin-bottom: 20px">{{ row.remarks ? '修改' : '添加' }}备注</p>
              <el-input v-model="row.remarksCopy" type="textarea" placeholder="请填写备注" style="width: 300px;"></el-input>
              <br>
              <el-button type="primary" size="mini" style="margin-top: 20px;float: right" @click="remarkConfirm(row)">
                确定
              </el-button>
              <a slot="reference" href="javascript:" style="margin-left: 20px"
                 @mouseenter="operateRemark(row)">{{ row.remarks ? '查看' : '添加' }}备注</a>
            </el-popover>
          </template>
        </el-table-column>

      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import api from '@/utils/request'

export default {
  name: "index",
  data() {
    return {
      limit: 10,
      page: 1,
      total: 0,
      formItemList: [
        {
          key: 'order_sn',
          type: 'input',
          labelName: '订单编号:',
          placeholder: '请输入订单编号'
        },
        {
          key: 'orderNumber',
          type: 'input',
          labelName: '物流编号:',
          placeholder: '请输入物流编号'
        },
        {
          key: 'phone',
          type: 'input',
          labelName: '手机号码:',
          placeholder: '请输入手机号码'
        },
        {
          key: 'sendUser',
          type: 'input',
          labelName: '发件人姓名:',
          placeholder: '请输入发件人姓名'
        },
        {
          key: 'is_sign',
          type: 'select',
          labelName: '是否签收:',
          option: [
            {label: '全部', value: ''},
            {label: '是', value: 1},
            {label: '否', value: 0},
          ]
        },
      ],
      tableColumns: [
        {slotName: 'status'},
        {slotName: 'order_sn'},
        {slotName: 'orderNumber'},
        {label: "发件人姓名", prop: "sendUser"},
        {label: "发件人电话", prop: "sendUserPhone"},
        {label: "发件人地址", prop: "sendAddress"},
        {label: "收件人姓名", prop: "receiveUser"},
        {label: "收件人电话", prop: "receiveUserPhone"},
        {label: "收件人地址", prop: "receiveAddress"},
        {label: "发件时间", prop: "add_time"},
        {slotName: "operate"},
      ],
      tableData: [],
      orderNumber: '',
      sendUser: '',
      sendUserPhone: '',
      order_sn: '',
      is_sign: '',
      fileList: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      api.orderLogisticsList({
        page: this.page,
        limit: this.limit,
        orderNumber: this.orderNumber,
        phone: this.phone,
        sendUser: this.sendUser,
        order_sn: this.order_sn,
        is_sign: this.is_sign,
      }).then(res => {
        this.tableData = res.data || []
        this.total = res.count
      })
    },
    handleConfirm(data) {
      console.log(`%c data`, 'color:red', data)
      this.orderNumber = data.orderNumber
      this.sendUser = data.sendUser
      this.phone = data.phone
      this.order_sn = data.order_sn
      this.is_sign = data.is_sign
      this.page = 1
      this.getList()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getList()
    },
    toOriginalOrder(row) {
      if (row.order_type === 2) {
        open(this.$router.resolve({name: '订单详情', params: {type: row.mold, orderId: row.order_id}}).href)
      }
      if (row.order_type === 3) {
        open(this.$router.resolve({name: '五折焕新订单列表', query: {order_sn: row.order_sn}}).href)
      }
    },
    queryLogisticsInfoNow(row) {
      open('http://www.kuaidi100.com/chaxun?nu=' + row.orderNumber)
    },
    sign(row) {
      api.orderLogisticsOpen({
        field: 'is_sign',
        value: [1, 0][row.is_sign],
        id: row.id
      }).then(res => {
        this.$message.success(res && res.msg || '操作成功')
        this.getList()
      }).catch(err => {
        console.log(err)
        this.$message.error(err && err.msg || '操作失败')
      })
    },
    operateRemark(row) {
      this.$set(row, 'remarksCopy', JSON.parse(JSON.stringify(row.remarks)))
    },
    remarkConfirm(row) {

      api.orderLogisticsRemarks({
        remarks: row.remarksCopy,
        id: row.id
      }).then(res => {
        this.$message.success(res && res.msg || '操作成功')
        this.getList()
      }).catch(err => {
        console.log(err)
        this.$message.error(err && err.msg || '操作失败')
      })
    },
    fileChange(e) {
      let file = e.raw
      let formData = new FormData()
      formData.append('file', file)
      api.orderLogisticsForm(formData).then(res => {

        if (res && res.data && res.data.error) {
          this.$alert(
              `<div style="max-height: 50vh;overflow-y: auto">${res.data.error}</div>`,
              '提示', {
                confirmButtonText: '确定',
                dangerouslyUseHTMLString: true
              });
        } else {
          this.$message.success(res && res.data && res.data.msg || '上传成功')
        }

        this.getList();
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.$refs.uploader.clearFiles()
      })
    }
  }
}
</script>

<style scoped>

</style>
