import Layout from '@/layout/layout'

const featureRouter = {
  path: '',
  component: Layout,
  redirect: 'noRedirect',
  name: '',
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/home/home'),
      name: '首页',
      meta: { title: '首页', noCache: true, fixed: true }
    },
  ]
}

export default featureRouter
