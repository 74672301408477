<template>
  <div class="Distributor_list">
    <GlobalInfoBar>
      分销商列表
      <a style="font-size: 14px;margin-left: 20px" target="_blank" href="http://businessadmin.cdydkj.cn/login">分销商后台地址</a>
      <el-button type="primary" style="padding: 1px 3px;margin-left: 10px" @click="copy">复制</el-button>
    </GlobalInfoBar>
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="所属省份:" slot="operationSelect">
        <el-select
          v-model="province"
          placeholder="请输入或选择所属省份查询"
          clearable
          filterable
          @change="handleChange"
        >
          <el-option
            v-for="item in ProviceList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div slot="AddSlot">
        <el-button size="small" @click="addPlan()" type="primary" icon="el-icon-plus">新增分销商</el-button>
      </div>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="DistripageNum"
      :total="Distritotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="旗下商家" slot="operatNum" align="center">
        <template slot-scope="{ row }">
          <span style="margin-right:10px">{{row.num}}</span>
          <el-button @click="clickView(row)" type="text">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="排序" slot="operatSort" align="center" class="el_sort">
        <template slot-scope="{ row }">
          <el-input-number @change='editGroupFxs($event,row)' size="mini" v-model="row.sort"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editPlan(row,'edit')" type="text">修改</el-button>
          <el-button @click="deleteDis(row)" type="text" class="btn_delete">删除</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :visible.sync="visible" :title="title" width="600px">
      <div v-if="this.title==='新增分销商'" class="tit_color">说明:分销商名称不能重复添加，名称长度限制25字内</div>
      <div v-else class="tit_color">说明: 修改前请仔细核对信息，否则将影响生成卡密</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="分销商名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入分销商名称" :maxlength="25"></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="旗下商家" prop="group">
          <el-select
            multiple
            collapse-tags
            v-model="ruleForm.group"
            placeholder="请选择旗下商家"
            clearable
            filterable
            @change="groupChange"
            value-key="group_id"
          >
            <el-option
              v-for="item in groupList"
              :key="item.group_id"
              :label="item.group_name"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_form" label="所属省份" prop="province">
          <el-select
            v-model="ruleForm.province"
            placeholder="请输入或选择省份查询"
            clearable
            @change="handleChange"
          >
            <el-option
              v-for="item in ProviceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_form" label="登录账号" prop="account">
          <el-input v-model="ruleForm.account" placeholder="请输入登录账号"></el-input>
        </el-form-item><el-form-item class="el_form" label="登录密码" prop="password">
          <el-input v-model="ruleForm.password" :placeholder="placeholderWard"></el-input>
        </el-form-item>
        <el-form-item class="el_form_r">
          <el-button type="primary" @click="submitForm('ruleForm','add')" v-if="this.title==='新增分销商'">确认新增</el-button>
          <el-button type="primary" @click="submitForm('ruleForm','edit')" v-else>确认修改</el-button>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <DialogTbale @onClose="onClose" :show="deleteVisible" :title="titleView"
      :columns="DialogTableColumns"
      :data="DialogDataList"
      :currentPage="pageNum"
      :total="total"
      :pageSize="pageSize"
      :width='1280'
      @handleCurrentChange="handleCurrentChangeDialog"
      >
      <div slot="seach" class="top_seach">
        <div>
          <GlobalForm
            :form-item-list="formItemListDia"
            :inline="true"
            round
            @handleConfirm="handleConfirmDia"
            confirmBtnName="查询"
          >
          </GlobalForm>
        </div>
        <div style="width:140px;height:40px;margin-left:680px">
          合计数量：<span style="color:red">{{this.DiaNum}}</span>
        </div>
      </div>
      <el-table-column label="变更后归属商家" slot="operation" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.group_name=='未变更'">{{row.group_name}}</span>
            <span v-else style="color:#FF687B;">{{row.group_name}}</span>
          </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Distributor_list",
  data() {
    return {
      //弹窗参数
      visible: false,
      title: "",
      placeholderWard:'',
      ruleForm: {
        group: Array,
        name: "",
        password: "",
        account: "",
        province: "",
        fxs_id:'',
      },
      rules: {
        name: [
          { required: true, message: "请填写分销商名称", trigger: "blur" }
        ],
        group: [
          { required: true, message: "请选择旗下商家", trigger: "blur" }
        ],
        province: [
          { required: true, message: "请选择所属省份", trigger: "blur" }
        ],
        account: [
          { required: true, message: "请输入登录账号", trigger: "blur" }
        ],
      },
      //列表
      ProviceList:[],//省份下拉
      groupList:[],//分销商旗下商家下拉
      initData: null,
      formItemList: [
        {
          key: "name",
          type: "input",
          labelName: "分销商家名称:",
          placeholder: "请输入分销商家名称查询"
        },
        // {
        //   key: "prefix",
        //   type: "input",
        //   labelName: "卡号前缀:",
        //   placeholder: "请输入卡号前缀查询"
        // },
        {slotName: "operationSelect"}
      ],
      formRules: {},
      tableData: [],
      Distritotal: 0,
      DistripageNum: 1,
      DistripageSize: 10,
      name: '',//分销商名称搜索
      province: '',//所属省份下拉id
      tableColumns: [
        { label: "分销商名称", prop: "name" },
        { slotName: "operatNum" },
        { label: "所属省份", prop: "province_name" },
        { label: "登录账号", prop: "account" },
        { slotName: "operatSort" },
        { label: "最后更新时间", prop: "add_time" },
        { slotName: "operat" }
      ],

      // 旗下商家弹窗
      DialogTableColumns: [
        {label: '旗下商家名称', prop: 'group_name'},
        {label: '省份', prop: 'province'},
      ],
      formItemListDia: [
        {
          key: 'group_name',
          type: 'input',
          labelName: '商家名称:',
          placeholder: '请输入商家名称'
        },
      ],
      DialogDataList:[],
      total:0,
      pageNum:1,
      pageSize:10,
      fxs_id:'',
      group_name:'',
      DiaNum:'',
      deleteVisible:false,
      titleView:'',
    };
  },
  mounted() {
    this.DisttributorList();
    _api.getRegion().then(res=>{
      this.ProviceList = res.data
    })
    _api.selectGroupList().then(res=>{
      this.groupList = res.data
    })
  },
  methods: {
    //列表请求
    DisttributorList() {
      const params = {
        page: this.DistripageNum,
        limit: this.DistripageSize,
        name: this.name,
        province: this.province,
      };
      _api.groupFxsList(params).then(res => {
        console.log(res);
        this.tableData = res.data;
        this.Distritotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.DistripageNum = val;
      this.DisttributorList();
    },
    handleChange(val){
      console.log(val)
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      this.name = data.name;
      const params = {
        page: 1,
        limit: 10,
        name: data.name,
        province: this.province,
      };
      _api.groupFxsList(params).then(res => {
        console.log(res);
        this.tableData = res.data;
        this.Distritotal = res.count;
      });
    },
    // 选择旗下商家
    groupChange(val){
      this.ruleForm.group=[...val]
      console.log(this.ruleForm.group,'this.ruleForm.group')
    },
    // 新增
    addPlan() {
      this.visible = true;
      this.title = "新增分销商";
      this.placeholderWard = '请输入密码（6-16位数字或大小写字母组合）'
      this.ruleForm.name = "";
      this.ruleForm.group = [];
      this.ruleForm.password = "";
      this.ruleForm.account = "";
      this.ruleForm.province = "";
    },
    //删除
    deleteDis(row){
      console.log(row)
      this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _api.delGroupFxs({fxs_id:row.fxs_id}).then(res=>{
            if(res.code===1){
              this.$message.success('删除成功')
              this.DisttributorList()
            }else{
              this.$message.error(res.msg)
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });
        });
    },
    //排序
    editGroupFxs(val,row){
      console.log(val,row)
      _api.editGroupFxs({sort:val,fxs_id:row.fxs_id}).then(res=>{
        if(res.code===1){
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //修改
    editPlan(row, type) {
      console.log(row)
      this.visible = true;
      this.title = "修改分销商";
      this.placeholderWard = '不输入则表示不修改'
      this.ruleForm.name = row.name
      this.ruleForm.group = row.group
      // this.ruleForm.password = row.password
      this.ruleForm.account = row.account
      this.ruleForm.province = row.province
      this.ruleForm.fxs_id = row.fxs_id
    },
    // 确认提交、修改
    submitForm(formName, type) {
      if (type === "add") {
        this.ruleForm.fxs_id = 0;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          _api.addGroupFxs(this.ruleForm).then(res => {
            console.log(res);
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.DisttributorList()
            }else{
              this.$message.success(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消
    resetForm(formName) {
      this.visible = false;
    },

    //查看
    clickView(row){
      this.fxs_id = row.fxs_id
      this.carmIDataList()
      this.DiaNum = row.num
      // this.group_name = row.group_name
      this.titleView = `查看旗下商家-${row.group_name?row.group_name:''}`
      this.deleteVisible = true
    },
    //查看分销商弹窗关闭
    onClose(){
      this.deleteVisible = false
    },
    //查看分销商弹窗列表请求
    carmIDataList(){
      const vo ={
        page:this.pageNum,
        limit:this.pageSize,
        fxs_id:this.fxs_id,
        group_name:this.group_name,
      }
      _api.getFxsGroup(vo).then(res => {
        if(res.code === 1){
          this.DialogDataList = res.data
          this.total = res.count
        }
      })
    },
    //查看分销商弹窗分页
    handleCurrentChangeDialog(val) {
      this.DialogDataList = []
      this.pageNum = val;
      this.carmIDataList()
    },
    //查看分销商弹窗查询
    handleConfirmDia(data, cd) {
      cd()
      this.group_name = data.group_name
      const vo ={
        page:1,
        limit:10,
        fxs_id:this.fxs_id,
        group_name:data.group_name,
      }
      _api.getFxsGroup(vo).then(res => {
        if(res.code === 1){
          this.DialogDataList = res.data
          this.total = res.count
          this.pageNum = 1
        }
      })
    },
    copy() {
      let input = document.createElement('input');
      document.body.appendChild(input)
      input.value='http://businessadmin.cdydkj.cn/login'
      input.select();
      document.execCommand("Copy");
      this.$message.success('复制成功！')
      document.body.removeChild(input)
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Distributor_list {
  /deep/ .el-select__tags-text{
    color: #666666;
  }
  .top_seach{
    width: 100%;
    display: flex;
    align-items: center;
    /deep/ .el-form-item__label{
      width:80px!important;
    }
  }
  .btn_delete{
    color: #FF687B;
  }
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_sort{
    /deep/ .el-input-number__decrease{
      // background:#0874e6!important;
    }
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r{
    float: right;
    margin-right: 80px;
  }
}
</style>
