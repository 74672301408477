module.exports = {
    id: 32,
    name: "智选换机",
    type: "claimSettlement",
    form: [
        {
            label: "编号",
            prop: "finish_sn",
            obj: "order",
            type: "input"
        },
        {
            label: "出险产品",
            prop: "instype_name",
            obj: "order",
            type: "input",
            append: "viewOrder"
        },
        {
            label: "产品类型",
            prop: "instype_name",
            obj: "order",
            type: "input"
        },
        {
            label: "手机正面照片",
            prop: "tem_img",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "工单",
            prop: "tem_work",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "工单2",
            prop: "tem_work2",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "工单3",
            prop: "tem_work3",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "原机IMEI",
            prop: "old_imei",
            obj: "order",
            type: "input"
        },
        {
            label: "上传日期",
            prop: "add_time",
            obj: "order",
            type: "date"
        },
        {
            label: "新机品牌",
            prop: "brand_id",
            obj: "order",
            type: "select",
            optListName: "brandList",
            optLabel: "brand",
            optValue: "brand_id",
            relate: "model"
        },
        {
            label: "新机型号",
            prop: "model_id",
            obj: "order",
            type: "select",
            optListName: "modelList",
            optLabel: "model_name",
            optValue: "model_id"
        },
        {
            label: "新IMEI码",
            prop: "imei",
            obj: "order",
            type: "input",
            isNew: true,
        },
        {
            label: "新机内存",
            prop: "ram_size",
            obj: "order",
            type: "select",
            optListName: "RAMList"
        },
        {
            label: "换机方式",
            prop: "ins_service_name",
            obj: "order",
            type: "select",
            optListName: "androidServeList",
            optLabel: "ins_service_name",
            optValue: "ins_service_id"
        },
        {
            label: "审核结果",
            prop: "exa_status",
            obj: "order",
            type: "radio",
            optListName: "statusList",
            optLabel: "name",
            optValue: "status"
        },
        {
            label: "新机裸机价格",
            prop: "new_price",
            obj: "order",
            type: "input",
            disabled:true
        },
        {
            label: "补交金额",
            prop: "money",
            obj: "order",
            type: "input",
            disabled:true
        },
        {
            label: "赔付金额",
            prop: "claims_money",
            obj: "order",
            type: "input",
            disabled:true
        },
        {
            label: "销单号/订单号",
            prop: "odd_num",
            obj: "order",
            type: "input"
        },
        {
            label: "回复内容",
            prop: "fail_exp",
            obj: "order",
            type: "textarea"
        }
    ],
    tableColumns: [
        {
            slotName: "headimg"
        },
        {
            label: "注册者昵称",
            prop: "nickname"
        },
        {
            slotName: "operation"
        }
    ],
    piccCallbackType: 2
}

