var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"planNum_list"},[_c('GlobalInfoBar',{attrs:{"title":"抽奖记录"}}),_c('GlobalForm',{attrs:{"init-data":_vm.initData,"form-rules":_vm.formRules,"form-item-list":_vm.formItemList,"inline":true,"labelWidth":80,"round":"","confirmBtnName":"查询"},on:{"handleConfirm":_vm.handleConfirm}},[_c('el-form-item',{staticClass:"el_from",staticStyle:{"margin":"0"},attrs:{"slot":"is_push_state_time","label-width":"80px","label":"选择时间:"},slot:"is_push_state_time"},[_c('el-date-picker',{attrs:{"type":"daterange","value-format":"timestamp","start-placeholder":"开始日期","range-separator":"~","end-placeholder":"结束日期","clearable":"","default-time":['00:00:00', '23:59:59']},model:{value:(_vm.stateTime),callback:function ($$v) {_vm.stateTime=$$v},expression:"stateTime"}})],1)],1),_c('GlobalTable',{ref:"GlobalTable",attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"maxHeight":800,"currentPage":_vm.PlanIpageNum,"total":_vm.PlanItotal,"isPagination":false},on:{"handleCurrentChange":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"slot":"write_time","label":"核销时间","align":"center"},slot:"write_time",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.write_time||"--"))])]}}])}),_c('el-table-column',{attrs:{"slot":"user_name","label":"操作人员","align":"center"},slot:"user_name",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.user_name||"--"))])]}}])}),_c('el-table-column',{attrs:{"slot":"remarks","label":"备注","align":"center"},slot:"remarks",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.remarks||"--"))])]}}])}),_c('el-table-column',{attrs:{"slot":"write_off","label":"核销状态","align":"center"},slot:"write_off",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.write_off==1?'已核销':'待核销'))])]}}])}),_c('el-table-column',{attrs:{"slot":"operat","label":"操作","align":"center"},slot:"operat",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"mr_sty",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.editPlan(row, 'edit')}}},[_vm._v("核销")])]}}])})],1),_c('el-dialog',{attrs:{"visible":_vm.visible,"title":"操作提示","width":"600px"},on:{"update:visible":function($event){_vm.visible=$event},"closed":function($event){return _vm.resetForm('ruleForm')}}},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"备注","prop":"remarks","label-width":"100px"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入备注","maxlength":200},model:{value:(_vm.ruleForm.remarks),callback:function ($$v) {_vm.$set(_vm.ruleForm, "remarks", $$v)},expression:"ruleForm.remarks"}})],1),_c('el-form-item',{staticClass:"el_form_r"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm', 'add')}}},[_vm._v("确认")]),_c('el-button',{on:{"click":function($event){return _vm.resetForm('ruleForm')}}},[_vm._v("取消")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }