<template>
  <div>
    <GlobalInfoBar>
      商家列表
      <a style="font-size: 14px;margin-left: 20px" target="_blank" href="http://businessadmin.cdydkj.cn/login">合作商后台地址</a>
      <el-button type="primary" style="padding: 1px 3px;margin-left: 10px" @click="copy">复制</el-button>
    </GlobalInfoBar>
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <GlobalForm :form-item-list="formItemList" :inline="true" round @handleConfirm="query" confirmBtnName="查询">
        <div slot="AddSlot">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addPartner">新增商家</el-button>
        </div>
      </GlobalForm>

      <GlobalTable ref="GlobalTable" :columns="tableColumns" :data="tableData" :currentPage="curPage"
        :total="totalElements" @handleCurrentChange="currentChange">
        <el-table-column label="门店数量" slot="group_num" align="center">
          <template slot-scope="{ row }">

            <el-popover placement="right" width="700" @show="storePopoverShow(row)" trigger="click">
              <GlobalForm :form-item-list="storePopover.formItemList" :inline="true" round @handleConfirm="storeQuery"
                confirmBtnName="查询"></GlobalForm>
              <span class="popover-sore-num">门店数量:<span>{{ row.group_num }}</span></span>
              <GlobalTable :columns="storePopover.tableColumns" :data="storePopover.tableData"
                :currentPage="storePopover.curPage" :total="storePopover.totalElements" :page-size="5"
                @handleCurrentChange="storeCurrentChange">
              </GlobalTable>
              <a href="javascript:" slot="reference" :class="{ 'no-count': !row.group_num }">{{ row.group_num }}</a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="排序" slot="lastLoginTime" align="center">
          <template slot-scope="{ row }">
            <el-input-number size="mini" v-model="row.sort" @change="(currentValue) => (handleSort(row, currentValue))"
              :min="1" label="描述文字"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="启用" slot="is_open" align="center">
          <template slot-scope="{ row }">
            <el-switch :value="row.is_open" :active-value="1" :inactive-value="0" @change="(e) => changeOpen(row, e)"/>
          </template>
        </el-table-column>
        <el-table-column label="最后更新时间" slot="lastLoginTime" align="center">
          <template slot-scope="{ row }">
            {{ row.add_time || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="登录账号" slot="loginAccount" align="center">
          <template slot-scope="{ row }">
            {{ row.account || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="跟进业务员" slot="remark" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.remarks" style="display: flex;align-items: center;justify-content: center">
              <span :title="row.remarks" class="remark">
                {{ row.remarks }}
              </span>
              <a href="javascript:" style="min-width: 2em;margin-left: 10px" @click="operateRemark('修改', row)">修改</a>
            </div>
            <a href="javascript:" v-else @click="operateRemark('添加', row)">添加</a>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center" width="223">
          <template slot-scope="{ row }">
            <el-button type="text" @click="editInfo(row)">修改</el-button>

            <el-popover placement="left" width="300" v-model="row.isShowPopover">
              <p><span class="el-icon-warning"></span>删除后数据不可恢复，确认删除吗？</p>
              <div class="btns">
                <el-button size="small" plain @click="row.isShowPopover = false">取消</el-button>
                <el-button type="primary" size="small" @click="row.isShowPopover = false; deleteRow(row)">确定</el-button>
              </div>
              <el-button type="text" class="delete-btn" slot="reference">删除</el-button>
            </el-popover>
            <!-- 新增 -->
            <el-button style="margin-left: 6px;" type="text" @click="editRedPacket(row)">红包配置</el-button>
            <el-button type="text" @click="editRedDetail(row)">红包明细</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <el-dialog :title="dialog.type + '商家'" :visible.sync="dialog.isShow" width="500px">
      <div class="dialog-notice">说明:商家名称不能重复添加，名称长度限制25字内</div>
      <el-form ref="dialogForm" :model="dialog.form" label-width="80px" size="small" class="dialog-form"
        :rules="dialog.rules">
        <el-form-item prop="group_name" label="商家名称">
          <el-input v-model="dialog.form.group_name" placeholder="请输入商家名称 " maxlength="25"></el-input>
        </el-form-item>

        <el-form-item prop="province" label="所属省份">
          <el-select v-model="dialog.form.province" placeholder="请选择所属省份">
            <el-option v-for="item in dialog.provinces" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="account" label="登录账号">
          <el-input v-model="dialog.form.account" placeholder="请输入账号（6-16位数字或大小写字母组合）" maxlength="16"></el-input>
        </el-form-item>

        <el-form-item prop="password" label="登录密码">
          <el-input v-model="dialog.form.password"
            :placeholder="dialog.type === '修改' ? '不输入则表示不修改' : '请输入密码（6-16位数字或大小写字母组合）'" type="password"
            maxlength="16"></el-input>
        </el-form-item>
        <div class="dialog-btns">
          <el-button type="primary" size="small" @click="confirm">确认{{ dialog.type }}</el-button>
          <el-button type="primary" size="small" plain @click="dialog.isShow = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog top="200px" :title="remarkDialog.type + '跟进业务员'" :visible.sync="remarkDialog.isShow" width="400px">
      <div style="font-weight: bold;margin-bottom: 10px">跟进业务员：</div>
      <el-input type="textarea" size="mini" style="margin-bottom: 10px" v-model="remarkDialog.remarks"
        placeholder="请输入跟进业务员"></el-input>
      <el-button type="primary" style="margin-bottom: 30px;float: right" size="mini" @click="dialogConfirm">确定
      </el-button>
    </el-dialog>
    <!-- 红包配置弹框 -->
    <el-dialog top="200px" :title="dialogTitle + '红包配置'" :visible.sync="redPacketDialog" width="1000px">
      <!-- 表格数据 -->
      <GlobalTable ref="GlobalTable" :columns="redPacketTableColumns" :data="redPacketTableData" :currentPage="curPage2"
        :total="total" :isPagination="false">
        <el-table-column width="200px" label="红包金额(元)" slot="red_money" align="center">
          <template slot-scope="{ row }">
            <el-input-number size="mini" v-model="row.red_money"
              @change="(currentValue) => (handleRedSave(row, currentValue))" :min="0" :max="200" :precision="0"
              label="请输入红包金额"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="红包配置开关" slot="open" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.open" :active-value="1" :inactive-value="0" @change="switchChange(row, 'open')">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="活动时间" slot="red_money" align="center" width="380px">
          <template slot-scope="{ row }">
            <!-- 编辑模式 -->
            <template v-if="row.isEditing">
              <el-date-picker v-model="row.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                @change="(currentValue) => (timeRedSave(row, currentValue))">
              </el-date-picker>
            </template>
            <!-- 非编辑模式，显示回显时间 -->
            <template v-else>
              {{ row.time || '--' }}<span class="el-icon-edit" style="margin-left: 5px;color: #0981FF;"
                @click="editTime(row)">编辑</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="活动时间开关" slot="open_time" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.open_time" :active-value="1" :inactive-value="0"
              @change="switchChange(row, 'open_time')">
            </el-switch>
          </template>
        </el-table-column>
      </GlobalTable>
      <div style="height: 20px;"></div>
    </el-dialog>
    <!-- 红包明细弹框 -->
    <el-dialog top="200px" :title="dialogTitle2 + '红包明细'" :visible.sync="redDetailDialog" width="1000px">
      <!-- 查询 -->
      <div class="box-box">
        <div class="search-box">
          <div class="search">
            <span style="width: 120px;">生成时间</span>
            <el-date-picker size="small" v-model="redTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
              range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
              @change="(currentValue) => (redLogSearch(currentValue))">
            </el-date-picker>
          </div>
          <el-button type="primary" size="mini" @click="search">查询</el-button>
        </div>
        <el-button size="mini" type="success" @click="redDownload">红包记录下载</el-button>
      </div>
      <!-- 表格数据 -->
      <GlobalTable ref="GlobalTable" :columns="redDetailTableColumns" :data="redDetailTableData" :currentPage="curPage3"
        :total="total3" @handleCurrentChange="currentChangeRed">
      </GlobalTable>
    </el-dialog>
  </div>
</template>

<script>


import { addGroup, delGroup, editGroup, groupList, groupShopList, hongbaoData, redOpen, redSave, redLog, redDownload, openGroup } from "@/utils/request/partnerManage";
import api from '@/utils/request/index'
import { editGroupRemarks } from "../../utils/request/partnerManage";
import loginVue from '../login/login.vue';

export default {
  name: "list",
  data() {
    let validateAccountAndPassword = (rule, value, callback) => {
      if (!value) {
        callback();
        return
      }
      if (!(new RegExp(/(?=.*[0-9])(?=.*[a-zA-Z])^[a-zA-Z0-9]{6,16}$/).test(value))) {
        callback('请输入6-16是数字+字母组合');
      } else {
        callback();
      }
    }
    return {
      // 红包明细
      redDetailTableColumns: [
        { label: "保险类型", prop: "instype_name" },
        { label: "红包金额", prop: "red_money" },
        { label: "用户名", prop: "name" },
        { label: "手机号", prop: "phone" },
        { label: "串码", prop: "imei" },
        { label: "发送时间", prop: "add_time" },
      ],
      redDetailTableData: [],
      curPage3: 1,
      total3: 0,
      dialogTitle2: '',
      redDetailDialog: false,
      redTime: '',//红包明细查询时间
      // 红包配置
      redPacketDialog: false,
      dialogTitle: '',
      group_id: 0,//商家id
      redPacketTableData: [],
      curPage2: 1,
      total: 0,
      redPacketTableColumns: [
        { label: "权益名称", prop: "instype_name" },
        { slotName: "red_money" },//红包金额
        { slotName: "open" },//红包配置开关
        { slotName: 'time' },//活动时间
        { slotName: "open_time" },//活动时间开关
      ],
      // **************
      formItemList: [
        {
          key: 'name',
          type: 'input',
          labelName: '商家名称:',
          placeholder: '请输入商家名称'
        }, {
          key: 'province',
          idKey: 'id',
          labelKey: 'name',
          type: 'selectFilterable',
          labelName: '所属省份:',
          option: [],
          placeholder: '请选择所属省份'
        }
      ],
      tableColumns: [
        { label: "商家名称", prop: "group_name" },
        { label: "所属省份", prop: "province_name" },
        { slotName: "group_num" },
        { slotName: "sort" },
        { slotName: "loginAccount" },
        { slotName: 'lastLoginTime' },
        { slotName: 'is_open' },
        { slotName: 'remark' },
        { slotName: "operation" },
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      province: '',
      name: '',
      dialog: {
        isShow: false,
        type: '新增',
        form: {
          group_id: '',
          province: '',
          group_name: '',
          account: '',
          password: ''
        },
        rules: {
          group_name: { required: true, message: '请输入商家名称', trigger: 'blur' },
          province: { required: true, message: '请选择所在省份', trigger: 'change' },
          account: [{ required: true, message: '请输入登录账号', trigger: 'blur' },
          { validator: validateAccountAndPassword, trigger: 'blur' }],
          password: [{ required: true, message: '请输入登录密码', trigger: 'blur' },
          { validator: validateAccountAndPassword, trigger: 'blur' }],
        },
        provinces: []
      },
      storePopover: {
        tableColumns: [
          { label: "门店名称", prop: "shop_name" },
          { label: "注册店员", prop: "num" },
        ],
        shop_name: '',
        group_id: '',
        tableData: [],
        curPage: 1,
        totalElements: 0,
        formItemList: [{
          key: 'shop_name',
          type: 'input',
          labelName: '门店名称:',
          placeholder: '请输入门店名称搜索'
        }]
      },
      remarkDialog: {
        isShow: false,
        type: '新增',
        remarks: {},
        group_id: ''
      }
    }
  },
  mounted() {
    this.getPartners()
    this.getProvinces()
  },
  methods: {
    // 红包记录下载
    redDownload() {
      let params = {
        group_id: this.group_id,
        beg_time: this.beg_time,//开始时间
        end_time: this.end_time,//结束时间
      }
      redDownload(params).then(res => {
        console.log(res, '6666');
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
              this.commandBtnLoading = false
            }
          } catch (err) {
            const fileName = `红包记录下载.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
            this.commandBtnLoading = false
            this.commandShow = false
          }
        });
        reader.readAsText(blob, "utf-8");
      })
    },
    // 红包详情
    editRedDetail(row) {
      this.group_id = row.group_id
      this.dialogTitle2 = row.group_name
      this.redDetailDialog = true
      this.currentChangeRed()
    },
    currentChangeRed() {
      let params = {
        group_id: this.group_id,
        page: this.curPage3,
        limit: 10,
        beg_time: this.beg_time,//开始时间
        end_time: this.end_time,//结束时间
      }
      redLog(params).then(res => {
        console.log(res, 'log');
        this.redDetailTableData = res.data || []
        this.total3 = res.count || 0
      })
    },
    changeOpen(row, value) {
      openGroup({
        group_id: row.group_id,
        is_open: value
      }).then(() => {
        this.$message.success('操作成功！')
        this.getPartners()
      })
    },
    redLogSearch(currentValue) {
      console.log(this.redTime, 'qqq');
      if (this.redTime) {
        this.beg_time = this.redTime[0]
        this.end_time = this.redTime[1]
      } else {
        this.beg_time = ''
        this.end_time = ''
      }
    },
    search() {
      this.currentChangeRed()
    },
    // 时间配置
    editTime(row) {
      this.redPacketTableData.forEach((item, i) => {
        if (item.instype_id == row.instype_id) {
          item.isEditing = true
        } else {
          item.isEditing = false
        }
      })
      console.log('222222', this.isEditing);
    },
    timeRedSave(row, currentValue) {
      console.log(currentValue[0], '111111');
      console.log(currentValue[1], '111111');
      redSave({ type: 2, red_id: row.red_id, start_time: currentValue[0], end_time: currentValue[1] }).then(res => {
        if (res.code === 1) {
          this.$message.success('活动时间配置成功')
        }
        this.hongbaoData()
        setTimeout(() => {
          row.isEditing = false
        }, 500)
      })
    },
    // 红包金额
    handleRedSave(row, currentValue) {
      redSave({ type: 1, red_id: row.red_id, red_money: currentValue }).then(res => {
        this.$message.success('红包金额配置成功')
        this.hongbaoData()
      })
    },
    // 红包/时间开关
    switchChange(row, type) {
      redOpen({
        red_id: row.red_id,
        field: type,
        value: row[type]
      }).then(res => {
        if (res.code === 1) {
          this.$message.success(res && res.msg || '操作成功')
        } else {
          this.$message.error(res && res.msg || '操作失败')
        }
        // 刷新数据
        this.hongbaoData()
      }).catch(err => {
      })
    },
    // 红包配置
    editRedPacket(row) {
      this.dialogTitle = row.group_name
      this.group_id = row.group_id
      this.redPacketDialog = true
      this.hongbaoData()
    },
    hongbaoData() {
      // 获取列表数据
      hongbaoData({ group_id: this.group_id }).then(res => {
        console.log(res, '11111111');
        this.redPacketTableData = res.data.list
        this.redPacketTableData.forEach(item => {
          item.isEditing = false
        })
        this.total = res.data.list.length
      })
    },
    // **********
    getPartners() {
      groupList({
        page: this.curPage,
        limit: 10,
        group_name: this.name,
        province: this.province
      }).then(res => {
        this.tableData = res.data || []
        this.totalElements = res.count || 0
      })
    },
    query(data, cd) {
      this.name = data.name
      this.province = data.province
      this.curPage = 1
      this.getPartners()
    },
    currentChange(page) {
      this.curPage = page
      this.getPartners()
    },
    editInfo(row) {
      this.dialog.isShow = true
      this.dialog.type = '修改'
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
        this.dialog.rules.password[0].required = false
        let form = {
          group_id: row.group_id,
          province: row.province,
          group_name: row.group_name,
          account: row.account,
          password: ''
        }
        this.$set(this.dialog, 'form', form)
      })
    },
    deleteRow(row) {
      delGroup({ group_id: row.group_id }).then(res => {
        this.$message.success(res.msg || '删除成功')
        this.getPartners()
      }).catch(err => {
      })
    },
    addPartner() {
      this.dialog.isShow = true
      this.dialog.type = '新增'
      this.dialog.form = {
        group_id: '',
        province: '',
        group_name: '',
        account: '',
        password: ''
      }
      this.dialog.rules.password[0].required = true
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
      })
    },
    handleSort(row, currentValue) {
      editGroup({ group_id: row.group_id, sort: currentValue }).then(res => {
        this.$message.success('排序成功')
        this.getPartners()
      })
    },
    getProvinces() {
      api.getRegion({ pid: '' }).then(res => {
        this.dialog.provinces = res.data || []
        this.formItemList[1].option = res.data || []
      })
    },
    storePopoverShow(row) {
      this.storePopover.totalElements = 0
      this.storePopover.shop_name = ''
      this.storePopover.group_id = row.group_id
      this.storePopover.curPage = 1
      this.getGroupShopList()
    },
    getGroupShopList() {
      groupShopList({
        page: this.storePopover.curPage,
        limit: 5,
        shop_name: this.storePopover.shop_name,
        group_id: this.storePopover.group_id
      }).then(res => {
        this.storePopover.tableData = res.data
        this.storePopover.totalElements = res.count
      })
    },
    storeCurrentChange(page) {
      this.storePopover.curPage = page
      this.getGroupShopList()
    },
    storeQuery(data) {
      this.storePopover.curPage = 1
      this.storePopover.shop_name = data.shop_name
      this.getGroupShopList()
    },
    operateRemark(type, row) {
      this.remarkDialog.isShow = true
      this.remarkDialog.type = type
      this.remarkDialog.group_id = row.group_id
      this.remarkDialog.remarks = row.remarks || ''

    },
    dialogConfirm() {
      editGroupRemarks({
        group_id: this.remarkDialog.group_id,
        remarks: this.remarkDialog.remarks
      }).then(res => {
        this.$message.success(res.msg || '操作成功')
        this.remarkDialog.isShow = false

        this.curPage = 1
        this.getPartners()
      }).catch(err => {
      })
    },
    confirm() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          addGroup(this.dialog.form).then(res => {
            this.$message.success(res.msg || this.dialog.type + '合作商成功')
            this.dialog.isShow = false

            this.curPage = 1
            this.getPartners()
          }).catch(err => {
          })
        }
      })
    },
    copy() {
      let input = document.createElement('input');
      document.body.appendChild(input)
      input.value = 'http://businessadmin.cdydkj.cn/login'
      input.select();
      document.execCommand("Copy");
      this.$message.success('复制成功！')
      document.body.removeChild(input)
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-form-item__label {
  width: 80px !important;
}

.search-box {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
}

.box-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px;
  justify-content: space-between;
}

.search {
  display: flex;
  align-items: center;
  width: 300px;
  margin-right: 10px;
}

.timer-config {
  display: flex;
  align-items: center;
}

.timer {
  width: 360px !important;
  // height: 30px;
  // line-height: 30px !important;
}

.dialog-notice {
  color: red;
  margin-bottom: 20px;
}

.dialog-form {

  .el-input,
  .el-select {
    width: 300px;
  }
}

.dialog-btns {
  margin: 30px 0 30px 60px;

  .el-button {
    margin-left: 20px;
  }
}

.el-popover {
  .el-icon-warning {
    color: #FAAD14;
    margin-right: 5px;
    font-size: 16px;
  }

  .btns {
    text-align: right;
    margin-top: 20px;
  }
}

.delete-btn {
  color: red;
  margin-left: 10px;
}

.el-popover {
  position: relative;
}

.popover-sore-num {
  position: absolute;
  right: 20px;
  top: 20px;

  span {
    color: red;
  }
}

.no-count {
  color: #000;
  pointer-events: none;
}

.remark {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block
}
</style>
