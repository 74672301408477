<template>
  <div>
      <GlobalInfoBar title="维修门店列表"/>
      <GlobalChunk
          :padding="[20,20,20,20]">
        <GlobalForm
            :form-item-list="formItemList"
            :inline="true"
            round
            @handleConfirm="query"
            confirmBtnName="查询"
        >
          <div slot="AddSlot">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="addRepairer">新增维修门店</el-button>
          </div>
        </GlobalForm>
      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="注册维修员" slot="member_num" align="center">
          <template slot-scope="{ row }">
            <el-popover
                placement="right"
                width="700"
                @show="staffPopoverShow(row)"
                trigger="click">
            <GlobalForm
                :form-item-list="staffPopover.formItemList"
                :inline="true"
                round
                @handleConfirm="staffQuery"
                confirmBtnName="查询"
            ></GlobalForm>
            <span class="popover-sore-num">维修员数量:<span>{{ row.member_num }}</span></span>
            <GlobalTable
                :columns="staffPopover.tableColumns"
                :data="staffPopover.tableData"
                :currentPage="staffPopover.curPage"
                :total="staffPopover.totalElements"
                :page-size="5"
                @handleCurrentChange="staffCurrentChange">
              <el-table-column label="头像" slot="headimg" align="center">
                <template slot-scope="{ row }">
                  <el-image
                      style="width:  50px; height:  50px"
                      :src="row.headimg"
                      class="staff-avatar"
                      :preview-src-list="[row.headimg]"
                      @click.stop="closePreview">
                  </el-image>
                </template>
              </el-table-column>
            </GlobalTable>
            <a href="javascript:" slot="reference" :class="{'no-count':!row.member_num}">{{ row.member_num }}</a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="最后更新时间" slot="add_time" align="center">
          <template slot-scope="{ row }">
            {{row.add_time||'--'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="editInfo(row)">修改</el-button>
            <el-popover
                placement="left"
                width="300"
                v-model="row.isShowPopover">
              <p><span class="el-icon-warning"></span>删除后数据不可恢复，确认删除吗？</p>
              <div class="btns">
                <el-button size="small" plain @click="row.isShowPopover = false">取消</el-button>
                <el-button type="primary" size="small" @click="row.isShowPopover = false;deleteRow(row)">确定</el-button>
              </div>
              <el-button type="text" class="delete-btn" slot="reference" >删除</el-button>
            </el-popover>

          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
    <el-dialog :title="dialog.type+'门店'" :visible.sync="dialog.isShow" width="500px">
      <div class="dialog-notice">说明:门店名称不能重复添加，名称长度限制25字内</div>
      <el-form ref="dialogForm" :model="dialog.form" label-width="120px" size="small" class="dialog-form"
               :rules="dialog.rules">
        <el-form-item prop="reshop_name" label="维修门店名称">
          <el-input v-model="dialog.form.reshop_name" placeholder="请输入维修门店名称 " maxlength="25"></el-input>
        </el-form-item>

        <el-form-item prop="remanager_id" label="所属维修商">
          <el-select v-model="dialog.form.remanager_id" placeholder="请选择所属维修商" filterable>
            <el-option
                v-for="item in dialog.repairers"
                :key="item.id"
                :label="item.manager"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="address" label="所属区域" v-if="dialog.isShow">
          <el-cascader v-model="dialog.form.address" :props="{lazy:true,lazyLoad:loadArea,label:'name',value:'id'}"></el-cascader>
        </el-form-item>

        <div class="dialog-btns">
          <el-button type="primary" size="small" @click="confirm">确认{{ dialog.type }}</el-button>
          <el-button type="primary" size="small" plain @click="dialog.isShow=false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import api from "@/utils/request/index";
import {
  addRepairShop,
  delRepairShop,
  repairManagerList,
  repairMemberList,
  repairShopList
} from "@/utils/request/repairerManage";

export default {
  name: "storeList",
  data() {
    return {
      formItemList:[ {
        key: 'storeName',
        type: 'input',
        labelName: '门店名称:',
        placeholder: '请输入门店名称'
      }, {
        key: 'repairer',
        idKey: 'id',
        labelKey: 'manager',
        type: 'selectFilterable',
        labelName: '所属维修商:',
        option: [],
        placeholder: '请选择所属维修商'
      }],
      storeName: '',
      repairer: '',
      tableColumns: [
        {label: "门店名称", prop: "reshop_name"},
        {label: "所属维修商家", prop: "remanager"},
        {label: "省份", prop: "province_name"},
        {label: "城市", prop: "city_name"},
        {label: "区县", prop: "district_name"},
        {slotName: "member_num"},
        {slotName: "add_time"},
        {slotName: "operation"},
      ],
      tableData:[],
      curPage: 1,
      totalElements:0,
      dialog: {
        isShow: false,
        type: '新增',
        form: {
          reshop_id: '',
          reshop_name:'',
          remanager_id:'',
          address: [],
          province:'',
          city:'',
          district:''
        },
        rules: {
          reshop_name:[{required:true,message:'请填写门店名称',trigger:'blur'}],
          remanager_id:[{required:true,message:'请选择所属商家',trigger:'change'}],
          address:[{required:true,message:'请选择所在区域',trigger:'change'}],
        },
        repairers:[]
      },
      staffPopover: {
        tableColumns: [
          {slotName: "headimg"},
          {label: "维修员姓名", prop: "remember_name"},
          {label: "手机号码", prop: "phone"},
          {label: "注册时间", prop: "add_time"},
        ],
        phone: '',
        reshop_id: '',
        tableData: [],
        curPage: 1,
        totalElements: 0,
        formItemList: [{
          key: 'phone',
          type: 'input',
          labelName: '注册手机:',
          placeholder: '请输入注册手机号查询'
        }]
      }
    }
  },
  mounted() {
    this.getStores()
    this.getRepairers()
  },
  methods:{
    getStores() {
      repairShopList({
        page:this.curPage,
        limit:10,
        reshop_name:this.storeName,
        remanager_id:this.repairer,
      }).then(res=>{
        this.tableData=res.data||[]
        this.totalElements=res.count||0
      })
    },
    getRepairers() {
      repairManagerList({page:1,limit:1000000}).then(res=>{
        this.formItemList[1].option=res.data||[]
        this.dialog.repairers=res.data||[]
      })
    },
    addRepairer() {
      this.dialog.isShow = true
      this.dialog.type = '新增'
      this.dialog.form = {
        reshop_id: '',
        reshop_name:'',
        remanager_id:'',
        address: [],
        province:'',
        city:'',
        district:''
      }
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
      })
    },
    editInfo(row) {
      this.dialog.isShow = true
      this.dialog.type = '修改'
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
        let form = {
          reshop_id: row.reshop_id,
          reshop_name:row.reshop_name,
          remanager_id:row.remanager_id,
          address: (row.province&&row.city&&row.district)?([row.province,row.city,row.district]):[],
          province:'',
          city:'',
          district:''
        }
        this.$set(this.dialog, 'form', form)
      })
    },
    deleteRow(row) {
      delRepairShop({reshop_id: row.reshop_id}).then(res => {
        this.$message.success(res.msg || '删除成功')
        this.getStores()
      }).catch(err => {
      })
    },
    currentChange(page) {
      this.curPage = page
      this.getStores()
    },
    query(data) {
      this.storeName=data.storeName
      this.repairer=data.repairer
      this.curPage=1
      this.getStores()
    },
    loadArea(node, resolve) {
      const { level } = node;
      api.getRegion({pid:node.data&&node.data.id||0}).then(res=>{
        let list = res.data || [];
        if(node.level===2){
          resolve(list.map(item=>{
            item.leaf=true
            return item
          }))
        }else {
          resolve(list)
        }

      });
    },
    staffPopoverShow(row) {
      this.staffPopover.totalElements = 0
      this.staffPopover.phone = ''
      this.staffPopover.reshop_id = row.reshop_id
      this.staffPopover.curPage = 1
      this.groupShopMemberList()
    },
    groupShopMemberList() {
      repairMemberList({
        page:this.staffPopover.curPage,
        limit:5,
        phone:this.staffPopover.phone,
        remember_name: '',
        reshop_id:this.staffPopover.reshop_id,
        //todo
        // remanager_id:
      }).then(res=>{
        this.staffPopover.tableData=res.data
        this.staffPopover.totalElements=res.count
      })
    },
    staffQuery(data) {
      this.staffPopover.curPage = 1
      this.staffPopover.phone = data.phone
      this.groupShopMemberList()
    },
    staffCurrentChange(page) {
      this.staffPopover.curPage = page
      this.groupShopMemberList()
    },
    confirm() {
      this.$refs.dialogForm.validate(valid=>{
        if (valid) {
          let {form}=this.dialog
          addRepairShop({
            reshop_id:form.reshop_id,
            reshop_name:form.reshop_name,
            remanager_id:form.remanager_id,
            province:form.address[0],
            city:form.address[1],
            district:form.address[2]
          }).then(res => {
            this.$message.success(res.msg || this.dialog.type + '门店成功')
            this.dialog.isShow = false

            this.curPage = 1
            this.getStores()
          }).catch(err => {
          })
        }
      })
    },
    closePreview() {
      // 获取遮罩层dom
      setTimeout(function(){
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if(e.target.parentNode.className === 'el-image-viewer__actions__inner') {
            return;  //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      },300)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-notice {
  color: red;
  margin-bottom: 20px;
}

.dialog-form {
.el-input, .el-select,.el-cascader {
  width: 300px;
}
}

.dialog-btns {
  margin: 30px 0 30px 60px;

.el-button {
  margin-left: 20px;
}
}

.el-popover {
.el-icon-warning {
  color: #FAAD14;
  margin-right: 5px;
  font-size: 16px;
}

.btns {
  text-align: right;
  margin-top: 20px;
}
}
.delete-btn{
  color:red;
  margin-left: 10px;
}
.el-popover{
  position: relative;
}
.popover-sore-num {
  position: absolute;
  right: 20px;
  top: 20px;
span{
  color: red;
}
}
.staff-avatar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.no-count{
  color: #000;
  pointer-events: none;
}
</style>
