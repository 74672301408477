<template>
  <div class="logistics-list">
    <GlobalInfoBar title="物流管理" />
    <GlobalForm
        :init-data="initData"
        :form-rules="formRules"
        :form-item-list="formItemList"
        :inline="true"
        round
        @handleConfirm="handleConfirm"
        confirmBtnName="查询"
    >
      <div slot="AddSlot">
        <el-button size="small" @click="addLogistics()" type="primary" icon="el-icon-plus">新增物流信息</el-button>
      </div>
    </GlobalForm>
    <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns"
        :data="tableData"
        :currentPage="currentPage"
        :total="totalElement"
        @handleCurrentChange="handleCurrentChange"
    >

      <el-table-column label="物流链接" slot="link" align="center">
        <template slot-scope="{ row }">
          <a :href="row.link" target="_blank">{{row.link}}</a>
        </template>
      </el-table-column>
      <el-table-column label="保险理赔" slot="is_open" align="center">
        <template slot-scope="{ row }">
          <el-switch
              v-model="row.is_open"
              :active-value="1"
              :inactive-value="0"
              @change="switchChange(row,'is_open')">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="五折焕新" slot="is_huanxin" align="center">
        <template slot-scope="{ row }">
          <el-switch
              v-model="row.is_huanxin"
              :active-value="1"
              :inactive-value="0"
              @change="switchChange(row,'is_huanxin')">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editLogistics(row,'edit')" type="text" class="mr_sty">修改</el-button>
          <el-button @click="deleteDis(row)" type="text" class="btn_delete">删除</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :visible.sync="visible" :title="dialogTitle" width="600px">
<!--      <div v-if="this.dialogTitle==='新增物流'" class="tit_color">说明:品牌名称不能重复添加，名称长度限制25字内</div>-->
<!--      <div v-else class="tit_color">说明:品牌名称不能重复添加，名称长度限制25字内</div>-->

      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="物流名称" prop="title">
          <el-input v-model="ruleForm.title" placeholder="请输入物流名称"></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="物流链接" prop="link">

          <el-input placeholder="请输入物流链接" v-model="ruleForm.link" class="link-input" @input="linkInput">
            <el-select v-model="protocol" slot="prepend" placeholder="请选择协议">
              <el-option label="http" value="http://"></el-option>
              <el-option label="https" value="https://"></el-option>
            </el-select>
          </el-input>

        </el-form-item>


        <el-form-item class="el_form_r">
          <el-button type="primary" @click="submitForm('ruleForm','add')" v-if="this.dialogTitle==='新增物流'">确认新增</el-button>
          <el-button type="primary" @click="submitForm('ruleForm','edit')" v-else>确认修改</el-button>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import _api from "@/utils/request";
export default {
  name: "LogisticsInfo",
  data() {
    let checkLink=(rule, value, callback)=>{
      if (/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/.test(value)) {
        callback()
      }else {
        callback('请填写正确格式的网址')
      }
    }
    return {
      //弹窗参数
      visible: false,
      dialogTitle: "",
      ruleForm: {
        title: "",
        link: "",
      },
      rules: {
        title: [
          { required: true, message: "请填写物流名称", trigger: "blur" },
        ],
        link: [
          { required: true, message: "请填写物流链接", trigger: "blur" },
          { validator:checkLink ,trigger: 'blur'},

        ],
      },
      //列表
      initData: null,
      end_time: "",
      beg_time: "",
      formItemList: [
        {
          key: "link",
          type: "input",
          labelName: "物流名称:",
          placeholder: "请输入物流名称"
        }
      ],
      formRules: {},
      tableData: [],
      totalElement: 0,
      currentPage: 1,
      pageSize: 10,
      title: '',
      tableColumns: [
        { label: "物流名称", prop: "title" },
        { slotName: "link" },
        { slotName: "is_open" },
        { slotName: "is_huanxin" },
        { label: "最后更新时间", prop: "add_time" },
        { slotName: "operat" }
      ],
      protocol:'http://'
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    //列表请求
    getListData() {
      const params = {
        page: this.currentPage,
        limit: this.pageSize,
        title: this.title
      };
      _api.logisticsList(params).then(res => {
        console.log(res);
        this.tableData = res.data;
        this.totalElement = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getListData();
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      this.title = data.title;
      this.currentPage=1
      const params = {
        // page: 1,
        // limit: 10,
        title: data.title
      };
      _api.logisticsList(params).then(res => {
        console.log(res);
        this.tableData = res.data;
        this.totalElement = res.count;
      });
    },
    addLogistics() {
      this.ruleForm.title = "";
      this.ruleForm.link = ''
      this.visible = true;
      this.dialogTitle = "新增物流";
    },
    //修改
    editLogistics(row, type) {
      this.visible = true;
      this.dialogTitle = "修改物流";
      this.ruleForm.title = row.title;
      if (/https:\/\//.test(row.link)) {
        this.protocol='https://'
      }else {
        this.protocol='http://'
      }
      this.ruleForm.link = row.link.replace('http://','').replace('https://','');
      this.ruleForm.id = row.id

      this.$nextTick(()=>{
        this.$refs.ruleForm.clearValidate()

      })
    },
    //删除
    deleteDis(row){
      console.log(row)
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _api.delLogistics({id:row.id}).then(res=>{
          if(res.code===1){
            this.$message.success('删除成功')
            this.getListData()
          }else{
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      });
    },
    submitForm(formName, type) {
      if (type === "add") {
        this.ruleForm.id= 0;
      }
      this.ruleForm.link=this.protocol+this.ruleForm.link
      this.$refs[formName].validate(valid => {
        if (valid) {
          _api.addLogistics(this.ruleForm).then(res => {
            console.log(res);
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.getListData()
            }else{
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.visible = false;
    },
    linkInput(value) {
      if (/http:\/\//g.test(value)) {
        this.ruleForm.link=value.replace('http://','')
        this.protocol='http://'
      }
      if (/https:\/\//g.test(value)) {
        this.ruleForm.link=value.replace('https://','')
        this.protocol='https://'
      }
    },
    switchChange(row,type) {
      _api.logisticsOpen({
        id:row.id,
        field: type,
        value:row[type]
      }).then(res=>{
        this.$message.success(res&&res.msg||'操作成功')
        this.getListData()
      }).catch(err=>{
        this.$message.error(err&&err.msg||'操作失败')
      })
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.logistics-list {
  /deep/ .el-form-item__label{
    width: 100px!important;
  }
  .btn_delete{
    color: #FF687B;
  }
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r{
    float: right;
    margin-right: 80px;
  }
}
///deep/.link-input .el-input__inner{
//  width: 50px!important;
//}
/deep/ .el-input .el-input--suffix,
/deep/ .el-input .el-input--suffix .el-input__inner{
  width: 80px!important;
}

/deep/ .el-input-group--prepend>.el-input__inner {
  width: 280px!important;
}
</style>
