<template>
   <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    @close="QualityDialogClose"
    :width="`${width}px`"
>
    <slot></slot>
    <span slot="footer" class="dialog-footer">
        <el-button @click="QualityDialogClose" v-if="showBtn">取 消</el-button>
        <el-button type="primary" @click="QualityDialogSub" v-if="showBtn">确 定</el-button>
    </span>
</el-dialog>
</template>

<script>
export default {
  name: 'DialongRemark',
  props: {
        QualityDialogFlag: {
          default: false,
        },
        title: String,
        showBtn:Boolean
    },
    data() {
        return {
            visible: false,
            width:"600"
        };
    },
    methods: {
        QualityDialogClose() {
            this.$emit("update:QualityDialogFlag", false);
        },
        QualityDialogSub() {
            this.$emit("QualityDialogSub");
        }
    },
    watch: {
        QualityDialogFlag() {
            this.visible = this.QualityDialogFlag;
        },
    },
    created() {
 
    },
    mounted() {

    },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">

</style>
