<template>
  <div class="table" ref="table" style="min-height: 700px">
    <div style="color: red;font-size: 14px;margin-bottom: 10px;display: inline-block">鼠标点击字段间的分割线，左右拖动可调整表格宽度</div>

    <el-table
        size="mini"
        :data="tableData"
        border
        max-height="650px"
        style="width: 100%">
      <el-table-column
          v-for="(th,index) in tableHead"
          :key="index"
          align="center"
          :prop="'prop_'+index"
          :label="th">
        <template slot-scope="{row}">
          <span :title="row['prop_'+index]">{{ row['prop_' + index] }}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          fixed="right"
          label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="copyRow(scope.row)" ty>复制行</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        background
        style="float: right;margin-top: 10px"
        layout="sizes,prev, pager, next ,jumper"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[5,10,20,30,40,50,100,200,300,400,500]"
        @current-change="pageChange"
        @size-change="pageSizeChange"
        :total="allData.length">
    </el-pagination>

    <input type="text" ref="input" style="opacity: 0">
  </div>

</template>

<script>
import api from "@/utils/request";

export default {
  name: "tb",
  data() {
    return {
      allData: [],
      tableHead: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10
    }
  },
  mounted() {
    let loadingInstance = this.$loading({
      text: '加载中...',
      target: this.$refs.table
    })
    api.exportExcel2(this.$route.query).then(res => {
      this.tableHead = res.key || []
      let allData = res.list||[]
      allData = allData.map(item => {
        let obj = {}
        if (Array.isArray(item)) {
          item.forEach((iItem, iIndex) => {
            obj['prop_' + iIndex] = iItem
          })
        }
        return obj;
      })
      this.allData = allData
      this.tableData = allData.slice(0, this.pageSize)

      loadingInstance.close()

    })
  },
  methods: {
    pageChange(page) {
      this.currentPage = page
      this.tableData = this.allData.slice((page - 1) * this.pageSize, page * this.pageSize)
    },
    pageSizeChange(size) {
      this.pageSize = size
      this.pageChange(1)
    },
    copyRow(row) {
      try {
        let arr = [];
        for (const rowKey in row) {
          let match = rowKey.match(/_(\d+)/);
          if (match) {
            let index = match[1]
            arr[index] = row[rowKey]
          }
        }

        this.$refs.input.value = arr.join('\t');
        this.$refs.input.select();
        document.execCommand("Copy");
        this.$message.warning('复制成功')
      } catch (err) {
        console.log(err)
        this.$message.error('复制失败')
      }
    }
  }
}
</script>

<style scoped>
/*.table{*/
/*  min-height:800px;*/

/*}*/
/*.table-wrapper {*/
/*  width: 100%;*/
/*  overflow-x: auto;*/
/*}*/

/*table {*/
/*  table-layout: fixed;*/
/*  border: 1px solid #ddd;*/
/*  font-size: 14px;*/
/*  color: #333;*/
/*}*/

/*tbody {*/
/*  display: inline-block;*/
/*  width: 100%;*/
/*  overflow: auto;*/
/*  max-height: 700px;*/
/*}*/

/*tr {*/
/*  display: inline-block;*/
/*  width: 100%;*/
/*  border-bottom: 1px solid #ddd;*/
/*  height: 30px;*/
/*  line-height: 30px;*/
/*}*/
/*td,th {*/
/*  display: inline-block;*/
/*  width: 150px;*/
/*  white-space: nowrap;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*  border-right: 1px solid #ddd;*/
/*  text-align: center;*/
/*}*/

/*!*.table-wrapper table td:nth-child(1), .table-wrapper table th:nth-child(1) {*!*/
/*!*  width: 30px;*!*/
/*!*}*!*/
</style>
