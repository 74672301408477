<template>
  <div class="home">
      <!-- <GlobalInfoBar title="首页" /> -->
      <div class="regist item" v-loading='loading'>
        <!-- //右侧 -->
        <div class="right_order" v-loading='loading'>
          <div class="item_order"  v-for="item in this.rightOrderList" :key='item.order_id' :value="item.order_id" @click="jumDetail(item)">
            <div :class="{red_color:item.red_lock===1}" :title="`【${item.name}】`">【{{item.name}}】</div>
            <div :class="{red_color:item.red_lock===1}">{{item.add_time}}</div>
          </div>
        </div>
        <!-- //注册订单 -->
        <div class="title_top">
          <div class="title">
            <div class="line">
              <img src="../../assets/images/home/zu18.png" alt="">
            </div>
            <div>注册订单</div>
          </div>
          <div class="title_order">
            <div style="display:flex">
              <div class="line">
                <img src="../../assets/images/home/zu18.png" alt="">
              </div>
              待审核订单
              <span style="color: red" v-if="this.rightOrderList.length">（{{this.rightOrderList.length}}）</span>
            </div>
            <div class="btn">
              <el-button type="primary" size="mini" icon="el-icon-refresh" @click="orderRight">刷新</el-button>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box iitem1" v-for="item in registList1" :key='item.id'>
              <div @click="$router.push({path:'order/list',query:{mold:item[5]}})">
              <div class="top_tit">{{item[0]}}</div>
              <div class="flex_day">
                <div class="day">
                  <div>今日订单</div>
                </div>
                <div class="day">
                  <div>昨日注册</div>
                </div>
                <div class="day">
                  <div>近七日注册</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
              <div class="bottom_n">
                <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
                <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
              </div>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box iitem2" v-for="item in registList2" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5]}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日订单</div>
                </div>
                <div class="day">
                  <div>昨日注册</div>
                </div>
                <div class="day">
                  <div>近七日注册</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box" :class="{iitem2:index===1,iitem1:index===0||index===2}" v-for="(item,index) in registList3" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5]}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日订单</div>
                </div>
                <div class="day">
                  <div>昨日注册</div>
                </div>
                <div class="day">
                  <div>近七日注册</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box" :class="{iitem2:index===1,iitem1:index===0||index===2}" v-for="(item,index) in registList4" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5]}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日订单</div>
                </div>
                <div class="day">
                  <div>昨日注册</div>
                </div>
                <div class="day">
                  <div>近七日注册</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box" :class="{iitem2:index===1,iitem1:index===0||index===2}" v-for="(item,index) in registList5" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5]}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日订单</div>
                </div>
                <div class="day">
                  <div>昨日注册</div>
                </div>
                <div class="day">
                  <div>近七日注册</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <!-- //理赔订单 -->
        <div class="title">
          <div class="line">
            <img src="../../assets/images/home/iimg_lpxx.png" alt="">
          </div>
          <div>理赔订单</div>
        </div>
        <div class="box box1">
          <div class="iitem_box iitem3" v-for="item in payList1" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5]}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日理赔</div>
                </div>
                <div class="day">
                  <div>昨日理赔</div>
                </div>
                <div class="day">
                  <div>近七日理赔</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box iitem4" v-for="item in payList2" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5],item:item}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日理赔</div>
                </div>
                <div class="day">
                  <div>昨日理赔</div>
                </div>
                <div class="day">
                  <div>近七日理赔</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box" :class="{iitem1:index===0,iitem3:[1,2].includes(index)}" v-for="(item,index) in payList3" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5],item:item}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日理赔</div>
                </div>
                <div class="day">
                  <div>昨日理赔</div>
                </div>
                <div class="day">
                  <div>近七日理赔</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box" :class="{iitem1:index===0,iitem3:[1,2].includes(index)}" v-for="(item,index) in payList4" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5],item:item}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日理赔</div>
                </div>
                <div class="day">
                  <div>昨日理赔</div>
                </div>
                <div class="day">
                  <div>近七日理赔</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="iitem_box" :class="{iitem1:index===0,iitem3:[1,2].includes(index)}" v-for="(item,index) in payList5" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5],item:item}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日理赔</div>
                </div>
                <div class="day">
                  <div>昨日理赔</div>
                </div>
                <div class="day">
                  <div>近七日理赔</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
            </div>
            </div>
          </div>
        </div>
        <!-- //其他订单 -->
        <div class="title">
          <div class="line">
            <img src="../../assets/images/home/img_qtdd.png" alt="">
          </div>
          <div>其他订单</div>
        </div>
        <div class="box box2">
          <div class="iitem_box iitem5" v-for="item in otherList1" :key='item.id'>
            <div @click="$router.push({path:'order/list',query:{mold:item[5]}})">
            <div class="top_tit">{{item[0]}}</div>
            <div class="flex_day">
                <div class="day">
                  <div>今日维修</div>
                </div>
                <div class="day">
                  <div>昨日维修</div>
                </div>
                <div class="day">
                  <div>近七日维修</div>
                </div>
              </div>
              <div class="flex_day">
                <div class="day">
                  <div class="num_size">
                    <span>{{item[2]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[3]}}</span><span>(单)</span>
                  </div>
                </div>
                <div class="day">
                  <div class="num_size">
                    <span>{{item[4]}}</span><span>(单)</span>
                  </div>
                </div>
              </div>
            <div class="bottom_n">
              <div>还有<span class="co_bom">{{item[1]}}条</span>订单等待审核</div>
              <div @click.stop="$router.push({path:'/order/list',query:{mold:item[5],status:0}})">立即查看<img src="../../assets/images/home/icon_zk.png" alt=""></div>
              <!-- <router-link tag="div" class="setting-item" :to="{path:'/order/list', query:{mold:item[5]}}">
                <i class="itlike-4"></i>
                <span>立即查看</span>
                <img src="../../assets/images/home/icon_zk.png" alt="">
              </router-link> -->
            </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'home',
  data(){
    return{
      loading:false,
      registList1:[],
      registList2:[],
      registList3:[],
      registList4:[],
      registList5:[],
      payList1:[],
      payList2:[],
      payList3:[],
      payList4:[],
      payList5:[],
      otherList1:[],
      rightOrderList:[]//右侧待审核订单列表
    }
  },
  mounted(){
    this.orderRight()
  },
  methods:{
    jumDetail(data){
      if(data.mold!==41){
        this.$router.push({name:'订单详情',params:{type:data.mold,orderId:data.order_id}})
      }else{
        this.$router.push({
        name: '报案理赔详情',
        params: {
          reportId: data.order_id
        }
      })
      }
    },
    //右侧订单
    orderRight(){
      this.loading = true
      this.registList()
      this.payList()
      this.otherList()
      _api.reviewedOrder().then(res=>{
        if(res.code===1){
          console.log(res)
          this.rightOrderList = res.data
          setTimeout(() => {
            this.loading = false
          }, 1000);
        }
      })

    },
    //注册订单
    registList(){
      _api.orderNews().then(res=>{
        if(res.code===1){
          this.registList1 = res.data.slice(0,3)
          this.registList2 = res.data.slice(3,6)
          this.registList3 = res.data.slice(6,9)
          this.registList4 = res.data.slice(9,12)
          this.registList5 = res.data.slice(12,15)
          console.log(this.registList1)
          console.log(this.registList2)
          console.log(this.registList3)
          console.log(this.registList4)
          console.log(this.registList5)
        }
      })
    },
    //理赔订单
    payList(){
      _api.orderNews2().then(res=>{
        if(res.code===1){
          this.payList1 = res.data.slice(0,3)
          this.payList2 = res.data.slice(3,6)
          this.payList3 = res.data.slice(6,9)
          this.payList4 = res.data.slice(9,12)
          this.payList5 = res.data.slice(12,15)
          console.log(this.registList1)
          console.log(this.registList2)
        }
      })
    },
    //其他订单
    otherList(){
      _api.orderNews3().then(res=>{
        if(res.code===1){
          this.otherList1 = res.data
        }
      })
    },
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.home{
  // .btn{
  //   padding-bottom: 2px;
  // }
  width: 100%;
  .item{
    position: relative;
    background: white;
    padding: 20px 0 0 20px;
    .right_order{
      position: absolute;
      right: 2%;
      top: 80px;
      width: 25%;
      height: 1130px;
      box-shadow: 0px 10px 18px 2px rgba(129, 146, 251, 0.3);
      overflow: auto;
      border-radius: 10px;
      .item_order:hover{
        color: #FF687B;
      }
      .red_color{
        color: red
      }
      .item_order{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        margin-top:10px ;
        color: #1091FF;
        line-height: 30px;
        div:first-child {
          max-width: 53%;
          margin-left: 2%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        div:last-child{
          max-width: 43%;
          margin-right: 4%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .title_top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title_order{
        margin-right: 2%;
        margin-bottom: 5px;
        width: 25%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        border-bottom: 1px solid #E3E9F1;;
      }
    }
    .title{
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 70%;
      text-align: left;
      font-size: 14px;
      border-bottom: 1px solid #E3E9F1;

      .line{
        img{
          margin: 2px 4px 0 0;
          width: 16px;
          height: 16px;
        }
      }
      div:last-child{
        font-size: 14px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: bold;
        color: #333333;
      }
    }
    .box2{
      width: 100%;
    }
    .box1{
      width: 100%;
    }
    .box{
      // border-top: 1px solid  #E3E9F1;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      .iitem1{
        background-image: url('../../assets/images/home/bg_az_zcdd.png');
      }
      .iitem2{
        background-image: url('../../assets/images/home/bg_pg_zcdd.png');
      }
      .iitem3{
        background-image: url('../../assets/images/home/bg_azlpxx_.png');
      }
      .iitem4{
        background-image: url('../../assets/images/home/bg_pglpxx.png');
      }
      .iitem5{
        background-image: url('../../assets/images/home/bg_qtdd.png');
      }
      .iitem_box{
        margin-top: 10px;
        margin-right: 2%;
        background-size: 100% 100%;
        width: 22%;
        height: 160px;
        color: white;
        .top_tit{
          margin: 2% 0 0 6%;
          font-size: 24px;
          font-family: FZLanTingHei-B-GBK;
          font-weight: 400;
          // text-align: center;
        }
        .bottom_n{
          font-size: 14px;
          font-family: FZLanTingHei-M-GBK;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 20px 0 20px;
          .co_bom{
            color: #FF546A;
          }
          img{
            width: 12px;
            height: 12px;
          }
          div:last-child{
            cursor: pointer;
          }
        }
        .flex_day{
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .day{
          width: 22%;
          display: flex;
          align-items: flex-end;
          margin: 2% 0;
          div:first-child{
            font-family: FZLanTingHei-M-GBK;
            font-weight: 400;
            font-size: 14px;
            width: 130px;
          }
          .num_size{
            line-height: 18px;
            span:first-child{
              font-size: 16px;
              font-family: FZLanTingHei-M-GBK;
              font-weight: 400;
              margin-right: 5px;
            }

          }
        }
      }
    }
  }
}
</style>
