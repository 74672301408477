<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="carmIpageNum"
      :total="carmItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="MID" slot="operatAddress" align="center">
        <template slot-scope="{ row }">
          <div class='send_color'>
            <div class="color_1" v-if="row.is_use==0">未使用</div>
            <div class="color_2" v-else>已使用</div>
            <div>
              {{row.pool_id}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="变更后归属商家" slot="operat" align="center">
        <template slot-scope="{ row }">
          {{row.group_name}}
        </template>
      </el-table-column>
      <el-table-column label="变更后子险种" slot="card_name2" align="center">
        <template slot-scope="{ row }">
          {{row.card_name2||'--'}}
        </template>
      </el-table-column>
      <el-table-column label="类型" slot="virtually" align="center">
        <template slot-scope="{ row }">
          <div>
           {{ row.virtually===1?'虚拟卡':'实体卡' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="250" label="操作" slot="operating" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleBtn(row, 'edit')">修改</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: ["tableData", "carmItotal","carmIpageNum","merchantType"],
  name: "Table",
  data() {
    return {
      tableColumns: [
        {slotName: 'operatAddress'},
        { label: "卡号", prop: "card_num" },
        { label: "卡密", prop: "card_secret" },
        { label: "初始归属商家", prop: "beg_group_name" },
        { label: "初始子险种", prop: "card_name" },
        { slotName: "operat" },
        { slotName: "card_name2" },
        { label: "有效期（年）", prop: "valid_stage" },
        { label: "险种类别", prop: "instype_name" },
        {slotName:'virtually'},
        {slotName:'operating'}
      ]
    };
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
      console.log(val)
    },
    // 表格操作
    handleBtn(row, type) {
      console.log("表格操作", type, row);
      this.$router.push({path:"/Carmi/singleEdit",query:{id:row.pool_id}})
      // if (type === "export"){

      // }else if(type ==='view'){

      // }else if(type ==='cancel'){

      // }else if(type ==='print'){

      // }
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .el-button--mini {
    width: 100px !important;
  }
  .remarkBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .send_color{
    display: flex;
    justify-content: center;
    align-items: center;
    .color_1 {
      margin-right: 5px;
      background: #999;
      color: white;
      width: 40px;
      height: 17px;
      line-height: 18px;
      text-align: center;
    }
    .color_2 {
      margin-right: 5px;
      color: white;
      background: #ff8000;
      width: 40px;
      height: 17px;
      line-height: 18px;
      text-align: center;
    }
  }
  .radioDialog {
    margin-bottom: 15px;
    color: #333333;
  }
  .review_Details{
    height: 80%;
    .DetailsRow{
      margin: 10px;
      span:first-child{
        color: black;
      }
    }
  }
  .StatusBtn1{
    color: #0981FF;
  }
  .StatusBtn2{
    color: #FF687B;
  }
  .tableBtn{
    color: #0981ff;
    cursor: pointer;
  }
  .sec_img {
      width: 20vw;
      height: 80vh;
      margin: auto;
      img{
        width: 20vw;
        height: 80vh;
        object-fit: contain;
      }
    }
    .dialog_Reply{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 20px;
      div{
        margin-bottom: 5px;
      }
    }
}
</style>
