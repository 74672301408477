<template>
  <div class="planNum_list">
    <GlobalInfoBar title="子方案列表" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div slot="AddSlot">
        <el-button
          size="small"
          @click="addPlan()"
          type="primary"
          icon="el-icon-plus"
          >添加子方案号</el-button
        >
      </div>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="是否验证爱思/视频" slot="is_ais" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.is_ais"
            :active-value="1"
            :inactive-value="0"
            @change="aisiChange(row)"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editPlan(row, 'edit')" type="text" class="mr_sty"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :visible.sync="visible" :title="title" width="600px">
      <div v-if="this.title === '新增子方案号'" class="tit_color">
        说明:子方案号名称不能重复添加，名称长度限制50字内
      </div>
      <div v-else class="tit_color">
        说明: 修改前请仔细核对信息，否则将影响生成卡密
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="子方案号名称" prop="zifang_name">
          <el-input
            v-model="ruleForm.zifang_name"
            placeholder="请输入子方案号名称"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="子方案号密钥" prop="zifangan_key">
          <el-input
            v-model="ruleForm.zifangan_key"
            placeholder="请关联子方案号（如无数据请先创建）"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="子方案号投保价格" prop="goods_price">
          <el-input
            v-model="ruleForm.ins_price"
            placeholder="请输入投保价格"
            :maxlength="5"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="子方案号保费" prop="goods_price">
          <el-input
            v-model="ruleForm.goods_price"
            placeholder="请输入保费（5位数以内数字）"
            :maxlength="5"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="子方案号效期" prop="valid_stage">
          <el-select
            v-model="ruleForm.valid_stage"
            placeholder=" 请选择效期（固定1年或2年）"
          >
            <el-option label="1年" value="1年"></el-option>
            <el-option label="2年" value="2年"></el-option>
            <el-option label="次年" value="次年"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="el_form_r">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 'add')"
            v-if="this.title === '新增子方案号'"
            >确认新增</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 'edit')"
            v-else
            >确认修改</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { formValidateNumberEight } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "planNum_list",
  data() {
    return {
      //弹窗参数
      visible: false,
      title: "",
      ruleForm: {
        zifang_id: "", //	是	int	0新增 大于0：修改
        zifang_name: "", //子方案号名称
        zifangan_key: "", //	子方案号秘钥
        goods_price: "", //	价格
        valid_stage: "", //年限
        ins_price:"",//投保价格
      },
      rules: {
        zifang_name: [
          { required: true, message: "请填写子方案号名称", trigger: "blur" },
        ],
        zifangan_key: [
          { required: true, message: "请填写子方案号密钥", trigger: "blur" },
        ],
        goods_price: [
          {
            required: true,
            trigger: "blur",
            validator: formValidateNumberEight,
          },
        ],
        ins_price: [
          {
            required: true,
            trigger: "blur",
            validator: formValidateNumberEight,
          },
        ],
        valid_stage: [
          { required: true, message: "请选择年限", trigger: "blur" },
        ],
      },
      //列表
      initData: null,
      end_time: "",
      beg_time: "",
      formItemList: [
        {
          key: "zifang_name",
          type: "input",
          labelName: "子方案名称查询:",
          placeholder: "请输入子方案名称查询",
        },
      ],
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      zifang_name: "",
      tableColumns: [
        { label: "子方案号名称", prop: "zifang_name" },
        { label: "子方案号密钥", prop: "zifangan_key" },
        { label: "保费（￥）", prop: "goods_price" },
        { label: "保期（年）", prop: "valid_stage" },
        { label: "投保价格", prop: "ins_price" },
        { label: "最后更新时间", prop: "add_time" },
        { slotName: "is_ais" },
        { slotName: "operat" },
      ],
    };
  },
  mounted() {
    this.PostPlanList();
  },
  methods: {
    //列表请求
    PostPlanList() {
      const params = {
        page: this.PlanIpageNum,
        limit: this.PlanIpageSize,
        zifang_name: this.zifang_name,
      };
      _api.zifanganList(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostPlanList();
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      this.zifang_name = data.zifang_name;
      const params = {
        page: 1,
        limit: 10,
        zifang_name: data.zifang_name,
      };
      _api.zifanganList(params).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    addPlan() {
      this.ruleForm.zifang_name = "";
      this.ruleForm.zifangan_key = "";
      this.ruleForm.goods_price = "";
      this.ruleForm.valid_stage = "";
      this.ruleForm.ins_price = "";
      this.visible = true;
      this.title = "新增子方案号";
    },
    //修改
    editPlan(row, type) {
      this.visible = true;
      this.title = "修改子方案号";
      this.ruleForm.zifang_id = row.zifang_id;
      this.ruleForm.zifang_name = row.zifang_name;
      this.ruleForm.zifangan_key = row.zifangan_key;
      this.ruleForm.goods_price = parseInt(row.goods_price);
      this.ruleForm.ins_price = parseInt(row.ins_price);
      this.ruleForm.valid_stage = row.valid_stage;
    },
    submitForm(formName, type) {
      if (type === "add") {
        this.ruleForm.zifang_id = 0;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.zifanganAdd(this.ruleForm).then((res) => {
            console.log(res);
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.PostPlanList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    aisiChange(row) {
      _api
        .zifangAis({
          is_ais: row.is_ais,
          zifang_id: row.zifang_id,
        })
        .then((res) => {
          this.PostPlanList();
        })
        .catch((err) => {
          console.log(err);
          this.$message.error((err && err.msg) || "修改失败");
        });
    },
    resetForm(formName) {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 140px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r {
    float: right;
    margin-right: 80px;
  }
}
</style>
