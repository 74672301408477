var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GlobalInfoBar',{attrs:{"title":"估价单列表"}}),_c('GlobalChunk',{attrs:{"padding":[20, 20, 20, 20]}},[_c('GlobalForm',{ref:"form",attrs:{"initData":_vm.initData,"form-item-list":_vm.formItemList,"inline":true,"round":"","confirmBtnName":"查询"},on:{"handleConfirm":_vm.query}},[_c('el-button',{attrs:{"slot":"AddSlot","type":"warning"},on:{"click":_vm.exportData},slot:"AddSlot"},[_vm._v("导出数据")])],1),_c('GlobalTable',{ref:"GlobalTable",attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"currentPage":_vm.curPage,"total":_vm.totalElements},on:{"handleCurrentChange":_vm.currentChange}},[_c('el-table-column',{attrs:{"slot":"pass_name","label":"估价类型","align":"center","width":"100px"},slot:"pass_name",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.estimateSource == 1 ? "用户估价" : "店员估价")+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"headimg","label":"机况图片","align":"center"},slot:"headimg",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"50px","height":"60px"},attrs:{"src":row.statusImages[0],"preview-src-list":row.statusImages}}),_c('p',{staticStyle:{"text-decoration":"underline","color":"#0981FF"}},[_vm._v("共"+_vm._s(row.statusImages.length)+"张")])]}}])}),_c('el-table-column',{attrs:{"slot":"store_name","label":"旧机机型","align":"center","width":"100px"},slot:"store_name",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.productModel || "-")+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"imei","label":"旧机IMEI","align":"center","width":"100px"},slot:"imei",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.imei || "-")+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"oldProp","label":"服务单号","align":"center","width":"150px"},slot:"oldProp",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.channelOrderNo)+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"query","label":"换新子表单号","align":"center","width":"150px"},slot:"query",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.replaceNewOrderNo))])]}}])}),_c('el-table-column',{attrs:{"slot":"new_way","label":"支付订单号","align":"center","width":"150px"},slot:"new_way",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.paymentNo ||"--"))])]}}])}),_c('el-table-column',{attrs:{"slot":"newProp","label":"终端零售价","align":"center","width":"150px"},slot:"newProp",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.retailPrice)+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"money","label":"自动报价折扣","align":"center","width":"100px"},slot:"money",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.rebate))])]}}])}),_c('el-table-column',{attrs:{"slot":"scrap_price","label":"报价金额","align":"center","width":"100px"},slot:"scrap_price",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v("￥"+_vm._s(row.estimatePrice))])]}}])}),(_vm.type==2)?_c('el-table-column',{attrs:{"slot":"cost_price","label":"驳回类型","align":"center","width":"100px"},slot:"cost_price",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.result==1?'通过':row.result==2?'图片不清晰 ': '其他'))])]}}],null,false,2085939747)}):_vm._e(),(_vm.type==2)?_c('el-table-column',{attrs:{"slot":"exa_status","label":"驳回原因","align":"center","width":"100px"},slot:"exa_status",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.rejectMessage||"--")+" ")]}}],null,false,3635655963)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }