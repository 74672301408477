import request from './request'

let type = 'api'
const menu = {
    //图片上传
    imageUp(params) {
        return request({
            method: 'POST',
            url: '/Uploadify/imageUp',
            data: params,
            type
        })
    },
    //订单图片
    orderImgUp(params) {
        return request({
            method: 'POST',
            url: '/Uploadify/orderImg',
            data: params,
            type
        })
    },
    postLogin(params) {
        return request({
            method: 'POST',
            url: '/Admin/doLogin',
            data: params,
            type
        })
    },
    //首页订单列表
    HomeOrderList(params) {
        return request({
            method: 'POST',
            url: '/Index/index',
            data: params,
            type
        })
    },
    //首页注册信息
    orderNews(params) {
        return request({
            method: 'POST',
            url: '/index/orderNews',
            data: params,
            type
        })
    },
    //首页右侧订单
    reviewedOrder(params) {
        return request({
            method: 'POST',
            url: '/Index/reviewedOrder',
            data: params,
            type
        })
    },
    //首页理赔信息
    orderNews2(params) {
        return request({
            method: 'POST',
            url: '/index/orderNews2',
            data: params,
            type
        })
    }, //首页其他信息
    orderNews3(params) {
        return request({
            method: 'POST',
            url: '/index/orderNews3',
            data: params,
            type
        })
    },

    //卡密查询
    cardPoolList(params) {
        return request({
            method: 'POST',
            url: '/Card/cardPoolList',
            data: params,
            type
        })
    },
    //卡密列表
    cardBatchList(params) {
        return request({
            method: 'POST',
            url: '/Card/cardBatchList',
            data: params,
            type
        })
    },
    //合作商下拉列表
    selectGroupList(params) {
        return request({
            method: 'POST',
            url: '/Group/selectGroupList',
            data: params,
            type
        })
    },
    //审核状态下拉列表
    orderAuditStatus(params) {
        return request({
            method: 'POST',
            url: '/order/orderAuditStatus',
            data: params,
            type
        })
    },
    //子险种下拉列表
    pullCardGoods(params) {
        return request({
            method: 'POST',
            url: '/Card/pullCardGoods',
            data: params,
            type
        })
    },
    //保险类型下拉列表
    pullInsTypeList(params) {
        return request({
            method: 'POST',
            url: '/Insurance/pullInsTypeList',
            data: params,
            type
        })
    },
    openSon(params) {
        return request({
            method: 'POST',
            url: '/Insurance/openSon',
            data: params,
            type
        })
    },

    //卡号卡密生成检查
    checkCardNum(params) {
        return request({
            method: 'POST',
            url: '/Card/checkCardNum',
            data: params,
            type
        })
    },
    //卡号卡密生成
    addCardPool(params) {
        return request({
            method: 'POST',
            url: '/Card/addCardPool',
            data: params,
            type
        })
    },
    //卡号卡密批次修改
    editsCardPool(params) {
        return request({
            method: 'POST',
            url: '/Card/editsCardPool',
            data: params,
            type
        })
    },
    //卡号卡密单个修改详情
    cardPoolInfo(params) {
        return request({
            method: 'POST',
            url: '/Card/cardPoolInfo',
            data: params,
            type
        })
    },
    //卡号卡密单个修改
    editCardPool(params) {
        return request({
            method: 'POST',
            url: '/Card/editCardPool',
            data: params,
            type
        })
    },
    //批次卡号卡密导出
    downloadCardPool(params) {
        return request({
            method: 'POST',
            url: '/Card/downloadCardPool',
            data: params,
            responseType: 'blob'
        })
    },
    //标记
    cardSend(params) {
        return request({
            method: 'POST',
            url: '/Card/cardSend',
            data: params,
            type
        })
    },
    //分配标记
    cardGroupSend(params) {
        return request({
            method: 'POST',
            url: '/Card/cardGroupSend',
            data: params,
            type
        })
    },
    // 分配
    cardBatchGroupList(params) {
        return request({
            method: 'POST',
            url: '/Card/cardBatchGroupList',
            data: params,
            type
        })
    },
    // 删除
    delCardBatchGroup(params) {
        return request({
            method: 'POST',
            url: '/Card/delCardBatchGroup',
            data: params,
            type
        })
    },

    // 分配
    addCardBatchGroup(params) {
        return request({
            method: 'POST',
            url: '/Card/addCardBatchGroup',
            data: params,
            type
        })
    },

    //////////////
    //////////////
    // 子方案列表
    zifanganList(params) {
        return request({
            method: 'POST',
            url: '/Card/zifanganList',
            data: params,
            type
        })
    },
    CardsortCard(params) {
        return request({
            method: 'POST',
            url: '/Card/sortCard',
            data: params,
        })
    },
    // 添加、修改子方案列表
    zifanganAdd(params) {
        return request({
            method: 'POST',
            url: '/Card/zifanganAdd',
            data: params,
            type
        })
    },
    zifangAis(params) {
        return request({
            method: 'POST',
            url: '/Card/zifangAis',
            data: params,
            type
        })
    },
    // 子险种列表
    cardGoodsList(params) {
        return request({
            method: 'POST',
            url: '/Card/cardGoodsList',
            data: params,
            type
        })
    },

    // 子险种列表
    addCardGoods(params) {
        return request({
            method: 'POST',
            url: '/Card/addCardGoods',
            data: params,
            type
        })
    },

    // 子险种列表
    pullZifangan(params) {
        return request({
            method: 'POST',
            url: '/Card/pullZifangan',
            data: params,
            type
        })
    },

    // 分销商列表
    groupFxsList(params) {
        return request({
            method: 'POST',
            url: '/Group/groupFxsList',
            data: params,
            type
        })
    },
    //省份
    getRegion(params) {
        return request({
            method: 'POST',
            url: '/index/getRegion2',
            data: params,
            type
        })
    },
    //分销商删除
    delGroupFxs(params) {
        return request({
            method: 'POST',
            url: '/Group/delGroupFxs',
            data: params,
        })
    },
    //分销商新增修改
    addGroupFxs(params) {
        return request({
            method: 'POST',
            url: '/Group/addGroupFxs',
            data: params,
        })
    },
    //分销商弹窗列表
    getFxsGroup(params) {
        return request({
            method: 'POST',
            url: '/Group/getFxsGroup',
            data: params,
        })
    },
    //分销商排序
    editGroupFxs(params) {
        return request({
            method: 'POST',
            url: '/Group/editGroupFxs',
            data: params,
        })
    },
    //品牌列表
    phoneBrandList(params) {
        return request({
            method: 'POST',
            url: '/Phone/phoneBrandList',
            data: params,
        })
    },
    hot(params) {
        return request({
            method: 'POST',
            url: 'Phone/hotPhoneMode',
            data: params,
        })
    },
    //品牌新增修改
    addPhoneBrand(params) {
        return request({
            method: 'POST',
            url: 'Phone/addPhoneBrand',
            data: params,
        })
    },
    //品牌删除
    delPhoneBrand(params) {
        return request({
            method: 'POST',
            url: 'Phone/delPhoneBrand',
            data: params,
        })
    },
    //规格列表
    phoneRam(params) {
        return request({
            method: 'POST',
            url: 'Phone/phoneRam',
            data: params,
        })
    },
    phoneModelList(params) {
        return request({
            method: 'POST',
            url: 'Phone/phoneModelList',
            data: params,
        })
    },
    //规格修改
    addPhoneRam(params) {
        return request({
            method: 'POST',
            url: 'Phone/addPhoneRam',
            data: params,
        })
    },
    addPhoneMode(params) {
        return request({
            method: 'POST',
            url: 'Phone/addPhoneMode',
            data: params,
        })
    },
    //规格修改
    delPhoneRam(params) {
        return request({
            method: 'POST',
            url: 'Phone/delPhoneRam',
            data: params,
        })
    },
    getPhoneMode(params) {
        return request({
            method: 'POST',
            url: 'phone/getPhoneMode',
            data: params,
        })
    },
    changeOrderImei(params) {
        return request({
            method: 'POST',
            url: '/Insurance/changeOrderImei',
            data: params,
        })
    },
    phoneModeOpen(params) {
        return request({
            method: 'POST',
            url: 'Phone/phoneModeOpen',
            data: params,
        })
    },
    delPhoneMode(params) {
        return request({
            method: 'POST',
            url: 'Phone/delPhoneMode',
            data: params,
        })
    },

    //机型列表
    phonePriceList(params) {
        return request({
            method: 'POST',
            url: 'Phone/phonePriceList',
            data: params,
        })
    },
    //机型价格删除
    delPhonePrice(params) {
        return request({
            method: 'POST',
            url: 'Phone/delPhonePrice',
            data: params,
        })
    },
    //删除
    delXiaoyiPhonePrice(params) {
        return request({
            method: 'POST',
            url: '/Xiaoyi/delPhonePrice',
            data: params,
        })
    },
    //机型价格编辑
    actionphoneXiaoyiPrice(params) {
        return request({
            method: 'POST',
            url: '/Xiaoyi/addPhonePrice',
            data: params,
        })
    },
    //机型价格详情
    phonePriceInfo(params) {
        return request({
            method: 'POST',
            url: 'Phone/phonePriceInfo',
            data: params,
        })
    },
    //机型价格新增
    addPhonePrice(params) {
        return request({
            method: 'POST',
            url: 'Phone/addPhonePrice',
            data: params,
        })
    },

    //机型价格修改
    phonePriceEdit(params) {
        return request({
            method: 'POST',
            url: 'Phone/phonePriceEdit',
            data: params,
        })
    },
    //机型模板下载
    downloadPhone(params) {
        return request({
            method: 'GET',
            url: 'Phone/downloadPhonePrice2',
            params: params,
            responseType: 'blob'
        })
    },

    //机型导入
    uploadPhone(params) {
        return request({
            method: 'POST',
            url: 'Phone/uploadPhonePrice2',
            data: params,
            // responseType: 'blob'
        })
    },
    //导入数据
    uploadXiaoyi(params) {
        return request({
            method: 'POST',
            url: '/Xiaoyi/uploadPhonePrice2',
            data: params,
            // responseType: 'blob'
        })
    },
    //模板下载
    downloadXiaoyi(params) {
        return request({
            method: 'GET',
            url: '/Xiaoyi/downloadPhonePrice2',
            params: params,
            responseType: 'blob'
        })
    },

    //保险类型列表-（注册协议及说明）
    insuranceTypeList(params) {
        return request({
            method: 'POST',
            url: 'Insurance/insuranceTypeList',
            data: params,
        })
    },
    //保险类型列表-（列表）
    articlelist(params) {
        return request({
            method: 'POST',
            url: 'Article/articlelist',
            data: params,
        })
    },
    //保险类型列表-（详情）
    insuranceTypeInfo(params) {
        return request({
            method: 'POST',
            url: 'Insurance/insuranceTypeInfo',
            data: params,
        })
    },
    //保险类型列表-（详情修改）
    editInsuranceType(params) {
        return request({
            method: 'POST',
            url: 'Insurance/editInsuranceType',
            data: params,
        })
    },
    //注意事项-（注意事项详情）
    articleInfo(params) {
        return request({
            method: 'POST',
            url: 'Article/articleInfo',
            data: params,
        })
    },
    //注意事项-（注意事项详情修改）
    editArticle(params) {
        return request({
            method: 'POST',
            url: 'Article/editArticle',
            data: params,
        })
    },

    //注意事项-（注意事项详情修改）
    orderList(params) {
        return request({
            method: 'POST',
            url: 'Order/orderList',
            data: params,
        })
    },
    markOrderTwo(params) {
        return request({
            method: 'POST',
            url: '/Order/markOrderTwo ',
            data: params,
        })
    },
    //订单详情-注册单详情
    orderInfo(params) {
        return request({
            method: 'POST',
            url: '/Order/orderInfo',
            data: params,
        })
    },
    //机型-下拉
    pullModelList(params) {
        return request({
            method: 'POST',
            url: '/Phone/pullModelList',
            data: params,
        })
    },
    //换机保，全面保-损坏配置
    damageConfig(params) {
        return request({
            method: 'POST',
            url: '/order/damageConfig',
            data: params,
        })
    },
    //换机保，全面保-理赔服务
    insService(params) {
        return request({
            method: 'POST',
            url: '/order/insService',
            data: params,
        })
    },
    getRelationType(params) {
        return request({
            method: 'POST',
            url: '/order/getRelationType',
            data: params,
        })
    },
    //安卓换机完成-换机方式
    insServiceAn(params) {
        return request({
            method: 'POST',
            url: '/order/insServiceAn',
            data: params,
        })
    },

    //更换IMEI原因
    replaceType(params) {
        return request({
            method: 'POST',
            url: '/order/replaceType',
            data: params,
        })
    },

    //微信推送
    sendWxPush(params) {
        return request({
            method: 'POST',
            url: '/Order/sendWxPush',
            data: params,
        })
    },
    //微信推送
    orderExamine(params) {
        return request({
            method: 'POST',
            url: '/Order/orderExamine',
            data: params,
        })
    },
    //维修完成-维修类型
    getFinishType(params) {
        return request({
            method: 'POST',
            url: '/order/getFinishType',
            data: params,
        })
    },
    //维修完成-维修方式
    getFinishMode(params) {
        return request({
            method: 'POST',
            url: '/order/getFinishMode',
            data: params,
        })
    },


    //维修商下拉
    selectRepairList(params) {
        return request({
            method: 'POST',
            url: '/Repair/selectRepairList',
            data: params,
        })
    },
    //回收订单列表
    recycleList(params) {
        return request({
            method: 'POST',
            url: '/Order/recycleList',
            data: params,
        })
    },
    //回收订单类型下拉
    orderMold(params) {
        return request({
            method: 'POST',
            url: '/Order/orderMold',
            data: params,
        })
    },


    //订单入回收站
    delOrder(params) {
        return request({
            method: 'POST',
            url: '/Order/delOrder',
            data: params,
        })
    },
    //订单回收操作
    recycleHandle(params) {
        return request({
            method: 'POST',
            url: '/Order/recycleHandle',
            data: params,
        })
    },
    //订单回收批量操作
    recycleBatch(params) {
        return request({
            method: 'POST',
            url: '/Order/recycleBatch',
            data: params,
        })
    },
    //网络制式-下拉
    pullNetList(params) {
        return request({
            method: 'POST',
            url: '/Phone/pullNetList',
            data: params,
        })
    },
    //菜单
    getMenu(params) {
        return request({
            method: 'POST',
            url: '/Index/index',
            data: params,
        })
    },
    //保留原图
    retainOldPic(params) {
        return request({
            method: 'POST',
            url: '/Order/retainOldPic',
            data: params,
        })
    },
    //数据导出
    exportExcel(params) {
        return request({
            method: 'GET',
            url: '/Excel/exportExcel',
            params,
            responseType: 'blob',
            timeOut: 10000000
        })
    },
    //数据导出
    exportExcel2(params) {
        return request({
            method: 'POST',
            url: '/Excel/exportExcel2',
            data: params,
            timeOut: 10000000
        })
    },

    damageConfig2(params) {
        return request({
            method: 'POST',
            url: '/Order/damageConfig2',
            data: params,
        })
    },
    nnhxService(params) {
        return request({
            method: 'POST',
            url: '/Order/nnhxService',
            data: params,
        })
    },
    get3032Bal(params) {
        return request({
            method: 'GET',
            url: '/Passageway/get3032Bal',
            data: params,
        })
    },
    insTypeSonList(params) {
        return request({
            method: 'POST',
            url: '/Insurance/insTypeSonList',
            data: params,
        })
    },
    addInsTypeSon(params) {
        return request({
            method: 'POST',
            url: '/Insurance/addInsTypeSon',
            data: params,
        })
    },
    section(params) {
        return request({
            method: 'POST',
            url: '/Insurance/section',
            data: params,
        })
    },
    section2(params) {
        return request({
            method: 'POST',
            url: '/Insurance/section2',
            data: params,
        })
    },
    // 新接口
    sectionZ(params) {
        return request({
            method: 'POST',
            url: '/Insurance/sectionZ ',
            data: params,
        })
    },
    idCardConfig(params) {
        return request({
            method: 'POST',
            url: '/System/idCardConfig',
            data: params,
        })
    },
    getIdCard(params) {
        return request({
            method: 'POST',
            url: '/System/getIdCard',
            data: params,
        })
    },
    logisticsList(params) {
        return request({
            method: 'POST',
            url: '/Logistics/logisticsList',
            data: params,
        })
    },
    addLogistics(params) {
        return request({
            method: 'POST',
            url: '/Logistics/addLogistics',
            data: params,
        })
    },
    delLogistics(params) {
        return request({
            method: 'POST',
            url: '/Logistics/delLogistics',
            data: params,
        })
    },
    getLogisticsList(params) {
        return request({
            method: 'POST',
            url: '/Logistics/getLogisticsList',
            data: params,
        })
    },
    logisticsOpen(params) {
        return request({
            method: 'POST',
            url: '/Logistics/logisticsOpen',
            data: params,
        })
    },
    orderLogisticsList(params) {
        return request({
            method: 'POST',
            url: '/Logistics/orderLogisticsList',
            data: params,
        })
    },
    orderLogisticsOpen(params) {
        return request({
            method: 'POST',
            url: '/Logistics/orderLogisticsOpen',
            data: params,
        })
    },
    orderLogisticsRemarks(params) {
        return request({
            method: 'POST',
            url: '/Logistics/orderLogisticsRemarks',
            data: params,
        })
    },
    orderLogisticsForm(params) {
        return request({
            method: 'POST',
            url: '/Logistics/orderLogisticsForm',
            data: params,
        })
    },
    perClaimList(params) {
        return request({
            method: 'POST',
            url: '/Lifeinsurance/perClaimList',
            data: params,
        })
    },
    perClaimList2(params) {
        return request({
            method: 'POST',
            url: '/Lifeinsurance/perClaimList',
            data: params,
            responseType: 'blob'
        })
    },
    perClaimInfo(params) {
        return request({
            method: 'POST',
            url: '/Lifeinsurance/perClaimInfo',
            data: params,
        })
    },
    perReportInfo(params) {
        return request({
            method: 'POST',
            url: '/Lifeinsurance/perReportInfo',
            data: params,
        })
    },
    perClaimPush(params) {
        return request({
            method: 'POST',
            url: '/Lifeinsurance/perClaimPush',
            data: params,
        })
    },
    perReportPush(params) {
        return request({
            method: 'POST',
            url: '/Lifeinsurance/perReportPush',
            data: params,
        })
    },
    lifePayCallbackLog(params) {
        return request({
            method: 'POST',
            url: '/Lifeinsurance/lifePayCallbackLog',
            data: params,
        })
    },
    acceptList(params) {
        return request({
            method: 'POST',
            url: '/Excelins/acceptList',
            data: params,
        })
    },
    // vivo专区接口
    // 承保单列表
    vivoAcceptList(params) {
        return request({
            method: 'POST',
            url: '/Vivoaccept/acceptList',
            data: params,
        })
    },
    // 承保单详情
    vivoAcceptInfo(params) {
        return request({
            method: 'POST',
            url: '/Vivoaccept/acceptInfo',
            data: params,
        })
    },
    //缴费列表
    vivoRenewalList(params) {
        return request({
            method: 'POST',
            url: '/Vivoaccept/renewal',
            data: params,
        })
    },
    //批改信息列表
    endorselList(params) {
        return request({
            method: 'POST',
            url: '/Vivoaccept/endorse',
            data: params,
        })
    },
    //退保信息列表
    surrenderlList(params) {
        return request({
            method: 'POST',
            url: 'Vivoaccept/surrender',
            data: params,
        })
    },
    //理赔报案列表
    settleReportList(params) {
        return request({
            method: 'POST',
            url: 'Vivoreport/reportList',
            data: params,
        })
    },
    //理赔报案详情
    settleReportDetail(params) {
        return request({
            method: 'POST',
            url: 'Vivoreport/reportInfo',
            data: params,
        })
    },
    //报案推送
    reportPush(params) {
        return request({
            method: 'POST',
            url: 'Vivoreport/reportPush',
            data: params,
        })
    },
    //理赔推送
    claimPush(params) {
        return request({
            method: 'POST',
            url: 'Vivoreport/claimPush',
            data: params,
        })
    },
    //资料审核
    examinePush(params) {
        return request({
            method: 'POST',
            url: 'Vivoreport/examine',
            data: params,
        })
    },
}
export default menu

export function acceptList(params) {
    return request({
        method: 'POST',
        url: '/Excelins/acceptList',
        data: params,
    })
}
export function acceptList2(params) {
    return request({
        method: 'POST',
        url: '/Excelins/acceptList',
        data: params,
        responseType: 'blob'
    })
}
export function reportList(params) {
    return request({
        method: 'POST',
        url: '/Excelins/reportList',
        data: params,
    })
}
export function reportList2(params) {
    return request({
        method: 'POST',
        url: '/Excelins/reportList',
        data: params,
        responseType: 'blob'
    })
}
export function claimList(params) {
    return request({
        method: 'POST',
        url: '/Excelins/claimList',
        data: params,
    })
}
export function claimList2(params) {
    return request({
        method: 'POST',
        url: '/Excelins/claimList',
        data: params,
        responseType: 'blob'
    })
}
export function vivoinsReportList(params) {
    return request({
        method: 'POST',
        url: '/Vivoins/reportList',
        data: params,
    })
}
export function vivoinsReportList2(params) {
    return request({
        method: 'POST',
        url: '/Vivoins/reportList',
        data: params,
        responseType: 'blob'
    })
}
export function vivoinsClaimList(params) {
    return request({
        method: 'POST',
        url: '/Vivoins/claimList',
        data: params,
    })
}
export function vivoinsClaimList2(params) {
    return request({
        method: 'POST',
        url: '/Vivoins/claimList',
        data: params,
        responseType: 'blob'
    })
}
export function vivoinsImportReport(params) {
    return request({
        method: 'POST',
        url: '/Vivoins/importReport',
        data: params,
    })
}
export function vivoinsImportClaim(params) {
    return request({
        method: 'POST',
        url: '/Vivoins/importClaim',
        data: params,
    })
}
export function vivoinsUploadReport(params) {
    return request({
        method: 'POST',
        url: '/Vivoins/uploadReport',
        data: params,
    })
}
export function vivoinsUploadClaim(params) {
    return request({
        method: 'POST',
        url: '/Vivoins/uploadClaim',
        data: params,
    })
}
export function pullInsTypeList(params) {
    return request({
        method: 'POST',
        url: '/Insurance/pullInsTypeList',
        data: params,
        type
    })
}
export function businessExce(params) {
    return request({
        method: 'POST',
        url: '/Excelins/businessExce',
        data: params,
    })
}

export function excelinsRemarks(params) {
    return request({
        method: 'POST',
        url: '/Excelins/remarks',
        data: params,
    })
}
export function importAccept(params) {
    return request({
        method: 'POST',
        url: '/Excelins/importAccept',
        data: params,
    })
}
export function importOrderImei(params) {
    return request({
        method: 'POST',
        url: '/Insurance/importOrderImei ',
        data: params,
    })
}
export function importCardMate(params) {
    return request({
        method: 'POST',
        url: '/Card/cardMate ',
        data: params,
    })
}
export function importReport(params) {
    return request({
        method: 'POST',
        url: '/Excelins/importReport',
        data: params,
    })
}
export function importClaim(params) {
    return request({
        method: 'POST',
        url: '/Excelins/importClaim',
        data: params,
    })
}
export function uploadAccept(params) {
    return request({
        method: 'POST',
        url: '/Excelins/uploadAccept',
        data: params,
        timeOut: 10000000
    })
}
export function uploadReport(params) {
    return request({
        method: 'POST',
        url: '/Excelins/uploadReport',
        data: params,
        timeOut: 10000000
    })
}
export function uploadClaim(params) {
    return request({
        method: 'POST',
        url: '/Excelins/uploadClaim',
        data: params,
        timeOut: 10000000
    })
}
export function rePush(params) {
    return request({
        method: 'POST',
        url: '/Excelins/rePush',
        data: params,
    })
}
export function acceptOffline(params) {
    return request({
        method: 'POST',
        url: '/Excelins/acceptOffline',
        data: params,
    })
}
export function claimOffline(params) {
    return request({
        method: 'POST',
        url: '/Excelins/claimOffline',
        data: params,
    })
}
export function perClaimRemark(params) {
    return request({
        method: 'POST',
        url: 'Lifeinsurance/perClaimRemark',
        data: params,
    })
}

export function getStockList(params) {
    return request({
        method: 'POST',
        url: '/Stock/StockList',
        data: params,
    })
}
export function guideStockPut(params) {
    return request({
        method: 'POST',
        url: '/Stock/StockPut',
        data: params,
    })
}

export function guideStockOut(params) {
    return request({
        method: 'POST',
        url: '/Stock/StockOut',
        data: params,
    })
}

export function stockDownload(params) {
    return request({
        method: 'POST',
        url: '/Stock/downloadStock',
        data: params,
        responseType: 'blob'
    })
}

export function printStock(params) {
    return request({
        method: 'POST',
        url: '/Stock/printStock',
        data: params,
    })
}