<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
<!--      <el-form-item class="el_from" label="商家名称" slot="operationSelect">-->
<!--        <el-select-->
<!--          v-model="group_id"-->
<!--          placeholder="请选择"-->
<!--          clearable-->
<!--          @change="handleChange"-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="item in GroupList"-->
<!--            :key="item.group_id"-->
<!--            :label="item.group_name"-->
<!--            :value="item.group_id"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import {formValidateMobile} from '@/utils/form-validate'
export default {
  name: 'Form',
  data() {
    return {
      initData: null,
      GroupList:[],
      group_id:'',
      formItemList: [
        {
          key: 'group_id',
          idKey: 'group_id',
          labelKey: 'group_name',
          type: 'selectFilterable',
          labelName: '商家名称:',
          option: [],
          placeholder: '请选择商家名称'
        },
        {
          key: 'instype_id',
          idKey: 'instype_id',
          labelKey: 'instype_name',
          type: 'selectFilterable',
          labelName: '保险类型:',
          option: [],
          placeholder: '请输选择保险类型'
        },
        {
          key: 'card_name',
          type: 'input',
          labelName: '子险种查询:',
          placeholder: '请输入初始子险种名称'
        },
        {
          key: 'card_num',
          type: 'input',
          labelName: '卡号:',
          placeholder: '请输入卡号'
        },
        {
          key: 'card_secret',
          type: 'input',
          labelName: '卡密:',
          placeholder: '请输入卡密'
        },
        {
          key: 'is_use',
          type: 'select',
          labelName: '使用状态:',
          placeholder: '请选择使用状态',
          option:[
            {
              value:"0",
              label:"未使用",
            },{
              value:"1",
              label:"已使用",
            },
          ]
        },//0未寄送 1已寄送
        {
          key: 'virtually',
          type: 'select',
          labelName: '是否虚拟卡:',
          placeholder: '请选择',
          option:[
            {
              value:"1",
              label:"虚拟卡",
            },{
              value:"0",
              label:"实体卡",
            },
          ]
        },
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      }
    }
  },
  mounted(){
    this.selectGroupList()
    _api.pullInsTypeList().then(res=>{
      this.formItemList[1].option=res.data||[]
    })
  },
  methods: {
    //合作商家下拉
    selectGroupList(){
      _api.selectGroupList().then(res => {
        if(res.code === 1){
          this.formItemList[0].option = res.data
        }
      })
    },
    handleChange(val) {
      this.group_id = val
      console.log(this.group_id)
    },
    handleConfirm(data, cd) {
      cd()
      console.log('提交了form', data)
      const SeachParams = {
        card_name:data.card_name,
        instype_id:data.instype_id,
        is_use:data.is_use,
        group_id:data.group_id,
        card_num:data.card_num,
        card_secret:data.card_secret,
        virtually:data.virtually,
        page: 1,
        limit: 10,
      }
      this.$emit("changeNav",SeachParams)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{
  /deep/ .el-form-item__label{
        width: 100px!important;
    }
  /deep/ .el-input__inner{
    width: 180px!important;
  }
}
</style>
