<template>
  <div class="account">
    <GlobalInfoBar title="缴费信息" />
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <GlobalForm :form-item-list="formItemList" :inline="true" round
        @handleConfirm="query" confirmBtnName="查询" :label-width="120">
        <el-form-item class="el_from" label="流水号:" slot="serialNo" style="margin:0">
          <el-input v-model="serialNo" clearable placeholder="请输入流水号查询"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="保单号:" slot="policyNo" style="margin:0">
          <el-input v-model="policyNo" clearable placeholder="请输入保单号查询"></el-input>
        </el-form-item>
      </GlobalForm>

      <GlobalTable ref="GlobalTable" :columns="tableColumns" :data="tableData" :currentPage="curPage"
        :total="totalElements" @handleCurrentChange="currentChange">
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import api from '@/utils/request'
export default {
  name: "account",
  data() {
    return {
      formItemList: [
        { slotName: "serialNo" },
        { slotName: "policyNo" },
        {
          key: "entryTime",
          type: "daterange",
          labelName: "时间:",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
        },
      ],
      serialNo: '',
      policyNo: '',
      beg_time:'',
      end_time:'',
      tableColumns: [
      { label: "流水号", prop: "serialNo"},
      { label: "保单号", prop: "policyNo"},
      { label: "分期号", prop: "installmentNo"},
      { label: "分期保费（分）", prop: "installmentPremium"},
      { label: "续期支付时间", prop: "paymentTime"},
      { label: "缴费状态", prop: "typeName"},
      { label: "断缴原因", prop: "releaseReason"},
      { label: "添加时间", prop: "add_time"},
      {slotName: 'operation'},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      pageSize: 10,
    }
  },
  created() {
    this.getList()
  },
  mounted() {
  },
  methods: {
    query(data, cd) {
      cd();
      if(data){
        if (data.entryTime === null) {
          (this.beg_time = ""), (this.end_time = "");
        } else {
          this.beg_time = (data.entryTime && data.entryTime[0]) || "";
          this.end_time = (data.entryTime && data.entryTime[1]) || "";
        }
      }else {
        (this.beg_time = ""), (this.end_time = "");
      }
      this.curPage = 1
      this.getList()
    },
    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    getList() {
      let params = {
        page: this.curPage,
        limit: this.pageSize,
        serialNo: this.serialNo,
        policyNo: this.policyNo,
        beg_time:this.beg_time,
        end_time:this.end_time
      }
      api.vivoRenewalList(params).then(res => {
        this.tableData = res.data
        this.totalElements = res.count || 0
      })
    },
  }
}
</script>

<style scoped lang="scss">
.account {
  /deep/ .el-form-item__label {
    width: 80px !important;
  }
}
</style>
