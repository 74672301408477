/* 合法uri*/
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母*/
export function validateAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/* 手机号码*/
export function validateMobile(str) {
  const reg = /^[1][0-9]{10}$/
  return reg.test(str)
}
/* 数字*/
export function validateNumber(str) {
  const reg = /^[0-9]*$/
  return reg.test(str)
}

/* 5位数字*/
export function validateNumberEight(str) {
  const reg = /^[0-9]{1,5}$/
  return reg.test(str)
}
/* 两位小数*/
export function validateNumberFloat(str) {
  const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^[1-9]$)|(^\d\.[1-9]{1,2}$)|(^\d\.[0]{1}[1-9]{1}$|(^\d\.[1-9]{1}[0]{1}$)$)/
  return reg.test(str)
}


/* 七位数字*/
export function validateNumberSeven(str) {
  const reg = /^(?!0+)\d{1,7}(\.\d{1,2})?$/
  return reg.test(str)
}

// 密码
export function validatePassWard(str) {
  const reg = /^[A-Za-z0-9]+$ /
  return reg.test(str)
}