<template>
  <section class="el-table-container">
    <el-table
        ref="tableGroup"
        :data="data"
        :highlight-current-row="true"
        :row-key="getRowKeys"
        :cell-style="columnStyle"
        :max-height="maxHeight"
        border
        stripe
        size="small"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @select="handleSelect"
        @select-all="handleSelectAll"
    >

      <!--多选列-->
      <el-table-column
          v-if="isSelection"
          type="selection"
          align="center"
          width="55"
          :selectable="isCheckbox"
      />

       <el-table-column
          v-if="isIndex"
          type="index"
          align="center"
          label="序号"
          width="70"
          :index='(index)=>{return (index+1) + (currentPage-1)*pageSize }'
      />


      <!-- 展示列 -->
      <template v-for="(column, index) in columns">
        <slot
            v-if="column.slotName"
            :name="column.slotName"
        />
        <component
            :is="column.component"
            v-if="column.component"
            :key="index"
            :params="column.params"
        />
        <el-table-column
            v-if="column.prop"
            :key="index"
            :label="column.label"
            :prop="column.prop"
            :width="column.width"
            :class-name="column.className"
            align="center"
        />
      </template>
    </el-table>

    <el-pagination
        v-if="isPagination"
        background
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="layout"
        :total="total"
        class="el-pagination-c"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
  </section>
</template>

<script>
export default {
  name: 'GlobalTable',
  props: {
    /*
    * 表格
    * */
    // 表格展示列
    columns: {
      type: Array,
      required: true
    },
    maxHeight:{
      type: Number
    },
    // 表格数据
    data: {
      type: Array,
      required: true
    },
    columnStyle: {
      type: Function,
      required: false
    },
    // 是否显示多选列
    isSelection: {
      type: Boolean,
      default: false
    },
    isIndex:{
      type: Boolean,
      default: true
    },
    // 传入自定义按钮时，需要延展宽度的最大值
    operationCustomLength: {
      type: Number,
      default: 0
    },
    // 回显判别值的name
    judgeValue: {
      // required: true,
      type: String,
      default: 'index'
    },
    /*
    * 分页
    * */
    // 是否显示
    isPagination: {
      type: Boolean,
      default: true
    },
    total: {
      type: Number,
      default: 0
    },
    // 当前页面
    currentPage: {
      type: Number,
      default: 1
    },
    // 自定义每页面数量组
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100]
    },
    // 每页面数量
    pageSize: {
      type: Number,
      default: 10
    },
    // total 数据总量, sizes 每页面数量组, prev 上一页, pager, next 下一页, jumper 页面跳转
    layout: {
      type: String,
      default: 'total,prev, pager, next, jumper'
    },
    // 判断选择是否禁用
    isCheckbox: {
      type: Function,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      getRowKeys(row) {
        return row.index
      },
      cacheCurrentPage: 1,
      cacheSelectData: []
    }
  },
  mounted() {
    this.cacheCurrentPage = this.currentPage
  },
  methods: {
    // 选中改变事件
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    // 单个选中事件
    handleSelect(list) {
      this.cacheSelectData[this.cacheCurrentPage] = list
      this.$emit('handleSelect', list)
    },
    // 全选事件
    handleSelectAll(list) {
      // 防止指针错乱
      this.cacheSelectData[this.cacheCurrentPage] = list.length ? list : null
      this.$emit('handleSelect', list)
    },
    // 单行选中
    setSelection(row, bool) {
      this.$refs.tableGroup.toggleRowSelection(row, bool)
    },
    // 清空选中
    clearSelection() {
      this.$refs.tableGroup.clearSelection()
    },
    // 分页选择回显
    echoSelection(currentPageSelectList) {
      if (currentPageSelectList) {
        const _list = this.data.filter(row => {
          return currentPageSelectList.some(item => {
            return item[this.judgeValue] === row[this.judgeValue]
          })
        })
        _list.forEach(row => {
          this.setSelection(row)
          // or
          // this.$refs.tableGroup.toggleRowSelection(row)
        })
      }
    },
    /*
    * 分页
    * */
    handleSizeChange(num) {
      this.$emit('handleSizeChange', num)
    },
    handleCurrentChange(page) {
      this.$emit('handleCurrentChange', page)
      this.cacheCurrentPage = page
      // 等待表格数据回显
      setTimeout(() => {
        this.echoSelection(this.cacheSelectData[page])
      }, 500)
    },
    /*
    * 按钮(外界可通过refs访问)
    * */
    // 确定
    handleConfirm(isClearSelections = true) {
      // 所有选中的数据
      const _AllSelectData = [].concat(...this.cacheSelectData).filter((item) => {
        return item !== null && typeof item === 'object'
      })
      this.$emit('handleConfirm', _AllSelectData);
      if (isClearSelections) {
        this.cacheSelectData = []
        this.clearSelection()
      }
    },
    // 取消
    handleCancel() {
      this.cacheSelectData = []
      this.$emit('handleCancel')
      this.clearSelection()
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.el-table-container {
  .el-pagination-c {
    text-align: right;
    margin-top: 1.5em;
    padding-bottom: 20px;
  }

  .el-row-select {
    margin-top: 6em;

    .el-col-select {
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
.el-table-container {
  .el-table .has-gutter tr th {
    color: #666666;
    font-weight: bold;
    height: 40px;
    padding: 0;
  }
}
</style>
