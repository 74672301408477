<template>
  <el-aside
      id="aside"
      class="aside"
      :width="`${isCollapse?'64':'210'}px`"
  >
    <el-menu
    :popper-append-to-body="false"
      :collapse="isCollapse"
      :default-active="$route.path"
      :default-openeds="defaultOpeneds"
      :unique-opened='true'
      background-color="#00182E"
      text-color="#C2C6CD"
      active-text-color="#ffffff"
    >
      <div v-for="(tab,tIndex) in routersList" :key="tIndex">
        <el-menu-item
          v-if="tab.type==='menu-item'"
          :index="tab.path"
          @click="tab.method || routerLinks(tab.path)"
        >
          <i :class="tab.icon" />
          <span slot="title" style="font-size: 16px;">{{ tab.name }}</span>
        </el-menu-item>

        <el-submenu v-if="tab.type==='submenu'" :index="tab.path">
          <template slot="title">
            <i :class="tab.icon" />
            <span v-show="!isCollapse" slot="title">{{ tab.name }}</span>
          </template>
          <div
            v-for="(item,index) in tab.children"
            :key="index"
          >
            <el-menu-item
              v-if="!item.method"
              :index="item.path"
              @click="routerLinks(item.path)"
            >
              {{ item.name }}
            </el-menu-item>
            <!--使用三目运算不能正常触发method，改成if-->
            <el-menu-item
              v-else
              :index="item.path"
              @click="item.method"
            >
              {{ item.name }}
            </el-menu-item>
          </div>
        </el-submenu>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
import api from "@/utils/request";

export default {
  name: 'Aside',
  data() {
    return {
      /*
      * type: 'menu-item'(单个菜单)、'submenu'(菜单组)
      * */

      defaultActive: this.activeTab,
      // 默认打开的tabs: ['/feature']
      defaultOpeneds: []
    }
  },
  mounted() {

    const path = this.$route.path
    if (path.indexOf('/feature/TabsView') === -1) {
      this.defaultActive = path
    } else {
      this.defaultActive = '/feature/TabsView'
    }
  },
  computed: {
    isCollapse () {
      return this.$store.state.tagsView.isCollapse
    },
    routersList() {
      return this.$store.state.menu
    }
  },
  methods: {
    routerLinks(path) {
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.aside{
  background: #00182E;
  // border-radius: 0px 20px 20px 0px;
  transition: width 0.3s;
}
.el-menu-item.is-active {
      background-color: #1091FF !important;
      color: #fff;
      span {
        color: #fff !important;
      }
    }
    .el-submenu__title:focus, .el-submenu__title:hover{
      outline: 0 !important;
      color: #409EFF !important;
      background: none !important;
    }
    .el-menu-item:hover{
      outline: 0 !important;
      color: #fff !important;
    }

</style>
