<template>
  <div>
    <GlobalInfoBar title="机型管理" subtitle="旧机机型管理，开启焕新后该机型可作为新机参与焕新活动"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      >
        <div slot="AddSlot">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addModel">新增机型</el-button>
        </div>
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="是否在售" slot="is_sell" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.is_sell" :inactive-value="0" :active-value="1"
                       @change="changeSellSwitch(row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否开启焕新" slot="is_open" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.is_open" :inactive-value="0" :active-value="1"
                       @change="changePhoneSwitch(row)"></el-switch>
            <el-popover
                v-if="!row.is_open&&row.reason"
                placement="right"
                width="400"
                trigger="click">
              <div>{{ row.reason }}</div>
              <a href="javascript:" slot="reference" style="margin-left: 20px">关闭原因</a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="editInfo(row)" style="margin-right: 20px">修改</el-button>

            <el-popover
                placement="left"
                width="300"
                v-model="row.isShowPopover">
              <p><span class="el-icon-warning"></span>删除后数据不可恢复，确认删除吗？</p>
              <div style="margin-top: 20px">
                <el-button size="small" plain @click="row.isShowPopover = false">取消</el-button>
                <el-button type="primary" size="small" @click="row.isShowPopover = false;deleteRow(row)">确定</el-button>
              </div>
              <el-button type="danger" size="mini" class="delete-btn" slot="reference">删除</el-button>
            </el-popover>

          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <el-dialog :title="dialog.type+'提示'" :visible.sync="dialog.isShow" width="500px" top="200px" :before-close="switchCancel">
      <div class="dialog-content" v-if="dialog.type==='开启'">
        <p>开启后，该机型可作为焕新机型，</p>
        <p>是否确认开启？</p>
      </div>
      <template v-else>
        <div class="dialog-content">
          <p>关闭后，该机型将不可作为焕新机型，</p>
          <p>是否确认关闭？</p></div>
        <div class="close-reason">关闭原因：
          <el-input type="textarea" class="dialog-textarea" v-model="dialog.row.reason" maxlength="50" placeholder="请输入关闭原因，最多可输入50字"></el-input>
        </div>
      </template>
      <div class="dialog-btns">
        <el-button type="primary" class="cancel" plain size="mini" @click="switchCancel">取消</el-button>
        <el-button type="primary" size="mini" @click="switchConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addPhoneModel2, phonePriceHandle, phonePriceList} from "../../../utils/request/changePhone";

export default {
  name: "index",
  data() {
    return {
      formItemList: [
        {
          key: 'model_name',
          idKey: 'model_name',
          labelKey: 'model_name',
          type: 'selectFilterable',
          labelName: '机型名称:',
          option: [],
          placeholder: '请选择机型名称'
        }
      ],
      tableColumns: [
        {label: "机型名称", prop: "model_name"},
        {label: "内存", prop: "ram_size"},
        {label: "颜色", prop: "colour_name"},
        {label: "官网价格", prop: "official_price"},
        {label: "旧机5折抵扣", prop: "price"},
        {slotName: "is_sell"},
        {slotName: "is_open"},
        {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      model_name: '',
      dialog: {
        isShow: false,
        type: '开启',
        row: null
      }
    }
  },
  mounted() {
    this.getList()
    this.getModelList()
  },
  methods: {
    query(data) {
      this.model_name=data.model_name
      this.curPage = 1
      this.getList()
    },
    getList() {
      phonePriceList({
        page: this.curPage,
        limit: 10,
        model_name: this.model_name
      }).then(res => {
        this.tableData = res.data
        this.totalElements = res.count
      })
    },
    getModelList() {
      addPhoneModel2().then(res=>{
        this.formItemList[0].option=res.data
      })
    },
    addModel() {
      this.$router.push('/changePhone/modelOperation')
    },
    currentChange(page) {
      this.curPage = page;
      this.getList()
    },
    editInfo(row) {
      this.$router.push({path:'/changePhone/modelOperation',query:{model_name:row.model_name}})
    },
    deleteRow(row) {
      phonePriceHandle({
        price_id: row.price_id,
        type: 2
      }).then(data=>{
        this.getList()
        this.$message.success(data&&data.msg||'删除成功')
      }).catch(err=>{
        console.error(err)
      })
    },
    changePhoneSwitch(row) {
      this.dialog.row = row
      this.dialog.isShow = true

      this.dialog.type = row.is_open === 1 ? '开启' : '关闭'
    },
    switchCancel() {
      this.dialog.isShow = false
      this.getList()
    },
    switchConfirm() {
      if (this.dialog.row.is_open === 0 && !this.dialog.row.reason) {
        this.$message.error('请填写关闭原因')
        return
      }
      phonePriceHandle({
        price_id: this.dialog.row.price_id,
        type: 1,
        value: this.dialog.row.is_open,
        reason:this.dialog.row.reason
      }).then(data=>{
        this.$message.success(data&&data.msg||this.dialog.type+'成功')
      }).catch(err=>{
        console.error(err)
      }).finally(()=>{
        this.dialog.isShow = false
        this.getList()
      });
    },
    changeSellSwitch(row) {
      console.log(`%c row`,'color:red;font-size:20px',row)
      phonePriceHandle({
        price_id: row.price_id,
        type: 3,
        value: row.is_sell,
      }).then(data=>{
        this.$message.success(data&&data.msg||this.dialog.type+'成功')
      }).catch(err=>{
        console.error(err)
      }).finally(()=>{
        this.getList()
      });
    },
  }
}
</script>

<style scoped>
.dialog-content p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.close-reason {
  margin-top: 20px;
  text-align: center;
}

.dialog-textarea {
  width: 300px;
  vertical-align: top;
}

.dialog-btns {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 42px;

}

.dialog-btns .cancel {
  margin-right: 20px;
}
::v-deep textarea {
  min-height: 7em!important;
}
</style>
