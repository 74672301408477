import Layout from '@/layout/layout'

const DemoVideoRouter = {
    path: '/dataOutput',
    component: Layout,
    name: '数据导出',
    children: [
        {
            path:'/',
            component: () => import('@/views/dataOutput/index'),
            name: '导出',
            meta: { title: '数据导出'}
        },
        {
            path:'table',
            component: () => import('@/views/dataOutput/table'),
            name: '查看',
            meta: { title: '数据查看'}
        },

    ]
}

export default DemoVideoRouter
