<template>
  <div>
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <GlobalForm :form-item-list="formItemList" :inline="true" round @handleConfirm="query" confirmBtnName="查询">
        <div slot="AddSlot">
          <el-upload
            ref="upload"
            action="#"
            :limit="1"
            :auto-upload="false"
            :on-change="getFile"
            >
            <el-button size="small" type="primary">配置导入</el-button>
          </el-upload>
        </div>
      </GlobalForm>

      <GlobalTable ref="GlobalTable" :columns="tableColumns" :data="tableData" :currentPage="curPage"
        :total="totalElements" @handleCurrentChange="currentChange">
        <el-table-column label="操作" slot="operation" align="center" width="223">
          <template slot-scope="{ row }">
            <el-link type="danger" :underline="false" @click="delItem(row)">删除</el-link>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import { configList, configDel, configImport } from "@/utils/request/renewal";
export default {
  data() {
    return {
      curPage: 1,
      totalElements: 0,
      searchParams: {},
      tableData: [],
      formItemList: [
        {
          key: 'order_sn',
          type: 'input',
          labelName: '订单编码:',
          placeholder: '请输入订单编码'
        },
      ],
      tableColumns: [
        { label: "订单编码", prop: "order_sn" },
        { label: "串码", prop: "imei" },
        { label: "操作员", prop: "user_name" },
        { label: "添加时间", prop: "add_time" },
        { slotName: "operation" },
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      configList({
        ...this.searchParams,
        page: this.curPage,
        limit: 10,
      }).then(res => {
        this.tableData = res.data || []
        this.totalElements = res.count || 0
      })
    },
    query(data) {
      this.searchParams = data
      this.curPage = 1
      this.getList()
    },
    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    delItem(row) {
      this.$confirm('是否删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        configDel({ id: row.id }).then(() => {
          this.$message.success('操作成功！')
          this.getList()
        })
      })
    },
    getFile(file) {
      let form=new FormData()
      form.append('file',file.raw)
      configImport(form).then(data => {
        this.getList()
        this.$message.success(data && data.msg || '导入成功')
      }).catch(err => {
        console.error(err)
      }).finally(()=>{
        this.$refs.upload.clearFiles()
      })
    }
  }
}
</script>