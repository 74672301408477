<template>
  <div>
    <GlobalInfoBar :title="this.$route.query.store_id?'编辑门店':'新增门店'"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <el-form ref="form" :model="form" label-width="80px" size="medium" :rules="rules">
        <el-form-item label="选择渠道" prop="pass_id">
          <el-select size="small" v-model="form.pass_id" placeholder="请选择渠道" @change="channelChange">
            <el-option
                v-for="item in channelList"
                :key="item.pass_id"
                :label="item.pass_name"
                :value="item.pass_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属代理" prop="agent_id">
          <el-select size="small" v-model="form.agent_id" placeholder="请选择所属代理">
            <el-option
                v-for="item in agentList"
                :key="item.agent_id"
                :label="item.agent_name"
                :value="item.agent_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="门店名称" prop="store_name">
          <el-input v-model="form.store_name" placeholder="请输入移动门店，最多20个字" maxlength="20"></el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="store_phone">
          <el-input v-model="form.store_phone" placeholder="请输入门店联系电话" type="tel" maxlength="15"
                    oninput="this.value=this.value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>

        <el-form-item label="选择地址" prop="region">
          <el-cascader :props="region" v-model="form.region" ref="cascader"></el-cascader>
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
          <el-button type="primary" size="small" style="margin-left: 20px" @click="genCoordinate">生成经纬度</el-button>
        </el-form-item>

        <el-form-item label="坐标">
          <el-input v-model="form.lon" placeholder="经度" disabled style="width: 200px"></el-input>
          <el-input v-model="form.lat" placeholder="维度" disabled style="width: 200px;margin-left: 20px"></el-input>
        </el-form-item>

        <div style="margin-left: 80px;">
          <el-button type="primary" plain size="medium" @click="$router.go(-1)">返回</el-button>
          <el-button type="primary" size="medium" @click="confirm">提交</el-button>
        </div>
      </el-form>
    </GlobalChunk>
  </div>
</template>

<script>
import {addEditStore, getRegion, selectAgent, selectPass, storeInfo} from "../../../utils/request/changePhone";
import axios from "axios";

export default {
  name: "operate",
  data() {
    let regionValidate = (rule, value, callback) => {
      if (!value || (Array.isArray(value) && !value.length)) {
        callback('请选择区域')
        return
      }
      callback()
    };
    return {
      form: {
        pass_id: '',
        store_name: '',
        store_phone: '',
        address: '',
        region: [],
        lon: '',
        lat: '',
        agent_id: ''
      },
      agentList: [],
      channelList: [],
      rules: {
        pass_id: [{required: true, message: '请选择通道', trigger: 'change'}],
        agent_id: [{required: true, message: '请选择所属代理', trigger: 'change'}],
        store_name: [{required: true, message: '请输入门店名称', trigger: 'change'}],
        store_phone: [{required: true, message: '请输入门店联系方式', trigger: 'change'}],
        address: [{required: true, message: '请输入门店详细地址', trigger: 'change'}],
        region: [  { required: true,validator: regionValidate, trigger: 'change' }],
      },
      region: {
        lazy: true,
        lazyLoad(node, resolve) {
          let pid = node.data && node.data.value || 0;
          getRegion({pid}).then(res => {
            let data = res || {};
            let children = []
            Object.keys(data).forEach(key => {
              children.push({
                value: key,
                label: data[key],
                leaf: node.level >= 2
              })
            })
            resolve(children)
          })
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.store_id) {
      this.getStoreDetail()
    }
    this.getChannelList();
  },
  methods: {
    getStoreDetail() {
      storeInfo({
        store_id: this.$route.query.store_id
      }).then(res => {
        let data = res.data;
        if (data) {
          this.form = data
          this.form.region = [data.province, data.city, data.district]
          this.$refs.cascader.inputValue = [data.province_name, data.city_name, data.district_name].join(' / ');
        }
        this.getAgentList()
      })
    },
    getChannelList() {
      selectPass().then(res=>{
        this.channelList=res.data||[]
      })
    },
    getAgentList() {
      selectAgent({
        pass_id:this.form.pass_id
      }).then(res=>{
        this.agentList=res.data||[]
      })
    },
    channelChange() {
      this.form.agent_id=''
      this.getAgentList();
    },
    genCoordinate() {
      if (!this.form.region || !this.form.region.length) {
        this.$message.error('请选择地区')
        return
      }
      if (!this.form.address) {
        this.$message.error('请输入详细地址')
        return
      }
      let key = process.env.VUE_APP_MAP_KEY;
      let region = this.$refs.cascader.inputValue.split(' / ')
      let address = `${region[0]}${region[1]}${region[2]}${this.form.address}`
      axios.get(`http://restapi.amap.com/v3/geocode/geo?key=${key}&s=rsv3&city=35&address=${address}`).then(res => {
        console.log(res.data, '得到经纬度')
        let location = res && res.data && res.data.geocodes && res.data.geocodes[0].location || ''
        let lon = location.split(',')[0]
        let lat = location.split(',')[1]

        this.form.lon = lon
        this.form.lat = lat
      }).catch(err=>{
        this.$message.error('无法获取坐标，请核实位置信息')
      })
    },
    confirm() {
      this.$refs.form.validate(valid=>{
        if (valid) {
          addEditStore({
            store_id: this.form.store_id || '',
            pass_id: this.form.pass_id || '',
            store_name: this.form.store_name,
            store_phone: this.form.store_phone,
            province: this.form.region[0],
            city: this.form.region[1],
            district: this.form.region[2],
            address: this.form.address,
            lon: this.form.lon,
            lat: this.form.lat,
            agent_id:this.form.agent_id
          }).then(data=>{
            this.$router.go(-1)
            this.$message.success(data&&data.msg||'操作成功')
          }).catch(err=>{
            console.error(err)
          })
        }
      })
    }

  }
}
</script>

<style scoped>
.el-input, .el-cascader,.el-select {
  width: 420px;
}
</style>
