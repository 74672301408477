<template>
  <div>
    <GlobalInfoBar title="身份证验证配置" />
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <div class="top_tit">
        动态配置订单审核时，是否强制检验身份信息（姓名与身份证号是否一致），开启后则校验，关闭则不校验。
      </div>
      <span>身份验证：<el-switch v-model="value" :active-value="1" :inactive-value="0"
          @change="switchChange"></el-switch></span>
    </GlobalChunk>
    <GlobalChunk style="margin-top: 30px;" :padding="[20, 20, 20, 20]">
      <div class="top_tit">
        批量新增序列号对应串码的权益订单。
      </div>
      <div style="display: flex;align-items: center;height: 30px"><span>文件上传：</span><el-upload  :disabled="isPushing" ref="uploader" style="display: flex;align-items: center;height: 30px;width: 300px;" action="#" list-type="text" :limit="1"
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              show-file-list :auto-upload="false" :before-remove="removeFile" :on-change="getFile">
              <el-button v-if="!file" type="primary" size="mini"  :disabled="isPushing">点击上传</el-button>
            </el-upload></div>
    </GlobalChunk>
    <GlobalChunk style="margin-top: 30px;" :padding="[20, 20, 20, 20]">
      <div class="top_tit">
        卡号匹配订单是否使用
      </div>
      <div style="display: flex;align-items: center;height: 30px"><span>文件上传：</span><el-upload  :disabled="isPushingCard" ref="uploaderCard" style="display: flex;align-items: center;height: 30px;width: 300px;" action="#" list-type="text" :limit="1"
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              show-file-list :auto-upload="false" :before-remove="removeFileCard" :on-change="getFileCard">
              <el-button v-if="!fileCard" type="primary" size="mini"  :disabled="isPushingCard">点击上传</el-button>
            </el-upload></div>
    </GlobalChunk>
    <el-dialog
        title='下载模板操作'
        :visible.sync="downloadShow"
        :close-on-click-modal="false"
        width="350px"
    >
      <div class="cance-tip">
        <div class="tip-sure">请确认下载模板？</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="downloadSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import {
  importOrderImei,importCardMate
} from "../../../utils/request";
export default {
  name: "idCardVerifyConfig",
  data() {
    return {
      value: true,
      isPushing: false,
      isPushingCard:false,
      file: '',
      fileCard :'',
      downloadShow:false,
      url:''
    }
  },
  mounted() {
    _api.getIdCard().then(res => {
      this.value = res.data.value
    })
  },
  methods: {
    switchChange() {
      _api.idCardConfig({
        value: this.value
      }).then(res => {
        this.$message.success(res && res.msg || '操作成功')
      });
    },
    getFile(file) {
      this.file = file
      this.uploadFile()
    },
    getFileCard(file) {
      this.fileCard = file
      this.uploadFileCard()
    },
    insuranceTypeChange() {
      this.uploadFile()
    },
    uploadFileCard() {
      if (!this.fileCard) {
        return
      }
      let formData = new FormData();
      formData.append('file', this.fileCard.raw)
      importCardMate(formData).then(res => {
        console.log(res)
        this.$message.success(res && res.msg || '操作成功')
        this.url = res.data.url
        this.downloadShow = true
        // this.downloadSubmit()
      }).catch(err => {
        this.fileCard = ''
        this.$refs.uploaderCard.clearFiles()
      });
    },
    uploadFile() {
      if (!this.file) {
        return
      }
      // if (this.type === 1 && !this.instype_id) {
      //   return;
      // }
      let formData = new FormData();
      formData.append('file', this.file.raw)
      // if (this.type === 1) {
      //   formData.append('instype_id', this.form.instype_id)
      // }
      importOrderImei(formData).then(res => {
        console.log(res)
        this.$message.success(res && res.msg || '操作成功')
      }).catch(err => {
        this.file = ''
        this.$refs.uploader.clearFiles()
      });
    },
    removeFile() {
      this.file = ''
    },
    removeFileCard() {
      this.fileCard = ''
    },
    downloadSubmit(){
      console.log(123,this.url);
      const link = document.createElement("a");
      link.href = this.url;
      link.download = "卡号匹配.xlsx"; // link.setAttribute("download", "卡号匹配.xlsx");
      document.body.appendChild(link);
      link.click();
      this.downloadShow = false
    }
  }
}
</script>

<style scoped>
.top_tit {
  margin: 0 0 20px 0;
  color: #0981FF;
}
</style>
