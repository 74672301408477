<template>
  <div class="CarMi_edit">
    <div class="red_text">
      说明: 修改前请仔细核对信息无误，否则将影响商家使用。
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item class="el_form1" label="起止卡号" prop="sta_id">
        <el-input
          v-model="ruleForm.sta_id"
          placeholder="请输入完整的起始卡号"
        ></el-input>
        ~
        <el-input
          v-model="ruleForm.end_id"
          placeholder="请输入完整的截止卡号"
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form2" label="关联商家" prop="group_id">
        <el-select
          v-model="ruleForm.group_id"
          placeholder="请选择关联商家"
          filterable
        >
          <el-option
            v-for="item in GroupList"
            :key="item.group_id"
            :label="item.group_name"
            :value="item.group_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form2" label="子险种" prop="goods_id">
        <el-select
          v-model="ruleForm.goods_id"
          placeholder="请选择子险种"
          filterable
        >
          <el-option
            v-for="item in subInsuranceList"
            :key="item.goods_id"
            :label="item.goods_name"
            :value="item.goods_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="bot_bom">
        <el-button type="primary" size="small" @click="submitForm('ruleForm')"
          >确认修改</el-button
        >
        <el-button @click="resetForm('ruleForm')" size="small">返回</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import _api from "@/utils/request";
export default {
  name: "CarMi_edit",
  data() {
    return {
      GroupList: [],
      ruleForm: {
        sta_id: "",
        end_id: "",
        group_id: "",
        goods_id: "",
      },
      rules: {
        group_id: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
        ],
        sta_id: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (!this.ruleForm.sta_id || !this.ruleForm.end_id) {
                callback("请选择完整卡号");
              } else {
                callback();
              }
            },
          },
        ],
        goods_id: [
          {
            required: true,
            trigger: "change",
            message: "请选择子险种",
          },
        ],
      },
      subInsuranceList: [],
    };
  },
  mounted() {
    this.selectGroupList();
    _api.pullCardGoods().then((res) => {
      this.subInsuranceList = res.data || [];
    });
  },
  methods: {
    selectGroupList() {
      _api.selectGroupList().then((res) => {
        if (res.code === 1) {
          this.GroupList = res.data;
        }
      });
    },
    submitForm(formName) {
      const vo = {
        sta_id: this.ruleForm.sta_id,
        end_id: this.ruleForm.end_id,
        group_id: this.ruleForm.group_id,
        goods_id: this.ruleForm.goods_id,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.editsCardPool(vo).then((res) => {
            console.log(res);
            if (res.code === 1) {
              this.$message.success(res.msg);
              this.$router.push("/Carmi/inquire");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.CarMi_edit {
  .red_text {
    color: #f4280b;
    margin: 0 0 20px 30px;
    font-size: 13px;
  }
  .el_form1 {
    /deep/ .el-input__inner {
      width: 172px;
    }
    /deep/ .el-input {
      width: 172px;
    }
  }
  .el_form2 {
    /deep/ .el-input__inner {
      width: 360px;
    }
  }
  .bot_bom {
    margin: 150px 0 0 30px;
  }
}
</style>
