<template>
  <div class="register_list">
    <GlobalInfoBar title="协议及子险种" />
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <div class="top_tit">
        <div class="os-type">
          <el-button-group
            v-if="['2', '3'].indexOf($route.query.instype_id) === -1"
            style="margin-right: 50px"
          >
            <el-button
              size="small"
              style="width: 80px"
              :type="currentOs === 1 ? 'primary' : ''"
              :class="{ current: currentOs === 1 }"
              @click="changeOsType(1)"
              >苹果
            </el-button>
            <el-button
              size="small"
              style="width: 80px"
              :type="currentOs === 2 ? 'primary' : ''"
              :class="{ current: currentOs === 2 }"
              @click="changeOsType(2)"
              >安卓
            </el-button>
          </el-button-group>
        </div>
        <div class="notice">
          主要配置子险种的名称、保障年限、成本等信息（如大险种无子险种则无需配置）
        </div>
        <el-button size="small" type="warning" @click="add"
          >+ 添加子险种</el-button
        >
      </div>
      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns"
        :data="tableData"
        :total="totalElement"
        :currentPage="pageNum"
        @handleCurrentChange="handleCurrentChange"
      >
        <el-table-column label="所属系统" slot="type" align="center">
          <template slot-scope="{ row }">
            {{ ["", "苹果", "安卓"][row.type] }}
          </template>
        </el-table-column>
        <el-table-column
          label="是否Pro版本"
          slot="is_pro"
          align="center"
          v-if="currentOs === 1"
        >
          <template slot-scope="{ row }">
            {{ ["不是", "是"][row.is_pro] }}
          </template>
        </el-table-column>
        <el-table-column
          label="价格区间"
          slot="price"
          align="center"
          v-if="currentOs === 2"
        >
          <template slot-scope="{ row }">
            {{ row.price }}
          </template>
        </el-table-column>
        <el-table-column label="成本" slot="cost_price" align="center">
          <template slot-scope="{ row }"> ￥{{ row.cost_price }} </template>
        </el-table-column>
        <el-table-column label="操作" slot="operate" align="center">
          <template slot-scope="{ row }">
            <a href="javascript:" @click="edit(row)">编辑</a>
          </template>
        </el-table-column>
        <el-table-column label="九机专属" slot="is_require" align="center">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.is_jiuji"
              :active-value="1"
              :inactive-value="0"
              @change="isjiujiChange(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="非必选/必选" slot="is_require" align="center">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.is_require"
              :active-value="1"
              :inactive-value="0"
              @change="isRequiredSwitchChange(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="积分" slot="points" width="160px" align="center">
        <template slot-scope="{ row }">
          <el-input-number :min="0" :max="999"  size="small" v-model="row.points" @change="handleChangeNum(row)"></el-input-number>
        </template>
      </el-table-column>
        <el-table-column label="禁用/启用" slot="is_open" align="center">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.is_open"
              :active-value="1"
              :inactive-value="0"
              @change="isOpenSwitchChange(row)"
            ></el-switch>
          </template>
        </el-table-column>
      </GlobalTable>

      <el-dialog
        :visible.sync="dialog.isShow"
        :title="dialog.row.son_id ? '编辑子险种' : '添加子险种'"
        width="600px"
      >
        <div style="color: #0981FF;margin-bottom: 20px;margin-left: 80px">
          说明：如一个子险种有多个年限或成本，请分多次添加。
        </div>

        <el-form
          size="small"
          label-width="120px"
          :model="dialog.row"
          :rules="dialog.rules"
          ref="dialogForm"
        >
          <el-form-item label="子险种名称" prop="son_name">
            <el-input
              v-model="dialog.row.son_name"
              placeholder="请填写子险种名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属系统" prop="type">
            <el-select
              v-model="dialog.row.type"
              placeholder="请选择所属系统"
              @change="dialogOsChange"
            >
              <el-option label="苹果" :value="1"></el-option>
              <el-option label="安卓" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="保障年限" prop="years">
            <el-select v-model="dialog.row.years" placeholder="请选择保障年限">
              <el-option label="1年" value="1年"></el-option>
              <el-option label="2年" value="2年"></el-option>
              <el-option label="次年" value="次年"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="是否Pro版本"
            prop="is_pro"
            v-if="dialog.row.type === 1"
            ref="is_pro"
          >
            <el-select
              v-model="dialog.row.is_pro"
              placeholder="请选择是否Pro版本"
            >
              <el-option label="是" :value="1"></el-option>
              <el-option label="不是" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="价格区间"
            prop="price"
            v-if="dialog.row.type === 2"
            ref="price"
          >
            <el-select v-model="dialog.row.price" placeholder="请选择价格区间">
              <el-option
                v-for="range in priceRange"
                :key="range"
                :label="range"
                :value="range"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="保险成本" prop="cost_price">
            <el-input
              v-model="dialog.row.cost_price"
              placeholder="保险成本请输入"
              @input="moneyInput(dialog.row, 'cost_price')"
            ></el-input>
          </el-form-item>
          <div style="text-align: right;margin-bottom: 20px">
            <el-button size="small" @click="dialog.isShow = false"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="confirm"
              >确认{{ dialog.row.son_id ? "修改" : "添加" }}</el-button
            >
          </div>
        </el-form>
      </el-dialog>
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import {hotPhoneMode} from "../../../utils/request/renewal"
export default {
  name: "subInsuranceSetting",
  data() {
    return {
      totalElement: 0,
      pageNum: 1,
      tableData: [],
      tableColumns: [
        { label: "子险种名称", prop: "son_name" },
        { label: "标记", prop: "son_id" },
        { label: "保障年限", prop: "years" },
        { slotName: "type" },
        { slotName: "is_pro" },
        { slotName: "price" },
        { slotName: "cost_price" },
        { label: "最后更新时间", prop: "add_time" },
        { slotName: "is_require" },
        { slotName: "is_open" },
        {slotName:"points"},
        { slotName: "operate" },
      ],
      currentOs: 1,
      priceRange: [],
      dialog: {
        isShow: false,
        row: {
          instype_id: "",
          son_name: "",
          type: "",
          years: "",
          is_pro: "",
          cost_price: "",
          price: "",
        },
        rules: {
          son_name: [
            { required: true, message: "请输入子保险名称", trigger: "blur" },
          ],
          type: [
            { required: true, message: "请选择所属系统", trigger: "change" },
          ],
          years: [{ required: true, message: "请选择年限", trigger: "change" }],
          is_pro: [
            {
              required: true,
              message: "请选择是否为Pro版本",
              trigger: "change",
            },
          ],
          price: [
            { required: true, message: "请选择价位区间", trigger: "change" },
          ],
          cost_price: [
            { required: true, message: "请输入保险成本", trigger: "blur" },
          ],
        },
      },
    };
  },
  mounted() {
    let currentOs;
    if (this.$route.query.instype_id === "2") {
      currentOs = 2;
    }
    if (this.$route.query.instype_id === "3") {
      currentOs = 1;
    }
    if (currentOs) {
      this.currentOs = currentOs;
    }
    this.getTableData();
    this.getPriceRange();
  },
  methods: {
     //排序
   handleChangeNum(val) {
      const params = {
        son_id: val.son_id,
        points: val.points
      };
      hotPhoneMode(params).then(res => {
        if (res.code === 1) {
          this.getTableData();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //列表请求
    getTableData() {
      _api
        .insTypeSonList({
          page: this.pageNum,
          limit: 10,
          type: this.currentOs,
          instype_id: this.$route.query.instype_id,
        })
        .then((res) => {
          this.tableData = res.data;
          this.totalElement = res.count;
        });
    },
    getPriceRange() {
      console.log(this.$route.query.instype_id);
      // if (this.$route.query.instype_id === "5") {
      //   _api.section().then((res) => {
      //     this.priceRange = res.data;
      //   });
      // } else if (this.$route.query.instype_id === "6") {
      //   _api.section2().then((res) => {
      //     this.priceRange = res.data;
      //   });
      // }
      _api.sectionZ({instype_id:this.$route.query.instype_id}).then(res=>{
        console.log(res,'4444444444');
        this.priceRange = res.data;
      })
    },
    changeOsType(type) {
      this.currentOs = type;
      this.pageNum = 1;
      this.getTableData();
    },
    //翻页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getTableData();
    },
    subInsuranceSetting() {
      this.$router.push({
        path: "subInsuranceSetting",
      });
    },
    dialogOsChange(value) {
      if (value === 1) {
        this.dialog.rules.is_pro[0].required = true;
        this.dialog.rules.price[0].required = false;
        this.$nextTick(() => {
          this.$refs.is_pro.clearValidate();
        });
      }
      if (value === 2) {
        this.dialog.rules.is_pro[0].required = false;
        this.dialog.rules.price[0].required = true;
        this.$nextTick(() => {
          this.$refs.price.clearValidate();
        });
      }
    },
    add() {
      this.dialog.isShow = true;
      this.dialog.row = {
        instype_id: "",
        son_name: "",
        type: "",
        years: "",
        is_pro: "",
        cost_price: "",
        price: "",
      };
      this.dialog.row.instype_id = this.$route.query.instype_id;

      this.$nextTick(() => {
        this.$refs.dialogForm.clearValidate();
        this.$refs.dialogForm.resetFields();
      });
    },
    edit(row) {
      this.dialog.isShow = true;
      this.$nextTick(() => {
        this.dialog.row = JSON.parse(JSON.stringify(row));
      });
    },
    isRequiredSwitchChange(row) {
      _api
        .openSon({
          type: 2,
          son_id: row.son_id,
          is_open: row.is_require,
        })
        .then((res) => {
          this.$message.success((res && res.msg) || "操作成功");
          this.getTableData();
        });
    },
    isOpenSwitchChange(row) {
      _api
        .openSon({
          type: 1,
          son_id: row.son_id,
          is_open: row.is_open,
        })
        .then((res) => {
          this.$message.success((res && res.msg) || "操作成功");
          this.getTableData();
        });
    },
    isjiujiChange(row) {
      console.log(row.is_jiuji);
      _api
        .openSon({
          type: 3,
          son_id: row.son_id,
          is_open: row.is_jiuji,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success((res && res.msg) || "操作成功");
            this.getTableData();
          } else {
            this.$message.warning((res && res.msg) || "操作失败");
            this.getTableData();
          }
        });
    },
    moneyInput(row, prop) {
      row[prop] = row[prop].replace("。", ".");
      let value = row[prop];
      if (value.indexOf(".") === value.length - 1) {
        return;
      }
      value = value.replace(/[^\d.]/g, "");
      value = value.replace("..", "");
      if (value) {
        this.$set(row, prop, parseFloat(parseFloat(value).toFixed(2)));
        // row[prop] = parseFloat(parseFloat(value).toFixed(2));
      } else {
        // row[prop] = ''
        this.$set(row, prop, "");
      }
    },
    confirm() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let { row } = this.dialog;
          if (row.type === 1) {
            delete this.dialog.row.price;
          }
          if (row.type === 2) {
            delete this.dialog.row.is_pro;
          }
          _api.addInsTypeSon(row).then((res) => {
            this.$message.success((res && res.msg) || "操作成功");
            this.dialog.isShow = false;
            this.getTableData();
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.top_tit {
  display: flex;
  height: 30px;
  align-items: center;
  margin-bottom: 20px;
}

.notice {
  color: #0981ff;
  flex: 1;
}

.el-select,
.el-input {
  width: 100%;
}
</style>
