<template>
  <div>
    <GlobalInfoBar :title="$route.query.model_name?'编辑机型':'新增机型'"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <div class="title inline">机型名称</div>
      <el-input v-model="model_name" placeholder="请输入机型名称，最多可输入20个字" maxlength="20" size="small"
                class="model-name" :disabled="!!$route.query.model_name||!!phoneList.length"></el-input>
      <div class="title">规格属性</div>
      <div class="property">
        <div class="prop-name">内存</div>
        <div class="props-wrapper">
          <div class="prop-item" v-for="(item) in RAMList" :key="item.ram_id">
            <span>{{ item.ram_size }}
              <el-popover
                  placement="right"
                  width="300"
                  v-model="item.isShowPopover">
                <p><span class="el-icon-warning"></span>删除后数据不可恢复，确认删除吗？</p>
                <div style="margin-top: 20px">
                  <el-button size="small" plain @click="item.isShowPopover = false">取消</el-button>
                  <el-button type="primary" size="small"
                             @click="item.isShowPopover = false;deleteProp('deleteRAM',item)">确定</el-button>
                </div>
                <span class="el-icon-close delete-prop" slot="reference"></span>
            </el-popover>
            </span>
          </div>
          <el-input placeholder="请输入" v-model="addRAM" size="medium" class="add-prop-btn" clearable>
            <template slot="append">
              <div @click="addProp('addRAM')"><span class="el-icon-plus"></span> 添加内存</div>
            </template>
          </el-input>
        </div>
      </div>
      <div class="property">
        <div class="prop-name">颜色</div>
        <div class="props-wrapper">
          <div class="prop-item" v-for="(item) in colorList" :key="item.colour_id">
            <span>{{ item.colour_name }}
                 <el-popover
                     placement="right"
                     width="300"
                     v-model="item.isShowPopover">
                  <p><span class="el-icon-warning"></span>删除后数据不可恢复，确认删除吗？</p>
                  <div style="margin-top: 20px">
                    <el-button size="small" plain @click="item.isShowPopover = false">取消</el-button>
                    <el-button type="primary" size="small"
                               @click="item.isShowPopover = false;deleteProp('deleteColor',item)">确定</el-button>
                  </div>
                  <span class="el-icon-close delete-prop" slot="reference"></span>
            </el-popover>
            </span>
          </div>
          <el-input placeholder="请输入" v-model="addColor" size="medium" class="add-prop-btn" clearable>
            <template slot="append">
              <div @click="addProp('addColor')"><span class="el-icon-plus"></span> 添加颜色</div>
            </template>
          </el-input>
        </div>
      </div>

      <div class="title inline">机型列表</div>
      <el-button @click="addPhone" style="margin-left: 20px;" type="primary" size="small"><span
          class="el-icon-plus"></span> 添加机型
      </el-button>

      <el-table
          :data="phoneList"
          style="width: 100%;margin-top: 15px">
        <el-table-column
            align="center"
            type="index"
            label="序号">
        </el-table-column>
        <el-table-column
            align="center"
            prop="model_name"
            label="机型名称">
        </el-table-column>
        <el-table-column
            align="center"
            prop="ram_size"
            label="规格">
          <template slot-scope="{row}">
            <el-select size="small" v-model="row.ram_size" placeholder="请选择规格">
              <el-option
                  v-for="item in RAMList"
                  :key="item.ram_size"
                  :label="item.ram_size"
                  :value="item.ram_size">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="colour_name"
            label="颜色">
          <template slot-scope="{row}">
            <el-select size="small" v-model="row.colour_name" placeholder="请选择颜色">
              <el-option
                  v-for="item in colorList"
                  :key="item.colour_name"
                  :label="item.colour_name"
                  :value="item.colour_name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="official_price"
            label="官网价格（元）">
          <template slot-scope="{row}">
            <el-input size="small" v-model="row.official_price" @input="moneyInput(row,'official_price')"
                      placeholder="请输入官网价格"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="price"
            label="五折焕新价格（元）">
          <template slot-scope="{row}">
            <el-input size="small" v-model="row.price" @input="moneyInput(row,'price')"
                      placeholder="请输入五折焕新价格"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="img"
            label="上传图片">
          <template slot-scope="scope">
            <el-upload
                v-if="!scope.row.img"
                action="#"
                list-type="text"
                :limit="1"
                show-file-list
                :auto-upload="false"
                :on-change="(file)=>{getFile(file,scope.row)}">
              <a href="javascript:">点击上传</a>
            </el-upload>
            <div v-else class="row-img-wrapper">
              <el-image style="width: 50px; height: 50px;" fit="contain" :src="scope.row.img"
                        :preview-src-list="[scope.row.img]"></el-image>
              <div class="delete-img"
                   @click="deleteImg(scope.$index)">删除
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-popover
                v-if="scope.row.price_id"
                placement="left"
                width="300"
                v-model="scope.row.isShowPopover">
              <p><span class="el-icon-warning"></span>删除后数据不可恢复，确认删除吗？</p>
              <div style="margin-top: 20px">
                <el-button size="small" plain @click="scope.row.isShowPopover = false">取消</el-button>
                <el-button type="primary" size="small" @click="scope.row.isShowPopover = false;deleteRow(scope.row,scope.$index)">确定</el-button>
              </div>
              <span class="el-icon-delete-solid" style="color: red;font-size: 24px" slot="reference"></span>
            </el-popover>
            <span v-else class="el-icon-delete-solid" style="color: red;font-size: 24px" @click="deleteRow(scope.row,scope.$index)"></span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button type="primary" plain size="medium" @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" size="medium" @click="confirm">确定</el-button>
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import {
  addEditPhonePrice,
  addPhoneColour,
  addPhoneRam2,
  delPhoneColour,
  delPhoneRam, imageUp, phonePriceHandle, phonePriceList,
  selectRamColour
} from "../../../utils/request/changePhone";

export default {
  name: "modelOperate",
  data() {
    return {
      model_name: '',
      RAMList: [],
      colorList: [],
      addRAM: '',
      addColor: '',
      phoneList: []
    }
  },
  mounted() {
    this.model_name = this.$route.query.model_name
    if (this.model_name) {
      this.getPhoneList()
    }
    this.getPropsList();
  },
  methods: {
    getPhoneList() {
      phonePriceList({
        page: 1,
        limit: 9999,
        model_name: this.model_name,
        type: 2
      }).then(res => {
        this.phoneList = res.data
      })
    },
    getPropsList() {
      selectRamColour().then(res => {
        this.RAMList = res.data.ram
        this.colorList = res.data.colour
      })
    },
    deleteProp(prop, item) {
      if (prop === 'deleteRAM') {
        let flag = false
        this.phoneList.forEach(p => {
          if (p.ram_size === item.ram_size) {
            flag = true
          }
        })
        if (flag) {
          this.$message.error('机型列表已使用此内存')
          return
        }
        delPhoneRam({
          ram_id: item.ram_id
        }).then(res => {
          this.getPropsList()
        }).catch(err => {
          console.error(err);
        });
      }
      if (prop === 'deleteColor') {
        let flag = false
        this.phoneList.forEach(p => {
          if (p.colour_name === item.colour_name) {
            flag = true
          }
        })
        if (flag) {
          this.$message.error('机型列表已使用此颜色')
          return
        }
        delPhoneColour({
          colour_id: item.colour_id
        }).then(res => {
          this.getPropsList()
        }).catch(err => {
          console.error(err);
        })
      }

    },
    addProp(prop) {
      if (prop === 'addColor') {
        if (!this.addColor) {
          return;
        }
        addPhoneColour({
          colour_name: this.addColor
        }).then(res => {
          this.addColor = '';
          this.getPropsList()
        }).catch(err => {
          console.error(err);
        });
      } else if (prop === 'addRAM') {
        if (!this.addRAM) {
          return;
        }
        addPhoneRam2({
          ram_size: this.addRAM
        }).then(res => {
          this.addRAM = '';
          this.getPropsList()
        }).catch(err => {
          console.error(err);
        });
      }
    },
    addPhone() {
      if (!this.model_name) {
        this.$message.error('请先填写机型名称')
        return
      }

      this.phoneList.push({
        price_id: 0,
        model_name: this.model_name,
        ram_size: '',
        colour_name: '',
        img: '',
        price: '',
        official_price: ''
      });
    },
    getFile(file, row) {
      let formData = new FormData();
      formData.append('file', file.raw)
      imageUp(formData).then(res => {
        console.log(`%c res`, 'color:red;font-size:20px', res)
        row.img = res.data.url
      })
    },
    deleteRow(row,index) {
      if (!row.price_id) {
        this.$delete(this.phoneList,index)
        return
      }
      phonePriceHandle({
        price_id: row.price_id,
        type: 2
      }).then(data => {
        this.getPhoneList()
        this.$message.success(data && data.msg || '删除成功')
      }).catch(err => {
        console.error(err)
      });
    },
    moneyInput(row, prop) {
      row[prop] = row[prop].replace('。', '.')
      let value = row[prop]
      if (value.indexOf('.') === value.length - 1) {
        return
      }
      value = value.replace(/[^\d.]/g, '');
      value = value.replace('..', '');
      if (value) {
        row[prop] = parseFloat(parseFloat(value).toFixed(2));
      } else {
        row[prop] = ''
      }
    },
    check() {
      let flag = true
      this.phoneList.forEach(item => {
        if (!item.model_name || !item.ram_size || !item.colour_name || !item.img || !item.price || !item.official_price) {
          flag = false
        }
      })
      if (!flag) {
        this.$message.error('请先填写完成机型数据')
      }
      return flag;
    },
    deleteImg(index) {
      this.phoneList[index].img = ''
    },
    confirm() {
      if (!this.check()) {
        return
      }
      addEditPhonePrice({phone: this.phoneList}).then(data => {
        this.$router.go(-1)
        this.$message.success(data && data.msg || '操作成功')
      }).catch(err => {
        console.error(err)
      });
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: bold;
  margin: 10px 0;
}

.title.inline {
  display: inline-block;
}

.model-name {
  width: 24em;
  margin-left: 20px;
  position: relative;
  top: -2px;
}

.property {
  display: flex;
}

.prop-name {
  font-weight: bold;
  margin-left: 2em;
  height: 36px;
  line-height: 36px;
  white-space: nowrap;
}

.props-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.prop-item {
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  border-radius: 5px;
  margin-left: 20px;
  background: #F2F2F2;
  margin-bottom: 10px;
  position: relative;
  color: #666;
}

.prop-item .delete-prop {
  border-radius: 20px;
  color: #fff;
  background: #e0e0e0;
  position: absolute;
  right: -5px;
  top: -5px;
  cursor: pointer;
  transition: all 0.1s;
  padding: 2px;
  font-size: 12px;
}

.prop-item .delete-prop:hover {
  transform: scale(1.2);
  background: #c9c9c9;
}

.add-prop-btn {
  width: 190px;
  position: relative;
  top: -5px;
  margin-left: 20px;
}

::v-deep .el-input-group__append, .el-input-group__prepend {
  background: #0981FF;
  color: #fff;
  padding: 0 10px;
  cursor: pointer;
  border-color: #0981FF;
}

.row-img-wrapper {
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
}

.delete-img {
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  height: 15px;
  line-height: 15px;
  width: 50px;
  font-size: 12px;
  background: #ff000050;
  color: #fff;
}
</style>
