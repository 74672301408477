var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"GlobalTable"},[_c('GlobalTable',{ref:"GlobalTable",attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"currentPage":_vm.carmIpageNum,"total":_vm.carmItotal},on:{"handleCurrentChange":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"slot":"operatAddress","label":"MID","align":"center"},slot:"operatAddress",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"send_color"},[(row.is_use==0)?_c('div',{staticClass:"color_1"},[_vm._v("未使用")]):_c('div',{staticClass:"color_2"},[_vm._v("已使用")]),_c('div',[_vm._v(" "+_vm._s(row.pool_id)+" ")])])]}}])}),_c('el-table-column',{attrs:{"slot":"operat","label":"变更后归属商家","align":"center"},slot:"operat",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.group_name)+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"card_name2","label":"变更后子险种","align":"center"},slot:"card_name2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.card_name2||'--')+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"virtually","label":"类型","align":"center"},slot:"virtually",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.virtually===1?'虚拟卡':'实体卡')+" ")])]}}])}),_c('el-table-column',{attrs:{"slot":"operating","width":"250","label":"操作","align":"center"},slot:"operating",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleBtn(row, 'edit')}}},[_vm._v("修改")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }