<template>
  <div>
    <GlobalInfoBar title="运营通道列表 / 机型设置"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询">
      </GlobalForm>
      <p style="color: #8080FF;font-size: 14px;margin-bottom: 20px">说明:在输入框内输入新机采购成本，点击提交完成采购价格设置</p>
      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">

        <el-table-column label="官网价格" slot="official_price" align="center">
          <template slot-scope="{ row }">
            <el-input style="width: 120px;text-align: center" v-model="row.official_price" @change="setChange(row)"
                      @input="moneyInput(row,'official_price')"
                      placeholder="请输入官网价格" size="mini"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="采购成本" slot="cost_price" align="center">
          <template slot-scope="{ row }">
            <el-input style="width: 120px;text-align: center" v-model="row.cost_price" @change="setChange(row)"
                      @input="moneyInput(row,'cost_price')"
                      placeholder="请输入新机价格" size="mini"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="5折价格" slot="price" align="center">
          <template slot-scope="{ row }">
            <el-input style="width: 120px;text-align: center" v-model="row.price" @change="setChange(row)"
                      @input="moneyInput(row,'price')"
                      placeholder="请输入5折价格" size="mini"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="库存" slot="stock" align="center">
          <template slot-scope="{ row }">
            <el-input style="width: 120px;text-align: center" v-model="row.stock" @change="setChange(row)" @input="stockInput(row,'stock')"
                      placeholder="请输入新机库存" size="mini"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="最后修改时间" slot="add_time" align="center">
          <template slot-scope="{ row }">
            {{ row.add_time || '--' }}
          </template>
        </el-table-column>

        <el-table-column label="修改人" slot="admin" align="center">
          <template slot-scope="{ row }">
            {{ row.admin || '--' }}
          </template>
        </el-table-column>
      </GlobalTable>
      <div style="margin-top: 20px">
        <el-button type="primary" plain size="medium" @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" size="medium" @click="confirm">修改</el-button>
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import {addPhoneModel2, editPassagewayPhone, passagewayPhone} from "../../../utils/request/changePhone";

export default {
  name: "newPhoneBuyPrice",
  data() {
    return {
      formItemList: [{
        key: 'model_name',
        idKey: 'model_name',
        labelKey: 'model_name',
        type: 'selectFilterable',
        labelName: '机型名称:',
        option: [],
        placeholder: '请输入机型名称查询'
      },
      ],
      tableColumns: [
        {label: "机型名称", prop: "model_name"},
        {label: "规格", prop: "ram_size"},
        {slotName: "official_price"},
        {slotName: "price"},
        {slotName: "cost_price"},
        {slotName: "stock"},
        {slotName: "add_time"},
        {slotName: "admin"}

      ],
      tableData: [],
      model_name: '',
      curPage: 1,
      totalElements: 0,
      totalData: {}
    }
  },
  mounted() {
    this.getTableData()
    this.getModelList()
  },
  methods: {
    /*{
        pass_id:this.$route.query.pass_id,
        page:1,
        limit:99999,
        model_name:this.model_name
      }*/
    getModelList() {
      addPhoneModel2().then(res => {
        this.formItemList[0].option = res.data || []
      })
    },
    query(data) {
      this.model_name = data.model_name
      this.curPage = 1
      this.getTableData()
    },
    getTableData() {
      passagewayPhone({
        pass_id: this.$route.query.pass_id,
        page: this.curPage,
        limit: 10,
        model_name: this.model_name
      }).then(res => {
        this.tableData = res.data || [];
        this.totalElements = res.count
      })
    },
    currentChange(page) {
      this.totalData[this.curPage] = this.tableData;
      this.curPage = page

      if (this.totalData[page]) {
        this.tableData = this.totalData[page];
      } else {
        this.getTableData()
      }
    },
    moneyInput(row, prop) {
      row[prop] = row[prop].replace('。', '.')
      let value = row[prop]
      if (value.indexOf('.') === value.length - 1) {
        return
      }
      value = value.replace(/[^\d.]/g, '');
      value = value.replace('..', '');
      if (value) {
        this.$set(row, prop, parseFloat(parseFloat(value).toFixed(2)))
        // row[prop] = parseFloat(parseFloat(value).toFixed(2));
      } else {
        // row[prop] = ''
        this.$set(row, prop, '')
      }
    },
    setChange(row) {
      this.$set(row, 'changed', true)
    },
    stockInput(row, prop) {
      let value = row[prop]
      if (value) {
        if (isNaN(value)) {
          this.$set(row, prop, '');
        } else {
          this.$set(row, prop, parseInt(value.replace(/[^0-9]/g, '')));
        }
      }

    },
    confirm() {
      let list = [];
      Object.keys(this.totalData).forEach(key => {
        list = list.concat(...this.totalData[key])
      })
      list = list.concat(...this.tableData)

      list = list.filter(item => {
        return item.changed
      })
      list.forEach(item => {
        delete item.changed
      })
      console.log(list)

      editPassagewayPhone(list).then(data => {
        // this.$router.go(-1)
        this.$message.success(data && data.msg || '操作成功')
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
