<template>
  <div class="planNum_list">
    <GlobalInfoBar title="机型列表" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="品牌名称:" slot="brand">
        <el-select
          v-model="brand_id"
          placeholder="请品牌名称"
          clearable
          filterable
          @change="handleChange"
        >
          <el-option
            v-for="item in brandList"
            :key="item.brand_id"
            :label="item.brand"
            :value="item.brand_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="是否已下架:"
        slot="diff"
        label-width="100px!important"
      >
        <el-select
          v-model="diff"
          placeholder="请选择"
          clearable
          filterable
          @change="handleChange2"
        >
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="是否是pro版本:"
        slot="ispro"
        label-width="120px!important"
      >
        <el-select
          v-model="ispro"
          placeholder="请选择"
          clearable
          filterable
          @change="handleChange3"
        >
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <!--      <div slot="AddSlot">-->
      <!--        <el-button size="small" @click="addPlan()" type="primary" icon="el-icon-plus">新增机型</el-button>-->
      <!--      </div>-->
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="pageNum"
      :total="totalElement"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="下架/上架" slot="diff" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.diff"
            :active-value="0"
            :inactive-value="1"
            @change="switchChange('diff', row)"
          ></el-switch>
        </template>
      </el-table-column>
      <!-- 是否折叠屏 -->
      <el-table-column label="是否折叠屏" slot="diff" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.fold"
            :active-value="1"
            :inactive-value="0"
            @change="switchChange('fold', row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否pro版本" slot="ispro" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.ispro"
            :active-value="1"
            :inactive-value="0"
            @change="switchChange('ispro', row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="热门度" slot="hot" align="center">
        <template slot-scope="{ row }">
          <el-input-number
            v-model="row.hot"
            :min="1"
            :step="1"
            @change="hotbtn(row)"
            step-strictly
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editPlan(row, 'edit')" type="text" class="mr_sty"
            >修改</el-button
          >
          <el-button @click="deleteDis(row)" type="text" class="btn_delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :visible.sync="visible" :title="title" width="600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="机型名称" prop="ram_size">
          <el-input
            v-model="ruleForm.model_name"
            placeholder="请输入机型名称"
            :maxlength="25"
          ></el-input>
        </el-form-item>

        <el-form-item class="el_form_r">
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 'add')"
            v-if="this.title === '新增机型'"
            >确认新增</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm', 'edit')"
            v-else
            >确认修改</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "planNum_list",
  data() {
    return {
      //弹窗参数
      visible: false,
      title: "",
      ruleForm: {
        model_id: "", //	是	int	0新增 大于0：修改
        model_name: "", //机型名称
      },
      rules: {
        model_name: [
          { required: true, message: "请填写机型名称", trigger: "blur" },
        ],
      },
      //列表
      initData: null,
      end_time: "",
      beg_time: "",
      formItemList: [
        { slotName: "brand" },
        {
          key: "model_name",
          type: "input",
          labelName: "机型名称:",
          placeholder: "请输入机型名称",
        },
        { slotName: "diff" },
        { slotName: "ispro" },
      ],
      formRules: {},
      tableData: [],
      totalElement: 0,
      pageNum: 1,
      pageSize: 10,
      model_name: "",
      brand_id: "",
      diff: "",
      ispro: "",
      tableColumns: [
        { label: "品牌名称", prop: "brand" },
        { label: "机型名称", prop: "model_name" },
        { slotName: "diff" },
        { slotName: "ispro" },
        { slotName: "hot" },
        { slotName: "operat" },
      ],
      brandList: [],
    };
  },
  mounted() {
    this.PostBrandList();
    this.getBrandList();
  },
  methods: {
    hotbtn(row) {
      _api.hot({ model_id: row.model_id, hot: row.hot }).then((res) => {
        this.PostBrandList();
      });
    },
    //列表请求
    PostBrandList() {
      const params = {
        page: this.pageNum,
        limit: this.pageSize,
        brand_id: this.brand_id,
        model_name: this.model_name,
        diff: this.diff,
        ispro: this.ispro,
      };
      _api.phoneModelList(params).then((res) => {
        this.tableData = res.data;
        this.totalElement = res.count;
      });
    },
    getBrandList() {
      _api
        .phoneBrandList({
          page: 1,
          limit: 99999,
        })
        .then((res) => {
          this.brandList = res.data || [];
        });
    },
    handleChange(val) {
      console.log(val);
      this.brand_id = val;
    },
    handleChange2(val) {
      console.log(val);
      this.diff = val;
    },
    handleChange3(val) {
      console.log(val);
      this.ispro = val;
    },
    //翻页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.PostBrandList();
    },
    //搜索
    handleConfirm(data) {
      this.pageNum = 1;
      console.log(data, "data");
      this.model_name = data.model_name;
      const params = {
        page: this.pageNum,
        limit: this.pageSize,
        brand_id: this.brand_id,
        model_name: this.model_name,
        diff: this.diff,
        ispro: this.ispro,
      };
      _api.phoneModelList(params).then((res) => {
        this.tableData = res.data;
        this.totalElement = res.count;
      });
    },
    addPlan() {
      this.ruleForm.model_name = "";
      this.visible = true;
      this.title = "新增机型";
    },
    //删除
    deleteDis(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _api.delPhoneMode({ model_id: row.model_id }).then((res) => {
            if (res.code === 1) {
              this.$message.success("删除成功");
              this.PostBrandList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });
        });
    },
    //修改
    editPlan(row, type) {
      this.visible = true;
      this.title = "修改机型";
      this.ruleForm.model_id = row.model_id;
      this.ruleForm.model_name = row.model_name;
    },
    switchChange(type, row) {
      _api
        .phoneModeOpen({
          model_id: row.model_id,
          field: type,
          value: type === "diff" ? row.diff : type === "ispro"? row.ispro:row.fold,
        })
        .then((res) => {
          this.$message.success((res && res.msg) || "操作成功");
          this.PostBrandList();
        })
        .catch((err) => {
          this.$message.error((err && err.msg) || "操作失败");
        });
    },
    submitForm(formName, type) {
      if (type === "add") {
        this.ruleForm.ram_id = 0;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.addPhoneMode(this.ruleForm).then((res) => {
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.PostBrandList();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  /deep/ .el-form-item__label {
    width: 80px !important;
  }
  .btn_delete {
    color: #ff687b;
  }
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r {
    float: right;
    margin-right: 80px;
  }
}
</style>
