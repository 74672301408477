import Layout from '@/layout/layout'

const DemoVideoRouter = {
  path: '/Distributor',
  component: Layout,
  redirect: 'noRedirect',
  name: '分销商管理',
  children: [
    {
      path:'list',
      component: () => import('@/views/Distributor/list/index'),
      name: '分销商列表',
      meta: { title: '分销商列表', noCache: true }
    },

  ]
}

export default DemoVideoRouter
