<template>
  <div class="planNum_list">
    <GlobalInfoBar title="抽奖配置" />
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :maxHeight="800"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      :isPagination="false"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="奖品图片" slot="gift_img" align="center">
        <template slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.gift_img"
            :preview-src-list="[row.gift_img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="价值" slot="money" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.money || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="核销状态" slot="type_title" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.type_title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="small"
            @click="editPlan(row, 'edit')"
            class="mr_sty"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog
      :visible.sync="visible"
      @closed="resetForm('ruleForm')"
      title="操作提示"
      width="600px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="奖品图片" prop="gift_img">
          <ElImgUpload
            key="gift_img"
            :exist-image="ruleForm.gift_img"
            @handleDelete="handleDelete('gift_img')"
            @handleChange="handleChange"
          />
          <span style="color: #999; margin-left: 20px">
            请上传1:1的图片，大小不超过1M
          </span>
        </el-form-item>
        <el-form-item class="el_form" label="奖品名称" prop="prize_name">
          <el-input
            v-model="ruleForm.prize_name"
            placeholder="请输入奖品名称"
            :maxlength="6"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="奖品数量" prop="prize_num">
          <el-input
            v-model="ruleForm.prize_num"
            placeholder="请输入奖品数量"
            :maxlength="6"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="奖品价值" prop="money">
          <el-input
            v-model="ruleForm.money"
            placeholder="请输入奖品价值"
            :maxlength="6"
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form" label="中奖比例" prop="rate">
          <el-input
            v-model="ruleForm.rate"
            placeholder="请输入中奖比例"
            :maxlength="6"
            ></el-input
          >
        </el-form-item>
        <el-form-item label="奖品类型" class="el_form" prop="type">
          <el-select
            clearable
            v-model="ruleForm.type"
            placeholder="请选择奖品类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_form_r">
          <el-button type="primary" @click="submitForm('ruleForm', 'add')"
            >确认</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import {
  prizeList,
  prizeEdit,
  prizeType,
  prizeInfo,
} from "../../../utils/request/users";
import _api from "@/utils/request";
export default {
  name: "planNum_list",
  components: { ElImgUpload },
  data() {
    return {
      //弹窗参数
      visible: false,
      ruleForm: {
        gift_img: "",
        prize_name: "",
        prize_num: "",
        money: "",
        rate: "",
        type: "",
      },
      rules: {
        gift_img: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
        prize_name: [
          { required: true, message: "请输入奖品名称", trigger: "blur" },
        ],
        prize_num: [
          { required: true, message: "请输入奖品数量", trigger: "blur" },
        ],
        money: [{ required: true, message: "请输入奖品价值", trigger: "blur" }],
        rate: [{ required: true, message: "请输入中奖比例", trigger: "blur" }],
        type: [
          { required: true, message: "请选择奖品类型", trigger: "change" },
        ],
      },
      //列表
      GroupList: [
        {
          name: "今日",
          id: 1,
        },
        {
          name: "本月",
          id: 2,
        },
        {
          name: "今年",
          id: 3,
        },
      ],
      initData: null,
      formItemList: [
        {
          key: "phone",
          type: "input",
          labelName: "手机号:",
          placeholder: "请输入手机号查询",
        },
        { slotName: "is_push_state_time" },
      ],
      stateTime: null,
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      mold: 2,
      tableColumns: [
        { slotName: "gift_img" },
        { label: "奖品名称", prop: "prize_name" },
        { label: "奖品数量", prop: "prize_num" },
        { label: "中奖比例", prop: "rate" },
        { slotName: "money" },
        { slotName: "type_title" },
        { slotName: "operat" },
      ],
      log_id: "",
      typeList: [],
    };
  },
  mounted() {
    this.PostPlanList();
  },
  methods: {
    handleDelete(val) {
      console.log(val);
      this.ruleForm.gift_img = "";
    },
    // 图标上传回调
    handleChange(e) {
      this.ruleForm.gift_img = e;
    },
    //列表请求
    PostPlanList() {
      prizeList().then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostPlanList();
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      prizeList().then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //发红包
    editPlan(row) {
      prizeInfo({ id: row.id }).then((res) => {
        this.visible = true;
        this.ruleForm = res.data;
      });
      prizeType().then((res) => {
        this.typeList = res.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          prizeEdit(this.ruleForm).then((res) => {
            console.log(res);
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.resetForm("ruleForm");
              this.PostPlanList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm = {};
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  /deep/ .el-form-item__label {
    width: 100px !important;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r {
    float: right;
    margin-right: 80px;
  }
}
/deep/.el-textarea {
  width: 80%;
}
.icon {
  cursor: pointer;
  margin-right: 20px;
}
</style>
