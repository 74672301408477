import Layout from '@/layout/layout'

const vivoZoneRouter = {
    path: '/vivoZone',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Vivo专区',
    children: [
        {
            path:'acceptList',
            component: () => import('@/views/vivoZone/acceptList'),
            name: '承保单列表',
            meta: { title: '承保单列表', noCache: true }
        },
        {
            path:'acceptDetail/:acceptId',
            props: true,
            component: () => import('@/views/vivoZone/acceptDetail'),
            name: '承保单详情',
            meta: { title: '承保单详情', noCache: true }
        },
        {
            path:'reportList',
            component: () => import('@/views/vivoZone/reportList'),
            name: '报案理赔列表',
            meta: { title: '报案理赔列表', noCache: true }
        },
        {
            path:'reportDetail/:reportId',
            props: true,
            component: () => import('@/views/vivoZone/reportListDetail'),
            name: '报案理赔详情',
            meta: { title: '报案理赔详情', noCache: true }
        },
        {
            path:'renewal',
            component: () => import('@/views/vivoZone/renewal'),
            name: '缴费信息',
            meta: { title: '缴费信息', noCache: true }
        },
        {
            path:'endorse',
            component: () => import('@/views/vivoZone/endorse'),
            name: '批改信息',
            meta: { title: '批改信息', noCache: true }
        },
        {
            path:'surrender',
            component: () => import('@/views/vivoZone/surrender'),
            name: '退保信息',
            meta: { title: '退保信息', noCache: true }
        },
        {
            path:'vivoReport',
            component: () => import('@/views/vivoZone/vivoReport'),
            name: 'vivo报案',
            meta: { title: 'vivo报案', noCache: true }
        },
        {
            path:'vivoClaim',
            component: () => import('@/views/vivoZone/vivoClaim'),
            name: 'vivo理赔',
            meta: { title: 'vivo理赔', noCache: true }
        },
    ]
}

export default vivoZoneRouter
