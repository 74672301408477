import Layout from '@/layout/layout'

const DemoVideoRouter = {
  path: '/Carmi',
  component: Layout,
  redirect: 'noRedirect',
  name: '卡密管理',
  children: [
    {
      path:'list',
      component: () => import('@/views/Carmi/list/index'),
      name: '卡密列表',
      meta: { title: '卡密列表', noCache: true }
    },
    {
      path:'generate',
      component: () => import('@/views/Carmi/generat/index'),
      name: '生成卡密',
      meta: { title: '生成卡密', noCache: true }
    },
    {
        path:'inquire',
        component: () => import('@/views/Carmi/inquire/index'),
        name: '卡密查询',
        meta: { title: '卡密查询', noCache: true }
    },
    {
      path:'singleEdit',
      component: () => import('@/views/Carmi/inquire/edit'),
      name: '卡密修改',
      meta: { title: '卡密修改', noCache: true }
    },
    {
        path:'edit',
        component: () => import('@/views/Carmi/edit/index'),
        name: '批量修改',
        meta: { title: '批量修改', noCache: true }
    },

  ]
}

export default DemoVideoRouter
