<template>
  <div class="account">
    <GlobalInfoBar
        title="打款回调日志"
    />
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :init-data="initData"
          :form-rules="formRules"
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      >
        <!--        <div slot="AddSlot">-->
        <!--        </div>-->
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
      </GlobalTable>
    </GlobalChunk>

  </div>
</template>

<script>
import api from '@/utils/request'
export default {
  name: "account",
  data() {
    return {
      initData: {
        sn:''
      },
      formRules: {},
      formItemList: [
        {
          key: 'sn',
          type: 'input',
          labelName: '订单编码:',
          placeholder: '请输入订单编码查询'
        }
      ],
      sn: '',
      tableColumns: [
        {label: "理赔用户姓名", prop: "name"},
        {label: "报案申请号（理赔单编码）", prop: "claimId"},
        {label: "保单号", prop: "policyNo"},
        {label: "打款金额", prop: "payMoney"},
        {label: "打款时间", prop: "payTime"},
        {label: "收款账户名称", prop: "payName"},
        {label: "收款账户", prop: "payAccount"},
        {label: "日志时间", prop: "add_time"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      pageSize: 10,
    }
  },
  mounted() {
    this.sn=this.$route.query.id
    this.initData.sn=this.$route.query.id
    this.getList()
  },
  methods: {
    query(data, cd) {
      this.sn = data.sn
      this.curPage = 1
      this.getList()
    },
    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    getList() {
      api.lifePayCallbackLog({
        page: this.curPage,
        limit: this.pageSize,
        sn: this.sn,
      }).then(res => {
        this.tableData = res.data
        this.totalElements = res.count || 0
      })
    },
  }
}
</script>

<style scoped lang="scss">
.account{
  /deep/ .el-form-item__label{
    width: 80px!important;
  }
}

</style>
