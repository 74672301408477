import request from './request'

export function couponPhoneList(params){
    return request({
        method:'POST',
        url:'/Coupon/phoneList',
        data:params,
    })
}

export function couponPhoneOpen(params){
    return request({
        method:'POST',
        url:'/Coupon/phoneOpen',
        data:params,
    })
}

export function couponPhoneRam(params){
    return request({
        method:'POST',
        url:'/Coupon/phoneRam',
        data:params,
    })
}

export function addCouponPhonePrice(params){
    return request({
        method:'POST',
        url:'/Coupon/addPhonePrice',
        data:params,
    })
}

export function couponPhonePriceInfo(params){
    return request({
        method:'POST',
        url:'/Coupon/phonePriceInfo',
        data:params,
    })
}

export function couponList(params){
    return request({
        method:'POST',
        url:'/Coupon/couponList',
        data:params,
    })
}

export function editCoupon(params){
    return request({
        method:'POST',
        url:'/Coupon/editCoupon',
        data:params,
    })
}

export function couponOrderlist(params){
    return request({
        method:'POST',
        url:'/Coupon/orderlist',
        data:params,
    })
}

export function couponOrderInfo(params){
    return request({
        method:'POST',
        url:'/Coupon/couponOrderInfo',
        data:params,
    })
}

export function couponOrderExamine(params){
    return request({
        method:'POST',
        url:'/Coupon/couponOrderExamine',
        data:params,
    })
}

export function exportOrder(params) {
    return request({
        method: 'GET',
        url: '/Coupon/orderlist',
        params: params,
        responseType: 'blob'
    })
}



