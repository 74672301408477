<template>
  <el-pagination
      v-bind="$attrs" v-on="$listeners"
      size="mini"
      background
      :page-sizes="[1,5, 10, 20, 50,100,200,500]"
      popper-class="el-pagination-popover">
  </el-pagination>
</template>

<script>
export default {
  name: "CyberpunkPagination"
}
</script>

<style scoped lang="scss">
::v-deep .el-input__inner {
  background: #1B4169;
  border-color: #58DBFF;
  box-shadow: inset 0 0 4px #58DBFF;
}

::v-deep .el-select .el-input__inner:focus {
  border-color: #58DBFF;
}

::v-deep * {
  color: #58DBFF;

  &::before {
    color: #58DBFF;
  }

  &::after {
    color: #58DBFF;
  }
}

::v-deep .btn-prev,
::v-deep .btn-next {
  background: #1B4169 !important;
  border-color: #58DBFF;
  color: #58DBFF;
  box-shadow: inset 0 0 4px #58DBFF;

  &[disabled="disabled"] {
    box-shadow: none;

    .el-icon-arrow-left:before {
      color: #337b91;
    }

    .el-icon-arrow-right:before {
      color: #337b91;
    }
  }
}

::v-deep .number,
::v-deep .more{
  background: #1B4169 !important;
  border-color: #58DBFF;
  color: #58DBFF !important;
  box-shadow: inset 0 0 4px #58DBFF;

  &.active {
    color: #fff !important;
    box-shadow: inset 0 0 20px #58DBFF;
  }
}
</style>

