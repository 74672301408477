<template>
  <div class="account">
    <GlobalInfoBar title="承保推送" />
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <GlobalForm
        :init-data="formFilterData"
        :form-rules="formRules"
        :form-item-list="formItemList"
        :inline="true"
        round
        @handleConfirm="query"
        confirmBtnName="查询"
      >
        <div slot="AddSlot">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-folder-opened"
            @click="dataOutput"
            >数据导出</el-button
          >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-download"
            @click="templateDownload"
            >承保推送模板下载</el-button
          >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="newCoveragePush"
            >新建承保推送</el-button
          >
        </div>
      </GlobalForm>

      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns"
        :data="tableData"
        :currentPage="curPage"
        :total="totalElements"
        @handleCurrentChange="currentChange"
      >
        <el-table-column
          width="150"
          header-align="center"
          align="center"
          prop="instype_id"
          slot="instype_id"
          label="产品类型"
        >
          <template slot-scope="{ row }">
            {{ getInsuranceNameById(row.instype_id) }}
          </template>
        </el-table-column>

        <el-table-column
          width="150"
          header-align="center"
          align="center"
          prop="order_sn"
          slot="order_sn"
          label="订单号"
        >
          <template slot-scope="{ row }">
            {{ row.order_sn }}
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          header-align="center"
          align="center"
          prop="push_back"
          slot="push_back"
          label="订单号"
        >
          <template slot-scope="{ row }">
            <el-tooltip :content="row.push_back" placement="top">
              <el-button
                style="border: none;
                      background: none;
                      width: 100%;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;"
                >{{ row.push_back }}</el-button
              >
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="push_state"
          slot="push_state"
          label="推送状态"
        >
          <template slot-scope="{ row }">
            <span :style="{ color: ['#F56C6C', '#67C23A'][row.push_state] }">{{
              ["失败", "成功"][row.push_state]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="mode"
          slot="mode"
          label="处理方式"
        >
          <template slot-scope="{ row }">
            {{ ["--", "系统处理 ", "线下处理"][row.mode] }}
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          slot="operation"
          align="center"
          fixed="right"
          width="280"
        >
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="remark(row)"
              >{{ row.remark ? "查看" : "添加" }}备注</el-button
            >
            <el-button
              style="margin-left: 10px"
              type="danger"
              size="mini"
              @click="rePush(row)"
              v-if="row.push_state !== 1"
              >重推</el-button
            >
            <el-button type="warning" size="mini" @click="offlineOperation(row)"
              >线下处理</el-button
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <el-dialog
      :title="remarkDialog.type"
      :visible.sync="remarkDialog.isShow"
      width="400px"
    >
      <el-input
        v-model="remarkDialog.remark"
        placeholder="请填写备注"
        type="textarea"
      ></el-input>
      <div style="margin: 20px 0;float: right">
        <el-button type="plain" size="mini" @click="remarkDialog.isShow = false"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="remarkConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="线下处理"
      :visible.sync="offlineOperationDialog.isShow"
      width="400px"
    >
      <p style="margin-bottom: 20px;font-weight: bold">
        说明:
        如无法完成推送，请联系人保处理，处理完成后请填写对应保单号，填写完成系统将修改推送状态为‘成功’
      </p>
      保单号：
      <el-input
        size="mini"
        style="width: 300px"
        v-model="offlineOperationDialog.policyNo"
        placeholder="必填"
      ></el-input>
      <div style="margin: 20px 0;float: right">
        <el-button
          type="plain"
          size="mini"
          @click="offlineOperationDialog.isShow = false"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="offlineOperationConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  acceptList,
  acceptList2,
  acceptOffline,
  businessExce,
  excelinsRemarks,
  pullInsTypeList,
  rePush,
} from "../../utils/request";

export default {
  name: "coveredPush",
  data() {
    return {
      formFilterData: {},
      formRules: {},
      formItemList: [
        {
          key: "imei",
          type: "input",
          labelName: "IMEI号:",
          placeholder: "请输入IMEI号查询",
        },
        {
          key: "planCode",
          type: "input",
          labelName: "子方案号:",
          placeholder: "请输入子方案号查询",
        },
        {
          key: "policyNo",
          type: "input",
          labelName: "保单号:",
          placeholder: "请输入保单号查询",
        },
        {
          key: "title",
          type: "input",
          labelName: "表单名称:",
          placeholder: "请输入表单名称",
        },
        {
          key: "instype_id",
          idKey: "instype_id",
          labelKey: "instype_name",
          type: "select",
          labelName: "产品类型:",
          option: [],
          placeholder: "请选择产品类型",
        },
        {
          key: "group_name",
          idKey: "name",
          labelKey: "name",
          type: "selectFilterable",
          labelName: "注册商户:",
          option: [],
          placeholder: "请选择注册商户",
        },
        {
          key: "push_state",
          idKey: "id",
          labelKey: "name",
          type: "select",
          labelName: "推送状态:",
          option: [
            { name: "成功", id: 1 },
            { name: "失败", id: 0 },
          ],
          placeholder: "请选择推送状态",
        },
        {
          key: "mode",
          idKey: "id",
          labelKey: "name",
          type: "select",
          labelName: "处理方式:",
          option: [
            { name: "系统处理", id: 1 },
            { name: "线下处理", id: 2 },
          ],
          placeholder: "请选择处理方式",
        },
        {
          key: "is_push_state_time",
          labelName: "推送时间:",
          placeholder: "请选择推送时间",
          type: "daterange",
          valueFormat: "timestamp",
        },
      ],
      tableColumns: [
        { label: "表单名称", prop: "title", width: 150 },
        { slotName: "instype_id" },
        { slotName: "order_sn" },
        { label: "IMEI号", prop: "imei", width: 150 },
        { label: "子方案号", prop: "planCode", width: 200 },
        { label: "注册商户", prop: "group_name", width: 200 },
        { label: "人保价格", prop: "cost_price" },
        { slotName: "push_state" },
        { label: "保单号", prop: "policyNo", width: 200 },
        { slotName: "push_back" },
        // { label: "返回信息", prop: "push_back", width: 200 },
        { label: "推送时间", prop: "push_time", width: 150 },
        { slotName: "mode" },
        { slotName: "operation" },
      ],
      is_push_state_time: [],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      pageSize: 10,
      imei: "",
      planCode: "",
      policyNo: "",
      title: "",
      instype_id: "",
      group_name: "",
      push_state: "",
      mode: "",
      download: "",
      template: "",
      beg_time: "",
      end_time: "",

      insuranceTypeList: [],
      remarkDialog: {
        isShow: false,
        remark: "",
        type: "",
        excel_order_id: "",
      },
      offlineOperationDialog: {
        isShow: false,
        policyNo: "",
        id: "",
      },
      childWindow: null,
    };
  },
  mounted() {
    this.getTableData();
    this.getInsuranceTypeList();
    this.getBusinessList();
  },
  computed: {
    getInsuranceNameById() {
      return (id) => {
        let name = this.insuranceTypeList.find((item) => {
          return item.instype_id === id;
        });
        return name ? name.instype_name : "--";
      };
    },
  },
  methods: {
    query(data, cb) {
      this.imei = data.imei || "";
      this.planCode = data.planCode || "";
      this.policyNo = data.policyNo || "";
      this.title = data.title || "";
      this.instype_id = data.instype_id || "";
      this.group_name = data.group_name || "";
      this.push_state =
        typeof data.push_state === "undefined" ? "" : data.push_state;
      this.mode = data.mode || "";
      this.is_push_state_time = data.is_push_state_time;
      // this.download = data.download || ''
      // this.template = data.template || ''

      this.curPage = 1;
      this.getTableData();
    },
    currentChange(page) {
      this.curPage = page;
      this.getTableData();
    },
    getTableData() {
      this.acceptList().then((res) => {
        this.tableData = (res && res.data) || [];
        this.totalElements = res.count || 0;
      });
    },
    acceptList(isDownload = false) {
      let params = {
        page: isDownload ? 1 : this.curPage,
        limit: isDownload ? 99999 : this.pageSize,
        imei: this.imei,
        planCode: this.planCode,
        policyNo: this.policyNo,
        title: this.title,
        instype_id: this.instype_id,
        group_name: this.group_name,
        push_state: this.push_state,
        mode: this.mode,
        download: isDownload ? 1 : 0,
        template: this.template,
        beg_time: (this.is_push_state_time && this.is_push_state_time[0]) || "",
        end_time: (this.is_push_state_time && this.is_push_state_time[1]) || "",
      };
      return isDownload ? acceptList2(params) : acceptList(params);
    },
    remark(row) {
      this.remarkDialog.isShow = true;
      this.remarkDialog.remark = row.remark;
      this.remarkDialog.type = row.remark ? "查看备注" : "新增备注";
      this.remarkDialog.excel_order_id = row.excel_order_id;
    },
    remarkConfirm() {
      excelinsRemarks({
        id: this.remarkDialog.excel_order_id,
        type: 1,
        remark: this.remarkDialog.remark,
      }).then((res) => {
        this.remarkDialog.isShow = false;
        this.$message.success((res && res.msg) || "操作成功");
        this.getTableData();
      });
    },
    getInsuranceTypeList() {
      pullInsTypeList().then((res) => {
        let list = (res && res.data) || [];
        this.formItemList[4].option = list;
        this.insuranceTypeList = list;
      });
    },
    getBusinessList() {
      businessExce().then((res) => {
        let list = (res && res.data) || [];
        this.formItemList[5].option = list.map((item, index) => {
          return { id: index, name: item };
        });
      });
    },
    dataOutput() {
      this.imei = this.formFilterData.imei || "";
      this.planCode = this.formFilterData.planCode || "";
      this.policyNo = this.formFilterData.policyNo || "";
      this.title = this.formFilterData.title || "";
      this.instype_id = this.formFilterData.instype_id || "";
      this.group_name = this.formFilterData.group_name || "";
      this.push_state =
        typeof this.formFilterData.push_state === "undefined"
          ? ""
          : this.formFilterData.push_state;
      this.mode = this.formFilterData.mode || "";
      this.beg_time =
        (this.is_push_state_time && this.is_push_state_time[0]) || "";
      this.end_time =
        (this.is_push_state_time && this.is_push_state_time[1]) || "";
      this.template = 0;
      this.acceptList(true).then((res) => {
        this.downloadFile(res, `承保推送_${+new Date()}.xls`);
      });
    },
    templateDownload() {
      this.template = 1;
      this.acceptList(true).then((res) => {
        this.downloadFile(res, `承保推送模板_${+new Date()}.xls`);
      });
    },
    newCoveragePush() {
      let height = 600;
      let width = 800;
      let top = (window.screen.height - height) / 2;
      let left = (window.screen.width - width) / 2;
      let url = this.$router.resolve({
        path: "/pushDialog",
        query: {
          type: 1,
        },
      }).href;
      this.childWindow = window.open(
        url,
        "push",
        `height=${height}, width=${width}, top=${top}, left=${left}, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no`
      );

      // this.childWindow.close()
    },
    downloadFile(res, fileName) {
      if (res.type === "application/json") {
        let fileReader = new FileReader();
        fileReader.readAsText(res);
        fileReader.addEventListener("loadend", (e) => {
          let err = JSON.parse(fileReader.result || "");
          this.$message.error(err.msg || "导出失败");
        });
      } else {
        var blob = new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        }); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = fileName; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      }
    },
    rePush(row) {
      this.$confirm(
        "重新推送建议间隔30分钟后在点击重推，若重推再次失败，请联系人保线下处理",
        "重新推送",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        rePush({
          id: row.excel_order_id,
          type: 1,
        })
          .then((res) => {
            this.getTableData();
            this.$message.success((res && res.msg) || "推送成功");
          })
          .catch((err) => {
            this.$confirm(
              (err && err.data && err.data.message) || "推送失败，请稍后再试",
              "失败原因",
              {
                type: "warning",
                showCancelButton: false,
              }
            );
          });
      });
    },
    offlineOperation(row) {
      this.offlineOperationDialog.isShow = true;
      this.offlineOperationDialog.policyNo = row.policyNo;
      this.offlineOperationDialog.id = row.excel_order_id;
    },
    offlineOperationConfirm() {
      if (!this.offlineOperationDialog.policyNo) {
        this.$message.error("请填写保单号");
        return;
      }
      acceptOffline({
        id: this.offlineOperationDialog.id,
        policyNo: this.offlineOperationDialog.policyNo,
      }).then((res) => {
        this.getTableData();
        this.offlineOperationDialog.isShow = false;
        this.$message.success((res && res.msg) || "操作成功");
      });
    },
    confirm() {},
  },
};
</script>

<style scoped lang="scss">
.account {
  /deep/ .el-form-item__label {
    width: 80px !important;
  }
}

.dialog-notice {
  color: red;
  margin-bottom: 20px;
}

.dialog-form {
  .el-input,
  .el-select {
    width: 300px;
  }

  .prompt {
    margin-left: 20px;
    color: #999;
  }
}

.dialog-btns {
  margin: 20px 0;
  float: right;

  .el-button {
    margin-left: 20px;
  }
}
</style>
