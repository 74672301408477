<template>
  <div>
    <GlobalInfoBar title="维修商列表"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      >
        <div slot="AddSlot">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addPartner">新增维修商</el-button>
        </div>
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="门店数量" slot="shop_num" align="center">
          <template slot-scope="{ row }">

            <el-popover
                placement="right"
                width="700"
                @show="storePopoverShow(row)"
                trigger="click">
              <GlobalForm
                  :form-item-list="storePopover.formItemList"
                  :inline="true"
                  round
                  @handleConfirm="storeQuery"
                  confirmBtnName="查询"
              ></GlobalForm>
              <span class="popover-sore-num">门店数量:<span>{{ row.shop_num }}</span></span>
              <GlobalTable
                  :columns="storePopover.tableColumns"
                  :data="storePopover.tableData"
                  :currentPage="storePopover.curPage"
                  :total="storePopover.totalElements"
                  :page-size="5"
                  @handleCurrentChange="storeCurrentChange">
              </GlobalTable>
              <a href="javascript:" slot="reference" :class="{'no-count':!row.shop_num}">{{ row.shop_num }}</a>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="维修范围" slot="rapair_range" align="center">
          <template slot-scope="{ row }">
            {{ row.rapair_range || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="备注" slot="remarks" align="center">
          <template slot-scope="{ row }">
            {{ row.remarks || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="排序" slot="lastLoginTime" align="center">
          <template slot-scope="{ row }">
            <el-input-number size="mini" v-model="row.sort" @change="(currentValue)=>(handleSort(row,currentValue))"
                             :min="1" label="描述文字"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="最后更新时间" slot="lastLoginTime" align="center">
          <template slot-scope="{ row }">
            {{ row.add_time || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="editInfo(row)">修改</el-button>

            <el-popover
                placement="left"
                width="300"
                v-model="row.isShowPopover">
              <p><span class="el-icon-warning"></span>删除后数据不可恢复，确认删除吗？</p>
              <div class="btns">
                <el-button size="small" plain @click="row.isShowPopover = false">取消</el-button>
                <el-button type="primary" size="small" @click="row.isShowPopover = false;deleteRow(row)">确定</el-button>
              </div>
              <el-button type="text" class="delete-btn" slot="reference">删除</el-button>
            </el-popover>

          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <el-dialog :title="dialog.type+'商家'" :visible.sync="dialog.isShow" width="500px">
      <div class="dialog-notice">说明:商家名称不能重复添加，名称长度限制25字内</div>
      <el-form ref="dialogForm" :model="dialog.form" label-width="100px" size="small" class="dialog-form"
               :rules="dialog.rules">
        <el-form-item prop="manager" label="维修商名称">
          <el-input v-model="dialog.form.manager" placeholder="请输入维修商名称 " maxlength="25"></el-input>
        </el-form-item>

        <el-form-item prop="province" label="所属省份">
          <el-select v-model="dialog.form.province" placeholder="请选择所属省份">
            <el-option
                v-for="item in dialog.provinces"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="rapair_range" label="维修范围">
          <el-select v-model="dialog.form.rapair_range" placeholder="请选择维修范围">
            <el-option
                v-for="item in dialog.repairScopes"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="remarks" label="备注说明">
          <el-input v-model="dialog.form.remarks" placeholder="请输入备注（30字内，非必填）" type="textarea"
                    maxlength="30"></el-input>
        </el-form-item>
        <div class="dialog-btns">
          <el-button type="primary" size="small" @click="confirm">确认{{ dialog.type }}</el-button>
          <el-button type="primary" size="small" plain @click="dialog.isShow=false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import {
  repairManagerList,
  addRepairManager,
  getRapairRange,
  delRepairManager,
  editRepair, repairShopList
} from '@/utils/request/repairerManage'
import api from '@/utils/request/index'

export default {
  name: "list",
  data() {
    return {
      formItemList: [
        {
          key: 'name',
          type: 'input',
          labelName: '维修商名称:',
          placeholder: '请输入维修商名称'
        }, {
          key: 'province',
          idKey: 'id',
          labelKey: 'name',
          type: 'selectFilterable',
          labelName: '所属省份:',
          option: [],
          placeholder: '请选择所属省份'
        }
      ],
      tableColumns: [
        {label: "维修商", prop: "manager"},
        {label: "所属省份", prop: "province_name"},
        {slotName: "shop_num"},
        {slotName: "rapair_range"},
        {slotName: "sort"},
        {slotName: "remarks"},
        {slotName: 'lastLoginTime'},
        {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      province: '',
      name: '',
      dialog: {
        isShow: false,
        type: '新增',
        form: {
          id: '',
          province: '',
          manager: '',
          rapair_range: '',
          remarks: ''
        },
        rules: {
          manager: {required: true, message: '请输入商家名称', trigger: 'blur'},
          province: {required: true, message: '请选择所在省份', trigger: 'change'},
          rapair_range: {required: true, message: '请选择维修范围', trigger: 'change'},
          remarks: {required: false, max: 30, message: '请输入30字以内的备注', trigger: 'change'},

        },
        provinces: []
      },
      storePopover: {
        tableColumns: [
          {label: "门店名称", prop: "reshop_name"},
          {label: "注册店员", prop: "member_num"},
        ],
        reshop_name: '',
        remanager_id: '',
        tableData: [],
        curPage: 1,
        totalElements: 0,
        formItemList: [{
          key: 'reshop_name',
          type: 'input',
          labelName: '门店名称:',
          placeholder: '请输入门店名称搜索'
        }]
      }
    }
  },
  mounted() {
    this.getPartners()
    this.getProvinces()
    this.getRepairScope()
  },
  methods: {
    getPartners() {
      repairManagerList({
        page: this.curPage,
        limit: 10,
        manager: this.name,
        province: this.province
      }).then(res => {
        this.tableData = res.data || []
        this.totalElements = res.count || 0
      })
    },
    query(data, cd) {
      this.name = data.name
      this.province = data.province
      this.curPage = 1
      this.getPartners()
    },
    currentChange(page) {
      this.curPage = page
      this.getPartners()
    },
    getRepairScope() {
      //repairScopes
      getRapairRange().then(res => {
        this.dialog.repairScopes = res.data
      })
    },
    editInfo(row) {
      this.dialog.isShow = true
      this.dialog.type = '修改'
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
        let form = {
          id: row.id,
          province: row.province,
          manager: row.manager,
          rapair_range: row.rapair_range,
          remarks: row.remarks
        }
        this.$set(this.dialog, 'form', form)
      })
    },
    deleteRow(row) {
      delRepairManager({id: row.id}).then(res => {
        this.$message.success(res.msg || '删除成功')
        this.getPartners()
      }).catch(err => {
      })
    },
    addPartner() {
      this.dialog.isShow = true
      this.dialog.type = '新增'
      this.dialog.form = {
        id: '',
        province: '',
        manager: '',
        rapair_range: '',
        remarks: ''
      }
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
      })
    },
    handleSort(row, currentValue) {
      editRepair({id: row.id, sort: currentValue}).then(res => {
        this.$message.success('排序成功')
        this.getPartners()
      })
    },
    getProvinces() {
      api.getRegion({pid: ''}).then(res => {
        this.dialog.provinces = res.data || []
        this.formItemList[1].option = res.data || []
      })
    },
    storePopoverShow(row) {
      this.storePopover.totalElements = 0
      this.storePopover.shop_name = ''
      this.storePopover.remanager_id = row.id
      this.storePopover.curPage = 1
      this.getGroupShopList()
    },
    getGroupShopList() {
      repairShopList({
        page: this.storePopover.curPage,
        limit: 5,
        reshop_name: this.storePopover.reshop_name,
        remanager_id: this.storePopover.remanager_id
      }).then(res => {
        this.storePopover.tableData = res.data
        this.storePopover.totalElements = res.count
      })
    },
    storeCurrentChange(page) {
      this.storePopover.curPage = page
      this.getGroupShopList()
    },
    storeQuery(data) {
      this.storePopover.curPage = 1
      this.storePopover.reshop_name = data.reshop_name
      this.getGroupShopList()
    },
    confirm() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          addRepairManager(this.dialog.form).then(res => {
            this.$message.success(res.msg || this.dialog.type + '维修商成功')
            this.dialog.isShow = false

            this.curPage = 1
            this.getPartners()
          }).catch(err => {
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-form-item__label{
        width: 100px!important;
    }
.dialog-notice {
  color: red;
  margin-bottom: 20px;
}

.dialog-form {
  .el-input, .el-select, .el-textarea {
    width: 300px;
  }
}

.dialog-btns {
  margin: 30px 0 30px 80px;

  .el-button {
    margin-left: 20px;
  }
}

.el-popover {
  .el-icon-warning {
    color: #FAAD14;
    margin-right: 5px;
    font-size: 16px;
  }

  .btns {
    text-align: right;
    margin-top: 20px;
  }
}

.delete-btn {
  color: red;
  margin-left: 10px;
}

.el-popover {
  position: relative;
}

.popover-sore-num {
  position: absolute;
  right: 20px;
  top: 20px;

  span {
    color: red;
  }
}

.no-count {
  color: #000;
  pointer-events: none;
}
</style>
