import Layout from '@/layout/layout'

const DemoVideoRouter = {
  path: '/insurance',
  component: Layout,
  redirect: 'noRedirect',
  name: '卡密管理',
  children: [
    {
      path:'list',
      component: () => import('@/views/insurance/list/index'),
      name: '子险种列表',
      meta: { title: '子险种列表', noCache: true }
    },
    {
      path:'planNum',
      component: () => import('@/views/insurance/planNum/index'),
      name: '子方案号',
      meta: { title: '子方案号', noCache: true }
    },
  ]
}

export default DemoVideoRouter
