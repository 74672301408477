<template>
  <div class="editAdd">
    <GlobalInfoBar :title="titleBar" />
    <GlobalChunk :icon="DetailIMG" :title="titleDetail" :padding="[32, 0, 95, 55]">
      <GlobalForm
        :init-data="initData"
        :form-rules="formRules"
        :form-item-list="formItemList"
        @handleConfirm="handleConfirm"
        :confirmBtnName="confirmBtnName"
      >
        <el-form-item class="el_from" label="机型名称:" slot="operation" prop="model_name">
          <el-input :disabled="disabled" v-model="initData.model_name" placeholder="请输入机型名称" :maxlength="40" clearable></el-input>
          <span class="text_item">
            机型名限制输入40个字符内
          </span>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="选择品牌:"
          slot="operation"
          prop="brand_id"
        >
          <el-select
            :disabled="disabled"
            v-model="initData.brand_id"
            placeholder="请选择归属品牌（可输入查询）"
            clearable
            @change="handleChange2"
          >
            <el-option
              v-for="item in options2"
              :key="item.brand_id"
              :label="item.brand"
              :value="item.brand_id"
            ></el-option>
          </el-select>
          <span class="text_item">
            如无品牌，可点击
            <span
              style="color:#0981FF;cursor:pointer;border-bottom:1px solid #0981FF"
              @click="$router.push('Brand')"
            >新建品牌</span> 创建
          </span>
        </el-form-item>

        <el-form-item
          class="el_from"
          label="选择规格:"
          slot="operation"
          prop="ram_id"
        >
          <el-select
            :disabled="disabled"
            v-model="initData.ram_id"
            placeholder="请选择规格"
            clearable
            @change="handleChange1"
          >
            <el-option
              v-for="item in options1"
              :key="item.ram_id"
              :label="item.ram_size"
              :value="item.ram_size"
            ></el-option>
          </el-select>
          <span class="text_item">
            如无规格，可点击
            <span
              style="color:#0981FF;cursor:pointer;border-bottom:1px solid #0981FF"
              @click="$router.push('format')"
            >新建规格</span> 创建
          </span>
        </el-form-item>

        <el-form-item class="el_from" label="亚丁协议价格:" slot="operation" prop="price">
          <el-input v-model="initData.price" placeholder="请输入亚丁协议价格（元）" clearable></el-input>
          <span class="text_item">
            请输入大于0小于1000000的数字（最多支持2位小数）
          </span>
        </el-form-item>

        <el-form-item class="el_from" label="讯捷协议价格:" slot="operation" prop="price1">
          <el-input v-model="initData.price1" placeholder="请输入讯捷协议价格（元）" clearable></el-input>
          <span class="text_item">
            请输入大于0小于1000000的数字（最多支持2位小数）
          </span>
        </el-form-item>

        <el-form-item class="el_from" label="卖教售协议价格:" slot="operation" prop="price2">
          <el-input v-model="initData.price2" placeholder="请输入卖教售协议价格（元）" clearable></el-input>
          <span class="text_item">
            请输入大于0小于1000000的数字（最多支持2位小数）
          </span>
        </el-form-item>
        <el-form-item class="el_from" label="官网价格:" slot="operation" prop="official_price">
          <el-input v-model="initData.official_price" placeholder="请输入官网价格（元）" clearable></el-input>
          <span class="text_item">
            请输入大于0小于1000000的数字（最多支持2位小数）
          </span>
        </el-form-item>
        <el-button slot="btnSlot" @click="FromBack">取消</el-button>
      </GlobalForm>
    </GlobalChunk>
  </div>
</template>

<script>
import { formValidateNumberSeven } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "editAdd",
  data() {
    return {
      disabled:false,
      titleBar: "",
      confirmBtnName:'',
      titleDetail: "",
      DetailIMG: "",
      options1: [],
      options2: [],
      initData: {
        price:'',
        price1:'',
        price2:'',
        official_price:'',
        model_name: "",
        brand_id: "",
        ram_id: ""
      },
      formItemList: [{ slotName: "operation" }],
      formRules: {
        model_name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入机型名称"
          },
        ],
        official_price: [
          {
            required: true,
            trigger: "blur",
            validator: formValidateNumberSeven
          },
        ],
        price: [
          {
            required: true,
            trigger: "blur",
            message: "请输入亚丁协议价格",
            validator: formValidateNumberSeven
          },
        ],
        price1: [
          {
            required: true,
            trigger: "blur",
            message: "请输入讯捷协议价格",
            validator: formValidateNumberSeven
          },
        ],
        price2: [
          {
            required: true,
            trigger: "blur",
            message: "请输入卖教售协议价格",
            validator: formValidateNumberSeven
          },
        ],
        brand_id: [
          {
            required: true,
            trigger: "blur",
            message: "请选择选择品牌"
          }
        ],
        ram_id: [
          {
            required: true,
            trigger: "blur",
            message: "请选择规格"
          }
        ]
      }
    };
  },
  created() {
      this.phoneBrandList()
      this.phoneRam()
  },
  mounted() {
    if (this.$route.query.type === "edit") {
      this.titleBar = "修改机型";
      this.confirmBtnName = '确认修改'
      this.disabled = true
      _api.phonePriceInfo({price_id:this.$route.query.id}).then(res=>{
          if(res.code===1){
            this.initData.brand_id = res.data.brand_id
            this.initData.ram_id = res.data.ram_size
            this.initData.model_name = res.data.model_name
            this.initData.price = res.data.price
            this.initData.price1 = res.data.price1
            this.initData.price2 = res.data.price2
            this.initData.official_price = res.data.official_price
          }
      })
    } else {
      this.titleBar = "新增机型";
      this.confirmBtnName = '确认添加'
      this.disabled = false
    }
  },
  methods: {
    // 选择
    handleChange1(val) {
      this.initData.ram_id = val;
    },
    handleChange2(val) {
      this.initData.brand_id = val;
    },
    // 规格下拉
    phoneRam() {
      _api.phoneRam().then(res => {
        if (res.code === 1) {
          this.options1 = res.data;
        }
      });
    },
    // 品牌下拉
    phoneBrandList() {
      _api.phoneBrandList().then(res => {
        console.log(res);
        if (res.code === 1) {
          this.options2 = res.data;
        }
      });
    },
    // 提交
    handleConfirm(data, cd) {
      cd();
      if(this.$route.query.type === "add"){
          const vo = {
            model_name: this.initData.model_name,
            official_price: this.initData.official_price,
            price: this.initData.price,
            price1: this.initData.price1,
            price2: this.initData.price2,
            ram_size_str: this.initData.ram_id,
            brand_id: this.initData.brand_id
        };
        _api.addPhonePrice(vo).then(res => {
            if (res.code === 1) {
                this.$message.success(res.msg)
            }
        });
      }else{
          const params={
              price_id: this.$route.query.id,
              official_price: this.initData.official_price,
              price: this.initData.price,
              price1: this.initData.price1,
              price2: this.initData.price2,
          }
          _api.phonePriceEdit(params).then(res => {
            if (res.code === 1) {
                this.$message.success(res.msg)
                this.$router.push('list')
            }
        });
      }
    },
    FromBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
::v-deep .el-form-item__label{
  width: 140px!important;
}
.editAdd {
  .text_item {
    color: #999;
    margin-left: 20px;
  }
  .el_from {
    margin-left: -120px;
  }
  /deep/ .el-input__inner {
    width: 380px;
  }
  /deep/ .el-input {
    width: 380px;
  }
  .item_dia {
    display: flex;
    justify-content: left;
    align-items: center;
    .left {
      width: 60px;
      margin: 0px 0 10px 10px;
      color: black;
    }
    .right {
      margin: 0px 0 10px 10px;
      color: #ff8080;
    }
  }
  .btn_bot {
    float: right;
    margin: 30px;
  }
}
</style>
