<template>
  <div class="planNum_list">
    <GlobalInfoBar title="规格列表" />
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div slot="AddSlot">
        <el-button size="small" @click="addPlan()" type="primary" icon="el-icon-plus">新增规格</el-button>
      </div>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editPlan(row,'edit')" type="text" class="mr_sty">修改</el-button>
          <el-button @click="deleteDis(row)" type="text" class="btn_delete">删除</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog :visible.sync="visible" :title="title" width="600px">
      <div v-if="this.title==='新增规格'" class="tit_color">说明:规格名称不能重复添加，名称长度限制25字内</div>
      <div v-else class="tit_color">说明:规格名称不能重复添加，名称长度限制25字内</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item class="el_form" label="规格名称" prop="ram_size">
          <el-input v-model="ruleForm.ram_size" placeholder="请输入规格名称" :maxlength="25"></el-input>
        </el-form-item>
        
        <el-form-item class="el_form">
          <div style="color:#999;float:right;margin-right:80px">苹果系统示例： 64GB <span style="margin-left:20px">安卓系统示例： 64GB+128GB</span></div>
        </el-form-item>
        <el-form-item class="el_form_r">
          <el-button type="primary" @click="submitForm('ruleForm','add')" v-if="this.title==='新增规格'">确认新增</el-button>
          <el-button type="primary" @click="submitForm('ruleForm','edit')" v-else>确认修改</el-button>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "planNum_list",
  data() {
    return {
      //弹窗参数
      visible: false,
      title: "",
      ruleForm: {
        ram_id: "", //	是	int	0新增 大于0：修改
        ram_size: "", //规格名称
      },
      rules: {
        ram_size: [
          { required: true, message: "请填写规格名称", trigger: "blur" }
        ],
      },
      //列表
      initData: null,
      end_time: "",
      beg_time: "",
      formItemList: [
        {
          key: "ram_size",
          type: "input",
          labelName: "规格名称:",
          placeholder: "请输入规格名称"
        }
      ],
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      ram_size: "",
      tableColumns: [
        { label: "规格名称", prop: "ram_size" },
        { label: "最后更新时间", prop: "add_time" },
        { slotName: "operat" }
      ]
    };
  },
  mounted() {
    this.PostBrandList();
  },
  methods: {
    //列表请求
    PostBrandList() {
      const params = {
        page: this.PlanIpageNum,
        limit: this.PlanIpageSize,
        ram_size: this.ram_size
      };
      _api.phoneRam(params).then(res => {
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostBrandList();
    },
    //搜索
    handleConfirm(data) {
      this.PlanIpageNum = 1
      console.log(data, "data");
      this.ram_size = data.ram_size;
      const params = {
        page: this.PlanIpageNum,
        limit: 10,
        ram_size: data.ram_size
      };
      _api.phoneRam(params).then(res => {
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    addPlan() {
      this.ruleForm.ram_size = "";
      this.visible = true;
      this.title = "新增规格";
    },
    //删除
    deleteDis(row){
      this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _api.delPhoneRam({ram_id:row.ram_id}).then(res=>{
            if(res.code===1){
              this.$message.success('删除成功')
              this.PostBrandList()
            }else{
              this.$message.error(res.msg)
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });          
        });
    },
    //修改
    editPlan(row, type) {
      this.visible = true;
      this.title = "修改规格";
      this.ruleForm.ram_id = row.ram_id
      this.ruleForm.ram_size = row.ram_size
    },
    submitForm(formName, type) {
      if (type === "add") {
        this.ruleForm.ram_id = 0;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          _api.addPhoneRam(this.ruleForm).then(res => {
            if (res.code === 1) {
              this.visible = false;
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.PostBrandList()
            }else{
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  /deep/ .el-form-item__label{
        width: 80px!important;
    }
  .btn_delete{
    color: #FF687B;
  }
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r{
    float: right;
    margin-right: 80px;
  }
}
</style>
