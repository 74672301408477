<template>
  <div>
    <GlobalInfoBar title="卡密修改日志"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-rules="formRules"
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      ></GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import {cardLog} from "@/utils/request/systemManage";

export default {
  name: "orderLog",
  data() {
    return {
      initData: {},
      formRules: {},
      formItemList: [
        {
          key: 'num',
          type: 'input',
          labelName: '卡号:',
          placeholder: '请输入卡号'
        }
      ],
      tableColumns: [
        {label: "开始卡号", prop: "beg"},
        {label: "结束卡号", prop: "end"},
        {label: "子险种", prop: "card_name"},
        {label: "子方案名称", prop: "zifangan"},
        {label: "年限", prop: "valid_stage"},
        {label: "操作管理员", prop: "admin"},
        {label: "操作时间", prop: "add_time"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      num: '',
    }
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    getOrders() {
      cardLog({
        page:this.curPage,
        limit:10,
        num:this.num,
      }).then(res=>{
        this.tableData=res.data||[]
        this.totalElements = res.count || 0
      })
    },
    query(data, cd) {
      this.operator = data.operator
      this.num = data.num
      this.curPage=1
      this.getOrders()
    },
    currentChange(page) {
      this.curPage=page
      this.getOrders()
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-form-item__label{
        width: 80px!important;
    }
</style>
