<template>
  <el-dialog
    :width="`${width || 628}px`"
    :title="title"
    :visible="show"
    :close-on-click-modal="false"
    @close="onClose"
    :top="top"
  >
    <slot name="seach" />
    <el-table
      ref="tableGroup"
      :data="data"
      :highlight-current-row="true"
      :row-key="getRowKeys"
      border
      stripe
      size="small"
      style="width: 100%"
    >
      <el-table-column
          type="index"
          align="center"
          label="序号"
          width="70"
          :index='(index)=>{return (index+1) + (currentPage-1)*pageSize }'
      />
      <!--多选列-->
      <el-table-column
        v-if="isSelection"
        type="selection"
        align="center"
        width="55"
        :selectable="isCheckbox"
      />

      <!-- 展示列 -->
      <template v-for="(column, index) in columns">
        <slot v-if="column.slotName" :name="column.slotName" />
        <component
          :is="column.component"
          v-if="column.component"
          :key="index"
          :params="column.params"
        />
        <el-table-column
          v-if="column.prop"
          :key="index"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :class-name="column.className"
          align="center"
        />
      </template>
    </el-table>

    <div class="orderPagina">
      <el-pagination
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next,jumper"
        :total="total"
        class="el-pagination-c"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogTbale",
  props: {
    // 表格展示列
    columns: {
      type: Array,
      required: true
    },
    // 表格数据
    data: {
      type: Array,
      required: true
    },
    // 是否显示多选列
    isSelection: {
      type: Boolean,
      default: false
    },
    // 传入自定义按钮时，需要延展宽度的最大值
    operationCustomLength: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean
    },
    title: {
      type: String
    },
    width: {
      type: Number,
      // default: "50%"
    },
    top: {
      type: String,
      default: "5%"
    },
    currentPage: {
      type: Number
    },
    total: {
      type: Number
    },
    pageSize:{
      type: Number
      
    }
  },
  data() {
    return {
      getRowKeys(row) {
        return row.index
      },
    };
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    handleCurrentChange(page) {
      this.$emit('handleCurrentChange', page)
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.orderPagina{
  float: right;
  padding: 30px;
}
</style>
