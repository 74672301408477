<template>
  <div>
    <GlobalInfoBar :title="this.$route.query.partner_id?'编辑合伙人':'新增合伙人'"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <el-form ref="form" :model="form" label-width="100px" size="medium" :rules="rules">
        <el-form-item label="合伙人名称" prop="partner_name">
          <el-input v-model="form.partner_name" placeholder="请输入合伙人名称"></el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入合伙人联系电话" maxlength="11" type="tel"></el-input>
        </el-form-item>

        <el-form-item label="省市区" prop="region">
          <el-cascader :props="region" v-model="form.region" ref="cascader"></el-cascader>
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-input v-model="form.address" placeholder="请输入合伙人详细地址"></el-input>
        </el-form-item>

        <div style="margin-left: 100px;">
          <el-button type="primary" plain size="medium" @click="$router.go(-1)">返回</el-button>
          <el-button type="primary" size="medium" @click="confirm">提交</el-button>
        </div>
      </el-form>
    </GlobalChunk>
  </div>
</template>

<script>
import {
  addEditPartner,
  addEditStore,
  getRegion,
  partnerInfo,
  selectAgent,
  selectPass,
  storeInfo
} from "../../../utils/request/changePhone";
import axios from "axios";

export default {
  name: "operate",
  data() {
    let regionValidate = (rule, value, callback) => {
      if (!value || (Array.isArray(value) && !value.length)) {
        callback('请选择区域')
        return
      }
      callback()
    };
    return {
      form: {
        partner_name: '',
        phone: '',
        region: [],
        address: ''
      },
      rules: {
        partner_name: [{required: true, message: '请输入合伙人名称', trigger: 'change'}],
        phone: [{required: true, message: '请输入合伙人联系电话', trigger: 'change'}],
        address: [{required: true, message: '请输入合伙人详细地址', trigger: 'change'}],
        region: [  { required: true,validator: regionValidate, trigger: 'change' }],
      },
      region: {
        lazy: true,
        lazyLoad(node, resolve) {
          let pid = node.data && node.data.value || 0;
          getRegion({pid}).then(res => {
            let data = res || {};
            let children = []
            Object.keys(data).forEach(key => {
              children.push({
                value: key,
                label: data[key],
                leaf: node.level >= 2
              })
            })
            resolve(children)
          })
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.partner_id) {
      this.getPartnerDetail()
    }
  },
  methods: {
    getPartnerDetail() {
      partnerInfo({
        partner_id: this.$route.query.partner_id
      }).then(res => {
        let data = res.data;
        if (data) {
          this.form = data
          this.form.region = [data.province, data.city, data.district]
          this.$refs.cascader.inputValue = [data.province_name, data.city_name, data.district_name].join(' / ');
        }
      })
    },
    confirm() {
      this.$refs.form.validate(valid=>{
        if (valid) {
          addEditPartner({
            partner_id: this.form.partner_id || '',
            partner_name:this.form.partner_name,
            phone:this.form.phone,
            province: this.form.region[0],
            city: this.form.region[1],
            district: this.form.region[2],
            address: this.form.address,
          }).then(data=>{
            this.$router.go(-1)
            this.$message.success(data&&data.msg||'操作成功')
          }).catch(err=>{
            console.error(err)
          })
        }
      })
    }

  }
}
</script>

<style scoped>
.el-input, .el-cascader,.el-select {
  width: 420px;
}
</style>
