<template>
  <div>
    <GlobalInfoBar title="注册员工"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询">
          <el-form-item slot="merchant" label="所属商家" label-width="80px">
          <el-select clearable v-model="group_id" placeholder="请选择所属商家" @change="merchanttopChange" filterable>
            <el-option
                v-for="item in merchantList"
                :key="item.group_id"
                :label="item.group_name"
                :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item slot="store" label-width="80px" label="所属门店">
          <el-select clearable :disabled="!group_id" v-model="group_shop_id" placeholder="请先选择所属商家" filterable>
            <el-option
                v-for="item in storeList"
                :key="item.group_shop_id"
                :label="item.shop_name"
                :value="item.group_shop_id">
            </el-option>
          </el-select>
        </el-form-item>

      </GlobalForm>
      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="头像" slot="headimg" align="center">
          <template slot-scope="{ row }">
            <el-image
                style="width:  50px; height:  50px"
                :src="row.headimg"
                :preview-src-list="[row.headimg]"
                @click.stop="closePreview">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center" width="200">
          <template slot-scope="{ row }">
            <el-button type="text" @click="editInfo(row)">修改资料</el-button>
            <el-button type="text" @click="deleteStaff(row)">删除员工</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <el-dialog title="修改员工资料" :visible.sync="dialog.isShow" width="500px">
      <div class="dialog-notice">说明: 修改前请仔细核对信息无误，并确认已与门店店员沟通核实。</div>
      <el-form ref="dialogForm" :model="dialog.form" label-width="80px" size="small" class="dialog-form"
               :rules="dialog.rules">
        <el-form-item prop="name" label="店员姓名">
          <el-input v-model="dialog.form.name" placeholder="请输入店员姓名 " maxlength="25"></el-input>
        </el-form-item>

        <el-form-item prop="phone" label="注册手机">
          <el-input v-model="dialog.form.phone" placeholder="请输入注册手机" maxlength="11"></el-input>
        </el-form-item>

        <el-form-item prop="group_id" label="所属商家">
          <el-select v-model="dialog.form.group_id" placeholder="请选择所属商家" @change="merchantChange" filterable>
            <el-option
                v-for="item in dialog.merchants"
                :key="item.group_id"
                :label="item.group_name"
                :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="group_shop_id" label="所属门店">
          <el-select v-model="dialog.form.group_shop_id" placeholder="请选择所属门店" filterable>
            <el-option
                v-for="item in dialog.stores"
                :key="item.group_shop_id"
                :label="item.shop_name"
                :value="item.group_shop_id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="address" label="所属区域" v-if="dialog.isShow">
          <el-cascader v-model="dialog.form.address" :props="{lazy:true,lazyLoad:loadArea,label:'name',value:'id'}"></el-cascader>
        </el-form-item>

        <div class="dialog-btns">
          <el-button type="primary" size="small" @click="confirm">确认{{ dialog.type }}</el-button>
          <el-button type="primary" size="small" plain @click="dialog.isShow=false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  editGroupShopMember,
  groupShopList,
  groupShopMemberInfo,
  groupShopMemberList,
  selectGroupList,
  delGroupShopMember
} from "../../utils/request/partnerManage";
import api from "@/utils/request";

export default {
  name: "registeredStaff",
  data() {
    let validatePhone = (rule, value, callback) => {
      if (!(new RegExp(/^\d{11}$/).test(value))) {
        callback('请输入11位数字手机号码')
      } else if (!(new RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/).test(value))) {
        callback('请输入正确格式的手机号码')
      } else {
        callback()
      }
    }
    return {
      storeList:[],
      merchantList:[],
      formItemList: [
        {
          key: 'name',
          type: 'input',
          labelName: '店员姓名:',
          placeholder: '请输入店员姓名'
        }, {
          key: 'phone',
          type: 'input',
          labelName: '店员手机:',
          placeholder: '请输入店员手机'
        }, 
        {slotName:"merchant"},
        {slotName:"store"},
        // {
        //   key: 'merchant',
        //   idKey: 'group_id',
        //   labelKey: 'group_name',
        //   type: 'selectFilterable',
        //   labelName: '所属商家:',
        //   option: [],
        //   placeholder: '请选择所属商家'
        // }, {
        //   key: 'store',
        //   idKey: 'group_shop_id',
        //   labelKey: 'shop_name',
        //   type: 'selectFilterable',
        //   labelName: '所属门店:',
        //   option: [],
        //   placeholder: '请选择所属门店'
        // },
      ],
      name: '',
      phone: '',
      merchant: '',
      store: '',
      tableColumns: [
        {slotName: 'headimg'},
        {label: "姓名", prop: "name"},
        {label: "微信昵称", prop: "nickname"},
        {label: "手机号", prop: "phone"},
        {label: "省份", prop: "province"},
        {label: "城市", prop: "city"},
        {label: "区域", prop: "district"},
        {label: "所属商家", prop: "group_name"},
        {label: "所属门店", prop: "shop_name"},
        {label: "注册时间", prop: "add_time"},
        {slotName: 'operation'},

      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      group_shop_id: '',
      group_id:"",
      dialog: {
        isShow: false,
        form: {
          id: '',
          name: '',
          phone: '',
          group_shop_id: '',
          group_id: '',
          address: [],
          province: '',
          city: '',
          district:''
        },
        rules: {
          name: {required: true, message: '请输入店员姓名', trigger: 'blur'},
          phone: [{required: true, message: '请输入注册手机号码', trigger: 'blur'},{validator: validatePhone, trigger: 'blur'}],
          group_shop_id: {required: true, message: '请选择所属门店', trigger: 'change'},
          group_id: {required: true, message: '请选择所属商家', trigger: 'change'},
          address: {required: true, message: '请选择所属区域', trigger: 'change'},
         },
        merchants:[],
        stores:[]
      },
    }
  },
  mounted() {
    this.getStaffs()
    this.getMerchants()
  },
  methods: {
    query(data) {
      this.name = data.name
      this.phone = data.phone
      this.curPage=1
      this.getStaffs()
    },
    currentChange(page) {
      this.curPage = page
      this.getStaffs()
    },
    loadArea(node, resolve) {
      const { level } = node;
      api.getRegion({pid:node.data&&node.data.id||0}).then(res=>{
        let list = res.data || [];
        if(node.level===2){
          resolve(list.map(item=>{
            item.leaf=true
            return item
          }))
        }else {
          resolve(list)
        }

      });
    },
    editInfo(row) {
      this.dialog.isShow = true
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
        groupShopMemberInfo({id:row.id}).then(res=>{
          let data=res.data||{}
          let form = {
            id:data.id,
            name: data.name,
            phone: data.phone,
            group_shop_id: data.group_shop_id,
            group_id: data.group_id,
            address: [data.province,data.city,data.district],
            province: '',
            city: '',
            district:''
          }
          this.$set(this.dialog, 'form', form)
          this.getStores(data.group_id)
        })
      })
    },
    deleteStaff(row) {
      this.$confirm(`<span>确定要删除员工<b>${row.name}</b>吗</span>`,'警告', {
        dangerouslyUseHTMLString: true
      }).then(res=>{
        delGroupShopMember({id: row.id}).then(res => {
          this.$message.success('删除成功')
          this.getStaffs()
        }).catch(err => {
        })
      }).catch(()=>{

      })
    },
    getMerchants() {
      selectGroupList().then(res => {
        this.merchantList = res.data || []
        this.dialog.merchants=res.data||[]
      })
    },
    merchantChange(id) {
      this.dialog.form.group_shop_id=''
      this.getStores(id)
    },
    merchanttopChange() {
      this.group_shop_id=''
      this.gettopStores()
    },
    gettopStores() {
      groupShopList({
        page: this.curPage,
        limit: 100000,
        group_id:this.group_id
      }).then(res => {
          this.storeList= res.data || [];
      })
    },
    getStores(group_id='') {
      groupShopList({
        page: this.curPage,
        limit: 100000,
        group_id
      }).then(res => {
        this.dialog.stores=res.data||[]
      })
    },
    getStaffs() {
      groupShopMemberList({
        page:this.curPage,
        limit:10,
        phone:this.phone,
        name:this.name,
        group_id:this.group_id,
        group_shop_id:this.group_shop_id
      }).then(res => {
        this.tableData = res.data || []
        this.totalElements=res.count||0
      })
    },
    confirm() {
      this.$refs.dialogForm.validate(valid=>{
        if (valid) {
          let params=this.dialog.form
          params.province=params.address[0]
          params.city=params.address[1]
          params.district=params.address[2]
          delete params.address;
          editGroupShopMember(params).then(res => {
            this.$message.success('修改成功')
            this.dialog.isShow = false

            this.curPage = 1
            this.getStaffs()
          }).catch(err => {
          })
        }
      })
    },
    closePreview() {
      // 获取遮罩层dom
      setTimeout(function(){
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if(e.target.parentNode.className === 'el-image-viewer__actions__inner') {
            return;  //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      },300)
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-form-item__label{
        width: 80px!important;
    }
.dialog-notice {
  color: red;
  margin-bottom: 20px;
}

.dialog-form {
.el-input, .el-select,.el-cascader {
  width: 300px;
}
}

.dialog-btns {
  margin: 30px 0 30px 60px;

.el-button {
  margin-left: 20px;
}
}

.el-popover {
.el-icon-warning {
  color: #FAAD14;
  margin-right: 5px;
  font-size: 16px;
}

.btns {
  text-align: right;
  margin-top: 20px;
}
}
.delete-btn{
  color:red;
  margin-left: 10px;
}
.el-popover{
  position: relative;
}
.popover-sore-num {
  position: absolute;
  right: 20px;
  top: 20px;
span{
  color: red;
}
}
.staff-avatar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.no-count{
  color: #000;
  pointer-events: none;
}
</style>
