import request from './request'

export function test1(params){
    return request({
        method:'POST',
        url:'/Statistics/test1',
        data:params,
    })
}
export function test5(params){
    return request({
        method:'POST',
        url:'/Statistics/test5',
        data:params,
    })
}
export function test2(params){
    return request({
        method:'POST',
        url:'/Statistics/test2',
        data:params,
    })
}
export function test3(params){
    return request({
        method:'POST',
        url:'/Statistics/test3',
        data:params,
    })
}
export function test4(params){
    return request({
        method:'POST',
        url:'/Statistics/test4',
        data:params,
    })
}
export function test6(params){
    return request({
        method:'POST',
        url:'/Statistics/test6',
        data:params,
    })
}

