module.exports = {
    id: 11,
    name: "苹果换机保理赔",
    type: "claimSettlement",
    idFlag: true,
    form: [
        {
            label: "注册订单",
            prop: "viewOrder"
        },
        {
            label: "预警提示",
            tempProp: "warning",
            obj: "warning",
            props: [
                "dateDay",
                "phoneC",
                "id_cardC",
                "imeiC"
            ],
            type: "warning"
        },
        {
            label: "身份证正面照片",
            prop: "tem_card",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "破屏手机照",
            prop: "tem_img",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "序列号手机照",
            prop: "tem_img2",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "手机后盖照",
            prop: "tem_back",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "理赔接件单照片",
            prop: "tem_receive",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "爱思助手截图",
            prop: "tem_elsie",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "附加摄像头,边框图片",
            prop: "additional",
            obj: "order",
            type: "img_add",
            watermark: true
        },
        {
            label: "权益选择",
            prop: "equity",
            obj: "order",
            type: "tex"
        },
        {
            label: "上传日期",
            prop: "add_time",
            obj: "order",
            type: "date"
        },
        {
            label: "姓名",
            prop: "name",
            obj: "order",
            type: "input"
        },
        {
            label: "手机号码",
            prop: "phone",
            obj: "order",
            type: "input"
        },
        {
            label: "身份证号",
            prop: "id_card",
            obj: "order",
            type: "input"
        },
        {
            label: "审核结果",
            prop: "exa_status",
            obj: "order",
            type: "radio",
            optListName: "statusList",
            optLabel: "name",
            optValue: "status"
        },
        {
            label: "个人报账",
            prop: "is_claim",
            obj: "order",
            type: "select",
            optListName: "personalReimbursementList",
            optLabel: "name",
            optValue: "value",
            append: 'pushXi'
        },
        {
            label: "生效日期",
            prop: "exa_time",
            obj: "order",
            type: "date"
        },
        {
            label: "手机品牌",
            prop: "brand_id",
            obj: "order",
            type: "select",
            optListName: "brandList",
            optLabel: "brand",
            optValue: "brand_id",
            relate: "model"
        },
        {
            label: "手机型号",
            prop: "model_id",
            obj: "order",
            type: "select",
            optListName: "modelList",
            optLabel: "model_name",
            optValue: "model_id"
        },
        {
            label: "IMEI码",
            prop: "imei",
            obj: "order",
            type: "input"
        },
        {
            label: "IMEI码2",
            prop: "push_imei",
            obj: "order",
            type: "input"
        },
        {
            label: "破损情况",
            prop: "damage_title",
            obj: "order",
            type: "select",
            optListName: "phoneStatusList",
            optLabel: "damage_title",
            optValue: "damage_id"
        },
        {
            label: "服务选择",
            prop: "ins_service_name",
            obj: "order",
            type: "select",
            optListName: "iphoneServeList",
            optLabel: "ins_service_name",
            optValue: "ins_service_name"
        },
        {
            label: "是否选择补差升级",
            prop: "is_up",
            obj: "order",
            type: "select",
            optListName: "isUpgradeList",
            associatedDisplay: "ins_service_name",
            showValueList: [
                "按比例补差价换新机",
                "按28%比例补交差价换新机",
                "按48%比例补交差价换新机"
            ]
        },
        {
            label: "新机机型",
            prop: "new_model",
            obj: "order",
            type: "select",
            optListName: "modelList",
            optLabel: "model_name",
            optValue: "model_id",
            associatedDisplay: "is_up",
            showValueList: [
                "迭代升级换新机"
            ]
        },
        {
            label: "新机规格",
            prop: "new_ram",
            obj: "order",
            type: "select",
            optListName: "RAMList",
            associatedDisplay: "is_up",
            showValueList: [
                "迭代升级换新机"
            ]
        },
        {
            label: "新机价格",
            prop: "new_price",
            obj: "order",
            type: "input",
            associatedDisplay: "ins_service_name",
            showValueList: [
                "按比例补差价换新机",
                "按28%比例补交差价换新机",
                "按48%比例补交差价换新机"
            ]
        },
        {
            label: "旧机价格",
            prop: "bare_price",
            obj: "order",
            type: "input",
            associatedDisplay: "ins_service_name",
            showValueList: [
                "按比例补差价换新机",
                "按28%比例补交差价换新机",
                "按48%比例补交差价换新机"
            ]
        },
        {
            label: "用户补交金额",
            prop: "money",
            obj: "order",
            type: "input",
            associatedDisplay: "ins_service_name",
            showValueList: [
                "按比例补差价换新机",
                "按28%比例补交差价换新机",
                "按48%比例补交差价换新机"
            ]
        },
        {
            label: "保险赔付金额",
            prop: "claims_money",
            obj: "order",
            type: "input",
            associatedDisplay: "ins_service_name",
            showValueList: [
                "按比例补差价换新机",
                "按28%比例补交差价换新机",
                "按48%比例补交差价换新机"
            ]
        },
        {
            label: "维修城市",
            prop: "repair_city",
            obj: "order",
            type: "input"
        }, {
            label: "选择物流",
            prop: "link",
            obj: "order",
            type: "select",
            optListName: "logisticsList",
            optLabel: "title",
            optValue: "link",
            clearable: true,
            append: "link_send"
        },
        {
            label: "回复内容",
            prop: "fail_exp",
            obj: "order",
            type: "textarea"
        },
        {
            label: "GPS定位",
            prop: "gps",
            obj: "order",
            type: "input"
        },
        {
            label: "备注信息",
            prop: "remark",
            obj: "order",
            type: "textarea"
        }
    ],
    tableColumns: [
        {
            slotName: "headimg"
        },
        {
            label: "注册者昵称",
            prop: "nickname"
        },
        {
            slotName: "operation"
        }
    ],
    piccCallbackType: 2
}

