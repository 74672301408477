<template>
  <div
      class="container"
      style="position: relative"
  >
    <el-breadcrumb
        class="breadcrumb-content"
        separator-class="el-icon-arrow-right"
    >
      <el-breadcrumb-item
        :key="-2"
		class="breadcrumb-back-box"
      >
        <span class="btn" @click="$router.go(-1)">返回</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item
          :key="-1"
          class="iscollapse-box"
      >
        <span @click="operateIscollapse">
          <i
              v-if="isCollapse"
              class="el-icon-s-fold"
          ></i>
          <i
              v-else
              class="el-icon-s-unfold"
          ></i>
        </span>
      </el-breadcrumb-item>
      <template v-for="(item, index) in breadcrumbList">
        <el-breadcrumb-item
            :to="item.path"
            :key="index"
            @click.native="handleBreadcrumb(index, item)"
        >{{ item.name }}
        </el-breadcrumb-item>
      </template>
    </el-breadcrumb>
    <TabBar
        style="width:75%;position:absolute;right:0;top:50%;transform:translateY(-50%);border-left: 1px solid #fff;"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import TabBar from '../TabBar/TabBar'

export default {
  name: 'Breadcrumb',
  components: {
    TabBar
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.tagsView.isCollapse,
      breadcrumbList: (state) => state.tagsView.breadcrumbList
    })
  },
  methods: {
    handleBreadcrumb(index, item) {
      this.$emit('handleBreadcrumb', index, item)
    },
    operateIscollapse() {
      // document.body.classList.toggle('sidebar-collapsed')
      this.$store.commit('tagsView/SET_ISCOLLAPSE', !this.isCollapse)
    }
  }
}
</script>

<style lang="scss">
.iscollapse-box {
  font-size: 24px;
  margin-right: 20px;

  .el-breadcrumb__separator {
    display: none !important;
  }

  i{
    cursor: pointer;
  }
}

.breadcrumb-content {
  position: sticky;
  top: -20px;
  z-index: 200;
  line-height: 40px !important;
}
.breadcrumb-back-box {
	margin-right: 16px;
	display: flex;
	.btn {
    padding: 0 10px;
		display: inline-block;
		vertical-align: middle;
		line-height: 24px;
		background: #FFFFFF;
		border-radius: 6px;
		border: none;
		font-size: 14px;
		color: #878BA4;
		cursor: pointer;
	}

	.el-breadcrumb__separator {
		display: none !important;
	}

	.btn:hover {
    padding: 0 10px;
		background: #3845F1;
		color: #fff;
	}
}
</style>
