<template>
  <div class="visualization">
    <el-scrollbar>
      <div class="head">
        <div class="logo">
          <img src="@/assets/images/ydlogo.png" alt />
          <span>亚丁科技-后台管理系统</span>
        </div>
        <div class="forehead">
          <div class="title">亚丁科技数据统计</div>
        </div>
        <div class="account-info">
          <img class="avatar" src="@/assets/images/Avatar.png" alt="" />
          <span class="username">欢迎您，王大老板（{{ userName }}）</span>
        </div>
      </div>
      <div class="container">
        <Aside class="aside"></Aside>
        <div class="content">
          <cyberpunk-title :length="6">
            注册和理赔订单数据统计图
          </cyberpunk-title>
          <div class="inquiry-wrapper">
            <div class="period">
              <span class="label">时间</span>
              <CyberpunkYearPicker @change="formChange" v-model="searchForm.year"></CyberpunkYearPicker>
            </div>
            <div class="period">
              <span class="label">商家</span>
              <CyberpunkSelect @change="formChange" v-model="searchForm.group_id" :options="groupList"></CyberpunkSelect>
            </div>
            <div class="period">
              <span class="label">保险类型</span>
              <CyberpunkSelect @change="formChange" v-model="searchForm.instype_id" :options="insTypeList"></CyberpunkSelect>
            </div>
          </div>
          <cyberpunk-box class="table_1-wrapper" :type="2">
            <div class="Polyline" element-loading-background="rgba(19, 30, 60, 0.6)" v-loading="loading" id="chartBoxDeal1"></div>
          </cyberpunk-box>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
// import * as echarts from "echarts";
import { Aside } from "@/layout/components";
import _api from "@/utils/request";
import CyberpunkTitle from "./components/CyberpunkTitle";
import CyberpunkYearPicker from "./components/CyberpunkYearPicker.vue";
import CyberpunkSelect from "./components/CyberpunkSelect.vue";
import CyberpunkBox from './components/CyberpunkBox'
import {test6} from "../../utils/request/visualization";
const echarts = require("echarts");
export default {
  name: "Visualization",
  components: { Aside, CyberpunkTitle,CyberpunkYearPicker,CyberpunkSelect,CyberpunkBox },
  data() {
    return {
      loading: false,
      searchForm: {
        year: "",
        group_id: "",
        instype_id: "",
      },
      groupList: [],
      insTypeList: [],
      dataValueX: [],
      dataValueY1: [],
      dataValueY2: [],
    };
  },
  computed: {
    userName() {
      let admin = localStorage.getItem("admin");
      if (admin) {
        return JSON.parse(admin).user_name;
      }
      return "管理员";
    },
  },
  created() {
    let year = new Date().getFullYear().toString()
    this.searchForm.year = year
    this.selectGroupList()
    this.pullInsTypeList()
  },
  mounted() {
    this.formChange()
  },
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    selectGroupList() {
      _api.selectGroupList().then((res) => {
        if (res.code === 1) {
          this.groupList = res.data.map(item => ({
            value: item.group_id,
            label: item.group_name
          }));
        }
      });
    },
    pullInsTypeList() {
      _api.pullInsTypeList().then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.insTypeList = res.data.map(item => ({
            value: item.instype_id,
            label: item.instype_name
          }));
        }
      });
    },
    formChange() {
      this.loading = true
      test6(this.searchForm).then(res => {
        if(res.code === 1){
          console.log(res);
          this.dataValueX = res.data.map(item => item.add_date + '月');
          this.dataValueY1 = res.data.map(item => item.register);
          this.dataValueY2 = res.data.map(item => item.claim);
          this.creatEchart1()
          this.loading = false
        }
      })
    },
    creatEchart1() {
      let myChart = echarts.init(document.getElementById("chartBoxDeal1"));
      // 绘制图表
      myChart.setOption({
        legend: {
          data: ["注册订单量", "理赔订单量"],
          itemGap: 20,
          icon: "roundRect",
          right: "45",
          top: "50",
          textStyle: { // 文字样式
            fontWeight: '400',
            color: '#FFFFFF', 
          }
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.dataValueX,
            axisLabel: {
              color: '#FFFFFF'
            }
          },
        ],
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
                // 设置线的颜色
                color: '#0095EE',
                // 设置线的类型
                type: 'dashed',
                // 设置线宽
                width: 1
            }
          },
          axisLabel: {
            color: '#FFFFFF'
          },
        },
        grid: {
          top: '18%',
          left: '6%',//原来是10%，修改为20%
          right: '2%',
          bottom: '24%',
        },
        series: [
          {
            name: "注册订单量",
            type: "bar",
            itemStyle: {
              barBorderRadius: [4, 4, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [   
                { offset: 0, color: '#1959DE'},  //开始的颜色
                { offset: 1, color: '#00DFFE' }  //结束的颜色
              ]),//中间可以设置多个数值，0 为百分之0 所以可以按照自己的想法，百分之10一个颜色变化都可。
            },
            barGap: "70%",
            barWidth: 20,
            data: this.dataValueY1,
            label:{
              show:true,
              position:'top',
              textStyle: {
                fontWeight: "500",
                fontSize: "14",
                color: "#FFFFFF"
              },
              formatter:function(data){
                return data.value + '单'
              }
            }
          },
          {
            name: "理赔订单量",
            type: "bar",
            itemStyle: {
              barBorderRadius: [4, 4, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [   
                { offset: 0, color: '#1FB4B4'},  //开始的颜色
                { offset: 1, color: '#00FFFF' }  //结束的颜色
              ]),//中间可以设置多个数值，0 为百分之0 所以可以按照自己的想法，百分之10一个颜色变化都可。
            },
            barGap: "70%",
            barWidth: 20,
            data: this.dataValueY2,
            label:{
              show:true,
              position:'top',
              textStyle: {
                fontWeight: "500",
                fontSize: "14",
                color: "#FFFFFF"
              },
              formatter:function(data){
                return data.value + '单'
              }
            }
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="scss">
.visualization {
  width: 100%;
  height: 100vh;
  // background-image: url("./img/bg.jpg");
  background-color: #0D1427;
  background-size: 50px 50px;
  cursor: url(./img/cursor.ico), auto;
}

::v-deep .el-scrollbar {
  height: 100%;
}

::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  height: calc(100vh + 18px);
}

::v-deep .aside {
  position: relative;
  top: -50px;
  // background: transparent !important;

  // * {
  //   background: transparent !important;
  // }
}

.head {
  height: 114px;
  width: 100%;
  display: flex;

  .logo {
    width: calc((100vw - 1250px) / 2);
    height: 64px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 22px;
    font-weight: 400;

    img {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      margin: 0 18px 0 34px;
    }
  }

  .account-info {
    width: calc((100vw - 1250px) / 2);
  }

  .forehead {
    width: 1250px;
    height: 114px;
    line-height: 72px;
    text-align: center;
    background-image: url("./img/dbbj.png");
    background-position: 100% 100%;
    flex: 1;
    color: #23cefd;
    user-select: none;

    .title {
      width: 1250px;
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      color: rgba(35, 206, 253, 0.6);
      background: -webkit-linear-gradient(
          -30deg,
          rgba(255, 255, 255, 0) 100px,
          rgba(255, 255, 255, 1) 180px,
          rgba(255, 255, 255, 1) 230px,
          rgba(255, 255, 255, 0) 300px
        ) -300px 0 no-repeat;
      -webkit-background-clip: text;
      animation: shine 3s infinite;
    }
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1250px;
  }
}

.account-info {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #23cefd;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin-right: 15px;
  }
}

.container {
  display: flex;

  .content {
    flex: 1;
    height: 100%;
    padding-left: 40px;
    box-sizing: border-box;
  }
}
.inquiry-wrapper {
  display: flex;
  margin-top: 22px;
}
.period {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 30px;
  margin-right: 30px;
  .label {
    white-space: nowrap;
    margin-right: 10px;
  }

}
.Polyline{
  width: 100%;
  height: calc(100% + 100px);
}
.table_1-wrapper {
  width: calc(100% - 40px);
  height: 737px;
  margin-top: 20px;
  background-color: rgba(19, 30, 60, 0.6);
}
</style>
