<template>
  <div class="CarMi_edit">
    <div class="red_text">
      说明: 修改前请仔细核对信息无误，否则将影响商家使用
    </div>
    <div class="item_singe">
      <div class="left">险种类型：</div>
      <div class="right">{{ this.singeList.instype_name }}</div>
    </div>
    <div class="item_singe">
      <div class="left">子险种：</div>
      <div class="right">{{ this.singeList.card_name }}</div>
    </div>
    <div class="item_singe">
      <div class="left">有效期：</div>
      <div class="right">{{ this.singeList.valid_stage }}</div>
    </div>
    <div class="item_singe">
      <div class="left">卡密：</div>
      <div class="right">{{ this.singeList.card_secret }}</div>
    </div>
    <div class="item_singe">
      <div class="left">MID：</div>
      <div class="right">{{ this.singeList.pool_id }}</div>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        class="el_form1"
        label="卡号"
        prop="card_num"
        :required="true"
      >
        <el-input
          v-model="ruleForm.card_num"
          placeholder="请输入完整的卡号"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="el_form2"
        label="关联商家"
        prop="group_id"
        :required="true"
      >
        <el-select
          v-model="ruleForm.group_id"
          filterable
          placeholder="请选择关联商家"
        >
          <el-option
            v-for="item in GroupList"
            :key="item.group_name"
            :label="item.group_name"
            :value="item.group_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="bot_bom">
        <el-button type="primary" size="small" @click="submitForm('ruleForm')"
          >确认修改</el-button
        >
        <el-button @click="resetForm('ruleForm')" size="small">返回</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import _api from "@/utils/request";
export default {
  name: "CarMi_edit",
  data() {
    return {
      GroupList: [],
      singeList: [],
      ruleForm: {
        card_num: "",
        pool_id: "",
        group_id: "",
      },
      rules: {
        group_id: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
        ],
        card_num: [
          {
            required: true,
            trigger: "blur",
            message: "输入完整卡号",
          },
        ],
      },
    };
  },
  mounted() {
    this.selectGroupList();
    this.singeDetails();
    console.log(this.$route.query.id);
    this.ruleForm.pool_id = this.$route.query.id;
  },
  methods: {
    singeDetails() {
      _api.cardPoolInfo({ pool_id: this.$route.query.id }).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.singeList = res.data;
          this.ruleForm.card_num = res.data.card_num;
          this.ruleForm.group_id = Number(res.data.group_id);
        }
      });
    },
    selectGroupList() {
      _api.selectGroupList().then((res) => {
        if (res.code === 1) {
          this.GroupList = res.data;
        }
      });
    },
    submitForm(formName) {
      const loading = this.$loading({
        lock: true,
        text: "正在生成卡号及卡密，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const vo = {
        card_num: this.ruleForm.card_num,
        pool_id: this.ruleForm.pool_id,
        group_id: this.ruleForm.group_id,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          setTimeout(() => {
            _api.editCardPool(vo).then((res) => {
              console.log(res);
              if (res.code === 1) {
                loading.close();
                this.$message.success(res.msg);
                this.$router.go(-1);
              } else {
                loading.close();
              }
            });
          }, 1000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.CarMi_edit {
  .red_text {
    color: #f4280b;
    margin: 0 0 20px 20px;
    font-size: 13px;
  }
  .el_form1 {
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form2 {
    /deep/ .el-input__inner {
      width: 360px;
    }
  }
  .bot_bom {
    margin: 150px 0 0 30px;
  }
  .item_singe {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 14px;
    .left {
      width: 85px;
      margin: 0px 0 10px 10px;
      color: black;
      text-align: right;
    }
    .right {
      margin: 0px 0 10px 10px;
      color: #666;
    }
  }
}
</style>
