<template>
  <div>
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <GlobalForm :form-item-list="formItemList" :inline="true" round @handleConfirm="query" confirmBtnName="查询">
        <div slot="AddSlot">
          <el-button size="small" type="success" @click="redDownload">红包记录下载</el-button>
        </div>
      </GlobalForm>

      <GlobalTable ref="GlobalTable" :columns="tableColumns" :data="tableData" :currentPage="curPage"
        :total="totalElements" @handleCurrentChange="currentChange">
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import { redLog, redDownload, selectGroupList } from "@/utils/request/partnerManage";
export default {
  data() {
    return {
      curPage: 1,
      totalElements: 0,
      searchParams: {},
      tableData: [],
      formItemList: [
        {
          key: 'group_id',
          idKey: 'group_id',
          labelKey: 'group_name',
          type: 'selectFilterable',
          labelName: '所属商家:',
          option: [],
          placeholder: '请选择所属商家'
        },
        {
          key: 'entryTime',
          type: 'daterange',
          labelName: '生成时间:',
          placeholder: '请选择生成时间',
          valueFormat:"timestamp"
        },
      ],
      tableColumns: [
        { label: "保险类型", prop: "instype_name" },
        { label: "红包金额", prop: "red_money" },
        { label: "用户名", prop: "name" },
        { label: "手机号", prop: "phone" },
        { label: "串码", prop: "imei" },
        { label: "发送时间", prop: "add_time" },
      ]
    }
  },
  created() {
    this.getMerchants()
    this.getList()
  },
  methods: {
    getList() {
      redLog({
        ...this.searchParams,
        page: this.curPage,
        limit: 10,
        // group_name: this.name,
        // province: this.province
      }).then(res => {
        this.tableData = res.data || []
        this.totalElements = res.count || 0
      })
    },
    getMerchants() {
      selectGroupList({page:1,limit:1000000}).then(res=>{
        this.formItemList[0].option=res.data||[]
      })
    },
    query(data, cd) {
      console.log(data)
      const [beg_time, end_time] = data.entryTime || []
      this.searchParams = {
        group_id: data.group_id,
        beg_time, end_time
      }
      this.curPage = 1
      this.getList()
    },
    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    redDownload() {
      redDownload(this.searchParams).then(res => {
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
              this.commandBtnLoading = false
            }
          } catch (err) {
            const fileName = `红包记录下载.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
            this.commandBtnLoading = false
            this.commandShow = false
          }
        });
        reader.readAsText(blob, "utf-8");
      })
    },
  }
}
</script>