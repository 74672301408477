<template>
  <div class="cyberpunk-box">
    <img class="corner left-top" :src="require(`../img/lt${type}.png`)" alt=""
         :style="{left:offsets.lt[0]+'px',top:offsets.lt[1]+'px'}">
    <img class="corner left-bottom" :src="require(`../img/lb${type}.png`)" alt=""
         :style="{left:offsets.lb[0]+'px',bottom:offsets.lb[1]+'px'}">
    <img class="corner right-top" :src="require(`../img/rt${type}.png`)" alt=""
         :style="{right:offsets.rt[0]+'px',top:offsets.rt[1]+'px'}">
    <img class="corner right-bottom" :src="require(`../img/rb${type}.png`)" alt=""
         :style="{right:offsets.rb[0]+'px',bottom:offsets.rb[1]+'px'}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CyberpunkBox",
  props: {
    type: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      offsets: {
        lt: [2, 2],
        lb: [2, 2],
        rt: [2, 2],
        rb: [2, 2],
      }
    }
  },
  mounted() {
    if (this.type === 2) {
      this.offsets = {
        lt: [-4, -2],
        lb: [-4, -2],
        rt: [-4, -2],
        rb: [-7, -2],
      }
    }

  }
}
</script>

<style scoped lang="scss">
.cyberpunk-box {
  border: 1px solid #00CBFF;
  box-shadow: inset 0 0 20px rgba(0, 203, 255, 0.7);
  position: relative;
  padding: 15px;
  box-sizing: border-box;
  transition: transform 0.2s;

  .corner {
    position: absolute;
    user-select: none;
  }
  &:hover{
    transform: translateY(-1px);
  }
}
</style>
