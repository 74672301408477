<template>
  <div>
    <GlobalInfoBar title="代理商管理"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      >
        <div slot="AddSlot">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addAgent">添加代理商</el-button>
        </div>
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="省市区" slot="region" align="center">
          <template slot-scope="{ row }">
            {{ row.province_name && row.city_name && row.district_name ? `${row.province_name}/${row.city_name}/${row.district_name}` : '--' }}
          </template>
        </el-table-column>

        <el-table-column label="启用/禁用" slot="is_open" align="center">
          <template slot-scope="{ row }">
            <el-switch v-model="row.is_open" :active-value="1" :inactive-value="0" @change="switchChange(row)"></el-switch>
          </template>
        </el-table-column>


        <el-table-column label="账户设置" slot="accountSetting" align="center">
          <template slot-scope="{ row }">
            <a href="javascript:" @click="editAccount(row)">{{ row.account?'编辑账户':'添加账户' }}</a>
          </template>
        </el-table-column>

        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button @click="editRow(row)" type="primary" size="mini">编辑</el-button>
          </template>
        </el-table-column>

      </GlobalTable>
    </GlobalChunk>


    <el-dialog :before-close="accountDialogBeforeClose" :close-on-click-modal="false" :title="accountDialog.type" :visible.sync="accountDialog.isShow" width="600px" class="account-dialog">
      <div class="dialog-notice">{{ accountDialog.notice }}</div>
      <el-form ref="dialogForm" :model="accountDialog.form" label-width="80px" size="small" class="dialog-form"
               :rules="accountDialog.rules">
        <el-form-item prop="account" label="账户名称">
          <el-input v-model="accountDialog.form.account" placeholder="请输入账户名称" maxlength="15" :disabled="accountDialog.type==='编辑账户'"></el-input>
        </el-form-item>

        <el-form-item prop="password" label="账户密码">
          <el-input v-model="accountDialog.form.password" placeholder="请输入账户密码"
                    show-word-limit class="password-input"></el-input>
        </el-form-item>

        <el-form-item prop="act" label="菜单权限" class="">
          <el-tree
              ref="tree"
              :check-strictly="true"
              @check="treeCheck"
              :data="accountDialog.menu"
              show-checkbox
              :default-expanded-keys="accountDialog.form.act"
              node-key="id"
              check-on-click-node
              :props="accountDialog.defaultTreeProps">
          </el-tree>
        </el-form-item>
      </el-form>

      <div class="dialog-btns">
        <el-button type="primary" size="small" @click="accountEditConfirm">确认{{ accountDialog.type }}</el-button>
        <el-button type="primary" size="small" plain @click="accountDialogBeforeClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {agentHandle, agentList} from "../../../utils/request/changePhone";
import {agentAcc, agentAccEdit, passagewayAcc, passagewayAccEdit} from "../../../utils/request/systemManage";

export default {
  name: "index",
  data() {
    let validateAct = (rule, value, callback) => {
      this.$nextTick(()=>{
        let act=this.accountDialog.form.act
        if (!(act && act.length)) {
          callback('请选择菜单权限')
        }else {
          callback()
        }
      })
    }
    return {
      formItemList: [
        {
          key: 'agent_name',
          type: 'input',
          labelName: '代理商名称:',
          placeholder: '请输入代理商名称'
        },
        {
          key: 'phone',
          type: 'input',
          labelName: '联系电话:',
          placeholder: '请输入联系电话'
        }
      ],
      tableColumns: [
        {label: "所属渠道", prop: "pass_name"},
        {label: "代理商名称", prop: "agent_name"},
        {label: "联系电话", prop: "phone"},
        {slotName: "region"},
        {label: "详细地址", prop: "address"},
        {slotName: "is_open"},
        {slotName: "accountSetting"},
        {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      agent_name: '',
      phone: '',
      accountDialog:{
        isShow: false,
        type: '添加账户',
        form: {
          account: '',
          password: '',
          act: []
        },
        rules: {
          account: [
            {required: true, message: '请填写角色名称', trigger: 'blur'},
            // {validator: validateRoleName, trigger: 'blur'},
          ],
          act: [
            {validator: validateAct, trigger: 'change'}
          ]
        },
        menu: [],
        defaultTreeProps: {
          children: 'children',
          label: 'name'
        },
        curPId:''
      },
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    query(data) {
      this.agent_name=data.agent_name
      this.phone=data.phone
      this.curPage = 1;
      this.getTableData()
    },
    addAgent() {
      this.$router.push({
        path:'agentOperate'
      })
    },
    currentChange(page) {
      this.curPage = page;
      this.getTableData()
    },
    switchChange(row) {
      agentHandle({
        agent_id:row.agent_id,
        value:row.is_open
      }).then(data=>{
        this.$message.success(data&&data.msg||this.dialog.type+'成功')
      }).catch(err=>{
        console.error(err)
      }).finally(()=>{
        this.getTableData()
      })
    },
    editRow(row) {
      this.$router.push({
        path:'agentOperate',
        query:{
          agent_id:row.agent_id
        }
      })
    },
    getTableData() {
      agentList({
        page: this.curPage,
        limit: 10,
        agent_name: this.agent_name,
        phone: this.phone
      }).then(res => {
        this.tableData = res.data || [];
        this.totalElements = res.count
      })
    },
    editAccount(row) {
      this.accountDialog.isShow = true
      this.accountDialog.form = row
      this.accountDialog.form.account = row.account||''
      this.accountDialog.form.password = ''
      let act = row.act_list&&row.act_list.split(',').map(Number)||[]
      this.accountDialog.form.act = act
      this.accountDialog.type=row.account?'编辑账户':'添加账户'
      this.$nextTick(() => {
        this.$refs.dialogForm.clearValidate()
        agentAcc({pass_id: row.pass_id}).then(res => {
          this.accountDialog.menu = res.data.menu
          this.$refs.tree.setCheckedKeys(act)
        })
      });
    },
    accountEditConfirm() {
      this.$refs.dialogForm.validate(valid=>{
        if (valid) {
          agentAccEdit({
            agent_id:this.accountDialog.form.agent_id,
            account:this.accountDialog.form.account,
            password:this.accountDialog.form.password,
            act:this.accountDialog.form.act
          }).then(res=>{
            this.$message.success(res.msg||'编辑成功')
          }).catch(err=>{
            this.$message.success(err.msg||'编辑失败')
          }).finally(()=>{
            this.accountDialog.isShow=false
            this.getTableData()
          })
        }
      })
    },
    treeCheck(data, node) {
      this.accountDialog.form.act = node.checkedKeys
    },
    accountDialogBeforeClose() {
      this.accountDialog.isShow=false
      this.getTableData()
    }
  }
}
</script>


<style scoped>
::v-deep .el-form-item__label {
  width: 10em !important;
}

.dialog-content p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.dialog-content.close {
  color: red;
}

.dialog-btns {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 42px;

}

.dialog-btns .cancel {
  margin-right: 20px;
}
.combine-btn{
  padding: 6px 5px;
}
.account-dialog::v-deep .el-input,
.account-dialog::v-deep .el-tree{
  width: 300px;
}
@font-face {
  font-family: pwd;
  src: url("../operationChannelConfig/PasswordEntry.ttf");
}
.password-input::v-deep .el-input__inner{
  font-family: pwd,serif!important;
}
</style>
<style>
.el-tree{
  height: 200px;
  overflow:auto;
}
.el-tree-node__content{
  margin-left: -24px;
}
</style>
