(function () {
        let scale = 1.0
        var os = function () {
            var ua = navigator.userAgent,
                isWindowsPhone = /Windows Phone/.test(ua),
                isSymbian = /SymbianOS/.test(ua) || isWindowsPhone,
                isAndroid = /Android/.test(ua),
                isFireFox = /Firefox/.test(ua),
                isChrome = /Chrome|CriOS/.test(ua),
                isTablet = /iPad|PlayBook/.test(ua) || (isAndroid && !/Mobile/.test(ua)) || (isFireFox && /Tablet/.test(ua)),
                isPhone = /iPhone/.test(ua) && !isTablet,
                isPc = !isPhone && !isAndroid && !isSymbian;
            return {
                isTablet: isTablet,
                isPhone: isPhone,
                isAndroid: isAndroid,
                isPc: isPc
            };
        }();
        if (os.isAndroid || os.isPhone) {
            // 手机
            scale = 0.3
        } else if (os.isTablet) {
            // 平板
            scale = 0.6
        } else if (os.isPc) {
            // pc
            scale = 1
        }
        console.log(os)

        let meta = document.createElement('meta');
        meta.content = 'width=device-width,initial-scale=' + scale;
        meta.name = 'viewport';
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
)()
