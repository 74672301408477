<template>
  <div class="account">
    <GlobalInfoBar
        title="系统账号"
    />
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :init-data="initData"
          :form-rules="formRules"
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      >
        <div slot="AddSlot">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addSysUser">新增系统用户</el-button>
        </div>
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="最后更新时间" slot="lastLoginTime" align="center">
          <template slot-scope="{ row }">
            {{ row.last_login || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="最后登录IP" slot="lastIp" align="center">
          <template slot-scope="{ row }">
            {{ row.last_ip || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="editInfo(row)">修改信息</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <el-dialog :title="dialog.type+'系统用户'" :visible.sync="dialog.isShow" width="800px">
      <div class="dialog-notice">{{ dialog.notice }}</div>
      <el-form ref="dialogForm" :model="dialog.form" label-width="80px" size="small" class="dialog-form"
               :rules="dialog.rules">
        <el-form-item prop="passport" label="登录账号">
          <el-input v-model="dialog.form.passport" placeholder="请输入登录账号" maxlength="16"></el-input>
          <span class="prompt">登录账号为6-16位数字+字母组合（不区分大小写）</span>
        </el-form-item>

        <el-form-item prop="role_id" label="选择角色">
          <el-select v-model="dialog.form.role_id" placeholder="请选择角色">
            <el-option
                v-for="item in dialog.roleList"
                :key="item.role_id"
                :label="item.role_name"
                :value="item.role_id">
            </el-option>
          </el-select>
          <span class="prompt">如无角色，可点击<a href="javascript:" @click="toAddRole">新建角色</a>创建</span>
        </el-form-item>

        <el-form-item prop="mobile" label="联系电话">
          <el-input v-model="dialog.form.mobile" placeholder="请输入联系电话" maxlength="11"></el-input>
          <span class="prompt">联系电话为11位手机号</span>
        </el-form-item>

        <el-form-item prop="user_name" label="姓名">
          <el-input v-model="dialog.form.user_name" placeholder="请输入姓名" maxlength="20"></el-input>
          <span class="prompt">姓名为20位内中文或英文字符</span>
        </el-form-item>

        <el-form-item prop="password" label="登录密码">
          <el-input v-model="dialog.form.password" placeholder="请输入登录密码" type="password" maxlength="16"></el-input>
          <span class="prompt">登录密码为6-16位数字+字母组合（不区分大小写）</span>
        </el-form-item>

        <div class="dialog-btns">
          <el-button type="primary" size="small" @click="confirm">确认{{ dialog.type }}</el-button>
          <el-button type="primary" size="small" plain @click="dialog.isShow=false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {addEditAdmin, adminList, pullRoleList} from "@/utils/request/systemManage";

export default {
  name: "account",
  data() {
    let validateAccountAndPassword = (rule, value, callback) => {
      if (!value) {
        callback();
        return
      }
      if (!(new RegExp(/(?=.*[0-9])(?=.*[a-zA-Z])^[a-zA-Z0-9]{6,16}$/).test(value))) {
        callback('请输入6-16位数字+字母组合（不区分大小写）');
      } else {
        callback();
      }
    }
    let validatePhone = (rule, value, callback) => {
      if (!(new RegExp(/^\d{11}$/).test(value))) {
        callback('请输入11位数字手机号码')
      } else if (!(new RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/).test(value))) {
        callback('请输入正确格式的手机号码')
      } else {
        callback()
      }
    }
    let validateName = (rule, value, callback) => {
      if (!(new RegExp(/^[\u4e00-\u9fa5a-zA-Z]{1,20}$/).test(value))) {
        callback('请输入20位内中文或英文字符')
      } else {
        callback()
      }
    }
    return {
      initData: {},
      formRules: {},
      formItemList: [
        {
          key: 'phone',
          type: 'input',
          labelName: '联系电话:',
          placeholder: '请输入联系电话查询'
        },
        {
          key: 'name',
          type: 'input',
          labelName: '姓名:',
          placeholder: '请输入姓名查询'
        },
      ],
      name: '',
      phone: '',
      tableColumns: [
        {label: "登录账号", prop: "passport"},
        {label: "姓名", prop: "user_name"},
        {label: "联系电话", prop: "mobile"},
        {label: "角色", prop: "role_name"},
        {slotName: "lastLoginTime"},
        {slotName: "lastIp"},
        {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      pageSize: 10,
      dialog: {
        isShow: false,
        type: '新增',
        note: '说明: 添加完成后请牢记登录账号及密码',
        form: {
          admin_id: '',
          passport: '',
          role_id: '',
          mobile: '',
          user_name: '',
          password: ''
        },
        rules: {
          passport: [{required: true, message: '请输入登录账号', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
            {validator: validateAccountAndPassword, trigger: 'blur'},
          ],
          role_id: [{required: true, message: '请选择角色', trigger: 'change'}],
          mobile: [{required: true, message: '请输入手机号码', trigger: 'blur'},
            {validator: validatePhone, trigger: 'blur'}],
          user_name: [{required: true, message: '请输入姓名', trigger: 'blur'},
            {validator: validateName, trigger: 'blur'}],
          password: [{required: true, message: '请输入密码', trigger: 'blur'},
            {validator: validateAccountAndPassword, trigger: 'blur'}],
        },
        roleList: []
      }
    }
  },
  mounted() {
    this.getAdminList()
    this.getRoleList()
  },
  methods: {
    query(data, cd) {
      this.name = data.name
      this.phone = data.phone
      this.curPage = 1
      this.getAdminList()
    },
    currentChange(page) {
      this.curPage = page
      this.getAdminList()
    },
    getAdminList() {
      adminList({
        page: this.curPage,
        limit: this.pageSize,
        user_name: this.name,
        mobile: this.phone
      }).then(res => {
        this.tableData = res.data
        this.totalElements = res.count || 0
      })
    },
    editInfo(row) {
      this.dialog.isShow = true
      this.dialog.type = '修改'
      this.dialog.note = '说明: 修改时不输入密码提交则系统不作密码修改'
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
        this.dialog.rules.password[0].required = false
        let form = {
          admin_id: row.admin_id,
          passport: row.passport,
          role_id: row.role_id,
          mobile: row.mobile,
          user_name: row.user_name,
          password: ''
        }
        this.$set(this.dialog, 'form', form)
      })
    },
    addSysUser() {
      this.dialog.isShow = true
      this.dialog.type = '新增'
      this.dialog.note = '说明: 添加完成后请牢记登录账号及密码'
      this.dialog.form = {
        admin_id: '',
        passport: '',
        role_id: '',
        mobile: '',
        user_name: '',
        password: ''
      }
      this.dialog.rules.password[0].required = true
      this.$nextTick(() => {
        this.$refs.dialogForm.resetFields();
      })
    },
    getRoleList() {
      pullRoleList().then(res => {
        this.dialog.roleList = res.data || []
      })
    },
    confirm() {
      this.$refs.dialogForm.validate(valid => {
        if (!valid) return
        addEditAdmin(this.dialog.form).then(res => {
          this.$message.success(res.msg || this.dialog.type + '系统账户成功')
          this.dialog.isShow = false

          this.curPage = 1
          this.getAdminList()
        }).catch(err => {
        })
      })
    },
    toAddRole() {
      this.$router.push('/systemManage/role')
    }
  }
}
</script>

<style scoped lang="scss">
.account{
  /deep/ .el-form-item__label{
        width: 80px!important;
    }
}

.dialog-notice {
  color: red;
  margin-bottom: 20px;
}

.dialog-form {
  .el-input, .el-select {
    width: 300px;
  }

  .prompt {
    margin-left: 20px;
    color: #999;
  }
}

.dialog-btns {
  margin: 30px 0 30px 60px;

  .el-button {
    margin-left: 20px;
  }
}
</style>
