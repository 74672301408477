import Layout from '@/layout/layout'

const DemoVideoRouter = {
  path: '/users',
  component: Layout,
  redirect: 'noRedirect',
  name: '个人中心',
  children: [
    {
      path:'usersList',
      component: () => import('@/views/users/usersList/index'),
      name: '会员列表',
      meta: { title: '会员列表', noCache: true }
    },
    {
        path:'usersRanking',
        component: () => import('@/views/users/usersRanking/index'),
        name: '排行榜',
        meta: { title: '排行榜', noCache: true }
    },
    {
        path:'redLog',
        component: () => import('@/views/users/redLog/index'),
        name: '发送红包记录',
        meta: { title: '发送红包记录', noCache: true }
    },
    {
        path:'prizeLog',
        component: () => import('@/views/users/prizeLog/index'),
        name: '抽奖配置',
        meta: { title: '抽奖配置', noCache: true }
    },
    {
        path:'prizeList',
        component: () => import('@/views/users/prizeList/index'),
        name: '抽奖记录',
        meta: { title: '抽奖记录', noCache: true }
    },
  ]
}

export default DemoVideoRouter
