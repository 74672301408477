var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GlobalInfoBar',[_vm._v(" 商家保险分配 ")]),_c('GlobalChunk',{attrs:{"padding":[20, 20, 20, 20]}},[_c('GlobalForm',{attrs:{"form-item-list":_vm.formItemList,"inline":true,"round":"","confirmBtnName":"查询"},on:{"handleConfirm":_vm.query}}),_c('GlobalTable',{ref:"GlobalTable",attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"currentPage":_vm.curPage,"total":_vm.totalElements},on:{"handleCurrentChange":_vm.currentChange}},[_c('el-table-column',{attrs:{"slot":"group_num","label":"门店数量","align":"center"},slot:"group_num",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popover',{attrs:{"placement":"right","width":"700","trigger":"click"},on:{"show":function($event){return _vm.storePopoverShow(row)}}},[_c('GlobalForm',{attrs:{"form-item-list":_vm.storePopover.formItemList,"inline":true,"round":"","confirmBtnName":"查询"},on:{"handleConfirm":_vm.storeQuery}}),_c('span',{staticClass:"popover-sore-num"},[_vm._v("门店数量:"),_c('span',[_vm._v(_vm._s(row.group_num))])]),_c('GlobalTable',{attrs:{"columns":_vm.storePopover.tableColumns,"data":_vm.storePopover.tableData,"currentPage":_vm.storePopover.curPage,"total":_vm.storePopover.totalElements,"page-size":5},on:{"handleCurrentChange":_vm.storeCurrentChange}}),_c('a',{class:{ 'no-count': !row.group_num },attrs:{"slot":"reference","href":"javascript:"},slot:"reference"},[_vm._v(_vm._s(row.group_num))])],1)]}}])}),_c('el-table-column',{attrs:{"slot":"lastLoginTime","label":"排序","align":"center"},slot:"lastLoginTime",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"size":"mini","min":1,"label":"描述文字"},on:{"change":function (currentValue) { return (_vm.handleSort(row, currentValue)); }},model:{value:(row.sort),callback:function ($$v) {_vm.$set(row, "sort", $$v)},expression:"row.sort"}})]}}])}),_c('el-table-column',{attrs:{"slot":"show","label":"展示","align":"center"},slot:"show",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"value":row.show,"active-value":1,"inactive-value":0},on:{"change":function (e) { return _vm.changeOpen(row, e); }}})]}}])}),_c('el-table-column',{attrs:{"slot":"lastLoginTime","label":"最后更新时间","align":"center"},slot:"lastLoginTime",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.add_time || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"loginAccount","label":"登录账号","align":"center"},slot:"loginAccount",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.account || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"operation","label":"操作","align":"center","width":"223"},slot:"operation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.editInfo(row)}}},[_vm._v("修改")])]}}])})],1)],1),_c('el-dialog',{attrs:{"title":"保险分配","visible":_vm.dialog.isShow,"width":"500px"},on:{"update:visible":function($event){return _vm.$set(_vm.dialog, "isShow", $event)}}},[_c('el-form',{ref:"dialogForm",staticClass:"dialog-form",attrs:{"model":_vm.dialog.form,"label-width":"80px","size":"small","rules":_vm.dialog.rules}},[_c('el-form-item',{attrs:{"prop":"insurance_str","label":"选择保险"}},[_c('el-select',{attrs:{"placeholder":"请选择保险","multiple":"","collapse-tags":""},model:{value:(_vm.dialog.form.insurance_str),callback:function ($$v) {_vm.$set(_vm.dialog.form, "insurance_str", $$v)},expression:"dialog.form.insurance_str"}},_vm._l((_vm.dialog.insurance),function(item){return _c('el-option',{key:item.instype_id,attrs:{"label":item.instype_name,"value":item.instype_id}})}),1)],1),_c('div',{staticClass:"dialog-btns"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.confirm}},[_vm._v("提交")]),_c('el-button',{attrs:{"type":"primary","size":"small","plain":""},on:{"click":function($event){_vm.dialog.isShow = false}}},[_vm._v("取消")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }