const environments = {
    development: {
        // cdydtestapi.9000ji.cn
        // baseURL: 'http://yading.viphfkj.com/admin',
        // uploadBaseUrl: 'https://mapi.cdydkj.cn/admin/Uploadify/imageUp',
        // multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
        baseURL: 'https://mapi.cdydkj.cn/admin',
        uploadBaseUrl: 'https://mapi.cdydkj.cn/admin/Uploadify/imageUp',
        multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    },
    test: {
        baseURL: 'http://yading.viphfkj.com/admin',
        uploadBaseUrl: 'https://mapi.cdydkj.cn/admin/Uploadify/imageUp',
        multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    },
    release: {
        baseURL: 'http://yading.viphfkj.com/admin',
        uploadBaseUrl: 'https://mapi.cdydkj.cn/admin/Uploadify/imageUp',
        multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    },
    production: {
        baseURL: 'https://mapi.cdydkj.cn/admin',
        uploadBaseUrl: 'https://mapi.cdydkj.cn/admin/Uploadify/imageUp',
        multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    },
    // production: {
    //     baseURL: 'https://mapi.fanxinghuishou.cn/admin',
    //     uploadBaseUrl: 'https://mapi.fanxinghuishou.cn/admin/Uploadify/imageUp',
    //     multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    // }
};

export default environments

