<template>
  <div>
    <el-upload
      action="#"
      list-type="picture-card"
      :file-list="fileList"
      show-file-list
      :limit="1"
      :auto-upload="false"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :class="{ hide: value }"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <el-tooltip content="查看大图">
              <i class="el-icon-zoom-in"></i>
            </el-tooltip>
          </span>
          <!-- <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
          >
            <i class="el-icon-download"></i>
          </span> -->
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <el-tooltip content="删除图片">
              <i class="el-icon-delete"></i>
            </el-tooltip>
          </span>
        </span>
      </div>
    </el-upload>
    <!-- <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog> -->
    <ImageViewer v-if="showViewer" :on-close="() => {showViewer = false}" :url-list="previewSrcList" />
  </div>
</template>
<script>
import api from "@/utils/request";
import ImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
export default {
  components: { ImageViewer },
  props: {
    value: {
      type: String,
      value: "",
    },
  },
  data() {
    return {
      fileList: [],
      // dialogImageUrl: "",
      // dialogVisible: false,
      disabled: false,
      showViewer: false,
      previewSrcList: []
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.fileList = [{ name: "1.jpg", url: val }];
        } else {
          this.fileList = [];
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleRemove(file, fileList) {
      this.$emit("input", "");
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
      this.previewSrcList = [file.url]
      this.showViewer = true
    },
    handleChange(file) {
      let formData = new FormData();
      formData.append("file", file.raw);
      // 上传图片
      api.imageUp(formData).then((res) => {
        this.$emit("input", res.data.url);
      });
    },
  },
};
</script>

<style scoped>
::v-deep.hide .el-upload--picture-card {
  display: none;
}
</style>