<template>
  <div>
    <GlobalInfoBar title="入库记录" />
    <GlobalChunk icon="search" title="筛选查询" :padding="[20, 20, 20, 20]">
      <GlobalForm
        :form-item-list="formItemList"
        :inline="true"
        :labelWidth="80"
        round
        @handleConfirm="handleConfirm"
        confirmBtnName="查询"
      >
        <el-upload
          style="float: right;margin-left: 30px"
          ref="uploader"
          action="#"
          :limit="1"
          :auto-upload="false"
          :show-file-list="false"
          :file-list="fileList"
          slot="btnSlot"
          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :on-change="fileChangePut"
        >
          <el-button type="primary" size="small" round>入库导入</el-button>
        </el-upload>
        <el-upload
          style="float: right;margin-left: 30px"
          ref="uploader"
          action="#"
          :limit="1"
          :auto-upload="false"
          :show-file-list="false"
          :file-list="fileList"
          slot="btnSlot"
          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :on-change="fileChangeOut"
        >
          <el-button type="primary" size="small" round>出库导入</el-button>
        </el-upload>
        <div slot="AddSlot">
          <el-button type="primary" size="mini" @click="tableExport"
            >数据导出</el-button
          >
        </div>
      </GlobalForm>
      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns"
        :data="tableData"
        :currentPage="page"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
      >
        <el-table-column
          label="操作"
          slot="operate"
          align="center"
          fixed="right"
          width="150px"
        >
          <template slot-scope="{ row }">
            <el-button size="mini" round type="warning" @click="printInfo(row)"
              >打印</el-button
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import {
  getStockList,
  guideStockPut,
  guideStockOut,
  stockDownload,
  printStock,
} from "@/utils/request";
import { getLodop } from "@/utils/LodopFuncs";
export default {
  name: "index",
  data() {
    return {
      limit: 10,
      page: 1,
      total: 0,
      formItemList: [
        {
          key: "claims_sn",
          type: "input",
          labelName: "订单编码:",
          placeholder: "请输入订单编码查询",
        },
        {
          key: "entryTime",
          type: "daterange",
          labelName: "入库时间:",
          valueFormat: "timestamp",
        },
      ],
      tableColumns: [
        { label: "订单编码", prop: "claims_sn" },
        { label: "用户姓名", prop: "name" },
        { label: "手机号", prop: "phone" },
        { label: "串码", prop: "imei" },
        { label: "损坏情况", prop: "damage_title" },
        { label: "品牌机型", prop: "brand_model" },
        { label: "入库时间", prop: "put_time" },
        { label: "入库打印", prop: "put_time2" },
        { label: "出库时间", prop: "out_time" },
        { slotName: "operate" },
      ],
      tableData: [],
      claims_sn: "",
      beg_time: "",
      end_time: "",
      fileList: [],
      put_time2: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getStockList({
        page: this.page,
        claims_sn: this.claims_sn,
        beg_time: this.beg_time,
        end_time: this.end_time,
      }).then((res) => {
        this.tableData = res.data || [];
        this.total = res.count;
      });
    },
    handleConfirm(data) {
      console.log(`%c data`, "color:red", data);
      this.claims_sn = data.claims_sn;
      if (data.entryTime === null) {
        (this.beg_time = ""), (this.end_time = "");
      } else {
        this.beg_time = (data.entryTime && data.entryTime[0]) || "";
        this.end_time = (data.entryTime && data.entryTime[1]) || "";
      }
      this.page = 1;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    fileChangeOut(e) {
      let file = e.raw;
      let formData = new FormData();
      formData.append("file", file);
      guideStockOut(formData)
        .then((res) => {
          console.log(res, "resresresres");

          if (res && res.msg) {
            this.$alert(
              `<div style="max-height: 50vh;overflow-y: auto">${res.msg}</div>`,
              "提示",
              {
                confirmButtonText: "确定",
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            this.$message.success(
              (res && res.data && res.data.msg) || "上传成功"
            );
          }

          this.getList();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$refs.uploader.clearFiles();
        });
    },
    fileChangePut(e) {
      let file = e.raw;
      let formData = new FormData();
      formData.append("file", file);
      guideStockPut(formData)
        .then((res) => {
          if (res && res.msg) {
            this.$alert(
              `<div style="max-height: 50vh;overflow-y: auto">${res.msg}</div>`,
              "提示",
              {
                confirmButtonText: "确定",
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            this.$message.success(
              (res && res.data && res.data.msg) || "上传成功"
            );
          }

          this.getList();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$refs.uploader.clearFiles();
        });
    },
    // 打印批次号按钮
    printInfo(row) {
      printStock({ id: row.id }).then((res) => {
        this.put_time2 = res.data.put_time2;
        this.CreatePrintPage(row);
        setTimeout(() => {
          // this.LODOP.PREVIEW(); //预览打印
          this.LODOP.PRINT(); // 直接打印
          // this.LODOP.PRINT_DESIGN();//设计模式打印
        }, 300);
      });
    },
    CreatePrintPage(data) {
      const now = new Date();
      const year = now.getFullYear(); // 获取四位数的年份
      const month = now.getMonth() + 1; // 获取月份（0-11），需要加1
      const day = now.getDate(); // 获取月份中的日期（1-31）

      this.LODOP = getLodop();
      // this.LODOP.PRINT_INIT("条形码");
      this.LODOP.SET_PRINT_PAGESIZE(1, "40mm", "30mm", "");
      // this.LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", 'Full-Width');
      // this.LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
      // this.LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);DMPQ2ABDG5W0
      // this.LODOP.SET_PRINT_STYLE("FontName",'微软雅黑');
      //纯文本字体间距
      // this.LODOP.SET_PRINT_STYLE("LetterSpacing", 35);
      // //字号
      this.LODOP.SET_PRINT_STYLE("FontSize", 8);
      this.LODOP.ADD_PRINT_TEXT(10, 0, "80mm", 0, data.name);
      this.LODOP.ADD_PRINT_TEXT(25, 0, "80mm", 0, data.imei);
      this.LODOP.ADD_PRINT_TEXT(40, 0, "80mm", 0, data.brand);
      this.LODOP.ADD_PRINT_TEXT(55, 0, "80mm", 0, data.model);
      this.LODOP.ADD_PRINT_TEXT(70, 0, "80mm", 0, data.ram_size);
      this.LODOP.ADD_PRINT_TEXT(85, 0, "80mm", 0, data.damage_title);
      this.LODOP.ADD_PRINT_TEXT(100, 0, "80mm", 0, this.put_time2);
    },
    tableExport() {
      stockDownload({
        claims_sn: this.claims_sn,
        beg_time: this.beg_time,
        end_time: this.end_time,
      }).then((res) => {
        if (res.type === "application/json") {
          let fileReader = new FileReader();
          fileReader.readAsText(res);
          fileReader.addEventListener("loadend", (e) => {
            let err = JSON.parse(fileReader.result || "");
            this.$message.error(err.msg || "导出失败");
          });
        } else {
          var blob = new Blob([res], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          }); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob); // 创建下载的链接
          downloadElement.href = href;
          downloadElement.download = `入库记录_${+new Date()}.xls`; // 下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 点击下载
          document.body.removeChild(downloadElement); // 下载完成移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
        }
      });
    },
  },
};
</script>

<style scoped></style>
