module.exports = {
  id: 35,
  name: "up+全心服务",
  type: "register",
  form: [
      {
          label: "保卡卡密",
          prop: "card_secret",
          obj: "card",
          type: "input"
      },
      {
          label: "卡号",
          prop: "card_num",
          obj: "card",
          type: "input"
      },
      {
          label: "卡密商家",
          prop: "group_name",
          obj: "card",
          type: "input"
      },
      {
          label: "子险种",
          prop: "card_name",
          obj: "card",
          type: "input"
      },
      {
          label: "订单编号",
          prop: "order_sn",
          obj: "order",
          type: "input"
      },
      {
          label: "手机正面图",
          prop: "tem_img",
          obj: "order",
          type: "img",
          watermark: true
      },
      {
          label: "上传日期",
          prop: "add_time",
          obj: "order",
          type: "date"
      },
      {
          label: "姓名",
          prop: "name",
          obj: "order",
          type: "input"
      },
      {
          label: "手机号码",
          prop: "phone",
          obj: "order",
          type: "input"
      },
      {
          label: "身份证类型",
          prop: "id_card_type",
          obj: "order",
          type: "select",
          optListName: "IDcard",
          optLabel: "name",
          optValue: "status",
      },
      {
          label: "身份证号",
          prop: "id_card",
          obj: "order",
          type: "input"
      },
      {
          label: "手机品牌",
          prop: "brand_id",
          obj: "order",
          type: "select",
          optListName: "brandList",
          optLabel: "brand",
          optValue: "brand_id",
          relate: "model"
      },
      {
          label: "手机型号",
          prop: "model_id",
          obj: "order",
          type: "select",
          optListName: "modelList",
          optLabel: "model_name",
          optValue: "model_id"
      },
      {
          label: "IMEI码",
          prop: "imei",
          obj: "order",
          type: "input"
      },
      {
        label: "IMEI码2",
        prop: "push_imei",
        obj: "order",
        type: "input"
    },
      {
          label: "手机规格",
          prop: "ram_size",
          obj: "order",
          type: "select",
          optListName: "RAMList"
      },
      {
          label: "审核结果",
          prop: "exa_status",
          obj: "order",
          type: "radio",
          optListName: "statusList",
          optLabel: "name",
          optValue: "status",
          relate: "dateRange",
          hx:"hx"
      },
      {
          label: "生效日期",
          tempProp: "dateRange_term_time",
          obj: "order",
          props: [
              "term_time_start",
              "term_time_end"
          ],
          type: "dateRange"
      },
      {
          label: "回复内容",
          prop: "fail_exp",
          obj: "order",
          type: "textarea"
      },
      {
          label: "备注信息",
          prop: "remark",
          obj: "order",
          type: "textarea"
      },
      {
          label: "跟进业务员",
          prop: "group_remark",
          obj: "order",
          type: "input"
      },
      {
          label: "非本店购机视频",
          prop: "video",
          obj: "order",
          type: "video",
          isNullValueHiding: true
      }
  ],
  tableColumns: [
      {
          slotName: "headimg"
      },
      {
          label: "注册者昵称",
          prop: "nickname"
      },
      {
          label: "注册人名称",
          prop: "name"
      },
      {
          label: "手机号码",
          prop: "phone"
      },
      {
          label: "商家名称",
          prop: "group_name"
      },
      {
          label: "省份",
          prop: "province"
      },
      {
          label: "分销商",
          prop: "fxs_name"
      },
      {
          label: "店铺名称",
          prop: "shop_name"
      },
      {
          slotName: "operation"
      }
  ],
  piccCallbackType: 1
}
