import Layout from '@/layout/layout'

export default {
  path: '/renewal',
  component: Layout,
  redirect: 'noRedirect',
  name: '续保配置',
  children: [
    {
      path:'list',
      component: () => import('@/views/renewal/list'),
      name: '续保配置列表',
      meta: { title: '续保配置列表', noCache: true }
    }
  ]
}
// export default PartnerRouter