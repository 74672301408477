<template>
  <div class="cyberpunk-title" :style="{backgroundPosition,backgroundImage}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CyberpunkTitle",
  props: {
    length: {
      type: Number,
      default: 1
    }
  },
  computed: {
    backgroundImage() {
      let middleImg = `url(${require("../img/titlebox_m.png")})`
      let middleImgs = Array(this.length).fill(middleImg).join(',')
      return `url(${require("../img/titlebox_l.png")}),${middleImgs}, url(${require("../img/titlebox_r.png")})`
    },
    backgroundPosition() {
      let position = '';
      let x = 46
      for (let i = 0; i < this.length; i++) {
        x += 30
        position += `,${x}px 0 `
      }
      return '0 0,16px 0, 46px 0'+position
    }
  }
}
</script>

<style scoped>
.cyberpunk-title {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #00CDFF;
  background-repeat: no-repeat;
}
</style>
