module.exports = {
    id: 14,
    name: "IMEI更换申请",
    type: "changeImei",
    form: [
        {
            label: "IMEI码",
            prop: "old_imei",
            obj: "order",
            type: "input"
        },
        {
            label: "新机正面照片",
            prop: "tem_img",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "换机正面照片",
            prop: "tem_planes",
            obj: "order",
            type: "img",
            watermark: true
        },
        {
            label: "上传日期",
            prop: "add_time",
            obj: "order",
            type: "date"
        },
        {
            label: "姓名",
            prop: "name",
            obj: "order",
            type: "input"
        },
        {
            label: "手机号码",
            prop: "phone",
            obj: "order",
            type: "input"
        },
        {
            label: "更换原因",
            prop: "replace_reason",
            obj: "order",
            type: "select",
            optListName: "changeIMEIReasonList",
            optLabel: "replace_name",
            optValue: "id"
        },
        {
            label: "新IMEI号",
            prop: "imei",
            obj: "order",
            type: "input"
        },
        {
            label: "更换类型",
            prop: "instype_id",
            obj: "order",
            type: "select",
            optListName: "changeIMEITypeList",
            optLabel: "instype_name",
            optValue: "instype_id"
        },
        {
            label: "审核结果",
            prop: "exa_status",
            obj: "order",
            type: "radio",
            optListName: "statusList",
            optLabel: "name",
            optValue: "status"
        },
        {
            label: "回复内容",
            prop: "fail_exp",
            obj: "order",
            type: "textarea"
        },
        {
            label: "订单备注",
            prop: "remark",
            obj: "order",
            type: "textarea"
        }
    ],
    tableColumns: [
        {
            slotName: "headimg"
        },
        {
            label: "注册者昵称",
            prop: "nickname"
        },
        {
            slotName: "operation"
        }
    ],
    piccCallbackType: 2
}

