module.exports = {
    id: 21,
    name: "照片补交",
    type: "photoResubmission",
    form: [
        {
            label: "补交照片",
            prop: "tem_img",
            obj: "order",
            type: "img2",
            watermark: true
        },
        {
            label: "上传日期",
            prop: "add_time",
            obj: "order",
            type: "date"
        },
        {
            label: "姓名",
            prop: "name",
            obj: "order",
            type: "input"
        },
        {
            label: "手机号码",
            prop: "phone",
            obj: "order",
            type: "input"
        },
        {
            label: "IMEI码",
            prop: "imei",
            obj: "order",
            type: "input"
        },
        {
            label: "补交照片类型",
            prop: "relation_type",
            obj: "order",
            type: "select",
            optListName: "photoResubmissionTypeList",
            optLabel: "name",
            optValue: "id",
            disabled: true
        },
        {
            label: "关联订单",
            prop: "order_list",
            type: "photoResubmissionOrderList"
        },
        {
            label: "审核结果",
            prop: "exa_status",
            obj: "order",
            type: "radio",
            optListName: "statusList",
            optLabel: "name",
            optValue: "status"
        },
        {
            label: "回复内容",
            prop: "fail_exp",
            obj: "order",
            type: "textarea"
        }
    ],
    tableColumns: [
        {
            slotName: "headimg"
        },
        {
            label: "注册者昵称",
            prop: "nickname"
        }
    ],
    piccCallbackType: 1
}

