var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logistics-list"},[_c('GlobalInfoBar',{attrs:{"title":"物流管理"}}),_c('GlobalForm',{attrs:{"init-data":_vm.initData,"form-rules":_vm.formRules,"form-item-list":_vm.formItemList,"inline":true,"round":"","confirmBtnName":"查询"},on:{"handleConfirm":_vm.handleConfirm}},[_c('div',{attrs:{"slot":"AddSlot"},slot:"AddSlot"},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addLogistics()}}},[_vm._v("新增物流信息")])],1)]),_c('GlobalTable',{ref:"GlobalTable",attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"currentPage":_vm.currentPage,"total":_vm.totalElement},on:{"handleCurrentChange":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"slot":"link","label":"物流链接","align":"center"},slot:"link",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":row.link,"target":"_blank"}},[_vm._v(_vm._s(row.link))])]}}])}),_c('el-table-column',{attrs:{"slot":"is_open","label":"保险理赔","align":"center"},slot:"is_open",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.switchChange(row,'is_open')}},model:{value:(row.is_open),callback:function ($$v) {_vm.$set(row, "is_open", $$v)},expression:"row.is_open"}})]}}])}),_c('el-table-column',{attrs:{"slot":"is_huanxin","label":"五折焕新","align":"center"},slot:"is_huanxin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.switchChange(row,'is_huanxin')}},model:{value:(row.is_huanxin),callback:function ($$v) {_vm.$set(row, "is_huanxin", $$v)},expression:"row.is_huanxin"}})]}}])}),_c('el-table-column',{attrs:{"slot":"operat","label":"操作","align":"center"},slot:"operat",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"mr_sty",attrs:{"type":"text"},on:{"click":function($event){return _vm.editLogistics(row,'edit')}}},[_vm._v("修改")]),_c('el-button',{staticClass:"btn_delete",attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteDis(row)}}},[_vm._v("删除")])]}}])})],1),_c('el-dialog',{attrs:{"visible":_vm.visible,"title":_vm.dialogTitle,"width":"600px"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"100px"}},[_c('el-form-item',{staticClass:"el_form",attrs:{"label":"物流名称","prop":"title"}},[_c('el-input',{attrs:{"placeholder":"请输入物流名称"},model:{value:(_vm.ruleForm.title),callback:function ($$v) {_vm.$set(_vm.ruleForm, "title", $$v)},expression:"ruleForm.title"}})],1),_c('el-form-item',{staticClass:"el_form",attrs:{"label":"物流链接","prop":"link"}},[_c('el-input',{staticClass:"link-input",attrs:{"placeholder":"请输入物流链接"},on:{"input":_vm.linkInput},model:{value:(_vm.ruleForm.link),callback:function ($$v) {_vm.$set(_vm.ruleForm, "link", $$v)},expression:"ruleForm.link"}},[_c('el-select',{attrs:{"slot":"prepend","placeholder":"请选择协议"},slot:"prepend",model:{value:(_vm.protocol),callback:function ($$v) {_vm.protocol=$$v},expression:"protocol"}},[_c('el-option',{attrs:{"label":"http","value":"http://"}}),_c('el-option',{attrs:{"label":"https","value":"https://"}})],1)],1)],1),_c('el-form-item',{staticClass:"el_form_r"},[(this.dialogTitle==='新增物流')?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm','add')}}},[_vm._v("确认新增")]):_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm','edit')}}},[_vm._v("确认修改")]),_c('el-button',{on:{"click":function($event){return _vm.resetForm('ruleForm')}}},[_vm._v("取消")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }