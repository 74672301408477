<template>
  <div class="account">
    <GlobalInfoBar
        title="个人理赔"
    />
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :init-data="initData"
          :form-rules="formRules"
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
          :label-width="120"
      >
        <el-form-item
          class="el_from"
          label="订单编码:"
          slot="sn"
          style="margin:0"
        >
          <el-input v-model="sn" clearable placeholder="请输入订单编码查询"></el-input>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="IMEI:"
          slot="imei"
          style="margin:0"
        >
          <el-input v-model="imei" clearable placeholder="请输入IMEI查询"></el-input>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="联系电话:"
          slot="telephone"
          style="margin:0"
        >
          <el-input v-model="telephone" clearable placeholder="请输入联系电话查询"></el-input>
        </el-form-item>
        <el-form-item
        class="el_from"
        label="理赔推送时间:"
        slot="is_push_state_time"
        style="margin: 0"
      >
        <el-date-picker
          v-model="is_push_state_time"
          type="daterange"
          value-format="timestamp"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
        <div slot="AddSlot">
          <el-button type="primary" size="mini" @click="tableExport">列表导出</el-button>
        </div>
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="理赔单编号" slot="claimId" align="center" width="150px">
          <template slot-scope="{ row }">
            <a :href="`/order/detail/${row.lp_mold}/${row.claims_id}`" target="a">
              {{ row.claimId }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="维修单编码" slot="finish_sn" align="center" width="150px">
          <template slot-scope="{ row }">
            <a :href="`/order/detail/${row.wx_mold}/${row.finish_id}`" target="b">
              {{ row.finish_sn }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="是否承保" slot="is_accept" align="center" width="80px">
          <template slot-scope="{ row }">
            {{ row.is_accept?'是':'否' }}
          </template>
        </el-table-column>
        <el-table-column label="是否报案" slot="is_report" align="center" width="80px">
          <template slot-scope="{ row }">
            {{ row.is_report?'是':'否' }}
          </template>
        </el-table-column>
        <el-table-column label="是否理赔推送" slot="is_push_state" align="center" width="100px">
          <template slot-scope="{ row }">
            {{ row.is_push_state?'是':'否' }}
          </template>
        </el-table-column>
        <el-table-column label="打款时间" slot="payTime" align="center" width="200px">
          <template slot-scope="{ row }">
            {{ row.payTime||'--' }}
            <el-button style="margin-left: 10px" v-if="row.payTime" type="text" @click="view(row)">查看</el-button>

          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operation" align="center" width="250" fixed="right">
          <template slot-scope="{ row }">
           <el-button type="warning" size="mini" @click="remark(row)">{{row.remark?'查看':'添加'}}备注</el-button>
           <el-button type="primary" size="mini" @click="report(row)">报案</el-button>
           <el-button type="primary" size="mini" @click="claimSettlement(row)">理赔</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <el-dialog :title="remarkDialog.type" :visible.sync="remarkDialog.isShow" width="400px">
      <el-input v-model="remarkDialog.text" placeholder="请填写备注" type="textarea"></el-input>
      <div style="margin: 20px 0;float: right">
        <el-button type="plain" size="mini" @click="remarkDialog.isShow=false">取消</el-button>
        <el-button type="primary" size="mini" @click="remarkConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/utils/request'
import {perClaimRemark} from "../../utils/request";
export default {
  name: "account",
  data() {
    return {
      initData: {},
      formRules: {},
      formItemList: [
        { slotName: "sn" },
        { slotName: "imei" },
        { slotName: "telephone" },
        { slotName: "is_push_state_time" },
      ],
      imei: '',
      sn: '',
      telephone: '',
      is_push_state_time: [],
      tableColumns: [
        {slotName: 'claimId'},
        {slotName: 'finish_sn'},
        {label: "IMEI", prop: "imei", width:"150px"},
        {label: "提交时间", prop: "add_time", width:"150px"},
        {label: "保险类型", prop: "instype_name", width:"150px"},
        {label: "领款人姓名", prop: "payeeName"},
        {label: "联系电话", prop: "telephone"},
        {slotName: 'is_accept'},
        {slotName: 'is_report'},
        {label: "报案时间", prop: "is_report_time"},
        {label: "报案回调", prop: "is_report_back"},
        {slotName: 'is_push_state'},
        {label: "理赔推送时间", prop: "is_push_state_time"},
        {label: "理赔推送回调", prop: "is_push_state_back"},
        {slotName: 'payTime'},
        {slotName: 'operation'},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      pageSize: 10,
      remarkDialog:{
        isShow:false,
        type: '',
        text:'',
        row:null
      },
      searchParams: null
    }
  },
  created(){
    if(sessionStorage.getItem('personal')){
      this.searchParams = JSON.parse(sessionStorage.getItem('personal'))
      sessionStorage.removeItem('personal')
        this.curPage = this.searchParams.page
      this.pageSize = this.searchParams.limit
      this.sn = this.searchParams.sn
      this.imei = this.searchParams.imei
      this.telephone = this.searchParams.telephone
      this.is_push_state_time = this.searchParams.beg_time?[this.searchParams.beg_time,this.searchParams.end_time]:[]
    }else{
      this.searchParams = null
    }
    this.getList()
  },
  mounted() {
    // if(sessionStorage.getItem('personal')){
    //   this.searchParams = JSON.parse(sessionStorage.getItem('personal'))
    //   sessionStorage.removeItem('personal')
    //   this.$nextTick(function () {
    //     this.curPage = this.searchParams.page
    //   })
    //   this.pageSize = this.searchParams.limit
    //   this.sn = this.searchParams.sn
    //   this.imei = this.searchParams.imei
    //   this.telephone = this.searchParams.telephone
    //   this.is_push_state_time = this.searchParams.beg_time?[this.searchParams.beg_time,this.searchParams.end_time]:[]
    // }else{
    //   this.searchParams = null
    // }
    // this.getList()
  },
  methods: {
    query(data, cd) {
      // this.sn = data.sn
      // this.imei = data.imei
      // this.telephone = data.telephone
      // this.is_push_state_time = data.is_push_state_time
      this.curPage = 1
      this.getList()
    },
    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    getList() {
      let params = {
        page: this.curPage,
        limit: this.pageSize,
        sn: this.sn,
        imei: this.imei,
        telephone: this.telephone,
        beg_time:this.is_push_state_time&&this.is_push_state_time[0]||'',
        end_time:this.is_push_state_time&&this.is_push_state_time[1]||'',
      }
      this.searchParams = params
      api.perClaimList(params).then(res => {
        this.tableData = res.data
        this.totalElements = res.count || 0
      })
    },
    claimSettlement(row) {
      // let routeData = this.$router.resolve({
      //   name:'个人理赔详情',
      //   params:{
      //     id:row.per_claim_id
      //   }
      // });
      // window.open(routeData.href, "_blank");
      sessionStorage.setItem('personal',JSON.stringify(this.searchParams))
      this.$router.push({
        name:'个人理赔详情',
        params:{
          id:row.per_claim_id
        }
      })
    },
    remark(row) {
      this.remarkDialog.type=row.remark?'编辑备注':'添加备注'
      this.remarkDialog.isShow=true
      this.remarkDialog.text=row.remark
      this.remarkDialog.row=row
    },
    remarkConfirm() {
      perClaimRemark({
        per_claim_id:this.remarkDialog.row.per_claim_id,
        remark:this.remarkDialog.text,
      }).then(data=>{
        this.getList()
        this.remarkDialog.isShow=false
        this.$message.success(data&&data.msg||'操作成功')
      }).catch(err=>{
        console.error(err)
      })
    },
    report(row) {
      console.log(row)
      // let routeData = this.$router.resolve({
      //   name:'报案详情',
      //   params:{
      //     id:row.per_claim_id
      //   }
      // });
      // window.open(routeData.href, "_blank");
      sessionStorage.setItem('personal',JSON.stringify(this.searchParams))
      this.$router.push({
        name:'报案详情',
        params:{
          id:row.per_claim_id
        }
      })
    },
    view(row) {
    let a=  document.createElement('a')
      a.target='paymentCallback'
      a.href= this.$router.resolve({
        name:'打款回调',
        query:{
          id:row.claimId
        }
      }).href
      a.click()
      document.removeChild(a)
    },
    tableExport() {
      api.perClaimList2({
        page: this.curPage,
        limit: this.pageSize,
        sn: this.sn,
        imei: this.imei,
        telephone: this.telephone,
        beg_time:this.is_push_state_time&&this.is_push_state_time[0]||'',
        end_time:this.is_push_state_time&&this.is_push_state_time[1]||'',
        download:1
      }).then(res => {

        if (res.type === 'application/json') {
          let fileReader = new FileReader()
          fileReader.readAsText(res)
          fileReader.addEventListener('loadend', (e) => {
            let err = JSON.parse(fileReader.result || "")
            this.$message.error(err.msg || '导出失败')
          });
        } else {
          var blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'}); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob); // 创建下载的链接
          downloadElement.href = href;
          downloadElement.download = `个人理赔_${+new Date()}.xls`; // 下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 点击下载
          document.body.removeChild(downloadElement); // 下载完成移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.account{
  /deep/ .el-form-item__label{
    width: 80px!important;
  }
}

</style>
