var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GlobalInfoBar',{attrs:{"title":"物流订单列表"}}),_c('GlobalChunk',{attrs:{"icon":"search","title":"筛选查询","padding":[20,20,20,20]}},[_c('GlobalForm',{attrs:{"form-item-list":_vm.formItemList,"inline":true,"round":"","confirmBtnName":"查询"},on:{"handleConfirm":_vm.handleConfirm}},[_c('el-upload',{ref:"uploader",staticStyle:{"float":"right","margin-left":"30px"},attrs:{"slot":"btnSlot","action":"#","limit":1,"auto-upload":false,"show-file-list":false,"file-list":_vm.fileList,"accept":".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","on-change":_vm.fileChange},slot:"btnSlot"},[_c('el-button',{attrs:{"type":"primary","size":"small","round":""}},[_vm._v("物流编码导入(签收)")])],1)],1),_c('GlobalTable',{ref:"GlobalTable",attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"currentPage":_vm.page,"total":_vm.total},on:{"handleCurrentChange":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"slot":"status","label":"状态","align":"center","width":"80px"},slot:"status",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_sign===1)?_c('el-tag',{attrs:{"size":"mini"}},[_vm._v("已签收")]):_vm._e(),(row.is_sign===0)?_c('el-tag',{attrs:{"size":"mini","type":"warning"}},[_vm._v("未签收")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"slot":"order_sn","label":"原始订单编码","align":"center"},slot:"order_sn",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticStyle:{"color":"#7F7FFF"},attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.toOriginalOrder(row)}}},[_vm._v(_vm._s(row.order_sn))])]}}])}),_c('el-table-column',{attrs:{"slot":"orderNumber","label":"物流订单编码","align":"center"},slot:"orderNumber",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popover',{ref:"popover1",attrs:{"placement":"top-start","width":"500","trigger":"hover"}},[_c('p',{staticStyle:{"font-weight":"bold","margin-bottom":"20px"}},[_vm._v("物流信息查询")]),_c('p',[_vm._v("系统默认查询网址为:快递100 "),_c('a',{attrs:{"href":"javascript:"}},[_vm._v("http://www.kuaidi100.com ")]),_c('el-button',{staticStyle:{"padding":"4px 10px","margin-left":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.queryLogisticsInfoNow(row)}}},[_vm._v("立即查询 ")])],1),_c('a',{staticStyle:{"color":"#3ABAF3"},attrs:{"slot":"reference","href":"javascript:"},slot:"reference"},[_vm._v(_vm._s(row.orderNumber))])])]}}])}),_c('el-table-column',{attrs:{"slot":"operate","label":"操作","align":"center","fixed":"right","width":"150px"},slot:"operate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.sign(row)}}},[_vm._v(_vm._s(row.is_sign === 0 ? '标记已签收' : '标记未签收'))]),_c('el-popover',{ref:"popover2",attrs:{"placement":"left","trigger":"hover"}},[_c('p',{staticStyle:{"font-weight":"bold","margin-bottom":"20px"}},[_vm._v(_vm._s(row.remarks ? '修改' : '添加')+"备注")]),_c('el-input',{staticStyle:{"width":"300px"},attrs:{"type":"textarea","placeholder":"请填写备注"},model:{value:(row.remarksCopy),callback:function ($$v) {_vm.$set(row, "remarksCopy", $$v)},expression:"row.remarksCopy"}}),_c('br'),_c('el-button',{staticStyle:{"margin-top":"20px","float":"right"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.remarkConfirm(row)}}},[_vm._v(" 确定 ")]),_c('a',{staticStyle:{"margin-left":"20px"},attrs:{"slot":"reference","href":"javascript:"},on:{"mouseenter":function($event){return _vm.operateRemark(row)}},slot:"reference"},[_vm._v(_vm._s(row.remarks ? '查看' : '添加')+"备注")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }