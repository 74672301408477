<template>
  <div class="account">
    <GlobalInfoBar title="报案理赔列表" />
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <GlobalForm :init-data="initData" :form-rules="formRules" :form-item-list="formItemList" :inline="true" round
        @handleConfirm="query" confirmBtnName="查询" :label-width="120">
        <el-form-item class="el_from" label="报案号:" slot="reportNo" style="margin:0">
          <el-input v-model="reportNo" clearable placeholder="请输入报案号查询"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="流水号:" slot="serialNo" style="margin:0">
          <el-input v-model="serialNo" clearable placeholder="请输入流水号查询"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="保单号:" slot="policyNo" style="margin:0">
          <el-input v-model="policyNo" clearable placeholder="请输入保单号查询"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="IMEI:" slot="imei" style="margin:0">
          <el-input v-model="imei" clearable placeholder="请输入IMEI查询"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="联系电话:" slot="phone" style="margin:0">
          <el-input v-model="phone" clearable placeholder="请输入联系电话查询"></el-input>
        </el-form-item>
        <div slot="AddSlot">
          <el-button type="primary" size="mini" @click="tableExport">列表导出</el-button>
        </div>
      </GlobalForm>

      <GlobalTable ref="GlobalTable" :columns="tableColumns" :data="tableData" :currentPage="curPage"
        :total="totalElements" @handleCurrentChange="currentChange">
        <el-table-column label="操作" slot="operation" align="center" width="250" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="text" size="mini" @click="seeDetails(row)">查看详情</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import environments from '../../config/url'
import api from '@/utils/request'
export default {
  name: "account",
  data() {
    return {
      initData: {},
      formRules: {},
      formItemList: [
        { slotName: "imei" },
        { slotName: "phone" },
        { slotName: "reportNo" },
        { slotName: "serialNo" },
        { slotName: "policyNo" },
        {
          key: "entryTime",
          type: "daterange",
          labelName: "时间:",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
        },
      ],
      imei: '',
      phone: '',
      reportNo:'',
      serialNo:'',
      policyNo:'',
      beg_time:'',
      end_time:'',
      tableColumns: [
      { label: "报案单号", prop: "reportNo"},
      { label: "流水号", prop: "serialNo"},
      { label: "保单号", prop: "policyNo"},
      { label: "报案人电话", prop: "reporterTel"},
      { label: "出险时间", prop: "accidentDate"},
      { label: "报损金额 （分）", prop: "reportLossSum"},
      { label: "故障描述", prop: "accidentDesc"},
      { label: "设备唯一标识IMEI", prop: "deviceIdentity"},
      { label: "产品编码", prop: "productCode"},
      { label: "产品责任编码", prop: "planCode"},
      { label: "出险区域", prop: "accidentArea"},
      { label: "理赔状态", prop: "claimStatusName"},
      { label: "添加时间", prop: "add_time"},
      {slotName: 'operation'},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      pageSize: 10,
      remarkDialog: {
        isShow: false,
        type: '',
        text: '',
        row: null
      },
      searchParams: null
    }
  },
  created() {
    this.getList()
  },
  mounted() {
  },
  methods: {
    tableExport() {
      console.log(environments[process.env.VUE_APP_BASE_URL].baseURL);
      const url = `${environments[process.env.VUE_APP_BASE_URL].baseURL}/Vivoreport/reportList?download=1&imei=${this.imei}&phone=${this.phone}&beg_time=${this.beg_time}&end_time=${this.end_time}&reportNo=${this.reportNo}&serialNo=${this.serialNo}&policyNo=${this.policyNo}` // 下载文件的url       
      const link = document.createElement('a')        
      link.href = url
      link.download = '报案理赔列表.xls' // 下载文件的名称
      link.click()
    },
    query(data, cd) {
      cd();
      if(data){
        if (data.entryTime === null) {
          (this.beg_time = ""), (this.end_time = "");
        } else {
          this.beg_time = (data.entryTime && data.entryTime[0]) || "";
          this.end_time = (data.entryTime && data.entryTime[1]) || "";
        }
      }else {
        (this.beg_time = ""), (this.end_time = "");
      }
      this.curPage = 1
      this.getList()
    },
    currentChange(page) {
      this.curPage = page
      this.getList()
    },
    getList() {
      let params = {
        page: this.curPage,
        limit: this.pageSize,
        imei: this.imei,
        phone: this.phone,
        reportNo:this.reportNo,
        serialNo:this.serialNo,
        policyNo:this.policyNo,
        beg_time:this.beg_time,
        end_time:this.end_time
      }
      this.searchParams = params
      api.settleReportList(params).then(res => {
        this.tableData = res.data
        this.totalElements = res.count || 0
      })
    },
    seeDetails(row) {
      console.log('===',row);
      this.$router.push({
        name: '报案理赔详情',
        params: {
          reportId: row.reportId
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.account {
  /deep/ .el-form-item__label {
    width: 80px !important;
  }
}
</style>
