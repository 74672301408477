import request from './request'

export function repairManagerList(params){
    return request({
        method:'POST',
        url:'/Repair/repairManagerList',
        data:params,
    })
}
export function addRepairManager(params){
    return request({
        method:'POST',
        url:'/Repair/addRepairManager',
        data:params,
    })
}
export function getRapairRange(){
    return request({
        method:'GET',
        url:'/Repair/getRapairRange'
    })
}
export function delRepairManager(params){
    return request({
        method:'POST',
        url:'/Repair/delRepairManager',
        data:params,
    })
}
export function editRepair(params){
    return request({
        method:'POST',
        url:'/Repair/editRepair',
        data:params,
    })
}
export function repairShopList(params){
    return request({
        method:'POST',
        url:'/Repair/repairShopList',
        data:params,
    })
}
export function repairMemberList(params){
    return request({
        method:'POST',
        url:'/Repair/repairMemberList',
        data:params,
    })
}
export function addRepairShop(params){
    return request({
        method:'POST',
        url:'/Repair/addRepairShop',
        data:params,
    })
}
export function delRepairShop(params){
    return request({
        method:'POST',
        url:'/Repair/delRepairShop',
        data:params,
    })
}
export function repairMemberInfo(params){
    return request({
        method:'POST',
        url:'/Repair/repairMemberInfo',
        data:params,
    })
}
export function editRepairMember(params){
    return request({
        method:'POST',
        url:'/Repair/editRepairMember',
        data:params,
    })
}