<template>
  <div class="editAdd">
    <GlobalInfoBar title="订单审核" />
    <GlobalChunk icon="order" title="订单信息" :padding="[32, 0, 95, 55]">
      <GlobalForm
        :init-data="initData"
        :form-item-list="formItemList"
        :form-rules="formRules"
        @handleConfirm="handleConfirm"
        confirmBtnName="确定审核"
      >
        <el-form-item class="el_from" label="订单类型:" slot="operation">
          <el-select :disabled="true" v-model="initData.order_mold">
            <el-option
              v-for="item in options1"
              :key="item.order_mold"
              :label="item.name"
              :value="item.order_mold"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_from" label="用户姓名:" slot="operation">
          <el-input :disabled="true" v-model="initData.name"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="用户手机:" slot="operation">
          <el-input :disabled="true" v-model="initData.phone"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="邮寄地址:" slot="operation">
          <el-input :disabled="true" v-model="initData.address"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="用户收件地址:" slot="operation" v-if="initData.user_address">
          <el-input :disabled="true" v-model="initData.user_address"></el-input>
        </el-form-item>
        <el-form-item class="el_from" label="支付宝账号:" slot="operation" v-if="initData.user_account">
          <el-input :disabled="true" v-model="initData.user_account"></el-input>
        </el-form-item>
        <template v-if="initData.order_mold == 2 || initData.order_mold == 3">
          <el-form-item class="el_from" label="活动机型:" slot="operation">
            <el-input :disabled="true" v-model="initData.old_model"></el-input>
          </el-form-item>
          <el-form-item class="el_from" label="活动机型内存:" slot="operation">
            <el-input :disabled="true" v-model="initData.old_ram"></el-input>
          </el-form-item>
          <el-form-item class="el_from" label="活动机型价格:" slot="operation">
            <el-input :disabled="true" v-model="initData.old_price"></el-input>
          </el-form-item>
          <el-form-item class="el_from" label="抵扣金额:" slot="operation">
            <el-input
              :disabled="true"
              v-model="initData.claims_money"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="initData.order_mold == 2"
            class="el_from"
            label="新机品牌:"
            slot="operation"
          >
            <el-input :disabled="true" v-model="initData.new_brand"></el-input>
          </el-form-item>
          <el-form-item
            v-if="initData.order_mold == 2"
            class="el_from"
            label="新机机型:"
            slot="operation"
          >
            <el-input :disabled="true" v-model="initData.new_model"></el-input>
          </el-form-item>
          <el-form-item
            v-if="initData.order_mold == 2"
            class="el_from"
            label="新机内存:"
            slot="operation"
          >
            <el-input :disabled="true" v-model="initData.new_ram"></el-input>
          </el-form-item>
          <el-form-item
            v-if="initData.order_mold == 2"
            class="el_from"
            label="新机颜色:"
            slot="operation"
          >
            <el-input :disabled="true" v-model="initData.new_colour"></el-input>
          </el-form-item>
          <el-form-item
            v-if="initData.order_mold == 2"
            class="el_from"
            label="新机价格:"
            slot="operation"
          >
            <el-input :disabled="true" v-model="initData.new_price"></el-input>
          </el-form-item>
          <el-form-item
            v-if="initData.order_mold == 2"
            class="el_from"
            label="用户补缴金额:"
            slot="operation"
          >
            <el-input :disabled="true" v-model="initData.money"></el-input>
          </el-form-item>
        </template>

        <el-form-item
          class="el_from"
          label="订单状态:"
          slot="operation"
          prop="order_state"
        >
          <el-radio-group v-model="initData.order_state">
            <el-radio
              v-for="item in stateOptions"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="initData.order_mold == 2"
          class="el_from"
          label="订单审核:"
          slot="operation"
          prop="order_examine"
        >
          <el-radio-group v-model="initData.order_examine">
            <el-radio
              v-for="item in examineOptions"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-button slot="btnSlot" @click="FromBack">取消</el-button>
      </GlobalForm>
    </GlobalChunk>
  </div>
</template>

<script>
import {
  couponOrderInfo,
  couponOrderExamine,
} from "../../../utils/request/coupon";
export default {
  name: "editAdd",
  data() {
    return {
      disabled: false,
      titleBar: "",
      options1: [
        { order_mold: 1, name: "维修" },
        { order_mold: 2, name: "以旧换新" },
        { order_mold: 3, name: "纯回收" },
      ],
      stateOptions: [],
      examineOptions: [],
      initData: {},
      formItemList: [{ slotName: "operation" }],
      formRules: {
        order_state: [
          {
            required: true,
            trigger: "change",
            message: "请选择订单状态",
          },
        ],
        order_examine: [
          {
            required: true,
            trigger: "change",
            message: "请选择订单审核",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    couponOrderInfo({ order_id: this.$route.query.id }).then((res) => {
      if (res.code === 1) {
        this.initData = res.data.order;
        this.initData.order_state =
          this.initData.order_state == 0 ? "" : this.initData.order_state;
        this.initData.order_examine =
          this.initData.order_examine == 0 ? "" : this.initData.order_examine;
        this.stateOptions = res.data.state;
        this.examineOptions = res.data.examine;
      }
    });
  },
  methods: {
    // 提交
    handleConfirm(data, cd) {
      cd();
      const params = {
        order_id: this.$route.query.id,
        order_state: this.initData.order_state,
        order_examine: this.initData.order_examine,
      };
      couponOrderExamine(params).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
          this.$router.push("/coupon/order");
        }
      });
    },
    FromBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
::v-deep .el-form-item__label {
  width: 140px !important;
}
.editAdd {
  .text_item {
    color: #999;
    margin-left: 20px;
  }
  .el_from {
    margin-left: -120px;
  }
  /deep/ .el-input__inner {
    width: 380px;
  }
  /deep/ .el-input {
    width: 380px;
  }
  .item_dia {
    display: flex;
    justify-content: left;
    align-items: center;
    .left {
      width: 60px;
      margin: 0px 0 10px 10px;
      color: black;
    }
    .right {
      margin: 0px 0 10px 10px;
      color: #ff8080;
    }
  }
  .btn_bot {
    float: right;
    margin: 30px;
  }
}
</style>
