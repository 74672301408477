var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GlobalInfoBar',{attrs:{"title":"运营通道列表 / 机型设置"}}),_c('GlobalChunk',{attrs:{"padding":[20,20,20,20]}},[_c('GlobalForm',{attrs:{"form-item-list":_vm.formItemList,"inline":true,"round":"","confirmBtnName":"查询"},on:{"handleConfirm":_vm.query}}),_c('p',{staticStyle:{"color":"#8080FF","font-size":"14px","margin-bottom":"20px"}},[_vm._v("说明:在输入框内输入新机采购成本，点击提交完成采购价格设置")]),_c('GlobalTable',{ref:"GlobalTable",attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"currentPage":_vm.curPage,"total":_vm.totalElements},on:{"handleCurrentChange":_vm.currentChange}},[_c('el-table-column',{attrs:{"slot":"official_price","label":"官网价格","align":"center"},slot:"official_price",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{staticStyle:{"width":"120px","text-align":"center"},attrs:{"placeholder":"请输入官网价格","size":"mini"},on:{"change":function($event){return _vm.setChange(row)},"input":function($event){return _vm.moneyInput(row,'official_price')}},model:{value:(row.official_price),callback:function ($$v) {_vm.$set(row, "official_price", $$v)},expression:"row.official_price"}})]}}])}),_c('el-table-column',{attrs:{"slot":"cost_price","label":"采购成本","align":"center"},slot:"cost_price",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{staticStyle:{"width":"120px","text-align":"center"},attrs:{"placeholder":"请输入新机价格","size":"mini"},on:{"change":function($event){return _vm.setChange(row)},"input":function($event){return _vm.moneyInput(row,'cost_price')}},model:{value:(row.cost_price),callback:function ($$v) {_vm.$set(row, "cost_price", $$v)},expression:"row.cost_price"}})]}}])}),_c('el-table-column',{attrs:{"slot":"price","label":"5折价格","align":"center"},slot:"price",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{staticStyle:{"width":"120px","text-align":"center"},attrs:{"placeholder":"请输入5折价格","size":"mini"},on:{"change":function($event){return _vm.setChange(row)},"input":function($event){return _vm.moneyInput(row,'price')}},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}})]}}])}),_c('el-table-column',{attrs:{"slot":"stock","label":"库存","align":"center"},slot:"stock",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{staticStyle:{"width":"120px","text-align":"center"},attrs:{"placeholder":"请输入新机库存","size":"mini"},on:{"change":function($event){return _vm.setChange(row)},"input":function($event){return _vm.stockInput(row,'stock')}},model:{value:(row.stock),callback:function ($$v) {_vm.$set(row, "stock", $$v)},expression:"row.stock"}})]}}])}),_c('el-table-column',{attrs:{"slot":"add_time","label":"最后修改时间","align":"center"},slot:"add_time",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.add_time || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"slot":"admin","label":"修改人","align":"center"},slot:"admin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.admin || '--')+" ")]}}])})],1),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('el-button',{attrs:{"type":"primary","plain":"","size":"medium"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")]),_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":_vm.confirm}},[_vm._v("修改")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }