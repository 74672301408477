import Layout from '@/layout/layout'

const DemoVideoRouter = {
  path: '/order',
  component: Layout,
  redirect: 'noRedirect',
  name: '订单管理',
  children: [
    {
      path:'list',
      component: () => import('@/views/order/list/index'),
      name: '订单列表',
      meta: { title: '订单列表', noCache: true }
    },{
      path:'detail/:type/:orderId', //type:订单类型 orderId
      component: () => import('@/views/order/detail'),
      props:true,
      name: '订单详情',
      meta: { title: '订单详情', noCache: true }
    },
    {
      path:'recycle', //type:订单类型 orderId
      component: () => import('@/views/order/recycle'),
      props:true,
      name: '订单回收站',
      meta: { title: '订单回收站', noCache: true }
    },
  ]
}

export default DemoVideoRouter
