<template>
  <div>
    <GlobalInfoBar title="活动订单" subtitle="" />
    <GlobalChunk :padding="[20, 20, 20, 20]">
      <GlobalForm
        :form-item-list="formItemList"
        :inline="true"
        round
        :labelWidth="80"
        @handleConfirm="query"
        confirmBtnName="查询"
      >
        <div slot="AddSlot">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="exportTable"
            >表格下载</el-button
          >
        </div>
      </GlobalForm>

      <GlobalTable
        v-loading="loading"
        ref="GlobalTable"
        :columns="tableColumns"
        :data="tableData"
        :currentPage="curPage"
        :total="totalElements"
        @handleCurrentChange="currentChange"
      >
        <el-table-column label="操作" slot="operation" align="center">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="editInfo(row)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import { couponOrderlist, exportOrder } from "../../../utils/request/coupon";

export default {
  name: "index",
  data() {
    return {
      loading: false,
      formItemList: [
        {
          key: "phone",
          type: "input",
          labelName: "手机号:",
          placeholder: "请输入手机号查询",
        },
        {
          key: "order_mold",
          idKey: "order_mold",
          labelKey: "name",
          type: "selectFilterable",
          labelName: "订单类型:",
          option: [
            { order_mold: 1, name: "维修" },
            { order_mold: 2, name: "以旧换新" },
            { order_mold: 3, name: "纯回收" },
          ],
          placeholder: "请选择订单类型",
        },
        {
          key: "entryTime",
          type: "daterange",
          labelName: "查询时间:",
          placeholder: "请选择查询时间",
          valueFormat: "timestamp",
        },
      ],
      tableColumns: [
        { label: "用户姓名", prop: "name" },
        { label: "用户手机", prop: "phone" },
        { label: "优惠劵名称", prop: "coupon_title" },
        { label: "邮寄地址", prop: "address" },
        { label: "用户地址/账号", prop: "user_address" },
        { label: "下单时间", prop: "add_time" },
        { label: "订单类型", prop: "order_mold_str" },
        { label: "订单状态", prop: "order_state_str" },
        { label: "订单审核", prop: "order_examine_str" },
        { slotName: "operation" },
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      order_mold: "",
      phone: "",
      beg_time: "",
      end_time: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    query(data) {
      if (data) {
        this.phone = data.phone;
        this.order_mold = data.order_mold;
        if (data.entryTime === null) {
          (this.beg_time = ""), (this.end_time = "");
        } else {
          this.beg_time = (data.entryTime && data.entryTime[0]) || "";
          this.end_time = (data.entryTime && data.entryTime[1]) || "";
        }
      } else {
        (this.beg_time = ""), (this.end_time = "");
      }
      this.curPage = 1;
      this.getList();
    },
    getList() {
      this.loading = true
      couponOrderlist({
        page: this.curPage,
        limit: 10,
        order_mold: this.order_mold,
        phone: this.phone,
        beg_time: this.beg_time,
        end_time: this.end_time,
      }).then((res) => {
        this.tableData = res.data;
        this.totalElements = res.count;
        this.loading = false
      });
    },
    currentChange(page) {
      this.curPage = page;
      this.getList();
    },
    editInfo(row) {
      this.$router.push({
        path: "/coupon/orderOperation",
        query: { id: row.order_id},
      });
    },
    exportTable() {
      exportOrder({
        order_mold: this.order_mold,
        phone: this.phone,
        beg_time: this.beg_time,
        end_time: this.end_time,
        download: 1,
      }).then((res) => {
        const content = res;
        const blob = new Blob([content]);
        const fileName = "活动订单.xls";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
  },
};
</script>

<style scoped>
.dialog-content p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.close-reason {
  margin-top: 20px;
  text-align: center;
}

.dialog-textarea {
  width: 300px;
  vertical-align: top;
}

.dialog-btns {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 42px;
}

.dialog-btns .cancel {
  margin-right: 20px;
}
::v-deep textarea {
  min-height: 7em !important;
}
</style>
