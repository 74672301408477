<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="carmIpageNum"
      :total="carmItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="批次号" slot="operatAddress" align="center">
        <template slot-scope="{ row }">
          <div class='send_color'>
            <div class="color_1" v-if="row.is_send==0">未印刷</div>
            <div class="color_2" v-else>已印刷</div>
            <div>
              {{row.batch_num}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="已分配商家" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="assignView(row,'0')" type='text' class="mr_sty" :disabled="row.batch_group_num == 0 ? true : false" :class="row.batch_group_num == 0 ? 'StatusBtn3' : 'StatusBtn1'">查看</el-button>
          <el-button @click="assignView(row,'1')" type='text' class="mr_sty" :disabled="row.total==row.group_total ? true : false" :class="row.total==row.group_total ? 'StatusBtn3' : 'StatusBtn2'">分配</el-button>
        </template>
      </el-table-column>
      <el-table-column label="类型" slot="virtually" align="center">
        <template slot-scope="{ row }">
          <div>
           {{ row.virtually===1?'虚拟卡':'实体卡' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="250" label="操作" slot="operating" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleBtn(row, 'export')">导出卡密</el-button>
          <el-button type="text" @click="handleBtn(row, 'view')">查看卡密</el-button>
          <el-button type="text" @click="handleBtn(row, 'cancel')" v-if="row.is_send=='1'">取消标记</el-button>
          <el-button type="text" @click="handleBtn(row, 'print')" v-else>标记印刷</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!--弹框-->
    <DialogTbale @onClose="onClose" :show="deleteVisible" :title="title"
      :columns="DialogTableColumns"
      :data="DialogDataList"
      :currentPage="pageNum"
      :total="total"
      :pageSize="pageSize"
      :width='1280'
      @handleCurrentChange="handleCurrentChangeDialog"
      >
      <div slot="seach" class="top_seach">
        <div>
          <GlobalForm
            :form-item-list="formItemListDia"
            :inline="true"
            round
            @handleConfirm="handleConfirmDia"
            confirmBtnName="查询"
          >
          <el-form-item class="el_from" label="商家名称:" slot="operationSelect">
            <el-select
              v-model="group_id"
              placeholder="请选择"
              clearable
              filterable
              @change="handleChange"
            >
              <el-option
                v-for="item in GroupList"
                :key="item.group_id"
                :label="item.group_name"
                :value="item.group_id"
              ></el-option>
            </el-select>
          </el-form-item>
          </GlobalForm>
        </div>
        <div style="width:140px;height:60px;margin-left:80px">
          合计数量：<span style="color:red">{{this.total}}</span>
        </div>
      </div>
      <el-table-column label="变更后归属商家" slot="operation" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.group_name=='未变更'">{{row.group_name}}</span>
            <span v-else style="color:#FF687B;">{{row.group_name}}</span>
          </template>
      </el-table-column>
    </DialogTbale>

    <!--分配弹框-->
    <DialogTbale @onClose="onClose" :show="visibleAssign" :title="titleAssign"
      :columns="DialogAssignColumns"
      :data="DialogAssignList"
      :currentPage="pageNumAssign"
      :total="totalAssign"
      :pageSize="pageSizeAssign"
      :width='1280'
      @handleCurrentChange="handleCurrentAssignDialog"
      >
      <div slot="seach" class="top_dia">
            <div class="item_top">
              <span>险种名称:</span>
              <span style="color:#FF687B">{{this.card_name_DiaAss}}</span>
            </div>
            <div class="item_top">
              <span>批次号:</span>
              <span style="color:#FF687B">{{this.batch_num_DiaAss}}</span>
            </div>
            <div class="item_top">
              <span>本批次总数量:</span>
              <span style="color:#FF687B">{{this.total_DiaAss}}</span>
            </div>
            <div class="item_top">
              <span>可分配数量:</span>
              <span style="color:#FF687B">{{this.sur_num_DiaAss}}</span>
            </div>
      </div>
      <div slot="seach" class="top_seach">
        <div>
          <GlobalForm
            v-if="this.titleAssign === '分配卡密'"
            :form-item-list="formItemAssignDia"
            :inline="true"
            round
            @handleConfirm="handleAssignDia"
            confirmBtnName="开始分配"
            >
            <el-form-item class="el_from" label="选择商家:" slot="operationSelect">
              <el-select
                filterable
                v-model="group_id"
                placeholder="请输入或选择商家名称查询"
                clearable
                @change="handleChange"
              >
                <el-option
                  v-for="item in GroupList"
                  :key="item.group_id"
                  :label="item.group_name"
                  :value="item.group_id"
                ></el-option>
              </el-select>
            </el-form-item>
          </GlobalForm>
          <div class="title_tabDia">
            <div></div>
            <div>已分配商家</div>
            <div v-if="this.titleAssign === '分配卡密'">如分配失误可点击‘删除’后重新分配</div>
            <div v-else>已经寄送卡密的商家，可点击‘标记寄送</div>
          </div>
          </div>
          <div style="height:110px;color:#999"  v-if="this.titleAssign === '分配卡密'">
            说明: 选择商家，输入数量，点击‘开始分配‘ 系统会自动分配卡号及卡密(按顺序分配)
          </div>
      </div>
      <el-table-column label="商家名称" slot="operationhea" align="center">
          <template slot-scope="{ row }">
              <div class="left_one">
                <div class="left_Dia left_Dia_color1" v-if="row.is_send===0">未寄送</div>
                <div class="left_Dia left_Dia_color2" v-else>已寄送</div>
                <div>{{row.group_name}}</div>
              </div>
          </template>
      </el-table-column>
      <el-table-column label="操作" slot="operation" align="center" v-if="this.titleAssign === '分配卡密'">
          <template slot-scope="{ row }">
            <el-button  type="text" class="btn_delete" @click="deleteAssgin(row)">删除</el-button>
          </template>
      </el-table-column>
      <el-table-column label="操作" slot="operation" align="center" v-else>
          <template slot-scope="{ row }">
            <el-button v-if="row.is_send=='1'" type="text" @click="handleSend(row, 'cancel')">取消标记</el-button>
            <el-button v-else type="text" @click="handleSend(row, 'print')">标记寄送</el-button>
          </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>

<script>
import _api from "@/utils/request";
import environments from '../../../config/url.js'
export default {
  props: ["tableData", "carmItotal","carmIpageNum","merchantType"],
  name: "Table",
  data() {
    return {
      // 查看分配弹窗
      card_name_DiaAss:'',
      batch_num_DiaAss:'',
      total_DiaAss:'',
      sur_num_DiaAss:'',
      assign_Batch_id:'',
      visibleAssign:false,
      titleAssign:'',
      DialogAssignColumns:[
        // {label: '商家名称', prop: 'group_name'},
        {slotName: 'operationhea'},
        {label: '分配数量', prop: 'total'},
        {label: '起始卡号', prop: 'beg_card_num'},
        {label: '截止卡号', prop: 'end_card_num'},
        {slotName: 'operation'}
      ],
      DialogAssignList:[],
      totalAssign:0,
      pageNumAssign:1,
      pageSizeAssign:10,
      formItemAssignDia: [
        { slotName: 'operationSelect' },
        {
          key: 'card_num',
          type: 'input',
          // labelName: '分配卡号:',
          placeholder: '输入分配数量(上限10000)'
        },
      ],
      // 表格
      tableColumns: [
        {slotName: 'operatAddress'},
        { label: "生成总量", prop: "total" },
        { label: "生成时间", prop: "add_time" },
        { label: "起始卡号", prop: "beg_card_num" },
        { label: "截止卡号", prop: "end_card_num" },
        { slotName: "operat" },
        { label: "已分配数量", prop: "group_total" },
        { label: "大类险种", prop: "instype_name" },
        { label: "子险种名称", prop: "card_name" },
        {slotName:'virtually'},
        {slotName:'operating'}
      ],
      //卡密查看表格弹窗
      GroupList:[],
      DiaTotal:'',//合计数量
      formItemListDia: [
        {
          key: 'card_num',
          type: 'input',
          labelName: '卡号:',
          placeholder: '请输入卡号'
        },
        {
          key: 'card_secret',
          type: 'input',
          labelName: '卡密:',
          placeholder: '请输入卡密'
        },
        { slotName: 'operationSelect' },
      ],
      deleteVisible:false,
      batch_id:'',
      card_num:'',
      group_id:'',
      card_secret:'',
      confirmBtn:'',
      total:0,
      pageNum:1,
      pageSize:10,
      DialogDataList:[],
      DialogTableColumns: [
        {label: '卡号', prop: 'card_num'},
        {label: '卡密', prop: 'card_secret'},
        {label: '初始归属商家', prop: 'beg_group_name'},
        {label: '子险种名称', prop: 'card_name'},
        {slotName: 'operation'}
      ],
      title: "",
    };
  },
  mounted(){
    this.selectGroupList()
  },
  watch:{
    visibleAssign(val){
      if(val===false){
        this.group_id = ''
      }
    },
    deleteVisible(val){
      if(val===false){
        this.group_id = ''
      }
    }
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
      console.log(val)
    },
    handleChange(val) {
      this.group_id = val
      console.log(this.group_id)
    },
    // 表格操作
    handleBtn(row, type) {
      this.batch_id=row.batch_id
      console.log("表格操作", type, row);
      if (type === "export"){
        this.$confirm('确认现在导出本批次的卡密数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              _api.downloadCardPool({batch_id:row.batch_id}).then(res => {
                console.log(res)
                const content = res
                const blob = new Blob([content])
                const fileName = '卡密列表.xls'
                if ('download' in document.createElement('a')) {
                    // 非IE下载
                    const elink = document.createElement('a')
                    elink.download = fileName
                    elink.style.display = 'none'
                    elink.href = URL.createObjectURL(blob)
                    document.body.appendChild(elink)
                    elink.click()
                    URL.revokeObjectURL(elink.href) // 释放URL 对象
                    document.body.removeChild(elink)
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, fileName)
                }
            })

        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });
        });
      }else if(type ==='view'){
        this.carmIDataList()
        this.title = `查看卡密-${row.card_name}`
        this.deleteVisible = true
      }else if(type ==='cancel'){
        const params = {batch_id:row.batch_id,is_send:0}
        this.confirmBtn = '确认取消标价?'
        this.cardSend(params)
      }else if(type ==='print'){
        const params = {batch_id:row.batch_id,is_send:1}
        this.confirmBtn = '确认本批次卡密已交付给合作商家?'
        this.cardSend(params)
      }
    },
    //查看分配
    assignView(row,type){
      if(type==='0'){
        this.titleAssign = '查看分配'

      }else{
        this.titleAssign = '分配卡密'
      }
      console.log(row)
      this.visibleAssign = true
      this.assign_Batch_id = row.batch_id
      this.card_name_DiaAss = row.card_name
      this.batch_num_DiaAss = row.batch_num
      this.total_DiaAss = row.total
      this.AssignDataList()
    },
    handleSend(row,type){
      const params = Object
      if(type==='cancel'){
         this.params = {batch_group_id:row.batch_group_id,is_send:0}
      }else{
         this.params = {batch_group_id:row.batch_group_id,is_send:1}
      }
      _api.cardGroupSend(this.params).then(res=>{
        if(res.code===1){
          this.$message.success(res.msg)
          this.AssignDataList()
        }
      })
    },
    cardSend(data){
      this.$confirm(this.confirmBtn, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _api.cardSend(data).then(res=>{
            if(res.code===1){
              this.$message.success(res.msg)
              this.$emit('carmIDataList')
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });
        });
    },
    selectGroupList(){
      _api.selectGroupList().then(res => {
        if(res.code === 1){
          this.GroupList = res.data
        }
      })
    },
    //查看卡密弹窗关闭
    onClose(){
      this.deleteVisible = false
      this.visibleAssign = false
    },
    //查看卡密弹窗列表请求
    carmIDataList(){
      const vo ={
        page:this.pageNum,
        limit:this.pageSize,
        card_num:this.card_num,
        group_id:this.group_id,
        card_secret:this.card_secret,
        batch_id:this.batch_id
      }
      _api.cardPoolList(vo).then(res => {
        if(res.code === 1){
          this.DialogDataList = res.data
          this.total = res.count
        }
      })
    },
    //查看卡密弹窗分页
    handleCurrentChangeDialog(val) {
      this.DialogDataList = []
      this.pageNum = val;
      this.carmIDataList()
    },
    //查看卡密弹窗查询
    handleConfirmDia(data, cd) {
      cd()
      this.card_num = data.card_num
      this.card_secret = data.card_secret
      const vo ={
        page:1,
        limit:10,
        batch_id: this.batch_id,
        card_num:data.card_num,
        group_id:this.group_id,
        card_secret:data.card_secret,
      }
      _api.cardPoolList(vo).then(res => {
        if(res.code === 1){
          this.DialogDataList = res.data
          this.total = res.count
          this.pageNum = 1
        }
      })
    },

    //分配，查看弹窗列表
    AssignDataList(){
      _api.cardBatchGroupList({batch_id:this.assign_Batch_id,limit:this.pageSizeAssign,page:this.pageNumAssign}).then(res => {
        if(res.code === 1){
          this.DialogAssignList = res.data.list
          this.totalAssign = res.count
          this.sur_num_DiaAss = res.data.batch.sur_num
        }
      })
    },
    //分配，查看弹窗分页
    handleCurrentAssignDialog(val) {
      this.DialogAssignList = []
      this.pageNumAssign = val;
      this.AssignDataList()
    },
    //分配，查看弹窗查询
    handleAssignDia(data) {
      if(!this.group_id){
        this.$message.error('请选择商家')
      }else{
        const params = {
          num:data.card_num,
          group_id:this.group_id,
          batch_id:this.assign_Batch_id
        }
        const loading = this.$loading({
            lock: true,
            text: '正在分配卡号及卡密，请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
            _api.addCardBatchGroup(params).then(res=>{
              if(res.code===1){
                loading.close();
                this.$emit('carmIDataList')
                data.card_num=''
                this.$message.success(res.msg)
                this.AssignDataList()
              }else{
                loading.close();
              }
            }).finally(()=>{
              loading.close()
            })
        }, 1000);

      }

    },
    // 删除
    deleteAssgin(row){
      console.log(row)
      this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _api.delCardBatchGroup({batch_group_id:row.batch_group_id}).then(res=>{
            console.log(res,'delete')
            if(res.code===1){
              this.$message.success('删除成功')
              this.AssignDataList()
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });
        });


    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  /deep/ .el-form-item__label{
    color: #111;
  }
  .left_one{
    display: flex;
    align-items: center;
    margin-left: 30px;
    .left_Dia{
      display: inline-block;
      width:40px;
      height: 20px;
      color: white;
      margin-right: 10px;
    }
    .left_Dia_color1{
      background: #0981FF;
    }
    .left_Dia_color2{
      background: #ff8000;
    }
  }

  .title_tabDia{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 30px;
    div{
      margin-left: 10px;
    }
    div:first-child{
      width: 5px;
      height: 20px;
      background: #0981FF;
    }
    div:nth-child(2){
      color: #111;
    }
    div:nth-child(3){
      color: #0981FF;
    }
  }
  .top_dia{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    width: 80%;
    .item_top{
      span:first-child{
        font-family: FZLanTingHei-EB-GBK;
        font-weight: 400;
        color: #111;
      }
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .btn_delete{
    color: #FF687B;
  }
  .top_seach{
    width: 100%;
    display: flex;
    align-items: center;
    /deep/ .el-form-item__label{
      width:80px!important;
    }
  }
  .el-button--mini {
    width: 100px !important;
  }
  .remarkBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .send_color{
    display: flex;
    justify-content: center;
    align-items: center;
    .color_1 {
      margin-right: 5px;
      background: #0981FF;
      color: white;
      width: 40px;
      height: 17px;
      line-height: 18px;
      text-align: center;
    }
    .color_2 {
      margin-right: 5px;
      color: white;
      background: #ff8000;
      width: 40px;
      height: 17px;
      line-height: 18px;
      text-align: center;
    }
  }

  .radioDialog {
    margin-bottom: 15px;
    color: #333333;
  }
  .review_Details{
    height: 80%;
    .DetailsRow{
      margin: 10px;
      span:first-child{
        color: black;
      }
    }
  }
  .StatusBtn1{
    margin-left: 10px;
    color: #0981FF;
    border-bottom: 1px solid #0981FF;
  }
  .StatusBtn2{
    margin-left: 10px;
    color: #FF687B;
    border-bottom: 1px solid #FF687B;
  }
  .StatusBtn3{
    margin-left: 10px;
    color: #999;
    border-bottom: 1px solid #999;
  }
  .mr_sty{
      padding: 3px 2px;
      border-radius: 1px;
  }
  .tableBtn{
    color: #0981ff;
    cursor: pointer;
  }
  .sec_img {
      width: 20vw;
      height: 80vh;
      margin: auto;
      img{
        width: 20vw;
        height: 80vh;
        object-fit: contain;
      }
    }
    .dialog_Reply{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 20px;
      div{
        margin-bottom: 5px;
      }
    }
}
</style>
